import {
  Box,
  Button,
  colors,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { useClipboard } from 'hooks'

import { theme } from 'theme'
import positionSealImage from 'images/position_seal.png'
import stampImage from 'images/selo_site.png'

const WebsiteStamp = ({ tag }: { tag: string }) => {
  const { copy } = useClipboard()

  const handleCopyHtmlStamp = () => copy(tag)

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <MUI.Paper
        variant="outlined"
        $borderTopRightRadius={0}
        $borderTopLeftRadius={0}
      >
        <Grid container spacing={8} py={4} pr={6} pl={2}>
          <Grid item xs={12} lg={5}>
            <img
              src={positionSealImage}
              alt="Identidade visual do canal de comunicação"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} lg={7} textAlign="justify">
            <Typography fontWeight={600} color="primary" gutterBottom>
              Selo dados pessoais protegidos
            </Typography>
            <Typography variant="body2" color="primary" gutterBottom>
              Utilize este código inserindo em seu site para demonstrar que sua
              empresa adotou controles íntegros e permanentes para se manter em
              conformidade com a LGPD.
            </Typography>
            <Typography variant="body2" color="primary">
              Por meio do selo, qualquer interessado é direcionado ao
              portal&nbsp;
              <strong>Privacidade & Você</strong> e pode verificar a
              autenticidade da adequação da empresa à LGPD, inclusive, podendo
              verificar: a última revisão de seus mecanismos de controle com a
              exibição da data e hora em que foi feita a revisão; as políticas
              de privacidade da empresa; o meio de contato dos titulares de
              dados dados; e o meio de contato da ANPD.
            </Typography>
          </Grid>
        </Grid>
      </MUI.Paper>
      <Paper variant="outlined">
        <Box p={4}>
          <Typography color="primary" fontWeight={600}>
            Selo
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" p={4} gap={4}>
          <Box>
            <img src={stampImage} alt="Selo do canal de comunicação" />
          </Box>
          <Box bgcolor={colors.grey[300]} borderRadius={1} p={4}>
            <Typography variant="body2" color={theme.palette.neutral.main}>
              {tag}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCopyHtmlStamp}
            >
              Copiar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default WebsiteStamp
