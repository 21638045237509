import antivirus from './antivirus'
import cyberInsurance from './cyberInsurance'
import dataAnonymization from './dataAnonymization'
import dataDiscovery from './dataDiscovery'
import DLP from './DLP'
import passwordVault from './passwordVault'
import pentest from './pentest'
import electronicsRecycling from './electronicsRecycling'

const services = {
  antivirus,
  cyberInsurance,
  dataAnonymization,
  dataDiscovery,
  DLP,
  passwordVault,
  pentest,
  electronicsRecycling,
}

export default services
