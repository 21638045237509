import { ChipColorType } from 'types/materialUI.types'

import routes from 'constants/routes'

const ACTIVATED_STATUS = 'actived'
const PENDING_STATUS = 'pending'
const CANCELED_STATUS = 'canceled'
const REQUESTED_STATUS = 'requested'

const PAGE_HEADER_CATEGORIES = [
  { name: 'Marketplace', url: routes.marketplace.all },
  { name: 'Meus pedidos', url: routes.marketplace.orders },
]

const STATUSES_IDS = [
  ACTIVATED_STATUS,
  PENDING_STATUS,
  CANCELED_STATUS,
  REQUESTED_STATUS,
] as const

const CHIP_STATUSES_LABEL = {
  [ACTIVATED_STATUS]: 'Ativo',
  [REQUESTED_STATUS]: 'Solicitado',
  [PENDING_STATUS]: 'Em andamento',
  [CANCELED_STATUS]: 'Cancelado',
}

const CHIP_STATUSES_COLOR: Record<OrderStatusType, ChipColorType> = {
  [ACTIVATED_STATUS]: 'success',
  [REQUESTED_STATUS]: 'alert',
  [PENDING_STATUS]: 'info',
  [CANCELED_STATUS]: 'error',
}

export type OrderStatusType = (typeof STATUSES_IDS)[number]

export default {
  PAGE_HEADER_CATEGORIES,
  CHIP_STATUSES_LABEL,
  CHIP_STATUSES_COLOR,
}
