import { useEffect, useState } from 'react'
import { AnimationBox, AnimationImg } from './components'

import { LoadingTrailsType } from './loadingTrails.types'

import { theme } from 'theme'
import DPOEasy from 'components/Svg/DPOEasy'

const LoadingTrails = ({
  open = false,
  variant = 'blue',
}: LoadingTrailsType) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (open) setShow(true)
    else setShow(false)
  }, [open])

  return (
    show && (
      <AnimationBox
        $backgroundColor={
          variant === 'white'
            ? theme.palette.common.white
            : theme.palette.primary.main
        }
        $pointerEvents={!open ? 'none' : 'auto'}
        $opacity={!open ? 0 : ''}
      >
        <AnimationImg width={300}>
          <DPOEasy
            width="100%"
            height={300}
            fill={
              variant === 'white'
                ? theme.palette.disabled.text
                : theme.palette.common.white
            }
          />
        </AnimationImg>
      </AnimationBox>
    )
  )
}

export default LoadingTrails
