import dpoeasy from '../dpoeasy'
import api from 'service/api'
import { AUTH_TOKEN, REGISTRATION_COMPANY_TOKEN } from 'service/constants'

const dponetAPI = api.create('dponet', false)

const login = async (data: object) => {
  const loginResponse = await dponetAPI.post('/authentication', data)

  if (loginResponse.data.authToken) {
    const token = loginResponse.data.authToken
    const cookieToken = loginResponse.data.cookieToken

    setToken(token)
    dpoeasy.auth.login({ cookieToken })
    return loginResponse
  }
  throw 'Erro na autenticação'
}

const transparentLogin = (authToken: string, cookieToken: string) => {
  setToken(authToken)
  dpoeasy.auth.login({ cookieToken })
}

const logout = () => {
  removeToken()
  dpoeasy.auth.logout()
}

const createAccount = async (data: object) =>
  await dponetAPI.post('/register', data)

const recoveryPassword = async (data: object) =>
  await dponetAPI.post('/users/password', { apiUser: data })

const newPassword = async (data: object) =>
  await dponetAPI.put('/users/password', { apiUser: data })

const updatePassword = async (data = {}) => {
  return await dponetAPI.put('/users/update_password', { user: data })
}

const setToken = (token: string) => {
  sessionStorage.setItem(AUTH_TOKEN, token)
}

const getToken = () => {
  return sessionStorage.getItem(AUTH_TOKEN)
}

const removeToken = () => {
  sessionStorage.removeItem(AUTH_TOKEN)
}

const getRegistrationCompanyToken = () => {
  return localStorage.getItem(REGISTRATION_COMPANY_TOKEN)
}

const setRegistrationCompanyToken = (token: string) => {
  return localStorage.setItem(REGISTRATION_COMPANY_TOKEN, token)
}

const removeRegistrationCompanyToken = () => {
  return localStorage.removeItem(REGISTRATION_COMPANY_TOKEN)
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

export default {
  createAccount,
  getRegistrationCompanyToken,
  getToken,
  isAuthenticated,
  login,
  transparentLogin,
  logout,
  newPassword,
  recoveryPassword,
  removeRegistrationCompanyToken,
  setRegistrationCompanyToken,
  setToken,
  updatePassword,
}
