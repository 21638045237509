import securityControlIcon from 'images/marketplace/icons/pentest_white_box.png'
import cryptographyIcon from 'images/marketplace/icons/safe_password_criptografia_avancada.png'
import secureStorageIcon from 'images/marketplace/icons/safe_password_armazenamento_seguro.png'

import securityIcon from 'images/marketplace/icons/safe_password_security.png'
import confidentialityIcon from 'images/marketplace/icons/safe_password_lock.png'
import productivityIcon from 'images/marketplace/icons/pentest_avaliacao_critica.png'

import passwordVaultCover from 'images/marketplace/covers/safe_password_cover.png'

const PAGE_ID = 36

const DESCRIPTION_CARDS = [
  {
    title: 'Acesso simplificado e controle de segurança',
    imgUrl: securityControlIcon,
  },
  {
    title: 'Criptografia avançada',
    imgUrl: cryptographyIcon,
  },
  {
    title: 'Armazenamento seguro',
    imgUrl: secureStorageIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Segurança reforçada',
    description:
      'Elimine riscos associados à perda, esquecimento ou compartilhamento inadequado de senhas.',
    imgUrl: securityIcon,
  },
  {
    title: 'Confidencialidade aprimorada',
    description:
      'Funcionários não têm conhecimento das senhas, impedindo possíveis vazamentos.',
    imgUrl: confidentialityIcon,
  },
  {
    title: 'Produtividade elevada',
    description:
      'Reduza o tempo gasto em recuperação de senhas e resolva problemas de acesso de forma ágil.',
    imgUrl: productivityIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Cofre de senhas',
  description:
    'Cofre de senhas é uma solução avançada para a gestão segura e eficiente de acessos em sua empresa.',
  info: {
    description:
      'Cofre de senhas é um sistema composto por um portal web e software exclusivo, especializado no transporte de senhas criptografadas, eliminando as vulnerabilidades associadas ao gerenciamento tradicional de credenciais.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'O Cofre de senhas é ideal para empresas que buscam aprimorar a segurança e eficiência no gerenciamento de acessos. Nosso público-alvo são organizações que valorizam a proteção de dados, buscam eliminar riscos de invasões e desejam uma solução intuitiva para distribuição segura de credenciais. Transforme a gestão de senhas na sua empresa com a inovação e confiabilidade de um cofre de senhas.',
    highlights: [
      'Segurança reforçada',
      'Gestão eficiente',
      'Confidencialidade aprimorada',
      'Produtividade elevada',
    ],
    imgUrl: passwordVaultCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
