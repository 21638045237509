import { Fragment, useState } from 'react'
import { Button, Typography } from '@mui/material'

import { SupportRequestDialog } from './components'

import { ContactSpecialistType } from './contactSpecialist.types'

const ContactSpecialist = ({
  product,
  isLoadingState,
}: ContactSpecialistType) => {
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  return (
    <Fragment>
      <Typography variant="h5" color="primary" textAlign="center">
        Quer aumentar a segurança de sua empresa e ficou com dúvidas?
      </Typography>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={handleOpenDialog}
      >
        <Typography fontSize="20px !important" fontWeight={600}>
          Fale com os nossos especialistas
        </Typography>
      </Button>
      <SupportRequestDialog
        open={openDialog}
        onClose={handleCloseDialog}
        isLoadingState={isLoadingState}
        serviceCategory={product?.name}
      />
    </Fragment>
  )
}

export default ContactSpecialist
