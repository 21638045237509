import {
  linearProgressClasses,
  LinearProgress as LinearProgressMui,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { TLength } from 'types/materialUI.types'
import { Property } from 'csstype'

import { theme } from 'theme'

const LinearProgress = styled(LinearProgressMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $border?: Property.Border<TLength>
  $width?: Property.Width<TLength>
}>(({ $border, $width }) => ({
  border: $border,
  width: $width,
  minHeight: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 5,
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}))

export default LinearProgress
