import * as yup from 'yup'

const schema = yup.object({
  title: yup.string().required(),
  status: yup.string(),
  content: yup.string().required(),
  category: yup.string().required(),
  allowAutomaticEditing: yup.boolean(),
})

export type ManagePoliciesSchemaType = yup.InferType<typeof schema>

export type ManagePoliciesControlType = keyof ManagePoliciesSchemaType

export default schema
