import { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { SelectableButton, AddInput } from 'components'

import { usePrivacyChannel, useJourney } from 'hooks'

const DataShare = () => {
  const {
    defaultDataShares,
    setDefaultDataShares,
    customDataShares,
    setCustomDataShares,
  } = usePrivacyChannel()
  const { setNextDisabled } = useJourney()

  const handleClickDataShare = (index: number) => {
    const newDataShare = [...defaultDataShares]
    newDataShare[index].active = !newDataShare[index].active
    setDefaultDataShares(newDataShare)
  }

  const handleClickDataShareCustom = (index: number) => {
    const newDataShareCustom = [...customDataShares]
    newDataShareCustom.splice(index, 1)
    setCustomDataShares(newDataShareCustom)
  }

  const addDataShareCustom = (textFieldValue: string) => {
    if (!textFieldValue) return

    const newDataShare = {
      active: true,
      name: textFieldValue,
    }

    setCustomDataShares([...customDataShares, newDataShare])
  }

  const activeCustoms = customDataShares.filter((customDataShare) => {
    return customDataShare.active === true
  })

  useEffect(() => {
    const allDataTreatments = [...defaultDataShares, ...customDataShares]

    const hasActives = allDataTreatments.filter(
      (treatment) => treatment.active === true
    )

    if (hasActives.length === 0) return setNextDisabled(true)

    setNextDisabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDataShares, customDataShares])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          fontWeight={700}
          textAlign="center"
          color="primary"
        >
          Compartilhamento de Dados Pessoais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap={5}>
          <Typography
            variant="h6"
            fontWeight={400}
            textAlign="center"
            color="primary"
            fontFamily="Raleway"
          >
            Selecione todos os tipos de parceiros com os quais você compartilha
            dados pessoais:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {defaultDataShares.map((dataShare, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <SelectableButton
                icon={dataShare.icon}
                name={dataShare.name}
                active={dataShare.active}
                onClick={() => handleClickDataShare(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={400}
          textAlign="center"
          color="primary"
          fontFamily="Raleway"
        >
          Caso compartilhe dados com outros tipos de parceiros, cadastre-os a
          seguir:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {activeCustoms &&
            activeCustoms.map((customDataShares, index) => (
              <Grid item xs={12} lg={4} key={index}>
                <SelectableButton
                  name={customDataShares.name}
                  active={customDataShares.active}
                  onClick={() => {
                    handleClickDataShareCustom(index)
                  }}
                />
              </Grid>
            ))}
          <Grid item xs={12} lg={4}>
            <AddInput onClick={addDataShareCustom} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DataShare
