import { formatters, validates } from 'helpers'

import { TicketMessageType, TicketType } from 'types/ticket.types'

import constants from 'constants/index'

const ticketInfoTableItems = (
  ticket: TicketType,
  ticketMessages: TicketMessageType[]
) => {
  const anpdTicket = ticket?.ticketType === 'anpd'

  const isFinished =
    ticket?.status?.id === constants.ticket.FINISHED_TICKET_ID ||
    ticket?.status?.id === constants.ticket.CANCELED_TICKET_ID

  const optionsTable = [
    {
      title: 'Título da solicitação',
      value: ticket?.title,
      visible: true,
      highlight: true,
    },
    {
      title: ticket?.info?.questionRequest?.title,
      value: ticket?.info?.questionRequest?.answer?.title,
      visible: !anpdTicket && ticket?.info?.questionRequest,
    },
    {
      title: ticket?.info?.questionRequest?.answer?.subOption?.title,
      value: ticket?.info?.questionRequest?.answer?.subOption?.subAnswer,
      visible: !anpdTicket && ticket?.info?.questionRequest?.answer?.subOption,
    },
    {
      title: ticket?.info?.questionRequest?.answer?.specificField?.title,
      value: ticket?.info?.questionRequest?.answer?.specificField?.response,
      visible:
        !anpdTicket && ticket?.info?.questionRequest?.answer?.specificField,
    },
    {
      title: ticket?.info?.questionRelationshipSelf?.title,
      value: ticket?.info?.questionRelationshipSelf?.answer?.title,
      visible: !anpdTicket && ticket?.info?.questionRelationshipSelf,
    },
    {
      title: ticket?.info?.questionRelationshipSelf?.answer?.subOption?.title,
      value:
        ticket?.info?.questionRelationshipSelf?.answer?.subOption?.subAnswer,
      visible:
        !anpdTicket &&
        ticket?.info?.questionRelationshipSelf?.answer?.subOption,
    },
    {
      title:
        ticket?.info?.questionRelationshipSelf?.answer?.specificField?.title,
      value:
        ticket?.info?.questionRelationshipSelf?.answer?.specificField?.response,
      visible:
        !anpdTicket &&
        ticket?.info?.questionRelationshipSelf?.answer?.specificField,
    },
    {
      title: ticket?.info?.questionDemand?.title,
      value: ticket?.info?.questionDemand?.answer?.title,
      visible: !anpdTicket && ticket?.info?.questionDemand,
    },
    {
      title: ticket?.info?.questionDemand?.answer?.subOption?.title,
      value: ticket?.info?.questionDemand?.answer?.subOption?.subAnswer,
      visible: !anpdTicket && ticket?.info?.questionDemand?.answer?.subOption,
    },
    {
      title: ticket?.info?.questionDemand?.answer?.specificField?.title,
      value: ticket?.info?.questionDemand?.answer?.specificField?.response,
      visible:
        !anpdTicket && ticket?.info?.questionDemand?.answer?.specificField,
    },
    {
      title: 'Três primeiros dígitos do CPF',
      value: ticket?.user?.document,
      visible: !anpdTicket,
    },
    {
      title: 'Cargo na ANPD',
      value: ticket?.info?.anpdJob,
      visible: anpdTicket,
    },
    {
      title: 'Solicitante',
      value: [
        ticket?.user?.name && ticket?.user?.name,
        ticket?.user?.email && ticket?.user?.email,
        ticket?.user?.phone && formatters.phoneDynamicMask(ticket?.user?.phone),
      ].filter(Boolean),
      visible: true,
    },
    {
      title: 'Controle de prazos',
      value: [
        ticket?.deadlineDate &&
          `Prazo para atendimento: ${formatters.date.ptBrFormatDate(
            ticket?.deadlineDate
          )}`,
        ticket?.createdAt &&
          `Data de criação: ${formatters.date.ptBrFormatDateTime(
            ticket?.createdAt
          )}`,
        ticket?.confirmedAt &&
          `Data de confirmação: ${formatters.date.ptBrFormatDateTime(
            ticket?.confirmedAt
          )}`,
        isFinished
          ? `Data de resolução: ${
              validates.ticket.resolutionDate(ticket, ticketMessages) ||
              'Não possui data definida'
            }`
          : '',
      ].filter(Boolean),
      visible: true,
    },
  ]

  return optionsTable.filter((option) => option.visible)
}

export default ticketInfoTableItems
