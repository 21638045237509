import { useEffect } from 'react'
import { cloneDeep, every, isEmpty, isObject, map } from 'lodash'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material'

import { MUI } from 'components'

import { FiltersItemsType } from './filtersItems.types'
import { CheckedType, SubCheckedType } from 'types/helpers.types'
import { CompanyServicesType } from 'types/marketplace/categories.types'

const FiltersItems = ({ categories, checkedState }: FiltersItemsType) => {
  const { checked, setChecked } = checkedState

  const checkAllFields = (
    items: CompanyServicesType[],
    unchecked = false
  ): boolean => {
    return every(items, (service) => {
      const status = checked[service.id]

      if (isObject(status)) {
        return every(status, (value) => (unchecked ? !value : value))
      } else {
        return unchecked ? !status : status
      }
    })
  }

  const checkAllSubFields = (
    companyService: CompanyServicesType,
    unchecked = false
  ): boolean => {
    if (!checked[companyService?.id]) return false

    return every(companyService?.dependentsCompanyServices, (service) => {
      const isChecked = (checked[companyService?.id] as CheckedType)?.[
        service?.id
      ]
      return unchecked ? !isChecked : isChecked
    })
  }

  const handleCheckedChange = (id: number, subId?: number) => {
    setChecked((prevChecked) => {
      if (subId) {
        return {
          ...prevChecked,
          [id]: {
            ...((prevChecked[id] || {}) as SubCheckedType),
            [subId]: !((prevChecked[id] as CheckedType)?.[subId] ?? false),
          },
        }
      } else {
        return {
          ...prevChecked,
          [id]: !(prevChecked[id] ?? false),
        }
      }
    })
  }

  const isCategoryIndeterminate = (categoryId: number): boolean => {
    const category = categories?.find((cat) => cat?.id === categoryId)
    if (!category) return false

    const allChecked = checkAllFields(category?.companyServices)
    const allUnchecked = checkAllFields(category?.companyServices, true)

    return !allChecked && !allUnchecked
  }

  const isSubCatIndeterminate = (companyService: CompanyServicesType) => {
    const allChecked = checkAllSubFields(companyService)
    const allUnchecked = checkAllSubFields(companyService, true)

    return !allChecked && !allUnchecked
  }

  const isCategoryChecked = (categoryId: number): boolean => {
    const category = categories?.find((cat) => cat.id === categoryId)

    const companyServices = category?.companyServices || []
    return companyServices.every((service) => checked[service.id])
  }

  const isSubCategoryChecked = (companyService: CompanyServicesType) => {
    return companyService?.dependentsCompanyServices?.every(
      (service) => (checked[companyService?.id] as CheckedType)?.[service?.id]
    )
  }

  const handleCategoryChange = (categoryId: number) => {
    const category = categories?.find((cat) => cat?.id === categoryId)

    const companyServices = category?.companyServices || []
    const updatedChecked = cloneDeep(checked)

    const allChecked = checkAllFields(companyServices)

    const allDependentsChecked = companyServices.every((companyService) => {
      const dependents = companyService?.dependentsCompanyServices || []
      const values = map(
        dependents,
        (service) =>
          (updatedChecked[companyService?.id] as CheckedType)?.[service?.id]
      )
      return every(values, (value) => value === values[0])
    })

    if (allDependentsChecked) {
      map(companyServices, (service) => {
        updatedChecked[service?.id] = !allChecked
      })
    }

    map(companyServices, (companyService) => {
      const dependents = companyService?.dependentsCompanyServices || []

      if (!isEmpty(dependents)) {
        if (
          !updatedChecked[companyService.id] ||
          !isObject(updatedChecked[companyService.id])
        )
          updatedChecked[companyService.id] = {}

        map(
          dependents,
          (service) =>
            ((updatedChecked[companyService?.id] as CheckedType)[service?.id] =
              !allChecked)
        )
      }
    })

    setChecked(updatedChecked)
  }

  const handleSubCategoryChange = (companyService: CompanyServicesType) => {
    const dependents = companyService.dependentsCompanyServices || []

    const allChecked = every(
      dependents,
      (service) => (checked[companyService?.id] as CheckedType)?.[service.id]
    )

    const updatedChecked = cloneDeep(checked)

    map(dependents, (service) => {
      updatedChecked[companyService?.id] = {
        ...(updatedChecked[companyService?.id] as SubCheckedType),
        [service.id]: !allChecked,
      }
    })

    setChecked(updatedChecked)
  }

  const isSubChildrenChecked = (id: number, subId: number): boolean => {
    return !!(checked[id] as CheckedType)?.[subId] ?? false
  }

  const initializeCheckedState = () => {
    const initialState: CheckedType = {}
    map(categories, (category) => {
      map(category?.companyServices, (service) => {
        if (!isEmpty(service?.dependentsCompanyServices)) {
          initialState[service?.id] = {}
          map(
            service?.dependentsCompanyServices,
            (subService) =>
              ((initialState[service?.id] as CheckedType)[subService?.id] =
                false)
          )
        } else {
          initialState[service?.id] = false
        }
      })
    })
    return initialState
  }

  useEffect(() => {
    if (isEmpty(checked)) setChecked(initializeCheckedState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  return (
    <Box>
      {categories?.map((category) => (
        <MUI.Accordion
          variant="outlined"
          $border={0}
          className="marketplace-custom-accordion"
        >
          <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />} $padding={0}>
            <FormControlLabel
              label={category?.name}
              control={
                <Checkbox
                  checked={isCategoryChecked(category.id)}
                  indeterminate={isCategoryIndeterminate(category.id)}
                  onChange={() => handleCategoryChange(category.id)}
                />
              }
            />
          </MUI.AccordionSummary>
          <AccordionDetails>
            <Box ml={3} display="flex" flexDirection="column">
              {category?.companyServices?.map((companyService) => {
                if (!isEmpty(companyService?.dependentsCompanyServices))
                  return (
                    <MUI.Accordion variant="outlined" $border={0}>
                      <MUI.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        $padding={0}
                      >
                        <FormControlLabel
                          label={companyService?.name}
                          control={
                            <Checkbox
                              checked={isSubCategoryChecked(companyService)}
                              indeterminate={isSubCatIndeterminate(
                                companyService
                              )}
                              onChange={() =>
                                handleSubCategoryChange(companyService)
                              }
                            />
                          }
                        />
                      </MUI.AccordionSummary>
                      <AccordionDetails>
                        <Box ml={3} display="flex" flexDirection="column">
                          {companyService?.dependentsCompanyServices?.map(
                            (dependentCompanyService) => (
                              <FormControlLabel
                                label={dependentCompanyService?.name}
                                control={
                                  <Checkbox
                                    checked={isSubChildrenChecked(
                                      companyService.id,
                                      dependentCompanyService.id
                                    )}
                                    onChange={() =>
                                      handleCheckedChange(
                                        companyService.id,
                                        dependentCompanyService.id
                                      )
                                    }
                                  />
                                }
                              />
                            )
                          )}
                        </Box>
                      </AccordionDetails>
                    </MUI.Accordion>
                  )
                return (
                  <FormControlLabel
                    label={companyService?.name}
                    control={
                      <Checkbox
                        checked={!!checked[companyService?.id]}
                        onChange={() => handleCheckedChange(companyService?.id)}
                      />
                    }
                  />
                )
              })}
            </Box>
          </AccordionDetails>
        </MUI.Accordion>
      ))}
    </Box>
  )
}

export default FiltersItems
