import { Box } from '@mui/material'

import { Body, BodyGridStructure } from './components'
import { Header } from 'layouts/components'

import { useDrawer } from 'hooks'

import { NodeChildren } from 'types/global.types'

const Navigation = ({ children }: NodeChildren) => {
  const { handleActionSwipeDrawer, isSwipeableDrawer } = useDrawer()

  return (
    <>
      <Box gridArea="header">
        <Header
          handleActionSwipeDrawer={handleActionSwipeDrawer}
          isSwipeableDrawer={isSwipeableDrawer}
          color="primary"
          maxWidth={false}
        />
      </Box>
      <BodyGridStructure gridArea="body">
        <Body>{children}</Body>
      </BodyGridStructure>
    </>
  )
}

export default Navigation
