import { Box, Typography } from '@mui/material'

import { MUI } from 'components'

import { formatters, validates } from 'helpers'

import { useMediaQuery } from 'hooks'

import { PocketCardImageType } from './pocketCardImage.types'

import { theme } from 'theme'
import cardStamp from 'images/cartao_selo_maturidade.png'

const PocketCardImage = ({
  id = 'pocket-card-image',
  company,
  qrCode,
}: PocketCardImageType) => {
  const mediaQuery = useMediaQuery()
  const adaptTextResponse = validates.serviceChannel.adaptTextResponse

  const lgDown = mediaQuery.down.lg
  const smDown = mediaQuery.down.sm

  const titleFontSize = adaptTextResponse({
    smResponse: smDown,
    mdResponse: lgDown,
  })

  const subTitleFontSize = adaptTextResponse({
    smResponse: smDown,
    mdResponse: lgDown,
    smText: 6,
    mdText: 10,
    lgText: 14,
  })

  const dateFontSize = adaptTextResponse({
    smResponse: smDown,
    mdResponse: lgDown,
    smText: 10,
    mdText: 18,
    lgText: 25,
  })

  return (
    <MUI.Box
      id={id}
      position="relative"
      $backgroundImage={`url(${cardStamp})`}
      $backgroundSize="cover"
      height={smDown ? 148 : lgDown ? 266 : 399}
      width={smDown ? 234 : lgDown ? 422 : 632}
      color={theme.palette.common.white}
    >
      <Box
        position="absolute"
        left={smDown ? 41 : lgDown ? 74 : 110}
        bottom={smDown ? 7 : lgDown ? 13 : 20}
      >
        <img
          src={qrCode}
          width={smDown ? 33 : lgDown ? 60 : 90}
          alt="QR Code do cartão do canal de comunicação"
        />
      </Box>
      <Box
        position="absolute"
        left={smDown ? 46 : lgDown ? 83 : 125}
        bottom={smDown ? 74 : lgDown ? 133 : 200}
      >
        <Typography fontFamily="Raleway" variant="h6" fontSize={dateFontSize}>
          {formatters.date.currentYear()}
        </Typography>
      </Box>
      <Box
        position="absolute"
        right={smDown ? 7 : lgDown ? 13 : 20}
        top={smDown ? 44 : lgDown ? 80 : 120}
        height="100%"
        textAlign="center"
        width={smDown ? 111 : lgDown ? 200 : 300}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            gutterBottom
            fontWeight={600}
            fontSize={titleFontSize}
            fontFamily="Roboto"
          >
            {(company?.name ?? 'Não informado').toUpperCase()}
          </Typography>
          <Typography
            variant="body2"
            fontSize={subTitleFontSize}
            fontFamily="Roboto"
          >
            CNPJ:&nbsp;
            {company?.cnpj
              ? formatters.cnpj.mask(company?.cnpj)
              : 'Não informado'.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </MUI.Box>
  )
}

export default PocketCardImage
