import {
  EmailStamp,
  PocketCardStamp,
  PrintingStamp,
  SocialMediaStamp,
  WebsiteStamp,
} from 'views/ServiceChannel/components'

import routes from './routes'

const GENERAL_STAMPS_ID = 'general'
const EMAIL_SIGNATURE_STAMPS_ID = 'emailSignature'
const SOCIAL_MEDIA_STAMPS_ID = 'socialMedia'
const PRINT_STAMPS_ID = 'print'
const CARD_STAMPS_ID = 'card'

const POCKET_CARD_STAMP_ORIGINAL_SIZE_ID = 'original'
const POCKET_CARD_STAMP_REDUCED_SIZE_ID = 'reduced'
const POCKET_CARD_STAMP_INCREASED_SIZE_ID = 'increased'

const POCKET_CARD_STAMP_ORIGINAL_SIZE_LABEL = 'Original'
const POCKET_CARD_STAMP_REDUCED_SIZE_LABEL = 'Reduzido'
const POCKET_CARD_STAMP_INCREASED_SIZE_LABEL = 'Aumentado'

const POCKET_CARD_STAMP_SIZE_IDS = [
  POCKET_CARD_STAMP_ORIGINAL_SIZE_ID,
  POCKET_CARD_STAMP_REDUCED_SIZE_ID,
  POCKET_CARD_STAMP_INCREASED_SIZE_ID,
] as const

const STAMPS_ITEMS_IDS = [
  GENERAL_STAMPS_ID,
  EMAIL_SIGNATURE_STAMPS_ID,
  SOCIAL_MEDIA_STAMPS_ID,
  PRINT_STAMPS_ID,
  CARD_STAMPS_ID,
] as const

const AUTOMATIC_RESPONSE_PAGE_HEADER_CATEGORIES = [
  { name: 'Canal de atendimento' },
  {
    name: 'Respostas automáticas',
    url: routes.serviceChannel.automaticAnswer,
  },
]

const STAMPS_PAGE_HEADER_CATEGORIES = [
  { name: 'Canal de atendimento' },
  { name: 'Meios de comunicação', url: routes.serviceChannel.stamps },
]

const STAMPS_ITEMS_TABS = [
  { id: GENERAL_STAMPS_ID, tabName: 'Para sites' },
  { id: EMAIL_SIGNATURE_STAMPS_ID, tabName: 'Para e-mail' },
  { id: SOCIAL_MEDIA_STAMPS_ID, tabName: 'Para redes sociais' },
  { id: PRINT_STAMPS_ID, tabName: 'Para impressão' },
  { id: CARD_STAMPS_ID, tabName: 'Cartão' },
]

const STAMPS_CONTENT_TABS = {
  [GENERAL_STAMPS_ID]: WebsiteStamp,
  [EMAIL_SIGNATURE_STAMPS_ID]: EmailStamp,
  [SOCIAL_MEDIA_STAMPS_ID]: SocialMediaStamp,
  [PRINT_STAMPS_ID]: PrintingStamp,
  [CARD_STAMPS_ID]: PocketCardStamp,
}

const POCKET_CARD_STAMP_SIZE_INPUT_ITEMS = [
  {
    value: POCKET_CARD_STAMP_ORIGINAL_SIZE_ID,
    name: POCKET_CARD_STAMP_ORIGINAL_SIZE_LABEL,
  },
  {
    value: POCKET_CARD_STAMP_REDUCED_SIZE_ID,
    name: POCKET_CARD_STAMP_REDUCED_SIZE_LABEL,
  },
  {
    value: POCKET_CARD_STAMP_INCREASED_SIZE_ID,
    name: POCKET_CARD_STAMP_INCREASED_SIZE_LABEL,
  },
]

const POCKET_CARD_STAMP_SIZE_ITEMS = {
  [POCKET_CARD_STAMP_ORIGINAL_SIZE_ID]: {
    width: 1011,
    height: 638,
    name: POCKET_CARD_STAMP_ORIGINAL_SIZE_LABEL,
  },
  [POCKET_CARD_STAMP_REDUCED_SIZE_ID]: {
    width: 506,
    height: 319,
    name: POCKET_CARD_STAMP_REDUCED_SIZE_LABEL,
  },
  [POCKET_CARD_STAMP_INCREASED_SIZE_ID]: {
    width: 2022,
    height: 1276,
    name: POCKET_CARD_STAMP_INCREASED_SIZE_LABEL,
  },
}

export type AutomaticAnswerMenuItemsObjectType = {
  id: number
  title: string
  automaticAnswer: string
  automaticAnswerEnabled: boolean
}

export type StampItemTabsType = (typeof STAMPS_ITEMS_IDS)[number]
export type PocketCardStampSizeType =
  (typeof POCKET_CARD_STAMP_SIZE_IDS)[number]

export default {
  AUTOMATIC_RESPONSE_PAGE_HEADER_CATEGORIES,
  GENERAL_STAMPS_ID,
  POCKET_CARD_STAMP_ORIGINAL_SIZE_ID,
  POCKET_CARD_STAMP_SIZE_INPUT_ITEMS,
  POCKET_CARD_STAMP_SIZE_ITEMS,
  STAMPS_CONTENT_TABS,
  STAMPS_ITEMS_TABS,
  STAMPS_PAGE_HEADER_CATEGORIES,
}
