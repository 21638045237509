import { KindReadingType } from 'constants/consent'

import constants from 'constants/index'

const readingTypeTranslation = (kind?: KindReadingType) => {
  return (
    (kind && constants.consent.READING_TYPE_TRANSLATION[kind]) || 'indeferido'
  )
}

const chipStatusColor = (kind?: KindReadingType) => {
  return (kind && constants.consent.READING_TYPE_CHIP_COLOR[kind]) || 'default'
}

export default {
  readingTypeTranslation,
  chipStatusColor,
}
