import { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, colors, Grid, Typography } from '@mui/material'

import {
  LoadingFeedback,
  MUI,
  PageHead,
  StandardPageStructure,
} from 'components'
import {
  TicketHead,
  TicketInfoTable,
  TicketMessage,
  TicketTextBox,
} from './components'

import { useAuth, useFetch, useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import {
  SolicitationLogsType,
  TicketMessageType,
  TicketType,
} from 'types/ticket.types'

import schema, { TicketContentSchemaType } from './schema'
import service from 'service'
import constants from 'constants/index'

import logoNotFound from 'images/not-found-ticket.svg'

const TicketContent = () => {
  const { ticketId } = useParams()
  const { snackbar } = useSnackbar()
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  const [statusButtonGroup, setStatusButtonGroup] = useState<
    number | undefined
  >()

  const { user } = useAuth()

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TicketContentSchemaType>({ resolver: yupResolver(schema) })

  const {
    response,
    loading,
    refresh: refreshTicket,
  } = useFetch(service.dponet.ticket.get, { ticketId }, [])

  const { response: responseLogs, refresh: refreshLogs } = useFetch(
    service.dponet.ticket.logs,
    { ticketId },
    []
  )

  const {
    response: responseMessages,
    loading: loadingMessages,
    refresh: refreshMessages,
  } = useFetch(service.dponet.ticket.getMessages, { ticketId }, [])

  const ticket: TicketType = response?.data?.ticket

  const ticketMessages: TicketMessageType[] =
    responseMessages?.data?.ticketMessages ?? []

  const solicitationLogs: SolicitationLogsType[] =
    responseLogs?.data?.dponetAudits

  const isMessageAvailable =
    (!loadingMessages && !loading && !isEmpty(ticketMessages)) ||
    ticket?.content

  useEffect(() => {
    if (isEmpty(statusButtonGroup)) setStatusButtonGroup(ticket?.status?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  const refresh = () => {
    refreshTicket()
    refreshMessages()
    refreshLogs()
  }

  const onSubmit = async (data: TicketContentSchemaType) => {
    try {
      const postMessageResponse = await service?.dponet?.ticket?.postMessage({
        ticketId,
        ticketMessage: {
          content: data?.content,
          attachments: Object.values(selectedFiles || {}),
        },
      })

      if (statusButtonGroup !== ticket?.status?.id) {
        await service?.dponet?.ticket.update({
          ticketId,
          ticket: {
            ticketMessageId: postMessageResponse?.data?.ticketMessage?.id,
            statusId: statusButtonGroup,
          },
        })
      }

      snackbar.open({
        message: 'Mensagem enviada com sucesso!',
        variant: 'success',
      })

      reset({})
      refresh()

      setSelectedFiles([])
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading || loadingMessages} />
      <PageHead
        name="Detalhes da solicitação"
        categories={constants.ticket.TICKET_CONTENT_PAGE_HEADER_CATEGORIES}
        actionButton={
          <TicketHead
            ticket={ticket}
            ticketMessages={ticketMessages}
            logs={solicitationLogs}
            loadingLogs={false}
          />
        }
      />
      <Grid container spacing={6}>
        {/* <Grid item xs={12}>
          <TicketHead
            ticket={ticket}
            ticketMessages={ticketMessages}
            logs={solicitationLogs}
            loadingLogs={false}
          />
        </Grid> */}
        <Grid item xs={12} lg={8.5}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            minHeight={isMessageAvailable ? 800 : 400}
            gap={4}
          >
            <PerfectScrollbar>
              <Box height="100%">
                {isMessageAvailable ? (
                  <TicketMessage
                    ticket={ticket}
                    ticketMessages={ticketMessages}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    gap={5}
                    justifyContent="center"
                    height="100%"
                  >
                    <MUI.Img $maxHeight={150} height="35%" src={logoNotFound} />
                    <Typography
                      variant="h5"
                      fontWeight="normal"
                      color={colors.grey[500]}
                    >
                      Nenhuma interação neste ticket
                    </Typography>
                  </Box>
                )}
              </Box>
            </PerfectScrollbar>
            {!loading && (
              <form onSubmit={handleSubmit(onSubmit)} id="ticket-content-form">
                <TicketTextBox
                  refresh={refresh}
                  ticket={ticket}
                  useForm={{ control, errors, register }}
                  user={user}
                  useStates={{ selectedFiles, statusButtonGroup }}
                  setStates={{
                    setSelectedFiles,
                    setStatusButtonGroup,
                  }}
                />
              </form>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={3.5}>
          <TicketInfoTable ticket={ticket} ticketMessages={ticketMessages} />
        </Grid>
      </Grid>
    </StandardPageStructure>
  )
}

export default TicketContent
