import answerOptionTicket from './answerOptionTicket'
import auth from './auth'
import company from './company'
import companyMaturity from './companyMaturity'
import financialPanel from './financialPanel'
import integrationPartner from './integrationPartner'
import octadeskIntermediator from './octadeskIntermediator'
import payment from './payment'
import plan from './plan'
import preRegistration from './preRegistration'
import privacyPortal from './privacyPortal'
import segment from './segment'
import ticket from './ticket'
import user from './user'
import userCompany from './userCompany'

export default {
  answerOptionTicket,
  auth,
  company,
  companyMaturity,
  integrationPartner,
  financialPanel,
  octadeskIntermediator,
  payment,
  plan,
  preRegistration,
  privacyPortal,
  segment,
  ticket,
  user,
  userCompany,
}
