import { Info as InfoIcon } from 'react-feather'
import { Box, Typography } from '@mui/material'

import { BasicDialog } from 'components'
import CookieBannerCode from '../CookieBannerCode'

import { SourceCodeDialogType } from './sourceCodeDialog.types'

import { theme } from 'theme'

const SourceCodeDialog = ({
  open,
  onClose,
  bannerToken,
}: SourceCodeDialogType) => {
  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Como instalar no Código Fonte"
      maxWidth="md"
    >
      <Box display="flex" flexDirection="column" gap={8}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography color={theme.palette.neutral.main} fontWeight={700}>
            Instalação direta no Código Fonte:
          </Typography>
          <Box
            p={4}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            alignItems="center"
            gap={4}
          >
            <Box>
              <InfoIcon color={theme.palette.primary.main} />
            </Box>
            <Typography color="primary" mt={1}>
              É necessário você ou um especialista instalar o código HTML em seu
              site.
            </Typography>
          </Box>
          <Typography color={theme.palette.neutral.main} variant="body2">
            Vá até a seção de&nbsp;
            <strong>
              Instalação de Cookie e copie o código conforme mostrado abaixo.
            </strong>
          </Typography>
        </Box>
        <CookieBannerCode bannerToken={bannerToken} />
        <Box>
          <Typography variant="body2" color={theme.palette.neutral.main}>
            Agora, cole no cabeçalho/header do seu site.
          </Typography>
          <Typography variant="body2" color={theme.palette.neutral.main}>
            <strong>
              Lembre-se de salvar e publicar a alteração no seu site.
            </strong>
          </Typography>
        </Box>
      </Box>
    </BasicDialog>
  )
}

export default SourceCodeDialog
