import PlanSection from '../PlanSection'
import { BasicDrawer } from 'components'

import { PlanDrawerType } from './planDrawer.types'

const PlanDrawer = ({ open, onClose }: PlanDrawerType) => {
  return (
    <BasicDrawer
      open={open}
      onClose={onClose}
      title="Escolha o seu plano"
      fullScreen
    >
      <PlanSection />
    </BasicDrawer>
  )
}

export default PlanDrawer
