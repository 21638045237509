import PerfectScrollbar from 'react-perfect-scrollbar'
import { visuallyHidden } from '@mui/utils'
import { Box, Radio, Tooltip, Typography } from '@mui/material'

import { MUI } from 'components'

import { PlanCardType } from './planCard.types'

import { theme } from 'theme'

const PlanCard = ({ card, field, inputRef }: PlanCardType) => {
  const { value, onChange } = field

  const Logo = card?.logo
  const selected = value === card?.radioValue

  const handleClick = () => {
    onChange(card?.radioValue)
    inputRef?.current?.focus()
  }

  return (
    <Tooltip title={card?.disable && 'Você já possui este plano'}>
      <MUI.Card
        variant="outlined"
        $border={selected ? `1px solid ${theme.palette.primary.main}` : ''}
        $backgroundColor={
          card?.disable ? theme.palette.disabled.background : ''
        }
        $transition="border-color 0.5s ease"
        $height="100%"
      >
        <MUI.CardActionArea
          disabled={card?.disable}
          onClick={handleClick}
          $height="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            gap={8}
            py={8}
            pl={8}
            pr={3}
          >
            <Box>
              <Logo
                fill={
                  card?.disable
                    ? theme.palette.disabled.text
                    : theme.palette.primary.main
                }
              />
            </Box>
            <PerfectScrollbar>
              <Box display="flex" flexDirection="column" gap={2} pr={5}>
                {card?.content?.map((item, index) => {
                  const Icon = item?.icon
                  const typographyColor =
                    item?.type === 'conceived'
                      ? 'primary'
                      : theme.palette.neutral.main
                  const fontWeight = item?.type === 'conceived' ? 600 : 400

                  return (
                    <Box key={index}>
                      <Box display="flex" gap={2} alignItems="center">
                        <Icon
                          size={20}
                          color={
                            card?.disable
                              ? theme.palette.disabled.text
                              : item?.type === 'conceived'
                                ? theme.palette.success.main
                                : theme.palette.error.main
                          }
                        />
                        <Typography
                          mt={0.5}
                          color={
                            card?.disable
                              ? theme.palette.disabled.text
                              : typographyColor
                          }
                          fontWeight={fontWeight}
                        >
                          {item?.benefit}
                        </Typography>
                      </Box>
                      {item?.descriptions?.map((description, index) => (
                        <MUI.UL key={index} $margin={0}>
                          <Typography
                            component="li"
                            color={
                              card?.disable
                                ? theme.palette.disabled.text
                                : typographyColor
                            }
                          >
                            {description}
                          </Typography>
                        </MUI.UL>
                      ))}
                    </Box>
                  )
                })}
              </Box>
            </PerfectScrollbar>
          </Box>
          <Radio style={visuallyHidden} value={card?.radioValue} />
        </MUI.CardActionArea>
      </MUI.Card>
    </Tooltip>
  )
}

export default PlanCard
