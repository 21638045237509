import { Box, Typography } from '@mui/material'

import { TicketMessageNotificationType } from './ticketMessageNotification.types'

import { formatters } from 'helpers'

import { theme } from 'theme'

const TicketMessageNotification = ({
  interaction,
}: TicketMessageNotificationType) => {
  return (
    <Box
      bgcolor={theme.palette.neutral.main}
      p={1}
      px={3}
      borderRadius={4}
      display="flex"
      maxWidth="max-content"
    >
      <Typography variant="body2" color="white" width="auto">
        {`${interaction?.content} - `}
        {formatters.date.ptBrFormatDateTime(interaction?.createdAt)}
      </Typography>
    </Box>
  )
}

export default TicketMessageNotification
