import { useFormContext } from 'react-hook-form'
import { Box, Paper } from '@mui/material'

import { MUI, Svg } from 'components'

import { fileControl } from 'helpers'

import {
  BannerConfigurationSchemaType,
  CookieBannerType,
} from 'types/banner.types'

const LogoPreview = ({ banner }: { banner?: CookieBannerType }) => {
  const { watch } = useFormContext<BannerConfigurationSchemaType>()

  const selectedImage = watch('logo')

  const logo = fileControl.handleImageChange(selectedImage, banner?.logo?.url)

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper variant="outlined" component={Box} width="fit-content" p={4}>
        {selectedImage || banner?.logo?.url ? (
          <MUI.Img src={logo} alt="Logotipo do banner" height={70} />
        ) : (
          <Svg.DPOEasy />
        )}
      </Paper>
    </Box>
  )
}

export default LogoPreview
