import { useAuth } from 'hooks'
import {
  DialogAnswerMaturity,
  DialogCookiesError,
  DialogServiceChannel,
} from '../'

const HomeDialogs = () => {
  const { loaded, bannerConfigured, companyMaturity } = useAuth()
  const journeyConcluded = !!companyMaturity?.privacyChannelConfigured

  if (!loaded) return <></>

  if (!journeyConcluded) {
    return <DialogServiceChannel />
  }

  if (!bannerConfigured) {
    return <DialogCookiesError />
  }

  return <DialogAnswerMaturity />
}

export default HomeDialogs
