import { useState } from 'react'
import { Snackbar, Alert } from '@mui/material'

import { SnackbarContentType } from './snackbarProvider.types'
import { NodeChildren } from 'types/global.types'

import { SnackbarContext } from 'contexts'

const SnackbarProvider = ({ children }: NodeChildren) => {
  const initialSnackbar: SnackbarContentType = {
    visible: false,
    message: '',
    variant: 'error',
    vertical: 'bottom',
    horizontal: 'left',
  }

  const [snackbar, setSnackbar] = useState<SnackbarContentType>(initialSnackbar)

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, visible: false })
  }

  const handleSnackbarOpen = (params: SnackbarContentType) => {
    setSnackbar({ ...initialSnackbar, ...params, visible: true })
  }

  return (
    <SnackbarContext.Provider
      value={{ snackbar: { open: handleSnackbarOpen } }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: snackbar.vertical || 'bottom',
          horizontal: snackbar.horizontal || 'left',
        }}
        open={snackbar.visible}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert severity={snackbar.variant}>{snackbar.message}</Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
