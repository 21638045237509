import { SVGType } from 'types/global.types'

import { theme } from 'theme'

const DPOnet = ({
  alt = 'Logo da DPOnet',
  fill = theme.palette.primary.main,
  height = 70,
  viewBox = '0 0 767.11 244.97', // Valor total da imagem
  width = 183.22,
}: SVGType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <g>
        <g>
          <ellipse
            className="st0"
            cx="109.97"
            cy="122.48"
            rx="7.73"
            ry="7.69"
          />
          <path
            className="st0"
            d="M204.38,54.25L122.07,6.97c-7.49-4.3-16.71-4.3-24.2,0L15.55,54.25C8,58.58,3.35,66.61,3.35,75.31v94.34
			c0,8.7,4.65,16.73,12.19,21.07L97.87,238c7.49,4.3,16.71,4.3,24.2,0l82.32-47.28c7.54-4.33,12.19-12.37,12.19-21.07V75.31
			C216.58,66.61,211.93,58.58,204.38,54.25z M77.98,119.62v82.35c0,0.06-0.07,0.1-0.12,0.07c-8.11-3.37-15.43-7.46-21.62-12.56
			v-64.53c-0.04-0.82-0.06-1.64-0.06-2.47c0-0.86,0.02-1.7,0.06-2.55v-0.31h0.01c1.5-28.32,25.14-50.8,54.02-50.64
			c29.64,0.17,53.74,24.54,53.46,54.02c-0.28,29.31-24.25,52.98-53.78,52.98c-7.62,0-14.86-1.58-21.43-4.42V146.4
			c5.69,5.05,13.2,8.12,21.43,8.12c17.96,0,32.48-14.62,32.21-32.55c-0.26-16.9-13.93-30.85-30.9-31.51
			c-17.4-0.68-31.89,12.38-33.38,29.15H77.98z M88.54,122.48c0-11.77,9.59-21.31,21.43-21.31s21.43,9.54,21.43,21.31
			c0,11.77-9.59,21.31-21.43,21.31S88.54,134.25,88.54,122.48z M109.97,208.21c-7.4,0-14.57-0.93-21.43-2.68v-22.59
			c6.7,2.35,13.91,3.64,21.43,3.64c35.59,0,64.43-28.7,64.43-64.1c0-35.4-28.85-64.1-64.43-64.1c-35.59,0-64.43,28.7-64.43,64.1
			v56.91c-13.52-15.14-21.74-35.07-21.74-56.91c0-47.34,38.58-85.72,86.17-85.72s86.17,38.38,86.17,85.72
			S157.56,208.21,109.97,208.21z"
            fill={fill}
          />
        </g>
        <g>
          <path
            className="st0"
            d="M680.06,188.67h-8.65v-30.66c0-11.41-4.19-17.11-12.56-17.11c-4.33,0-7.91,1.62-10.74,4.86
			c-2.83,3.24-4.25,7.32-4.25,12.26v30.66h-8.65v-53.76h8.65v8.93h0.21c4.08-6.79,9.99-10.18,17.73-10.18
			c5.91,0,10.43,1.9,13.56,5.7c3.13,3.8,4.7,9.28,4.7,16.46V188.67z"
            fill={fill}
          />
          <path
            className="st0"
            d="M731.7,163.94h-38.16c0.14,5.98,1.76,10.6,4.86,13.86c3.1,3.26,7.35,4.88,12.77,4.88
			c6.09,0,11.68-1.99,16.78-5.99v8.09c-4.75,3.43-11.03,5.14-18.84,5.14c-7.63,0-13.63-2.44-18-7.32
			c-4.36-4.88-6.54-11.75-6.54-20.61c0-8.36,2.38-15.18,7.15-20.45c4.77-5.27,10.69-7.9,17.76-7.9c7.07,0,12.54,2.28,16.41,6.82
			c3.87,4.55,5.81,10.87,5.81,18.95V163.94z M722.83,156.65c-0.04-4.97-1.24-8.84-3.61-11.6c-2.37-2.76-5.67-4.15-9.9-4.15
			c-4.08,0-7.55,1.45-10.4,4.36c-2.85,2.91-4.61,6.7-5.28,11.39H722.83z"
            fill={fill}
          />
          <path
            className="st0"
            d="M761.75,188.15c-2.04,1.12-4.73,1.68-8.07,1.68c-9.46,0-14.2-5.25-14.2-15.75v-31.82h-9.29v-7.35h9.29v-13.12
			l8.65-2.78v15.91h13.62v7.35h-13.62v30.29c0,3.61,0.62,6.18,1.85,7.72c1.23,1.54,3.27,2.31,6.12,2.31c2.18,0,4.06-0.59,5.65-1.79
			V188.15z"
            fill={fill}
          />
        </g>
        <path
          className="st0"
          d="M564.22,187.61c-36.11,0-65.49-29.38-65.49-65.49s29.38-65.49,65.49-65.49c36.11,0,65.49,29.38,65.49,65.49
		S600.34,187.61,564.22,187.61z M564.22,77.15c-24.79,0-44.97,20.17-44.97,44.97s20.17,44.97,44.97,44.97s44.97-20.17,44.97-44.97
		S589.02,77.15,564.22,77.15z"
          fill={fill}
        />
        <path
          className="st0"
          d="M304.27,56.62c-36.11,0-65.49,29.38-65.49,65.49v65.41h20.53v-65.41c0-24.79,20.17-44.97,44.97-44.97
		c24.79,0,44.97,20.17,44.97,44.97s-20.17,44.97-44.97,44.97h-30.58v20.53h30.58c36.11,0,65.5-29.38,65.5-65.49
		S340.39,56.62,304.27,56.62z"
          fill={fill}
        />
        <path
          className="st0"
          d="M474.31,73.02c-10.63-10.42-25.07-16.4-39.62-16.41c0,0-0.01,0-0.01,0c-14.2,0-27.89,5.72-38.55,16.1
		c-9.13,8.89-15.5,20.58-17.47,32.07c-0.63,3.7-0.65,7.41-0.64,8.56v74.17h20.53c0,0,0.04-77.53,0.34-79.26
		c2.15-12.55,15.01-31.12,35.78-31.12c0,0,0.01,0,0.01,0c19.59,0,36.13,16.55,36.13,36.13c0,19.92-16.21,36.13-36.13,36.13
		l-1.43-0.03h-22.1v20.53h16.18c1.12,0.11,4.97,0.03,7.35,0.03c31.24,0,56.66-25.42,56.66-56.66
		C491.35,98.09,485.3,83.8,474.31,73.02z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default DPOnet
