import { Fragment, useEffect } from 'react'
import { Image as ImageIcon } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Typography,
} from '@mui/material'
import { FormProvider, Controller, useForm } from 'react-hook-form'

import { FileUploadInput, MUI } from 'components'

import { formatters } from 'helpers'

import { useAuth, useJourney, usePrivacyChannel, useSnackbar } from 'hooks'

import constants from 'constants/index'
import schema, { CompanyLogoSchemaType } from './schema'
import { theme } from 'theme'

const CompanyLogo = () => {
  const { createLogo } = usePrivacyChannel()
  const { userCompany, updateCompany } = useAuth()
  const { handleNext, setNextDisabled } = useJourney()
  const { snackbar } = useSnackbar()

  const company = userCompany?.company

  const formMethods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      hasLogo: !!company?.logo?.url ?? false,
      logo: undefined,
    },
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = formMethods

  const hasLogo = watch('hasLogo')

  const onSubmit = async (data: CompanyLogoSchemaType) => {
    try {
      setNextDisabled(true)
      const response = await createLogo(data.logo)
      updateCompany({ logo: response?.data })
      handleNext()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setNextDisabled(false)
    }
  }

  const getImageType = (fileName: string) => {
    const extension = fileName?.split('.')?.[1]
    if (!extension || extension === 'jpg') {
      return 'jpeg'
    }

    return extension
  }

  useEffect(() => {
    const loadLogo = async () => {
      const blob = await fetch(formatters.baseURL(company?.logo?.url)).then(
        (response) => response.blob()
      )
      const fileName = company?.logo?.url?.split(/(\\|\/)/g).pop() ?? 'logo'
      const type = getImageType(fileName)
      setValue('logo', new File([blob], fileName, { type: `image/${type}` }))
    }

    if (company?.logo?.url && hasLogo) {
      loadLogo()
    }

    //eslint-disable-next-line
  }, [company?.logo])

  return (
    <FormProvider {...formMethods}>
      <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Typography variant="h3" textAlign="center" color="primary">
              Vamos personalizar a logo da sua empresa
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontWeight="400"
              textAlign="center"
              color="primary"
            >
              A Seguir vamos personalizar a identidade visual do seu canal de
              comunicação.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h5"
              fontWeight="400"
              textAlign="center"
              color="primary"
            >
              A sua organização possui uma logo?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <MUI.RadioGroup
                      $gap={8}
                      row
                      {...field}
                      onChange={(event) =>
                        field.onChange(event.target.value === 'true')
                      }
                    >
                      {constants.welcomeSteps.COMPANY_LOGO_RADIO_BUTTON_CONTROL_ITEMS.map(
                        (item, index) => (
                          <FormControlLabel
                            key={index}
                            value={item?.value}
                            disabled={!!company?.logo?.url}
                            control={<Radio size="medium" />}
                            label={
                              <Typography
                                variant="h5"
                                fontWeight="800"
                                color="primary"
                                textTransform="uppercase"
                              >
                                {item?.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </MUI.RadioGroup>
                    {!!errors?.hasLogo && (
                      <FormHelperText error={!!errors?.hasLogo}>
                        {<>{errors?.hasLogo?.message}</>}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                control={control}
                name="hasLogo"
              />
            </Box>
          </Grid>
          {hasLogo && (
            <Fragment>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight="400"
                  textAlign="center"
                  color="primary"
                >
                  Anexe sua logo na caixa abaixo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <FileUploadInput
                    controlName="logo"
                    title="Faça o upload da sua logo aqui"
                    {...(!company?.logo?.url && {
                      accept:
                        constants.policies.SUPPORTED_LOGO_FILE_INPUT_TYPES,
                    })}
                    backgroundColor={theme.palette.background.default}
                    StartIcon={ImageIcon}
                    EndIcon={ImageIcon}
                    width="60%"
                  />
                </Box>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </form>
    </FormProvider>
  )
}

export default CompanyLogo
