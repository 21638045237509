import { QuestionnaireType } from 'types/maturityQuestionnaire.types'

const A_RESULT = 'A'
const B_RESULT = 'B'
const C_RESULT = 'C'
const D_RESULT = 'D'
const E_RESULT = 'E'

const MATURITY_QUESTIONNAIRE: QuestionnaireType = {
  totalPoints: 25,
  title: 'Avaliação de Maturidade',
  subtitle: 'Você sabe como a sua empresa está em relação à privacidade?',
  description:
    'Responda perguntas rápidas e descubra se a sua empresa está avançada ou engatinhando na Lei de Proteção de Dados.',
  questions: [
    {
      kind: 'unique',
      question:
        '1. As decisões no seu negócio consideram a privacidade e proteção dos dados pessoais?',
      options: [
        {
          name: 'Privacidade é considerada em quase todas as nossas decisões.',
          points: 3,
        },
        {
          name: 'Alguns departamentos consideram isso ao tomar decisões e modificar sistemas.',
          points: 2,
        },
        {
          name: 'Privacidade é discutida em certos momentos, com uma equipe dedicada a isso.',
          points: 1,
        },
        {
          name: 'Privacidade não é um assunto que nós nos preocupamos ou falamos por aqui.',
          points: 0,
        },
      ],
    },
    {
      kind: 'unique',
      question:
        '2. Os termos e condições, assim como a política de privacidade, estão facilmente acessíveis para os usuários?',
      options: [
        {
          name: 'Sim, todas as políticas de privacidade estão atualizadas e facilmente acessíveis em nosso site.',
          points: 3,
        },
        {
          name: 'Nossa política de privacidade no site não foi atualizada desde a implementação.',
          points: 2,
        },
        {
          name: 'Temos uma política de privacidade, mas não está facilmente acessível para nossos clientes.',
          points: 1,
        },
        {
          name: 'Não possuímos uma política de privacidade.',
          points: 0,
        },
      ],
    },
    {
      kind: 'multiple',
      question: '3. Os funcionários recebem treinamentos periódicos sobre:',
      options: [
        {
          name: 'LGPD e proteção de dados',
          points: 1,
        },
        {
          name: 'Segurança da informação',
          points: 1,
        },
        {
          name: 'Responsabilidades em relação à dados pessoais',
          points: 1,
        },
        {
          name: 'Cultura de proteção de dados',
          points: 1,
        },
        {
          name: 'Não recebem esses treinamentos',
          points: 0,
          master: true,
        },
      ],
    },
    {
      kind: 'unique',
      question:
        '4. Com que frequência você se preocupa com questões de segurança da informação?',
      options: [
        {
          name: 'Sempre considero a segurança das informações em todas as decisões.',
          points: 3,
        },
        {
          name: 'Me preocupo com a segurança quando é mencionada por um responsável.',
          points: 2,
        },
        {
          name: 'Me preocupo com segurança, mas ainda não tenho nenhum responsável por isso na empresa.',
          points: 1,
        },
        {
          name: 'Na realidade, não penso nisso no meu dia-a-dia.',
          points: 0,
        },
      ],
    },
    {
      kind: 'unique',
      question:
        '5. Em caso de violação de segurança, você sabe quem contatar dentro da empresa?',
      options: [
        {
          name: 'Sim, existe um DPO (encarregado de proteção de dados).',
          points: 3,
        },
        {
          name: 'Sim, existe uma pessoa dentro da empresa que cuida de problemas de segurança.',
          points: 2,
        },
        {
          name: 'Sim, existe uma pessoa externa que cuida de violações de segurança.',
          points: 1,
        },
        {
          name: 'Meu negócio não possui uma pessoa específica, terei que contratar caso aconteça.',
          points: 0,
        },
      ],
    },
    {
      kind: 'unique',
      question:
        '6. Qual é a penalidade pelo não cumprimento da Lei Geral de Proteção de Dados (LGPD)?',
      options: [
        {
          name: 'Advertência e multa máxima de R$ 50.000,00',
          points: 'a',
        },
        {
          name: 'Multa de até 2% do faturamento da empresa',
          points: 4,
        },
        {
          name: 'Multa de até 10% do faturamento da empresa',
          points: 'b',
        },
        {
          name: 'Não sei',
          points: 'c',
        },
      ],
    },
    {
      kind: 'multiple',
      question: '7. Assinale as opções que a sua empresa possui:',
      options: [
        {
          name: 'DPO nomeado',
          points: 1,
        },
        {
          name: 'Política de Privacidade',
          points: 1,
        },
        {
          name: 'Plano de Respostas à Violações de Segurança',
          points: 1,
        },
        {
          name: 'Antivírus Atualizado',
          points: 1,
        },
        {
          name: 'Canal de Atendimento ao Titular de Dados',
          points: 1,
        },
        {
          name: 'Não sei',
          points: 0,
          master: true,
        },
      ],
    },
    {
      kind: 'unique',
      question: '8. Aproximadamente, qual o faturamento anual da empresa?',
      options: [
        {
          name: 'Menos de 1 milhão (Seu negócio poderá receber uma multa de até R$ 20.000,00 reais por violação à LGPD)',
          points: 'a',
        },
        {
          name: 'Superior a 1 milhão (Seu negócio poderá receber uma multa de até R$ 200.000,00 reais por violação à LGPD)',
          points: 'b',
        },
        {
          name: 'Acima de 10 milhões (Seu negócio poderá receber uma multa de mais de R$ 1.000.000,00 reais por violação à LGPD)',
          points: 'c',
        },
        {
          name: 'Não sei',
          points: 'd',
        },
      ],
    },
  ],
}

const MATURITY_FEEDBACKS = {
  [A_RESULT]: {
    title: 'Parece que a sua organização está indo muito bem!',
    description: `A privacidade e proteção de dados é super importante para a sua empresa, sendo considerada em grande parte das decisões, e atendendo aos requisitos mínimos de conscientização e boas práticas. 
\nEsta iniciativa não apenas demonstra a responsabilidade da organização em relação à conformidade legal, mas também reflete um profundo compromisso com a ética e a privacidade dos indivíduos.`,
  },
  [B_RESULT]: {
    title: 'Parece que a sua organização está na direção certa!',
    description: `É ótimo ver o compromisso da organização em garantir a conformidade com as regulamentações de privacidade de dados.
    \nEntretanto, acreditamos que ainda há espaço para melhorias. Seria valioso explorar maneiras de aprimorar ainda mais os processos internos para garantir uma proteção abrangente dos dados pessoais dos clientes e parceiros.
    \nUma revisão contínua das políticas e práticas, juntamente com investimentos adicionais em treinamento e conscientização da equipe, poderia fortalecer significativamente a postura da organização em relação à proteção de dados.`,
  },
  [C_RESULT]: {
    title: 'Parece que a sua organização poderia estar melhor…',
    description: `Embora reconheça os esforços em andamento, é evidente que ainda há desafios significativos a enfrentar. A atual situação revela lacunas que precisam ser urgentemente abordadas para garantir uma conformidade efetiva com as regulamentações de proteção de dados.
    \nÉ crucial reconhecer que, neste momento, não estamos atendendo plenamente aos requisitos da LGPD, o que representa um risco tanto para a organização quanto para nossos clientes e parceiros.`,
  },
  [D_RESULT]: {
    title:
      'Parece que a sua organização não está nada bem em proteção de dados!',
    description: `Reconheço os esforços que têm sido feitos até o momento, no entanto, é evidente que há margem para melhorias significativas. Atualmente, nossa situação é crítica, e não está atendendo aos padrões desejados de conformidade com as regulamentações de proteção de dados.
    \nÉ crucial que reconheçamos essa realidade e redobremos nossos esforços para fortalecer nossas práticas de proteção de dados. Isso pode envolver uma revisão abrangente de nossos processos internos, a implementação de medidas adicionais de segurança de dados e investimentos em treinamento para garantir que todos os membros da equipe estejam plenamente conscientes de suas responsabilidades.`,
  },
  [E_RESULT]: {
    title:
      'Sua organização está totalmente desprotegida em termos de privacidade!',
    description: `Lamento informar que, após uma análise detalhada, constatamos que nossa organização se encontra totalmente desprotegida em relação às regulamentações de proteção de dados.
    \nEsta constatação é extremamente preocupante e demanda ação imediata. Nossa falta de conformidade representa um risco significativo não apenas para a organização, mas também para nossos clientes, parceiros e a reputação da empresa como um todo.
    \nDiante desse cenário alarmante, é essencial que tomemos medidas urgentes para remediar essa situação. `,
  },
}

const maturityQuestionnaire = {
  MATURITY_QUESTIONNAIRE,
  MATURITY_FEEDBACKS,
}

export default maturityQuestionnaire
