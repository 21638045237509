import { Controller, useFormContext } from 'react-hook-form'
import { Grid, RadioGroup } from '@mui/material'

import { PlanCard } from './components'

import { CheckoutSchemaType } from 'constants/checkout/form'
import { PlanSectionType } from './planSection.types'

import constants from 'constants/index'

const PlanSection = ({ inputRef }: PlanSectionType) => {
  const { control } = useFormContext<CheckoutSchemaType>()

  return (
    <Controller
      render={({ field }) => (
        <RadioGroup {...field}>
          <Grid container spacing={4} justifyContent="center">
            {constants.checkout.form.CONTROLLER_PLAN_CARDS.map(
              (card, index) => (
                <Grid key={index} item xs={12} sm={6} lg={4} xl={6}>
                  <PlanCard field={field} card={card} inputRef={inputRef} />
                </Grid>
              )
            )}
          </Grid>
        </RadioGroup>
      )}
      control={control}
      name="plan"
    />
  )
}

export default PlanSection
