import { StandardFlagIdType } from 'constants/cardFlags'
import constants from 'constants/index'

const id = (
  cardNumber?: string | null,
  permittedFlags = constants.cardFlags.CARD_FLAG_PATTERN_STANDARD_VALIDATION
): StandardFlagIdType | null => {
  const cardNumberDiff = cardNumber?.replace(/[^0-9]+/g, '')

  if (cardNumberDiff) {
    for (const flagId in permittedFlags) {
      if (permittedFlags[flagId].test(cardNumberDiff)) {
        return Number(flagId) as StandardFlagIdType
      }
    }
  }

  return null
}

const format = (id?: number | null) => {
  return (
    (id && constants.cardFlags.CARD_FLAG_FORMAT_VALIDATION[id]) ||
    constants?.cardFlags.DEFAULT_FORMAT
  )
}

export default { id, format }
