import { Box, CardActionArea, Radio } from '@mui/material'

import { MUI } from 'components'

import { CardRadioType } from './cardRadio.types'

import { theme } from 'theme'
import { palette } from 'helpers'

const CardRadio = ({
  children,
  field,
  radioValue,
  error = false,
}: CardRadioType) => {
  const { value, onChange } = field

  const checked = value === radioValue
  const handleClick = () => onChange(radioValue)

  const cardBorder =
    error || checked
      ? `solid 1px ${error ? theme.palette.error.main : theme.palette.secondary.main}`
      : ''

  return (
    <MUI.Card
      variant="outlined"
      $border={cardBorder}
      $backgroundColor={
        checked ? palette.hexToRgba(theme.palette.secondary.light, 0.1) : ''
      }
      $transition="border-color 0.5s ease, background-color 0.5s ease"
    >
      <CardActionArea onClick={handleClick}>
        <Box display="flex" justifyContent="space-between">
          {children}
          <Box display="flex" alignItems="start" p={1}>
            <Radio value={radioValue} checked={checked} />
          </Box>
        </Box>
      </CardActionArea>
    </MUI.Card>
  )
}

export default CardRadio
