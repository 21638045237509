import { startsWith } from 'lodash'

const website = (site?: string | null) => {
  if (site === null || site === undefined || site === '') return ''

  if (site.split('.').length > 1) {
    if (startsWith(site, 'http://') || startsWith(site, 'https://')) {
      return site
    } else {
      if (site.includes('://')) {
        const splitted = site.split('://')

        return `https://${splitted[splitted.length - 1]}`
      }
    }

    return `https://${site}`
  }
  return site
}

export default website
