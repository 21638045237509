import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import BannerPreview from '../BannerPreview'

import { theme } from 'theme'
import constants from 'constants/index'

const PositionPreview = () => {
  const { watch } = useFormContext()

  const position = watch('position')

  const isFixed = position === constants.banner.BANNER_FIXED_POSITION

  return (
    <Box
      bgcolor={theme.palette.disabled.background}
      height={400}
      borderRadius={2}
      p={!isFixed ? 4 : 0}
    >
      <BannerPreview position={position} iconProps={{ p: isFixed ? 4 : 0 }} />
    </Box>
  )
}

export default PositionPreview
