import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { MUI, StandardTable, StandardTableCell } from 'components'

import { formatters, validates } from 'helpers'

import { TicketTableType } from './ticketTable types'

import { useAuth } from 'hooks'

import { theme } from 'theme'
import constants from 'constants/index'

const { routes } = constants

const TicketTable = ({ tickets }: TicketTableType) => {
  const navigate = useNavigate()
  const { userCompany } = useAuth()

  const handleShowTicket = (ticketId: number) =>
    navigate(
      reverse(routes.tickets.show, {
        ticketId,
      })
    )

  return (
    <TableContainer>
      <StandardTable aria-label="Listagem de solicitações de titulares">
        <TableHead>
          <TableRow>
            <StandardTableCell align="center">Ticket</StandardTableCell>
            <StandardTableCell align="left">Solicitante</StandardTableCell>
            <StandardTableCell align="left">Solicitação</StandardTableCell>
            <StandardTableCell align="center">Atualizado em</StandardTableCell>
            <StandardTableCell align="left">Responsável</StandardTableCell>
            <StandardTableCell align="center">Prazo</StandardTableCell>
            <StandardTableCell align="center">Status</StandardTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets?.map((ticket) => (
            <TableRow
              key={ticket?.id}
              hover
              onClick={() => handleShowTicket(ticket?.id)}
            >
              <StandardTableCell align="center" $fontWeight={500}>
                {ticket?.ticketRef}
              </StandardTableCell>
              <StandardTableCell align="left" width={250} $maxWidth={250}>
                {ticket?.userName}
              </StandardTableCell>
              <StandardTableCell align="left" width={350} $maxWidth={350}>
                <Tooltip
                  title={ticket?.questionDemand?.answer?.title}
                  placement="bottom-start"
                >
                  <Typography variant="body2" maxWidth="fit-content" noWrap>
                    {ticket.ticketType === constants.ticket.TITULAR_TICKET_KIND
                      ? ticket?.questionDemand?.answer?.title
                      : ticket?.title}
                  </Typography>
                </Tooltip>
                <Tooltip title="Titular de dados" placement="bottom-start">
                  <Typography
                    variant="body2"
                    color={theme.palette.neutral.main}
                    maxWidth="fit-content"
                    noWrap
                  >
                    {ticket.ticketType === constants.ticket.TITULAR_TICKET_KIND
                      ? 'Titular de dados'
                      : 'ANPD'}
                  </Typography>
                </Tooltip>
              </StandardTableCell>
              <StandardTableCell align="center" $fontWeight={500}>
                {formatters.date.ptBrFormatDateTime(ticket?.updatedAt)}
              </StandardTableCell>
              <StandardTableCell align="left" width={150} $maxWidth={150}>
                {userCompany?.company?.name}
              </StandardTableCell>
              <StandardTableCell align="center">
                <MUI.Chip
                  label={validates.ticket.deadlineChipTranslation(
                    ticket?.daysToDeadlineDate
                  )}
                  color={validates.ticket.deadlineChipColor(
                    ticket?.daysToDeadlineDate
                  )}
                  variant="filled"
                  size="small"
                />
              </StandardTableCell>
              <StandardTableCell align="center">
                <MUI.Chip
                  label={ticket?.status?.name?.toUpperCase()}
                  variant="filled"
                  size="small"
                  $color="white"
                  $borderRadius={3}
                  $fontWeight={500}
                  $background={validates.ticket.statusBackgroundColor(
                    ticket?.status?.id
                  )}
                />
              </StandardTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StandardTable>
    </TableContainer>
  )
}

export default TicketTable
