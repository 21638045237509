import { ObjectOf } from 'types/global.types'
import { ChipColorType } from 'types/materialUI.types'

const FINANCIAL_NOT_PAID_STATUS = 'NOT_PAID'
const FINANCIAL_WAITING_PAYMENT_STATUS = 'WAITING_PAYMENT'
const FINANCIAL_WAITING_CONFIRM_STATUS = 'WAITING_CONFIRM'
const FINANCIAL_PAID_STATUS = 'PAID'
const FINANCIAL_CANCELED_STATUS = 'CANCELED'
const FINANCIAL_FAILURE_STATUS = 'FAILURE'
const FINANCIAL_OVERDUE_STATUS = 'OVERDUE'
const FINANCIAL_INTEGRATION_STATUS = 'INTEGRATION'
const FINANCIAL_PRE_CAPTURED_STATUS = 'PRE_CAPTURED'

const READING_STATUS_CHIP_COLOR: ObjectOf<ChipColorType> = {
  [FINANCIAL_NOT_PAID_STATUS]: 'error',
  [FINANCIAL_WAITING_PAYMENT_STATUS]: 'warning',
  [FINANCIAL_WAITING_CONFIRM_STATUS]: 'info',
  [FINANCIAL_PAID_STATUS]: 'success',
  [FINANCIAL_CANCELED_STATUS]: 'neutral',
  [FINANCIAL_FAILURE_STATUS]: 'error',
  [FINANCIAL_OVERDUE_STATUS]: 'error',
  [FINANCIAL_INTEGRATION_STATUS]: 'success',
  [FINANCIAL_PRE_CAPTURED_STATUS]: 'info',
}

const ALL_PAYMENT_ORDER_STATUS = [
  FINANCIAL_NOT_PAID_STATUS,
  FINANCIAL_WAITING_PAYMENT_STATUS,
  FINANCIAL_WAITING_CONFIRM_STATUS,
  FINANCIAL_PAID_STATUS,
  FINANCIAL_CANCELED_STATUS,
  FINANCIAL_FAILURE_STATUS,
  FINANCIAL_OVERDUE_STATUS,
  FINANCIAL_INTEGRATION_STATUS,
  FINANCIAL_PRE_CAPTURED_STATUS,
] as const

export type StatusPaymentOrderReadingType =
  (typeof ALL_PAYMENT_ORDER_STATUS)[number]

const READING_STATUS_TRANSLATION = {
  [FINANCIAL_NOT_PAID_STATUS]: 'Não pago',
  [FINANCIAL_WAITING_PAYMENT_STATUS]: 'Aguardando pagamento',
  [FINANCIAL_WAITING_CONFIRM_STATUS]: 'Aguardando confirmação',
  [FINANCIAL_PAID_STATUS]: 'Pago',
  [FINANCIAL_CANCELED_STATUS]: 'Cancelado',
  [FINANCIAL_FAILURE_STATUS]: 'Falha',
  [FINANCIAL_OVERDUE_STATUS]: 'Vencido',
  [FINANCIAL_INTEGRATION_STATUS]: 'Aguardando integração',
  [FINANCIAL_PRE_CAPTURED_STATUS]: 'Aguardando confirmação',
}

export default {
  FINANCIAL_PAID_STATUS,
  FINANCIAL_PRE_CAPTURED_STATUS,
  READING_STATUS_TRANSLATION,
  READING_STATUS_CHIP_COLOR,
}
