import { useRef } from 'react'

import { Image as ImageIcon } from 'react-feather'
import { Box, colors, Divider, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { MUI } from 'components'

import { useClipboard } from 'hooks'

import positionSignatureImage from 'images/position_signature.png'
import stampImage from 'images/selo_site.png'

const EmailStamp = ({ stampURL }: { stampURL: string }) => {
  const reference = useRef(null)

  const { copyComponentContent, isLoading } = useClipboard()

  const handleCopyImageStamp = () => {
    copyComponentContent(reference?.current)
  }

  return (
    <MUI.Paper
      variant="outlined"
      $borderTopRightRadius={0}
      $borderTopLeftRadius={0}
    >
      <Grid container p={4} spacing={8}>
        <Grid item xs={12} lg={6}>
          <img
            src={positionSignatureImage}
            alt="Identidade visual do canal de comunicação para e-mails"
            width="100%"
          />
        </Grid>
        <Grid item xs={12} lg={6} textAlign="justify">
          <Typography fontWeight={600} color="primary" gutterBottom>
            Insira na sua assinatura
          </Typography>
          <Typography variant="body2" color="primary" gutterBottom>
            A geração dos códigos para incluir o selo "Dados Pessoais
            Protegidos" no website da empresa e nas assinaturas de e-mail
            encerra a publicidade que toda empresa precisa mostrar para o
            mercado. Não basta a empresa dispor de diversos mecanismos para
            proteger os dados pessoais, é necessário demonstrar para todas as
            partes interessadas que adotou controles íntegros e permanentes para
            se manter em conformidade com a LGPD.
          </Typography>
          <Typography variant="body2" color="primary">
            Por meio do selo, qualquer interessado é direcionado ao portal
            Privacidade&Você e pode verificar a autenticidade da adequação da
            empresa à LGPD, inclusive, podendo verificar: a última revisão de
            seus mecanismos de controle com a exibição da data e hora em que é
            feita a revisão; as políticas de privacidade da empresa; o meio de
            contato dos titulares de dados; e o meio de contato da ANPD.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            bgcolor={colors.grey[300]}
            borderRadius={1}
            p={4}
            display="flex"
            justifyContent="center"
          >
            <a href={stampURL} ref={reference}>
              <img src={stampImage} width="150px" />
            </a>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Box display="flex" justifyContent="end" p={4}>
        <LoadingButton
          startIcon={<ImageIcon size={18} />}
          variant="contained"
          loading={isLoading}
          onClick={handleCopyImageStamp}
        >
          Copiar
        </LoadingButton>
      </Box>
    </MUI.Paper>
  )
}

export default EmailStamp
