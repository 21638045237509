const eightCharacters = (value = '') => {
  return /^.{8,}$/.test(value)
}

const uppercaseLetter = (value = '') => {
  return /.*[A-Z].*/.test(value)
}

const lowercaseLetter = (value = '') => {
  return /.*[a-z].*/.test(value)
}

const specialCharacters = (value = '') => {
  return /[\W_]/.test(value)
}

const numberCharacters = (value = '') => {
  return /.*[0-9].*/.test(value)
}

export default {
  eightCharacters,
  uppercaseLetter,
  lowercaseLetter,
  specialCharacters,
  numberCharacters,
}
