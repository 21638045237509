import { useEffect, useState } from 'react'
import { isUndefined } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { usePrivacyChannel, useJourney } from 'hooks'

import schema, { DataDestroySchemaType } from './schema'

const DataDestroy = () => {
  const { dataDestroyStep, setDataDestroyStep } = usePrivacyChannel()
  const { handleNext, setNextDisabled } = useJourney()

  const [destroyTheirOwnData, setDestroyTheirOwnData] = useState<
    boolean | undefined
  >(dataDestroyStep?.destroyTheirOwnData)

  const negativeSelected =
    !isUndefined(destroyTheirOwnData) && !destroyTheirOwnData

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: dataDestroyStep?.description ?? '',
    },
  })

  const handleClick = (value: boolean) => {
    setDestroyTheirOwnData(value)
  }

  const onSubmit = (data: DataDestroySchemaType) => {
    setDataDestroyStep({
      description: data.description,
      destroyTheirOwnData: destroyTheirOwnData,
    })

    handleNext()
  }

  useEffect(() => {
    setNextDisabled(
      isUndefined(destroyTheirOwnData) ||
        (destroyTheirOwnData && !watch('description'))
    )

    return () => setNextDisabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destroyTheirOwnData, watch('description')])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color="primary"
          fontWeight={700}
          textAlign="center"
        >
          Exclusão de Dados Pessoais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={400}
          textAlign="center"
          color="primary"
          fontFamily="Raleway"
          gutterBottom
        >
          O usuário pode excluir seus dados pessoais por conta própria, por
          exemplo, através de um site ou aplicativo?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
          <Button
            variant={negativeSelected ? 'contained' : 'outlined'}
            onClick={() => handleClick(false)}
          >
            Não
          </Button>
          <Button
            variant={destroyTheirOwnData ? 'contained' : 'outlined'}
            onClick={() => handleClick(true)}
          >
            Sim
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
          {negativeSelected && (
            <Typography
              variant="h6"
              fontWeight={400}
              textAlign="center"
              color="primary"
              fontFamily="Raleway"
              gutterBottom
            >
              Entendido! Caso algum cliente solicite a exclusão de seus dados
              pessoais, irei te notificar.
            </Typography>
          )}
          {destroyTheirOwnData && (
            <>
              <Typography
                variant="h6"
                fontWeight={400}
                textAlign="center"
                color="primary"
                fontFamily="Raleway"
                gutterBottom
              >
                Explique como ele pode realizar essa exclusão ou forneça o link
                de uma FAQ, caso haja uma.
              </Typography>

              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    helperText={errors?.description?.message}
                    multiline
                    fullWidth
                    rows={12}
                  />
                )}
                control={control}
                name="description"
              />
            </>
          )}
        </form>
      </Grid>
    </Grid>
  )
}

export default DataDestroy
