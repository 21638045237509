import * as yup from 'yup'

import { password } from 'helpers'

const schema = yup.object({
  name: yup.string().min(4).max(255).required(),
  email: yup.string().email().min(4).max(254).required(),
  phone: yup.string().required().min(14).max(15),
  currentPassword: yup.string().when(['confirmPassword', 'newPassword'], {
    is: (confirmPassword?: string | null, newPassword?: string | null) =>
      confirmPassword || newPassword,
    then: () => yup.string().required(),
  }),
  newPassword: yup
    .string()
    .nullable()
    .test(
      'strongPassword',
      'A senha não é forte o suficiente',
      password.validateStrongPassword
    )
    .when(['confirmPassword'], {
      is: (confirmPassword?: string | null) => confirmPassword,
      then: () =>
        yup
          .string()
          .nullable()
          .required()
          .test(
            'strongPassword',
            'A senha não é forte o suficiente',
            password.validateStrongPassword
          ),
    }),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword')],
      'A confirmação de senha não coincide com a nova senha'
    ),
})

export default schema
