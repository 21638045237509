import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const get = async (params: object) =>
  await dponetAPI.get(`/segments`, { params })

export default {
  get,
}
