import Visa from 'components/Svg/Visa'
import Mastercard from 'components/Svg/Mastercard'
import Amex from 'components/Svg/Amex'
import Hipercard from 'components/Svg/Hipercard'
import Elo from 'components/Svg/Elo'

import { SVGComponentType } from 'types/global.types'
import { CreditCard, Icon } from 'react-feather'

const MASTER_CARD_ID = 1
const VISA_ID = 2
const AMEX_ID = 3
const ELO_ID = 4
const HIPERCARD_ID = 5

const DANKORT_ID = 6
const JCB_ID = 7
const LASER_ID = 8
const MAESTRO_ID = 9
const DINERS_CLUB_ID = 10
const MIR_ID = 11
const TROY_ID = 12
const UNION_PAY_ID = 13
const VISA_ELECTRON_ID = 14
const DISCOVER_ID = 15

const VISA_LABEL = 'Visa'
const MASTERCARD_LABEL = 'Mastercard'
const AMEX_LABEL = 'Amex'
const ELO_LABEL = 'Elo'
const HIPERCARD_LABEL = 'Hipercard'
const ANOTHER_LABEL = 'Outro'

const AMEX_PATTERN = /^3[47]/
const DANKORT_PATTERN = /^5019/
const DINERS_CLUB_PATTERN = /^(36|38|30[0-5])/
const DISCOVER_PATTERN = /^(6011|65|64[4-9]|622)/
const ELO_PATTERN =
  /^401178|^401179|^431274|^438935|^451416|^457393|^457631|^457632|^504175|^627780|^636297|^636369|^636368|^(506699|5067[0-6]\d|50677[0-8])|^(50900\d|5090[1-9]\d|509[1-9]\d{2})|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])|^(65092[1-9]|65097[0-8])/
const HIPERCARD_PATTERN =
  /^(384100|384140|384160|606282|637095|637568|60(?!11))/
const JCB_PATTERN =
  /^(308[8-9]|309[0-3]|3094[0]{4}|309[6-9]|310[0-2]|311[2-9]|3120|315[8-9]|333[7-9]|334[0-9]|35)/
const LASER_PATTERN = /^(6706|6771|6709)/
const MAESTRO_PATTERN = /^(50|5[6-9]|6007|6220|6304|6703|6708|6759|676[1-3])/
const MASTER_CARD_PATTERN =
  /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/
const MIR_PATTERN = /^220[0-4][0-9][0-9]\d{10}$/
const TROY_PATTERN = /^9792/
const UNION_PAY_PATTERN = /^62/
const VISA_ELECTRON_PATTERN = /^4(026|17500|405|508|844|91[37])/
const VISA_PATTERN = /^4/

const DEFAULT_FORMAT = /(\d{1,4})/g
const AMEX_FORMAT = /(\d{1,4})(\d{1,6})?(\d{1,5})?/
const DINERS_CLUB_FORMAT = /(\d{1,4})(\d{1,6})?(\d{1,4})?/

const ALL_STANDARD_FLAGS = [
  ELO_ID,
  HIPERCARD_ID,
  MASTER_CARD_ID,
  VISA_ID,
  AMEX_ID,
] as const

const ALL_STANDARD_LABELS = [
  VISA_LABEL,
  MASTERCARD_LABEL,
  AMEX_LABEL,
  ELO_LABEL,
  HIPERCARD_LABEL,
  ANOTHER_LABEL,
] as const

const CARD_FLAG_PATTERN_STANDARD_VALIDATION: { [key: number]: RegExp } = {
  [AMEX_ID]: AMEX_PATTERN,
  [ELO_ID]: ELO_PATTERN,
  [HIPERCARD_ID]: HIPERCARD_PATTERN,
  [MASTER_CARD_ID]: MASTER_CARD_PATTERN,
  [VISA_ID]: VISA_PATTERN,
}

const CARD_BRAND_DEFINITION_BY_ID: {
  [key: number]: StandardLabelType
} = {
  [AMEX_ID]: AMEX_LABEL,
  [ELO_ID]: ELO_LABEL,
  [HIPERCARD_ID]: HIPERCARD_LABEL,
  [MASTER_CARD_ID]: MASTERCARD_LABEL,
  [VISA_ID]: VISA_LABEL,
}

const METHOD_ID_DEFINITION_BY_BRAND: {
  [key: string]: StandardFlagIdType
} = {
  [AMEX_LABEL]: AMEX_ID,
  [ELO_LABEL]: ELO_ID,
  [HIPERCARD_LABEL]: HIPERCARD_ID,
  [MASTERCARD_LABEL]: MASTER_CARD_ID,
  [VISA_LABEL]: VISA_ID,
}

const CARD_FLAG_FORMAT_VALIDATION: { [key: number]: RegExp } = {
  [AMEX_ID]: AMEX_FORMAT,
  [DINERS_CLUB_ID]: DINERS_CLUB_FORMAT,
}

const STANDARD_FLAGS_ITEMS: FlagItemsType[] = [
  {
    id: VISA_ID,
    Flag: Visa,
  },
  {
    id: MASTER_CARD_ID,
    Flag: Mastercard,
  },
  {
    id: AMEX_ID,
    Flag: Amex,
    bgcolor: '#1F72CD',
    grayscale: '#757575',
  },
  {
    id: HIPERCARD_ID,
    Flag: Hipercard,
  },
  {
    id: ELO_ID,
    Flag: Elo,
  },
]

const FLAGS_BY_LABEL: LabelItemsType = {
  [VISA_LABEL]: {
    Flag: Visa,
  },
  [MASTERCARD_LABEL]: {
    Flag: Mastercard,
  },
  [AMEX_LABEL]: {
    Flag: Amex,
    bgcolor: '#1F72CD',
    grayscale: '#757575',
    textColor: 'white',
  },
  [HIPERCARD_LABEL]: {
    Flag: Hipercard,
  },
  [ELO_LABEL]: {
    Flag: Elo,
  },
  [ANOTHER_LABEL]: {
    Flag: CreditCard,
  },
}

export type StandardFlagIdType = (typeof ALL_STANDARD_FLAGS)[number]
export type StandardLabelType = (typeof ALL_STANDARD_LABELS)[number]
export type FlagItemsType = {
  id: StandardFlagIdType
  Flag: SVGComponentType
  bgcolor?: string
  grayscale?: string
}

export type LabelItemsType = {
  [keyof in StandardLabelType]: {
    Flag: SVGComponentType | Icon
    bgcolor?: string
    grayscale?: string
    textColor?: string
  }
}

export default {
  AMEX_ID,
  AMEX_PATTERN,
  CARD_FLAG_PATTERN_STANDARD_VALIDATION,
  DANKORT_ID,
  DANKORT_PATTERN,
  STANDARD_FLAGS_ITEMS,
  DINERS_CLUB_ID,
  DINERS_CLUB_PATTERN,
  DISCOVER_ID,
  DISCOVER_PATTERN,
  ELO_ID,
  ELO_PATTERN,
  FLAGS_BY_LABEL,
  CARD_BRAND_DEFINITION_BY_ID,
  METHOD_ID_DEFINITION_BY_BRAND,
  HIPERCARD_ID,
  HIPERCARD_PATTERN,
  JCB_ID,
  JCB_PATTERN,
  LASER_ID,
  LASER_PATTERN,
  MAESTRO_ID,
  MAESTRO_PATTERN,
  MASTER_CARD_ID,
  MASTER_CARD_PATTERN,
  MIR_ID,
  MIR_PATTERN,
  TROY_ID,
  TROY_PATTERN,
  UNION_PAY_ID,
  UNION_PAY_PATTERN,
  VISA_ELECTRON_ID,
  VISA_ELECTRON_PATTERN,
  VISA_ID,
  VISA_PATTERN,
  CARD_FLAG_FORMAT_VALIDATION,
  DEFAULT_FORMAT,
}
