import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Grid, Typography } from '@mui/material'
import { TextField } from './components'

import { size } from 'lodash'

import { useAuth, useFetch, useJourney, useSnackbar } from 'hooks'

import { yupResolver } from '@hookform/resolvers/yup'

import { CEPType } from 'types/viaCEP.types'
import { AddressType } from 'types/company.types'

import { formatters } from 'helpers'

import service from 'service'
import schema from './schema'

const FormAddress = () => {
  const { snackbar } = useSnackbar()
  const { userCompany, updateCompany } = useAuth()
  const { setNextDisabled, handleNext } = useJourney()

  const companyAddress = userCompany?.company?.address

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      city: companyAddress?.city ?? '',
      postalCode: companyAddress?.postalCode ?? '',
      state: companyAddress?.state ?? '',
      neighborhood: companyAddress?.neighborhood ?? '',
      number: companyAddress?.number ?? '',
      street: companyAddress?.street ?? '',
      complement: companyAddress?.complement ?? '',
      country: companyAddress?.country ?? 'Brasil',
    },
  })

  const cep = watch('postalCode') ?? ''
  const isCEP = size(cep) === 9

  const { response, loading: loadingCep } = useFetch(
    service.external.viaCEP.get,
    {
      cep,
    },
    [cep],
    isCEP
  )

  const address: CEPType = response?.data

  useEffect(() => {
    if (isCEP) {
      setValue('city', address?.localidade)
      setValue('neighborhood', address?.bairro)
      setValue('state', address?.uf)
      setValue('street', address?.logradouro)
      setValue('complement', address?.complemento)
    } else {
      setValue('city', '')
      setValue('neighborhood', '')
      setValue('state', '')
      setValue('street', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isCEP])

  const onSubmit = async (data: object) => {
    const addressData = data as AddressType
    try {
      setNextDisabled(true)
      await service.dponet.company.updateSelf({ address: addressData })
      updateCompany({ address: addressData })
      handleNext()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error),
      })
    } finally {
      setNextDisabled(false)
    }
  }

  return (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" color="primary">
          Informe seu endereço!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight="400"
          textAlign="center"
          color="primary"
        >
          Utilizaremos o endereço informado a seguir, para geração de suas
          politicas, termos e avisos.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="CEP"
                name="postalCode"
                type="text"
                onChange={(e) => {
                  e.target.value = formatters.cep.format(e.target.value)
                }}
                error={errors?.postalCode}
                control={control}
                loading={loadingCep}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Rua"
                name="street"
                type="text"
                error={errors?.street}
                control={control}
                disabled={!isCEP}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={errors?.neighborhood}
                label="Bairro"
                type="text"
                name="neighborhood"
                control={control}
                loading={loadingCep}
                disabled={!isCEP}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                label="Cidade"
                name="city"
                type="text"
                error={errors?.city}
                control={control}
                loading={loadingCep}
                disabled={!isCEP}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={errors?.state}
                label="Estado"
                type="text"
                name="state"
                control={control}
                loading={loadingCep}
                disabled={!isCEP}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={errors?.country}
                label="País"
                type="text"
                name="country"
                control={control}
                loading={loadingCep}
                disabled={!isCEP}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Número"
                name="number"
                type="text"
                error={errors?.number}
                control={control}
                loading={loadingCep}
                disabled={!isCEP}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Complemento"
                name="complement"
                type="text"
                error={errors?.complement}
                control={control}
                loading={loadingCep}
                disabled={!isCEP}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default FormAddress
