import { useNavigate } from 'react-router-dom'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import { Box, Button, Hidden, Paper, TablePagination } from '@mui/material'

import {
  // FilterButton,
  NoData,
  PageHead,
  StandardPageStructure,
} from 'components'
import { PolicyFilters, PolicyTable } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import routes from 'constants/routes'
import service from 'service'

const PoliciesMain = () => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const navigate = useNavigate()
  const filter = useFilter()

  const handleCreatePolicy = () => navigate(routes.policies.new)

  const { response, refresh } = useFetch(
    service.dponet.privacyPortal.get,
    {
      page,
      ...filter.filters,
      perPage,
    },
    []
  )

  const privacyPolicies = response?.data?.privacyPolicies

  return (
    <StandardPageStructure>
      <PageHead
        name="Políticas"
        actionButton={
          <Box display="flex" gap={4}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusCircleIcon size={18} />}
              onClick={handleCreatePolicy}
            >
              Nova Política
            </Button>
            {/* Necessário implementar futuramente */}
            {/* <FilterButton setDrawerOpen={filter.setDrawerOpen} /> */}
          </Box>
        }
      />
      {isEmpty(privacyPolicies) ? (
        <NoData
          title="Nenhuma política foi encontrada"
          subTitle="Defina uma nova politica no botão acima"
        />
      ) : (
        <Paper variant="outlined">
          <PolicyTable privacyPolicies={privacyPolicies} refresh={refresh} />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            slotProps={{
              actions: {
                previousButton: { size: 'small' },
                nextButton: { size: 'small' },
              },
            }}
          />
        </Paper>
      )}
      <PolicyFilters filter={filter} />
    </StandardPageStructure>
  )
}

export default PoliciesMain
