import { Container } from '@mui/material'

import { styled } from '@mui/material/styles'

type RootBoxStyledType = {
  $backgroundColor: string
  $padding?: number
}

const WrapperBox = styled(Container, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<RootBoxStyledType>(({ $backgroundColor, theme, $padding = 4 }) => ({
  width: '100%',
  backgroundColor: $backgroundColor || 'white',
  paddingLeft: `${theme.spacing($padding)} !important`,
  paddingRight: `${theme.spacing($padding)} !important`,
  [theme.breakpoints.down('md')]: {
    minHeight: '85vh',
    paddingBottom: theme.spacing($padding),
    paddingTop: theme.spacing($padding),
  },
}))

export default WrapperBox
