import { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import ReCAPTCHA from 'react-google-recaptcha'

import { ShoppingBag as ShoppingBagIcon } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material'

import {
  AddressSection,
  CompanySizeSection,
  PaymentSection,
  PlanDrawer,
  PlanSection,
} from './components'
import { LoadingTrails, MUI } from 'components'

import { useAuth, useMediaQuery } from 'hooks'
import { useFetch } from 'hooks'

import { CheckoutSchemaType } from 'constants/checkout/form'

import constants from 'constants/index'
import { StandardFlagIdType, StandardLabelType } from 'constants/cardFlags'

import { getBaseURL, getGoogleRecaptchaToken } from 'service/env'
import { theme } from 'theme'
import service from 'service'

import { CheckoutFormType } from './checkoutForm.types'
import { formatters } from 'helpers'

import schema from './schema'
import { PaymentContractType } from 'types/paymentOrder.types'

const CheckoutForm = ({
  segment,
  preRegistration,
  setTrailId,
  setErrorTitle,
}: CheckoutFormType) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [brand, setBrand] = useState<StandardLabelType>('Visa')
  const inputRef = useRef<HTMLButtonElement>(null)
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const { userCompany, user } = useAuth()
  const address = preRegistration?.address || userCompany?.company?.address

  const { response, loading: isLoadingPlans } = useFetch(
    service.dponet.plan.get,
    { segment },
    [segment]
  )

  const { response: responseContracts, loading: isLoadingContracts } = useFetch(
    service.dponet.financialPanel.listContracts,
    {},
    []
  )

  const paymentContracts: PaymentContractType[] =
    responseContracts?.data?.paymentContracts
  const creditCards = paymentContracts?.map(
    (paymentContract) => paymentContract.creditCard
  )

  const navigate = useNavigate()
  const mediaQuery = useMediaQuery()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState,
    register,
    ...formRest
  } = useForm<CheckoutSchemaType>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      plan: 'dponet',
      address: {
        street: address?.street ?? '',
        number: address?.number ?? '',
        neighborhood: address?.neighborhood ?? '',
        complement: address?.complement ?? '',
        postalCode: formatters.cep.format(address?.postalCode) ?? '',
        city: address?.city ?? '',
        state: address?.state ?? '',
      },
      card: {
        name: '',
        number: '',
        cvv: '',
        expiration: '',
      },
      companySize: preRegistration?.planId?.toString(),
      privacyNotice: false,
      terms: false,
      recaptcha: '',
    },
  })

  useEffect(() => {
    register('card.id')
    register('typeCard')
  }, [register])

  useEffect(() => {
    if (!isLoadingContracts && isEmpty(creditCards)) {
      setValue('typeCard', true)
    }
    //eslint-disable-next-line
  }, [isLoadingContracts])

  const smUp = mediaQuery.up.sm

  const portalUrl = getBaseURL('dponetPortal')
  const recaptcha = watch('recaptcha')
  const privacyNotice = watch('privacyNotice')
  const terms = watch('terms')

  const handleClickBack = () => navigate(constants.routes.root)

  const handleOpenDrawer = () => setOpenDrawer(true)
  const handleCloseDrawer = () => setOpenDrawer(false)

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    expiredRecaptcha()
  }

  const expiredRecaptcha = () => setValue('recaptcha', '')

  const handleRecaptcha = (token: string | null) => {
    if (token) setValue('recaptcha', token)
  }

  const pay = async (preRegistrationToken: string, cardId?: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const method: StandardFlagIdType = brand
        ? constants.cardFlags.METHOD_ID_DEFINITION_BY_BRAND[brand]
        : 1
      const response = await service.dponet.payment.create({
        preRegistrationToken,
        method,
        card: { id: cardId },
      })
      const status = response?.data?.status
      switch (status) {
        case constants.paymentOrder.FINANCIAL_PAID_STATUS:
          setTrailId('success')
          setTimeout(goToPortal, 2000)
          break
        case constants.paymentOrder.FINANCIAL_PRE_CAPTURED_STATUS:
          setTrailId('pre_captured')
          break
        default:
          setTrailId('error')
          setErrorTitle('Ocorreu um erro ao processar o pagamento!')
      }
    } catch (e) {
      setErrorTitle(formatters.errorMessage(e))
      setTrailId('error')
    }
  }

  const createPreRegistration = async (data: CheckoutSchemaType) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const preRegistration = formatters.mountCheckoutData(
        recaptcha,
        data,
        userCompany?.company,
        user,
        data.companySize,
        brand
      )

      setTrailId('processing')

      const response =
        await service.dponet.preRegistration.promote(preRegistration)
      return response
    } catch (e) {
      throw e
    }
  }

  const goToPortal = async () => {
    setTrailId('redirect')
    const token = service.dponet.auth.getToken()
    const cookieToken = service.dpoeasy.auth.getToken()

    setTimeout(() => {
      service.dponet.auth.logout()

      window.open(`${portalUrl}/dpo-easy/${token}/${cookieToken}`, '_self')
    }, 2500)
  }

  const onSubmit = async (data: CheckoutSchemaType) => {
    try {
      resetRecaptcha()
      const response = await createPreRegistration(data)
      const tokenPreRegistration = response?.data?.token
      await pay(tokenPreRegistration, data?.card?.id)
    } catch (e) {
      setErrorTitle(formatters.errorMessage(e))
      setTrailId('error')
    }
  }

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      setValue={setValue}
      watch={watch}
      register={register}
      formState={formState}
      {...formRest}
    >
      <LoadingTrails
        open={isLoadingPlans || isLoadingContracts}
        variant="white"
      />
      <Grid
        container
        maxHeight="auto"
        component="form"
        id="checkout-form"
        onSubmit={handleSubmit(onSubmit)}
        spacing="3vw"
      >
        <Grid
          item
          xs={12}
          xl={5}
          height="100%"
          display="flex"
          flexDirection="column"
          position="relative"
          gap={4}
          marginTop={4}
        >
          <Box
            {...(mediaQuery.up.xl && { position: 'fixed', maxWidth: '37%' })}
          >
            <Typography variant="h4" color="primary" textAlign="center">
              Dê um upgrade em sua adequação!
            </Typography>
            {smUp ? (
              <PlanSection inputRef={inputRef} />
            ) : (
              <Box display="flex" justifyContent="center">
                <MUI.IconButton
                  color="primary"
                  size="large"
                  onClick={handleOpenDrawer}
                  $backgroundColor={`${theme.palette.custom.main} !important`}
                >
                  <ShoppingBagIcon />
                </MUI.IconButton>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} xl={7} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" gap={4}>
            <CompanySizeSection plans={response?.data?.plans} />
            <AddressSection inputRef={inputRef} />
            <PaymentSection setBrand={setBrand} creditCards={creditCards} />
          </Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Box display="flex" flexDirection="column">
              {constants.checkout.form.CONTROLLER_TERMS_ITEMS.map(
                (item, index) => (
                  <Controller
                    key={index}
                    render={({ field }) => (
                      <FormControl {...field}>
                        <FormControlLabel
                          label={item.label}
                          control={<Checkbox size="medium" />}
                        />
                        {!!formState?.errors?.[item?.controlName] && (
                          <FormHelperText
                            error={!!formState?.errors?.[item?.controlName]}
                          >
                            {formState?.errors?.[item?.controlName]?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                    control={control}
                    name={item?.controlName}
                  />
                )
              )}
            </Box>
            <MUI.Box
              display="flex"
              justifyContent="space-between"
              $mdDown={{ flexDirection: 'column' }}
              gap={6}
              mb={4}
            >
              <Controller
                render={({ field }) => (
                  <FormControl {...field}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={getGoogleRecaptchaToken()}
                      onChange={(token) => handleRecaptcha(token)}
                      onExpired={expiredRecaptcha}
                      size="normal"
                    />
                    {!!formState.errors.recaptcha && (
                      <FormHelperText error={!!formState.errors.recaptcha}>
                        {formState?.errors?.recaptcha?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                control={control}
                name="recaptcha"
              />
              <Grid container spacing={6} justifyContent="end" alignItems="end">
                <Grid item xs={6} md={3}>
                  <MUI.Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={handleClickBack}
                    $backgroundColor="white"
                  >
                    Voltar
                  </MUI.Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    fullWidth
                    disabled={!recaptcha || !privacyNotice || !terms}
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </MUI.Box>
          </Box>
        </Grid>
      </Grid>
      <PlanDrawer open={openDrawer} onClose={handleCloseDrawer} />
    </FormProvider>
  )
}

export default CheckoutForm
