import { useContext } from 'react'
import { JourneyContext } from 'contexts'

const useJourney = () => {
  const context = useContext(JourneyContext)

  if (context === undefined) {
    throw new Error('useJourney must be used within a JourneyContext.Provider')
  }

  return context
}

export default useJourney
