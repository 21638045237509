import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

import { theme } from 'theme'

const MenuButton = styled(Button, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $backgroundColor: Property.BackgroundColor | undefined
  $paddingLeft?: Property.PaddingLeft<TLength> | undefined
}>(({ $backgroundColor, $paddingLeft }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textTransform: 'none',
  color: theme.palette.primary.contrastText,
  paddingLeft: $paddingLeft,
  backgroundColor: $backgroundColor,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export default MenuButton
