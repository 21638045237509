import { Outlet } from 'react-router-dom'
import { Box, Hidden, ThemeProvider } from '@mui/material'

import {
  DescriptionBox,
  Image,
  LeftBox,
  RootBox,
  Stepper,
  WrapperBox,
} from './components'

import { JourneyProvider } from 'providers'
import { themeJourney } from 'theme'

import { useMediaQuery } from 'hooks'

import { JourneyLayoutType } from './journeyLayout.types'

import DPOEasyLogo from 'images/dpoeasy_logo.svg'

const JourneyLayout = ({
  journey = 'welcome',
  height = '100%',
}: JourneyLayoutType) => {
  const { up } = useMediaQuery()

  return (
    <ThemeProvider theme={themeJourney}>
      <JourneyProvider>
        <RootBox>
          <LeftBox background={journey === 'welcome' ? 'gradient' : 'normal'}>
            <Image width="40%" src={DPOEasyLogo} alt="Logo DPO Easy" />
            <Hidden mdDown>
              <DescriptionBox />
            </Hidden>
            <Stepper />
          </LeftBox>
          <WrapperBox>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              {...(up.md && {
                height: '100vh',
                sx: { overflowY: 'auto', overflowX: 'hidden' },
              })}
            >
              <Box width="100%" height={height}>
                <Outlet />
              </Box>
            </Box>
          </WrapperBox>
        </RootBox>
      </JourneyProvider>
    </ThemeProvider>
  )
}

export default JourneyLayout
