import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'

import { isEmpty } from 'lodash'

import { MUI, StandardTableCell } from 'components'
import { FileText as FileTextIcon } from 'react-feather'

import { formatters, validates } from 'helpers'

import { InvoicesType } from './invoices.types'

const Invoices = ({ paymentOrders }: InvoicesType) => {
  const getNfe = (id: number | string) => !!id

  return (
    <Paper variant="outlined" elevation={0} sx={{ m: 4 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StandardTableCell align="center" width="5%">
                ID
              </StandardTableCell>
              <StandardTableCell align="left" width="15%">
                Pagamento
              </StandardTableCell>
              <StandardTableCell align="left" width="15%">
                Nº do contrato
              </StandardTableCell>
              <StandardTableCell align="left" width="10%">
                Plano
              </StandardTableCell>
              <StandardTableCell align="center" width="15%">
                Data de vencimento
              </StandardTableCell>
              <StandardTableCell align="center" width="15%">
                Mês de referência
              </StandardTableCell>
              <StandardTableCell align="center" width="10%">
                Nota fiscal
              </StandardTableCell>
              <StandardTableCell align="center" width="20%">
                Status
              </StandardTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty(paymentOrders) && (
              <TableRow>
                <StandardTableCell align="center" colSpan={7}>
                  Nenhuma fatura foi encontrada
                </StandardTableCell>
              </TableRow>
            )}
            {paymentOrders?.map((order) => (
              <TableRow key={order?.id}>
                <StandardTableCell align="center">
                  {order?.id}
                </StandardTableCell>
                <StandardTableCell align="left">
                  {order?.paymentMethod}
                </StandardTableCell>
                <StandardTableCell align="left">
                  {order?.contractIdentifier}
                </StandardTableCell>
                <StandardTableCell align="left">
                  {order?.planName ?? '-'}
                </StandardTableCell>
                <StandardTableCell align="center">
                  {formatters.date.ptBrFormatDate(order?.expirationDate) || '-'}
                </StandardTableCell>
                <StandardTableCell align="center">
                  {formatters.date
                    .ptBrFormatMonthYear(order?.orderReferenceDate)
                    ?.toLocaleUpperCase()}
                </StandardTableCell>
                <StandardTableCell align="center">
                  <Tooltip title={!order?.haveNfe ? 'NF-e não emitida' : ''}>
                    <Box>
                      <IconButton
                        size="small"
                        disabled={!order?.haveNfe}
                        onClick={() => getNfe(order?.id)}
                      >
                        <FileTextIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </StandardTableCell>
                <StandardTableCell align="center">
                  <MUI.Chip
                    size="small"
                    variant="filled"
                    label={validates.settings.readingStatusTranslation(
                      order?.status
                    )}
                    color={validates.settings.chipStatusColor(order?.status)}
                  />
                </StandardTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default Invoices
