import * as yup from 'yup'

const schema = yup.object({
  automaticAnswerEnabled: yup.boolean().required(),
  automaticAnswer: yup.string().when(['automaticAnswerEnabled'], {
    is: (automaticAnswerEnabled?: boolean | null) => automaticAnswerEnabled,
    then: () => yup.string().required().min(4),
  }),
})

export type AutomaticAnswerSchemaType = yup.InferType<typeof schema>

export type AutomaticAnswerControlType = keyof AutomaticAnswerSchemaType

export default schema
