import routes from 'constants/routes'

import { isEmpty } from 'lodash'

import { NavigateFunction } from 'react-router-dom'

import { getBaseURL } from 'service/env'
import formatters from './formatters'

import { CompanyMaturityType } from 'types/maturityQuestionnaire.types'
import { CompanyMinimalType } from 'types/company.types'

const mountHomeSteps = (
  navigate: NavigateFunction,
  company?: CompanyMinimalType,
  companyMaturity?: CompanyMaturityType
) => {
  const privacyPortalBase = getBaseURL('privacyPortal')
  const automationConfigured = !!companyMaturity?.privacyChannelConfigured
  const createdDate = formatters.date.ptBrFormatDateTime(company?.createdAt)

  const goTo = (route: string) => {
    navigate(route)
  }

  return [
    {
      name: 'Criação do Canal de Atendimento',
      date: createdDate,
      buttonName: 'Visualizar',
      completed: true,
      action: () =>
        window.open(
          `${privacyPortalBase}/portal-de-privacidade?token=${company?.token}`,
          '_blank'
        ),
    },
    {
      name: 'Aviso de Privacidade',
      date: createdDate,
      buttonName: 'Editar',
      completed: true,
      action: () => goTo(routes.policies.all),
    },
    {
      name: 'Políticas de Cookies',
      date: createdDate,
      buttonName: 'Editar',
      completed: true,
      action: () => goTo(routes.policies.all),
    },
    {
      name: 'Criação do Banner de Cookies',
      date: createdDate,
      buttonName: 'Editar',
      completed: true,
      action: () => goTo(routes.banners.all),
    },
    {
      name: 'Automação do Canal de Atendimento',
      date: formatters.date.ptBrFormatDateTime(
        companyMaturity?.privacyChannelConfiguredAt
      ),
      buttonName: automationConfigured ? 'Editar' : 'Iniciar',
      completed: automationConfigured,
      action: () =>
        goTo(
          automationConfigured
            ? routes.serviceChannel.automaticAnswer
            : routes.privacyChannel.configuration
        ),
    },
    {
      name: 'Checklist de Maturidade',
      date: formatters.date.ptBrFormatDateTime(companyMaturity?.answeredAt),
      buttonName: 'Editar',
      completed: !isEmpty(companyMaturity?.answers),
      action: () => goTo(routes.maturityQuestionnaire),
    },
  ]
}

export default mountHomeSteps
