import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/hooks.types'

import constants from 'constants/index'

const PolicyFilters = ({ filter }: { filter: FilterHookType }) => {
  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identificador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Título', name: 'title' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Status',
          name: 'status',
          options: constants.policies.STATUSES_OPTIONS.map((status) => ({
            id: status.id,
            name: status.name,
          })),
        }}
      />
    </Filters>
  )
}

export default PolicyFilters
