import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const ColorSuccess = styled(Box)(({ theme }) => ({
  color: theme.palette.success.main,
  gap: theme.spacing(2),
  display: 'flex',
}))

export default ColorSuccess
