import api from 'service/api'

import { getPaymentAuthKey } from 'service/env'

const dponetAPI = api.create('dponet', false)

const index = async (params: object) => {
  return await dponetAPI.get('/payment_orders', { params })
}

const create = async (data: object) => {
  return await dponetAPI.post('/payment_orders', {
    authKey: getPaymentAuthKey(),
    paymentOrder: data,
  })
}

export default { index, create }
