import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { size } from 'lodash'
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { CheckoutSchemaType } from 'constants/checkout/form'

import { formatters } from 'helpers'

import { useFetch } from 'hooks'

import { CEPType } from 'types/viaCEP.types'
import { AddressSectionType } from './addressSection.types'
import { HandleOnChangeType } from 'views/Checkout/checkout.types'

import { theme } from 'theme'
import service from 'service'
import constants from 'constants/index'

const AddressSection = ({ inputRef }: AddressSectionType) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<CheckoutSchemaType>()

  const cep = watch('address.postalCode')
  const isCEP = size(cep) === 9

  const { response, loading: loadingCep } = useFetch(
    service.external.viaCEP.get,
    {
      cep,
    },
    [cep],
    isCEP
  )

  const address: CEPType = response?.data

  const handleOnChange = ({ event, type }: HandleOnChangeType) => {
    let inputValue = event?.target?.value

    if (type === 'address.state')
      inputValue = inputValue.slice(0, 2).toUpperCase()
    else if (type === 'address.number') inputValue = inputValue.toUpperCase()
    else if (type === 'address.postalCode')
      inputValue = formatters.cep.format(inputValue)

    setValue(type, inputValue)
  }

  useEffect(() => {
    if (isCEP) {
      setValue('address.city', address?.localidade)
      setValue('address.neighborhood', address?.bairro)
      setValue('address.complement', address?.complemento)
      setValue('address.state', address?.uf)
      setValue('address.street', address?.logradouro)
    } else {
      setValue('address.city', '')
      setValue('address.neighborhood', '')
      setValue('address.complement', '')
      setValue('address.state', '')
      setValue('address.street', '')
      setValue('address.number', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isCEP])

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h6" color="primary">
        Endereço
      </Typography>
      <Grid container spacing={4}>
        {constants.checkout.form.CONTROLLER_ADDRESS_ITEMS.map((item, index) => {
          const disabledInput =
            !isCEP && item?.controlName !== 'address.postalCode'

          const fieldName = item?.controlName?.split(
            '.'
          )[1] as keyof CheckoutSchemaType['address']

          return (
            <Grid key={index} item xs={12} sm={item?.gridSm} md={item?.gridMd}>
              <Box display="flex" gap={1}>
                <Typography color="primary">{item.label}</Typography>
                {item?.required && <Typography color="error">*</Typography>}
              </Box>
              <Controller
                render={({ field }) => (
                  <Tooltip
                    title={
                      disabledInput &&
                      'Por favor, informe o código postal antes de preencher este campo.'
                    }
                  >
                    <TextField
                      {...field}
                      error={!!errors?.address?.[fieldName]}
                      helperText={<>{errors?.address?.[fieldName]?.message}</>}
                      disabled={disabledInput}
                      InputProps={{
                        inputMode: item?.type,
                        ...(loadingCep && {
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress size={22} />
                            </InputAdornment>
                          ),
                        }),
                        sx: {
                          backgroundColor: disabledInput
                            ? theme.palette.disabled.background
                            : theme.palette.common.white,
                        },
                      }}
                      onChange={(event) =>
                        handleOnChange({ event, type: item?.controlName })
                      }
                      inputRef={
                        item?.controlName === 'address.postalCode'
                          ? inputRef
                          : undefined
                      }
                      fullWidth
                    />
                  </Tooltip>
                )}
                control={control}
                name={item.controlName}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default AddressSection
