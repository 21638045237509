import { MUI } from 'components'

import { BannerPlanCellType } from './bannerPlanCell.types'

const BannerPlanCell = ({ plan, handleUpgrade }: BannerPlanCellType) => {
  return (
    <MUI.Box display="flex" flexDirection="column" $alignItems="baseline">
      <MUI.Typography gutterBottom>
        {plan?.name?.toUpperCase() ?? 'GRATUITO'}
      </MUI.Typography>
      {plan?.kind !== 'unlimited' && (
        <MUI.Button
          $color="primary"
          variant="contained"
          size="small"
          onClick={handleUpgrade}
        >
          Fazer Upgrade
        </MUI.Button>
      )}
    </MUI.Box>
  )
}

export default BannerPlanCell
