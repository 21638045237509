// import { useState } from 'react'
// import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import { Hidden, Paper, TablePagination } from '@mui/material'

import {
  StandardPageStructure,
  NoData,
  PageHead,
  LoadingFeedback,
  // MUI,
} from 'components'
import {
  BannerTable,
  // NewBannerModal
} from './components'

import { useFetch, usePagination } from 'hooks'

import service from 'service'

const BannerMain = () => {
  // const [isLoading, setIsLoading] = useState(false)
  // const [openModal, setOpenModal] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dpoeasy.banner.get,
    {
      perPage,
      page,
    },
    [perPage, page]
  )

  const banners = response?.data?.banners

  // const handleOpenModal = () => setOpenModal(true)
  // const handleCloseModal = () => setOpenModal(false)

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Meus banners"
        // actionButton={
        //   <MUI.Button
        //     startIcon={<PlusCircleIcon size={18} />}
        //     variant="contained"
        //     onClick={handleOpenModal}
        //     $smDown={{ width: '100%' }}
        //   >
        //     Adicionar Site
        //   </MUI.Button>
        // }
      />
      {isEmpty(banners) ? (
        <NoData
          title="Nenhum banner foi encontrado"
          subTitle="Vincule um novo banner a algum site"
        />
      ) : (
        <Paper variant="outlined">
          <BannerTable banners={banners} />
          <TablePagination
            component="div"
            count={response?.data?.banners?.length || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            slotProps={{
              actions: {
                previousButton: { size: 'small' },
                nextButton: { size: 'small' },
              },
            }}
          />
        </Paper>
      )}
      {/* <NewBannerModal
        open={openModal}
        onClose={handleCloseModal}
        setLoading={setIsLoading}
        refresh={refresh}
      /> */}
    </StandardPageStructure>
  )
}

export default BannerMain
