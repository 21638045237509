import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material'

import { LoadingFeedback, StandardPageStructure } from 'components'
import {
  ProductCard,
  ProductRequestModal,
  SupplierInfoCard,
} from './components'

import { useAuth, useFetch } from 'hooks'

import { PartnerServicesResponseType } from 'types/marketplace/partners.types'

import service from 'service'

const ProductShow = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [openRequestModal, setOpenRequestModal] = useState(false)

  const { partnerServiceId } = useParams()
  const { userCompany, user } = useAuth()

  const userValidation = isEmpty(userCompany) || isEmpty(user)

  const { response, loading } = useFetch(service.marketplace.partners.get, {
    partnerServiceId,
  })

  const partnerService: PartnerServicesResponseType =
    response?.data?.partnerService

  const attributes = partnerService?.resources?.attributes

  const handleOpenRequestModal = () => setOpenRequestModal(true)
  const handleCloseRequestModal = () => setOpenRequestModal(false)

  return (
    <StandardPageStructure>
      <LoadingFeedback open={isLoading || loading} />
      <Paper variant="outlined" component={Box} p={5}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <ProductCard partnerService={partnerService} />
            </Paper>
          </Grid>
          <Grid item xs={12} xl={8} textAlign="justify">
            <Typography variant="h6" color="primary" gutterBottom>
              Sobre a empresa
            </Typography>
            <Typography variant="body2" color="primary">
              {partnerService?.partner?.description}
            </Typography>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              Características da solução
            </Typography>
            {isEmpty(attributes) ? (
              <Typography variant="body2" color="primary">
                Nenhuma característica fornecida
              </Typography>
            ) : (
              <Typography variant="body2" color="primary" component="ul" pl={4}>
                {attributes?.map((attributes) => <li>{attributes}</li>)}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            xl={8}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <SupplierInfoCard
              supplier={partnerService?.partner?.tradeName}
              category={partnerService?.companyService?.serviceCategory?.name}
            />
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                title={
                  userValidation &&
                  'Ocorreu uma falha ao tentar recuperar dados do usuário, por favor entre em contato com o suporte.'
                }
              >
                <Box>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleOpenRequestModal}
                    disabled={userValidation}
                  >
                    Solicitar Serviço
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {!userValidation && (
        <ProductRequestModal
          open={openRequestModal}
          onClose={handleCloseRequestModal}
          partnerService={partnerService}
          userCompany={userCompany}
          user={user}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </StandardPageStructure>
  )
}

export default ProductShow
