import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Grid, Typography } from '@mui/material'

import { MUI } from 'components'

import { ProductCardType } from './productCard.types'

const ProductCard = ({ partnerService }: ProductCardType) => {
  return (
    <Grid container spacing={4} textAlign="justify" alignItems="center" p={4}>
      <Grid item xs={12} lg={4} xl={3.5}>
        <Box display="flex" justifyContent="center">
          <MUI.Img
            src={partnerService?.partner?.logo ?? ''}
            alt={`Logo ${partnerService?.partner?.name}`}
            $maxWidth={170}
            $alignContent="center"
            $textAlign="center"
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
        xl={8.5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100%"
        gap={4}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontWeight={600} color="primary">
            {partnerService?.companyService?.serviceCategory?.name}
            &nbsp;-&nbsp;
            {partnerService?.companyService?.name}
          </Typography>
          <Box height={130}>
            <PerfectScrollbar>
              <Typography variant="body2" color="primary" pr={4}>
                {partnerService?.description}
              </Typography>
            </PerfectScrollbar>
          </Box>
        </Box>
        {partnerService?.partner && (
          <Typography variant="body2" color="primary">
            Oferecido por:&nbsp;
            <strong>{partnerService?.partner?.tradeName}</strong>
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default ProductCard
