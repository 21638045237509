import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTrait = api.create('dponet', false)

const get = async () => await dponetAPI.get('/company_maturity')

const reply = async (data: object) =>
  await dponetAPINotTrait.put('/company_maturity/reply', data)

const nameDpo = async (data: object) =>
  await dponetAPINotTrait.put('/company_maturity/name_dpo', data)

const removeDpo = async () =>
  await dponetAPINotTrait.put('/company_maturity/remove_dpo')

export default {
  get,
  reply,
  nameDpo,
  removeDpo,
}
