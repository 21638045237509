import globalComponents from 'utils/jsPDF/components'
import components from '../../components'

import { formatters, validates } from 'helpers'

import { TicketMessagesType } from './ticketMessages.types'

import globalTemplates from 'utils/jsPDF/templates'
import constants from 'constants/index'
import { theme } from 'theme'

const ticketMessages = ({
  pdf,
  date,
  ticketMessages,
  exportOptions,
}: TicketMessagesType) => {
  const GAP = constants.jsPdf.GAP
  const ROW_GAP = constants.jsPdf.ROW_GAP

  const solicitationMessagesValidation = () => {
    if (exportOptions?.externalMessage && !exportOptions?.internalMessage) {
      return (
        ticketMessages?.filter(
          (message) => message?.kind === 2 || message?.kind === 3
        ) || []
      )
    } else if (
      exportOptions?.internalMessage &&
      !exportOptions?.externalMessage
    ) {
      return ticketMessages?.filter((message) => message?.kind === 1) || []
    } else {
      return ticketMessages || []
    }
  }

  const messages = solicitationMessagesValidation().slice().reverse()

  globalTemplates.chapterCover({
    pdf,
    title: 'MENSAGENS',
    subtitle:
      'Síntese das principais informações referente as mensagens e interações do ticket',
  })

  pdf.addPage('a4', 'p')

  globalComponents.lineWithDate({
    pdf,
    date: date,
  })

  globalComponents.numberAndTitle({ pdf, number: '2', title: 'Mensagens' })

  let pageNumber = pdf.getCurrentPageInfo().pageNumber
  globalComponents.numberPage({ pdf, color: theme.palette.primary.main })

  messages.map((message, index) => {
    const interactionRows = message?.ticketMessageInteractions?.map(
      (interaction, index) => {
        return [
          `Interação ${index + 1}`,
          interaction.content || 'Não possui ou não descreve suas interações',
        ]
      }
    )

    components.table({
      pdf,
      body: [
        ['Nome do atendente', message?.author?.name || 'Não informado'],
        [
          'Tipo de mensagem',
          validates.ticket.discoveringKindMessage(
            message?.kind,
            'Não informado'
          ),
        ],
        [
          'Data da publicação',
          formatters.date.ptBrFormatDateTime(
            message?.createdAt,
            'Não possui data definida'
          ),
        ],
        ['Mensagem', message?.content || 'Não informado'],
        ...(interactionRows || []),
      ],
      startY: index === 0 ? GAP * 3.5 : pdf?.lastAutoTable?.finalY + ROW_GAP,
      cellWidth: 0.3,
      type: message?.kind,
    })
    if (pageNumber != pdf.getCurrentPageInfo().pageNumber) {
      globalComponents.numberPage({ pdf, color: theme.palette.primary.main })
      pageNumber = pdf.getCurrentPageInfo().pageNumber
    }
  })
}

export default ticketMessages
