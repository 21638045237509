import { CSSProperties } from 'react'

import loadingAnimation from 'images/animations/loading.json'
import successAnimation from 'images/animations/success.json'
import errorAnimation from 'images/animations/error.json'

const PROCESSING_TRAIL_ID = 'processing'
const COMPANY_CREATING_TRAIL_ID = 'creating'
const PRE_CAPTURED_TRAIL_ID = 'pre_captured'
const SUCCESS_TRAIL_ID = 'success'
const REDIRECTION_TRAIL_ID = 'redirect'
const ERROR_TRAIL_ID = 'error'

const ALL_TRAIL_IDS = [
  COMPANY_CREATING_TRAIL_ID,
  PROCESSING_TRAIL_ID,
  PRE_CAPTURED_TRAIL_ID,
  SUCCESS_TRAIL_ID,
  REDIRECTION_TRAIL_ID,
  ERROR_TRAIL_ID,
] as const

const COMPANY_CREATING_ITEM: CheckoutTrailItem = {
  id: COMPANY_CREATING_TRAIL_ID,
  title: 'Estamos configurando sua organização, por favor, aguarde.',
  animation: loadingAnimation,
  style: { height: 350, transform: 'scale(2)' },
  loop: true,
}

const PROCESSING_TRAIL_ITEM: CheckoutTrailItem = {
  id: PROCESSING_TRAIL_ID,
  title: 'Estamos processando seu pagamento',
  animation: loadingAnimation,
  style: { height: 350, transform: 'scale(2)' },
  loop: true,
}

const PRE_CAPTURED_TRAIL_ITEM: CheckoutTrailItem = {
  id: PRE_CAPTURED_TRAIL_ID,
  title: 'Seu pagamento está sendo processado.',
  subTitle:
    'Instruções de como prosseguir serão enviadas para o seu e-mail de acesso. Em caso de dúvidas, entre em contato através do financeiro@dponet.com.br',
  animation: successAnimation,
  style: { height: 287, marginBottom: 30, marginTop: 100 },
  loop: false,
}

const SUCCESS_TRAIL_ITEM: CheckoutTrailItem = {
  id: SUCCESS_TRAIL_ID,
  title: 'Pagamento aprovado!',
  animation: successAnimation,
  style: { height: 350 },
  loop: false,
}

const REDIRECTION_TRAIL_ITEM: CheckoutTrailItem = {
  id: REDIRECTION_TRAIL_ID,
  title: 'Estamos redirecionando você para a plataforma',
  animation: successAnimation,
  style: { height: 350 },
  loop: false,
}

const ERROR_TRAIL_ITEM: CheckoutTrailItem = {
  id: ERROR_TRAIL_ID,
  title: 'Desculpe, ocorreu um erro inesperado',
  subTitle: 'Entre em contato com nosso suporte para a avaliação do erro',
  animation: errorAnimation,
  style: { height: 287, marginBottom: 30, marginTop: 100 },
  loop: false,
}

const CHECKOUT_TRAIL_ITEM = {
  [PROCESSING_TRAIL_ID]: PROCESSING_TRAIL_ITEM,
  [PRE_CAPTURED_TRAIL_ID]: PRE_CAPTURED_TRAIL_ITEM,
  [SUCCESS_TRAIL_ID]: SUCCESS_TRAIL_ITEM,
  [REDIRECTION_TRAIL_ID]: REDIRECTION_TRAIL_ITEM,
  [ERROR_TRAIL_ID]: ERROR_TRAIL_ITEM,
  [COMPANY_CREATING_TRAIL_ID]: COMPANY_CREATING_ITEM,
}

export type CheckoutTrailId = (typeof ALL_TRAIL_IDS)[number]

export type CheckoutTrailItem = {
  id: CheckoutTrailId
  title: string
  subTitle?: string
  animation: unknown
  style?: CSSProperties
  loop?: boolean
}

export default { CHECKOUT_TRAIL_ITEM }
