import { NodeChildren } from 'types/global.types'
import { isUndefined } from 'lodash'
import { WrapperBoxStyled } from './components'
import { useJourney } from 'hooks'
import { themeJourney } from 'theme'

const WrapperBox = ({ children }: NodeChildren) => {
  const { stepInfo } = useJourney()

  const maxWidth = stepInfo?.leftBoxDisplay == 'none' ? false : 'lg'
  const backgroundColor =
    stepInfo?.leftBoxDisplay == 'none'
      ? themeJourney.palette.background.default
      : 'white'
  const padding = stepInfo?.padding

  return (
    <WrapperBoxStyled
      maxWidth={maxWidth}
      $backgroundColor={backgroundColor}
      {...(!isUndefined(padding) && { $padding: padding })}
    >
      {children}
    </WrapperBoxStyled>
  )
}

export default WrapperBox
