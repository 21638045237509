import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  PaginationHookReturnedType,
  PaginationHookType,
} from 'types/hooks.types'

import qs from 'query-string'

const usePagination = ({
  initialPerPage = 10,
  pageLabel = 'page',
  perPageLabel = 'perPage',
  resetPage,
}: PaginationHookType = {}): PaginationHookReturnedType => {
  const location = useLocation()
  const navigate = useNavigate()

  const search = qs.parse(location.search)
  const searchPage = search?.[pageLabel] as string
  const searchPerPage = search?.[perPageLabel] as string
  const page = parseInt(searchPage) || 1
  const perPage = parseInt(searchPerPage) || initialPerPage

  const [query, setQuery] = useState({
    [pageLabel]: page,
    [perPageLabel]: perPage,
    ...search,
  })

  const handleChangePage = (newPage: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, [pageLabel]: newPage + 1 }))
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setQuery((prevQuery) => ({
      ...prevQuery,
      [perPageLabel]: newPerPage,
      [pageLabel]: 1,
    }))
  }

  useEffect(() => {
    const newQueryString = qs.stringify(query)
    navigate(`${location.pathname}?${newQueryString}`)
  }, [location.pathname, navigate, query])

  useEffect(() => {
    handleChangePage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPage])

  return { page, perPage, handleChangePage, handleChangeRowsPerPage }
}

export default usePagination
