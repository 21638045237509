import { ListItem as ListItemMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

const ListItem = styled(ListItemMUI)(({ theme }) => ({
  display: 'block',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

export default ListItem
