import { Box, Button, Grid, Paper, Typography } from '@mui/material'

import { useAuth } from 'hooks'

import welcomeImg from 'images/welcome_platform.svg'

const WelcomeCard = () => {
  const { setUCompanyMaturity } = useAuth()

  const handleOpenDialog = () => {
    setUCompanyMaturity((companyMaturity) => {
      if (!companyMaturity) return undefined

      return {
        ...companyMaturity,
        privacyChannelConfigured: false,
      }
    })
  }

  return (
    <Paper
      variant="outlined"
      component={Box}
      display="flex"
      height="100%"
      alignItems="center"
    >
      <Grid container p={8} spacing={8} justifyContent="center">
        <Grid item xs={12} sm={8} lg={5}>
          <img src={welcomeImg} width="100%" height="100%" />
        </Grid>
        <Grid item xs={12} lg={7} textAlign="justify">
          <Typography gutterBottom variant="h6" color="primary">
            Seja bem-vindo à DPO Easy
          </Typography>
          <Typography color="primary" gutterBottom>
            Parabéns pelo primeiro passo em direção à conformidade com a LGPD!
            Siga as etapas a seguir para avançar na configuração e adequação da
            sua empresa.
          </Typography>

          <Button onClick={handleOpenDialog}>Assistir Vídeo</Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default WelcomeCard
