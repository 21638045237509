import { ChangeEvent, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { isNil } from 'lodash'
import { visuallyHidden } from '@mui/utils'
import { Box, Button, ButtonProps, FormHelperText } from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'

import { InputFileType } from './inputFile.types'

const InputFile = ({
  accept,
  controlName,
  Icon = CloudUploadIcon,
  title,
  ...rest
}: InputFileType & ButtonProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleClickButton = () => inputRef?.current?.click()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files
    if (!isNil(files)) setValue(controlName, files[0])
  }

  const isError = !!errors?.[controlName]

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="fit-content">
        <Controller
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field }) => (
            <Button
              variant="contained"
              startIcon={<Icon size={20} sx={{ fontSize: 20 }} />}
              onClick={handleClickButton}
              color={isError ? 'error' : 'primary'}
              fullWidth
              {...rest}
            >
              {title}
              <input
                accept={accept}
                aria-label="Selecionar arquivo"
                ref={inputRef}
                style={visuallyHidden}
                type="file"
                onChange={handleFileChange}
              />
            </Button>
          )}
          control={control}
          name={controlName}
        />
      </Box>
      {isError && (
        <FormHelperText error={isError}>
          <>{errors?.[controlName]?.message}</>
        </FormHelperText>
      )}
    </Box>
  )
}

export default InputFile
