import home from './home'
import orders from './orders'
import products from './products'

const marketplace = {
  home,
  orders,
  products,
}

export default marketplace
