import { CircularProgress } from '@mui/material'

import { Backdrop } from 'components'

import { LoadingFeedbackType } from './loadingFeedback.types'

const LoadingFeedback = ({ open }: LoadingFeedbackType) => {
  return (
    <Backdrop open={open}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}

export default LoadingFeedback
