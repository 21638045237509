import { Alert, Typography } from '@mui/material'

const GreyAlert = ({ description }: { description: string }) => {
  return (
    <Alert
      severity="error"
      sx={{
        backgroundColor: '#D9D9D9',
        '.MuiAlert-icon': {
          color: '#233154',
        },
      }}
    >
      <Typography color="#233154" fontWeight="700">
        {description}
      </Typography>
    </Alert>
  )
}

export default GreyAlert
