import { useEffect, useRef, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import Lottie from 'lottie-react'

import { useJourney, usePrivacyChannel } from 'hooks'

import loadingAnimation from 'images/animations/loading.json'
import successAnimation from 'images/animations/success.json'

const DataLoading = () => {
  const [findData, setFindData] = useState(false)
  const lottieRef = useRef(null)

  const { handleNext, setNextDisabled } = useJourney()
  const { setShowFinishTemplateButton } = usePrivacyChannel()

  useEffect(() => {
    setNextDisabled(true)
    const timeout = setTimeout(() => {
      setFindData(true)
      setNextDisabled(false)
      setShowFinishTemplateButton(true)
    }, 3000)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    handleNext()
  }

  return (
    <Grid container spacing={6} display="grid" justifyItems="center" mt={25}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          color="primary"
          fontWeight={700}
          textAlign="center"
        >
          {findData
            ? 'Configuração automática concluída!'
            : 'Estamos configurando seu canal com base nos seus parceiros.'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {findData ? (
          <Lottie
            animationData={successAnimation}
            style={{ height: 200, width: 200 }}
            loop={false}
            initialSegment={[0, 130]}
            lottieRef={lottieRef}
            autoplay
          />
        ) : (
          <Lottie
            animationData={loadingAnimation}
            style={{ height: 200, width: 200, transform: 'scale(2)' }}
            loop
            autoplay
          />
        )}
      </Grid>
      <form id="form-register" onSubmit={onSubmit}></form>
    </Grid>
  )
}

export default DataLoading
