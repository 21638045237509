import { useState } from 'react'

import { JourneyContext } from 'contexts'

import { NodeChildren } from 'types/global.types'
import { StepDescription } from 'types/journey.types'

const JourneyProvider = ({ children }: NodeChildren) => {
  const [steps, setSteps] = useState<StepDescription[]>([])
  const [activeStep, setActiveStep] = useState(0)
  const [stepInfo, setStepInfo] = useState<StepDescription | undefined>()
  const [nextDisabled, setNextDisabled] = useState(false)
  const [backDisabled, setBackDisabled] = useState(false)

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1)
      setStepInfo(steps[activeStep + 1])
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
      setStepInfo(steps[activeStep - 1])
    }
  }

  return (
    <JourneyContext.Provider
      value={{
        activeStep,
        backDisabled,
        nextDisabled,
        stepInfo,
        steps,
        handleBack,
        handleNext,
        setActiveStep,
        setBackDisabled,
        setNextDisabled,
        setStepInfo,
        setSteps,
      }}
    >
      {children}
    </JourneyContext.Provider>
  )
}

export default JourneyProvider
