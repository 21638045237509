import { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { SelectableButton, AddInput } from 'components'

import { usePrivacyChannel, useJourney } from 'hooks'

const DataTreatment = () => {
  const {
    defaultsDataTreatments,
    setDefaultsDataTreatments,
    customDataTreatments,
    setCustomDataTreatments,
  } = usePrivacyChannel()
  const { setNextDisabled } = useJourney()

  const handleClickDataTreatment = (index: number) => {
    const newDataTreatments = [...defaultsDataTreatments]
    newDataTreatments[index].active = !newDataTreatments[index].active
    setDefaultsDataTreatments(newDataTreatments)
  }

  const handleClickDataTreatmentCustom = (index: number) => {
    const newDataTreatmentsCustom = [...customDataTreatments]
    newDataTreatmentsCustom.splice(index, 1)
    setCustomDataTreatments(newDataTreatmentsCustom)
  }

  const addDataTreatmentCustom = (textFieldValue: string) => {
    if (!textFieldValue) return

    const newDataTreatment = {
      active: true,
      name: textFieldValue,
    }

    setCustomDataTreatments([...customDataTreatments, newDataTreatment])
  }

  const activeCustoms = customDataTreatments.filter((customDataTreatment) => {
    return customDataTreatment.active === true
  })

  useEffect(() => {
    const allDataTreatments = [
      ...defaultsDataTreatments,
      ...customDataTreatments,
    ]

    const hasActives = allDataTreatments.filter(
      (treatment) => treatment.active === true
    )

    if (hasActives.length === 0) return setNextDisabled(true)

    setNextDisabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultsDataTreatments, customDataTreatments])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          fontWeight={700}
          textAlign="center"
          color="primary"
        >
          Dados Pessoais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap={5}>
          <Typography
            variant="h6"
            fontWeight={400}
            textAlign="center"
            color="primary"
            fontFamily="Raleway"
          >
            Selecione todos os tipos de dados pessoais que você coleta:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {defaultsDataTreatments.map((dataTreatment, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <SelectableButton
                icon={dataTreatment.icon}
                name={dataTreatment.name}
                active={dataTreatment.active}
                onClick={() => handleClickDataTreatment(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={400}
          textAlign="center"
          color="primary"
          fontFamily="Raleway"
        >
          Caso colete outros tipos de dados pessoais, cadastre-os a seguir:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {activeCustoms.map((customDataTreatment, index) => (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <SelectableButton
                name={customDataTreatment.name}
                active={customDataTreatment.active}
                onClick={() => {
                  handleClickDataTreatmentCustom(index)
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} lg={3}>
            <AddInput onClick={addDataTreatmentCustom} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DataTreatment
