import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty, isNull } from 'lodash'
import { Typography } from '@mui/material'

import {
  LoadingFeedback,
  MUI,
  PageHead,
  StandardPageStructure,
} from 'components'
import {
  ContactSpecialist,
  FeaturedCompanies,
  ServiceAbout,
  ServiceBenefits,
  ServiceIndicator,
} from './components'

import { validates } from 'helpers'

import { useFetch } from 'hooks'

import { ServiceType } from 'types/marketplace/services.types'
import { PartnerServicesResponseType } from 'types/marketplace/partners.types'

import { theme } from 'theme'
import constants from 'constants/index'
import service from 'service'

const MarketplaceShow = () => {
  const [isLoading, setIsLoading] = useState(true)

  const { serviceId } = useParams()
  const navigate = useNavigate()

  const { response, loading } = useFetch(
    service.marketplace.partners.getAll,
    {
      companyServiceId: serviceId,
    },
    [serviceId]
  )

  const product: ServiceType = validates.marketplace.servicePageContent(
    Number(serviceId)
  )

  const partnerServices: PartnerServicesResponseType[] =
    response?.data?.partnerServices

  useEffect(() => {
    if (isNull(product)) navigate(constants.routes.notFound)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 100)
  }, [])

  return (
    <StandardPageStructure>
      <LoadingFeedback open={isLoading || loading} />
      {!isNull(product) && (
        <Fragment>
          <MUI.Box
            bgcolor={theme.palette.primary.main}
            p={12}
            $smDown={{ padding: '36px 24px 36px 24px' }}
          >
            <PageHead
              name={product?.name}
              categories={[
                { name: 'Marketplace', url: constants.routes.marketplace.all },
                { name: product?.name, url: '#' },
              ]}
              themeComponent="dark"
              variant="h4"
              color="white"
            />
            <Typography color="white">{product?.description}</Typography>
          </MUI.Box>
          <MUI.Box
            display="flex"
            flexDirection="column"
            bgcolor={theme.palette.common.white}
            textAlign="justify"
            gap={12}
            p={12}
            $smDown={{ padding: '36px 24px 36px 24px' }}
          >
            <ServiceAbout product={product} />
            <ServiceIndicator product={product} />
            <ServiceBenefits product={product} />
            {!isEmpty(partnerServices) && (
              <FeaturedCompanies partnerServices={partnerServices} />
            )}
            <ContactSpecialist
              product={product}
              isLoadingState={{ isLoading, setIsLoading }}
            />
          </MUI.Box>
        </Fragment>
      )}
    </StandardPageStructure>
  )
}

export default MarketplaceShow
