import * as yup from 'yup'

const schema = yup.object({
  content: yup.string().required(),
  attachment: yup.mixed(),
})

export type TicketContentSchemaType = yup.InferType<typeof schema>

export type TicketContentControlType = keyof TicketContentSchemaType

export default schema
