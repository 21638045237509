import { useFormContext } from 'react-hook-form'
import { Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { BasicDialog } from 'components'

import { ChangePasswordModalType } from './changePasswordModal.types'
import { PersonalFormSchemaType } from 'constants/settings'

const ChangePasswordModal = ({
  open,
  onClose,
  loading,
  onSubmit,
}: ChangePasswordModalType) => {
  const { handleSubmit } = useFormContext<PersonalFormSchemaType>()

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Alteração de senha"
      dialogActions={
        <Box display="flex" gap={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Alterar
          </LoadingButton>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gap={5}>
        <Typography textAlign="justify">
          Ao alterar a senha,&nbsp;
          <strong>
            todas as senhas associadas aos serviços da DPOnet serão atualizadas
            automaticamente.
          </strong>
        </Typography>
        <Typography color="red" textAlign="center">
          Certifique-se de que deseja prosseguir com essa alteração e que a nova
          senha seja segura.
        </Typography>
      </Box>
    </BasicDialog>
  )
}

export default ChangePasswordModal
