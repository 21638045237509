import { Box } from '@mui/material'

import { Property } from 'csstype'
import { styled } from '@mui/material/styles'

const RoundedAlert = styled(Box, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $cursor?: Property.Cursor | undefined
  $paddingLeft?: string | number
  $backgroundColor: Property.BackgroundColor
}>(({ $cursor, $backgroundColor, $paddingLeft, theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  borderRadius: 46,
  paddingLeft: $paddingLeft || '10%',
  gap: theme.spacing(5),
  cursor: $cursor,
  backgroundColor: $backgroundColor,
}))

export default RoundedAlert
