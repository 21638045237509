import { AccordionSummary as AccordionSummaryMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const AccordionSummary = styled(AccordionSummaryMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $padding?: Property.Padding<TLength>
}>(({ $padding }) => ({
  padding: $padding,
}))

export default AccordionSummary
