import { createContext } from 'react'

import { AuthContextType } from 'types/auth.types'

const contextValues: AuthContextType = {
  loaded: false,
  loadData: async () => {},
  setUCompanyMaturity: () => {},
  loadCompanyMaturity: async () => {},
  loadOut: () => {},
  updateCompany: () => {},
}

const authContext = createContext(contextValues)

export default authContext
