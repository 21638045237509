import * as yup from 'yup'

const schema = yup.object({
  internalMessage: yup.bool(),
  externalMessage: yup.bool(),
})

export type TicketExportDialogFormType = yup.InferType<typeof schema>

export type TicketExportDialogControlType = keyof TicketExportDialogFormType

export default schema
