import { ChangeEvent, useState } from 'react'

import { FormControlLabel } from '@mui/material'

import { BasicSection, FormOutsourcedDpo, MUI } from 'components'

import { useFormContext } from 'react-hook-form'
import { useAuth } from 'hooks'

import { NamedDpoSchemaType } from 'schemas/namedDpoSchema'

import { NamedDpoSectionType } from './namedDpoSection.types'
import { formatters } from 'helpers'

const NamedDpoSection = ({ kind }: NamedDpoSectionType) => {
  const { setValue, unregister } = useFormContext<NamedDpoSchemaType>()
  const { companyMaturity } = useAuth()

  const [namedDpo, setNamedDpo] = useState(!!companyMaturity?.namedDpo)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const hasNamedDpo = e.target.value === 'true'
    setNamedDpo(hasNamedDpo)

    if (!hasNamedDpo) {
      unregister(
        [
          'namedDpo.document',
          'namedDpo.kindPerson',
          'namedDpo.name',
          'namedDpo.responsible',
          'namedDpo.substitute',
        ],
        { keepError: false, keepDirty: false }
      )
    } else {
      setValue(
        'namedDpo.document',
        formatters.cpfOrCnpj(companyMaturity?.namedDpo?.document ?? '')
      )
      setValue(
        'namedDpo.kindPerson',
        companyMaturity?.namedDpo?.kindPerson ?? ''
      )
      setValue('namedDpo.name', companyMaturity?.namedDpo?.name ?? '')
      setValue(
        'namedDpo.responsible',
        companyMaturity?.namedDpo?.responsible ?? ''
      )
      setValue(
        'namedDpo.substitute',
        companyMaturity?.namedDpo?.substitute ?? ''
      )
    }
  }

  return (
    <BasicSection title="DPO nomeado (Encarregado)">
      <MUI.Grid container spacing={4}>
        <MUI.Grid item xs={12}>
          <MUI.RadioGroup
            onChange={handleChange}
            defaultValue={namedDpo?.toString()}
          >
            <FormControlLabel
              value="false"
              control={<MUI.Radio />}
              label="Sou um agente de tratamento de pequeno porte"
            />
            <FormControlLabel
              value="true"
              control={<MUI.Radio />}
              label="Desejo nomear um DPO próprio"
            />
          </MUI.RadioGroup>
        </MUI.Grid>
        {namedDpo && <FormOutsourcedDpo kind={kind} />}
      </MUI.Grid>
    </BasicSection>
  )
}

export default NamedDpoSection
