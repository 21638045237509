import { Tab as TabMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const Tab = styled(TabMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $minWidth?: Property.MinWidth<TLength> | undefined
}>(({ $minWidth }) => ({
  minWidth: $minWidth,
}))

export default Tab
