import { useState } from 'react'
import { Grid, Typography } from '@mui/material'

import { BaseBox, FormNewPassword } from './components'

import { Link } from 'components'

const NewPassword = () => {
  const [isLoading, setLoading] = useState<boolean>(false)

  return (
    <BaseBox title="Recuperar senha">
      <Grid item xs={12}>
        <FormNewPassword isLoading={isLoading} setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <Link to="/">Voltar</Link>
        </Typography>
      </Grid>
    </BaseBox>
  )
}

export default NewPassword
