import { IconButton as IconButtonMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

type IconButtonType = {
  $backgroundColor?: Property.BackgroundColor
  $color?: Property.Color
}

const IconButton = styled(IconButtonMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<IconButtonType>(({ $backgroundColor, $color }) => ({
  backgroundColor: $backgroundColor,
  color: $color,
}))

export default IconButton
