import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PolicyForm } from './components'
import { LoadingFeedback, PageHead, StandardPageStructure } from 'components'

import { useFetch } from 'hooks'

import service from 'service'
import constants from 'constants/index'

const ManagePolicies = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { policyId } = useParams()

  const { response, loading } = useFetch(
    service.dponet.privacyPortal.get,
    { policyId },
    []
  )

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading || isLoading} />
      <PageHead
        name="Política de privacidade"
        categories={constants.policies.POLICIES_NEW_PAGE_HEADER_CATEGORIES}
      />
      {!loading && (
        <PolicyForm
          setLoading={setIsLoading}
          policy={response?.data?.privacyPolicy}
        />
      )}
    </StandardPageStructure>
  )
}

export default ManagePolicies
