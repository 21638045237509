import { useState } from 'react'
import { Alert, Box, Button, Paper } from '@mui/material'

import { DialogConfirmTitularData } from './components'

import { ConfirmTitularDataType } from './confirmTitularData.types'

const ConfirmTitularData = ({ refresh, ticket }: ConfirmTitularDataType) => {
  const [openDialogLegitimacy, setOpenDialogLegitimacy] = useState(false)
  const [ticketLegitimacy, setTicketLegitimacy] = useState<
    boolean | undefined
  >()

  const handleCloseDialogLegitimacy = () => setOpenDialogLegitimacy(false)
  const handleOpenDialogLegitimacy = (ticketLegitimacy: boolean) => {
    setOpenDialogLegitimacy(true)
    setTicketLegitimacy(ticketLegitimacy)
  }

  return (
    <Paper component={Box} elevation={0}>
      <Alert
        severity="info"
        variant="filled"
        icon={false}
        action={
          <Box display="flex" gap={2}>
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              onClick={() => handleOpenDialogLegitimacy(false)}
            >
              NÃO
            </Button>
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              onClick={() => handleOpenDialogLegitimacy(true)}
            >
              SIM
            </Button>
          </Box>
        }
      >
        É necessário confirmar a identidade do titular. Prosseguir?
      </Alert>

      <DialogConfirmTitularData
        open={openDialogLegitimacy}
        onClose={handleCloseDialogLegitimacy}
        result={ticketLegitimacy || false}
        ticketId={ticket.id}
        refresh={refresh}
      />
    </Paper>
  )
}

export default ConfirmTitularData
