import { Fragment } from 'react'
import { Image as ImageIcon } from 'react-feather'
import { Box, Divider, Hidden, Typography } from '@mui/material'

import { FileUploadInput } from 'components'

import constants from 'constants/index'

const LogoUpload = () => {
  return (
    <Fragment>
      <Box display="flex" flexDirection="column" width="100%">
        <Typography color="primary" fontWeight={600} gutterBottom>
          Logo
        </Typography>
        <FileUploadInput
          title="Faça o upload da sua logo aqui"
          subCaption="Proporções recomendadas de 500x500 pixels"
          accept={constants.banner.SUPPORTED_IMAGE_FILE_INPUT_TYPES}
          controlName="logo"
          StartIcon={ImageIcon}
          EndIcon={ImageIcon}
        />
      </Box>
      <Hidden lgDown>
        <Divider orientation="vertical" variant="middle" flexItem />
      </Hidden>
    </Fragment>
  )
}

export default LogoUpload
