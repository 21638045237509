import { Box, Button, Divider, Grid, Typography } from '@mui/material'

import { MUI } from 'components'

import { theme } from 'theme'
import upgradeImage from 'images/upgrade_to_dponet.svg'

import { handleUpgrade } from 'helpers'
import { useNavigate } from 'react-router-dom'
import routes from 'constants/routes'

const DPOnetCard = () => {
  const navigate = useNavigate()

  const goToCheckout = () => {
    navigate(routes.checkout.all)
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        bgcolor={theme.palette.primary.main}
        borderRadius="5px 5px 0px 0px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={4}
        py="15px"
      >
        <Typography color="white" variant="h5">
          DPOnet
        </Typography>
      </Box>
      <Divider />
      <Box
        bgcolor={theme.palette.primary.main}
        borderRadius="0px 0px 5px 5px"
        textAlign="justify"
        color="white"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={4}
        gap={8}
      >
        <Box>
          <MUI.Typography variant="h6" gutterBottom $lgOnly={{ fontSize: 18 }}>
            Garanta sua conformidade!
          </MUI.Typography>
          <Typography variant="body2">
            Como usuário da DPO Easy, você está aproveitando a versão gratuita
            para dar os primeiros passos em direção à adequação. Faça o upgrade
            para a DPOnet e tenha acesso à plataforma completa, que te
            possibilitará alcançar a conformidade.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <img src={upgradeImage} width="50%" />
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} xl={5}>
            <Button
              onClick={handleUpgrade}
              variant="outlined"
              color="inherit"
              fullWidth
            >
              Saiba Mais
            </Button>
          </Grid>
          <Grid item xs={12} xl={7}>
            <Button
              onClick={goToCheckout}
              variant="contained"
              color="tertiary"
              fullWidth
            >
              Atualizar para DPOnet
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default DPOnetCard
