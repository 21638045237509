import { Box, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import { MUI } from 'components'
import { QuestionChart } from './components'

import { useAuth } from 'hooks'

import { theme } from 'theme'
import maturityQuestionnaire from 'constants/maturityQuestionnaire'
import routes from 'constants/routes'

const QuestionCard = () => {
  const navigate = useNavigate()
  const { companyMaturity } = useAuth()
  const points = companyMaturity?.points ?? 0

  const handleQuestionnaire = () => navigate(routes.maturityQuestionnaire)
  const percentage =
    (points / maturityQuestionnaire.MATURITY_QUESTIONNAIRE.totalPoints) * 100

  return (
    <Box
      p={4}
      bgcolor={theme.palette.primary.main}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Box display="flex" alignItems="end" gap={2}>
            <Typography variant="h2" color="white">
              {percentage?.toFixed(0)}
            </Typography>
            <Typography variant="body1" fontWeight={600} color="white" pb={2}>
              %
            </Typography>
          </Box>
          <Typography color="white" textTransform="uppercase" variant="caption">
            Checklist de maturidade
          </Typography>
        </Box>
        <Box>
          <MUI.Button
            onClick={handleQuestionnaire}
            variant="text"
            color="inherit"
            $color="white"
          >
            Ver Mais
          </MUI.Button>
        </Box>
      </Box>
      <Box>
        <QuestionChart />
      </Box>
    </Box>
  )
}

export default QuestionCard
