import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
// import { useNavigate } from 'react-router-dom'

import ReCAPTCHA from 'react-google-recaptcha'

import { CardPlan } from 'views/WelcomeSteps/components'
import { Box, Container, Grid, Typography } from '@mui/material'
import { formatters, validates } from 'helpers'

import {
  useCheckoutTrails,
  useJourney,
  useRegistration,
  useSnackbar,
} from 'hooks'
import { useNavigate } from 'react-router-dom'

import { CheckoutTrails } from 'components'

import { FormAddress, FormCard, TextField, UsingTerm } from './components'
import { yupResolver } from '@hookform/resolvers/yup'

import { UserType } from 'types/user.types'

import schema, { WelcomePlanFormSchemaType } from './schema'

import service from 'service'

import constants from 'constants/index'
import { StandardFlagIdType } from 'constants/cardFlags'

import routes from 'constants/routes'

const Plan = () => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()
  const {
    trailId,
    setTrailId,
    errorTitle,
    setErrorTitle,
    styleTrail,
    styleChildren,
  } = useCheckoutTrails()

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const { setNextDisabled } = useJourney()

  // const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const { recaptcha, setRecaptcha, company, loadOut, integrationPartner } =
    useRegistration()

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    setRecaptcha(undefined)
  }

  const { setValue, handleSubmit, register, unregister, ...rest } = useForm({
    resolver: yupResolver(schema),
  })

  const planPrice = Number(integrationPartner?.plan.price ?? 1)
  const paidPlan = planPrice > 0

  useEffect(() => {
    setTrailId(undefined)
    setMounted(true)
    setNextDisabled(!recaptcha)

    return () => setMounted(false)
    //eslint-disable-next-line
  }, [])

  const pay = async (
    preRegistrationToken: string,
    method?: StandardFlagIdType | null
  ) => {
    if (paidPlan && method) {
      return service.dponet.payment.create({
        preRegistrationToken,
        method,
        bannerToken: service.dpoeasy.auth.getCompanyBannerToken(),
      })
    }
  }

  const traitPayment = (paymentStatus: string) => {
    switch (paymentStatus) {
      case 'PRE_CAPTURED':
        setTrailId('pre_captured')
        break
      case 'PAID':
        setTrailId('success')
        break
      default:
        setTrailId('error')
        setErrorTitle('Ocorreu um erro ao processar o pagamento!')
        break
    }
  }

  const redirectToHome = (tokens: {
    cookieToken: string
    authToken: string
  }) => {
    service.dpoeasy.auth.setRegistrationCookieToken(tokens.cookieToken)
    service.dponet.auth.setToken(tokens.authToken)
    setTrailId('redirect')
    setTimeout(() => navigate(routes.root), 2000)
  }

  const onSubmit = async (data: WelcomePlanFormSchemaType) => {
    try {
      if (recaptcha) {
        setNextDisabled(true)
        setTrailId(data.planPrice ? 'processing' : 'creating')
        const cardId = validates.cardFlag.id(data.card.number)
        if (cardId || !data.planPrice) {
          const brand =
            constants.cardFlags.CARD_BRAND_DEFINITION_BY_ID[cardId ?? 1]

          const preRegistration = formatters.mountCheckoutData(
            recaptcha,
            data,
            company,
            { ...company, email: data.email } as UserType,
            integrationPartner?.plan?.id,
            brand,
            data?.planPrice
              ? {}
              : {
                  bannerToken: service.dpoeasy.auth.getCompanyBannerToken(),
                  integrationPartnerId: integrationPartner?.id,
                }
          )

          const response =
            await service.dponet.preRegistration.checkout(preRegistration)
          const responsePayment = await pay(response?.data?.token, cardId)
          const status = responsePayment?.data?.status
          if (responsePayment?.data?.authToken && status === 'PAID') {
            setTrailId('success')
            setTimeout(
              () => redirectToHome(responsePayment.data.authToken),
              2000
            )
          } else {
            if (status) {
              traitPayment(status)
            } else {
              setTrailId('redirect')
              snackbar.open({
                variant: 'success',
                message:
                  'Por favor, verifique as instruções em sua caixa de e-mail.',
              })
            }
            loadOut()
            setTimeout(() => navigate(routes.root), 2500)
          }
        }
      }
    } catch (error) {
      setTrailId('error')
      resetRecaptcha()
      setErrorTitle(formatters.errorMessage(error))
    } finally {
      setNextDisabled(false)
    }
  }

  useEffect(() => {
    register('planPrice')
    setValue('planPrice', paidPlan)
    //eslint-disable-next-line
  }, [planPrice])

  if (!mounted) {
    return <></>
  }

  return (
    <>
      <CheckoutTrails
        trailId={trailId}
        setTrailId={setTrailId}
        errorTitle={errorTitle}
        style={styleTrail}
        minHeight="fit-content"
        height="100vh"
        overflow="hidden"
      />
      <Container maxWidth="xl" style={styleChildren} sx={{ paddingY: 10 }}>
        <Grid container spacing={6} justifyContent="stretch">
          <Grid item xs={12}>
            <Typography variant="h3" textAlign="center" color="primary">
              Finalizar cadastro
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h6" fontWeight="500">
              {!paidPlan
                ? 'Informe seu e-mail a seguir. Você receberá o link de acesso na conta informada'
                : 'Informe qual o seu plano escolhido e os dados do seu meio de pagamento.'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={6}
              height="100%"
              {...(!paidPlan && {
                alignItems: 'stretch',
              })}
            >
              <Grid item xl={3} lg={4} md={5} xs={12}>
                <CardPlan
                  title="DPO EASY"
                  subtitle={
                    'R$ ' +
                    formatters.formatToCurrencyBrl(
                      integrationPartner?.plan?.price ?? '59.90'
                    )
                  }
                  {...(!!integrationPartner?.id && {
                    exclusive: integrationPartner?.name,
                    oldPrice: 'R$ 59,90',
                  })}
                  isCentered
                  advantages={
                    constants.welcomeSteps.DPOEASY_ADVANTAGES[
                      integrationPartner?.cookiePlan ?? 'unlimited'
                    ]
                  }
                  height="fit-content"
                />
              </Grid>
              <Grid item xl={9} lg={7} md={7} xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormProvider
                    setValue={setValue}
                    handleSubmit={handleSubmit}
                    register={register}
                    unregister={unregister}
                    {...rest}
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Box mb={4}>
                            <Typography
                              variant="h6"
                              fontWeight="700"
                              color="primary"
                              gutterBottom
                            >
                              Dados de usuário
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            label="E-mail"
                            name="email"
                            type="text"
                            error={rest.formState.errors?.email}
                            control={rest.control}
                          />
                        </Grid>
                      </Grid>
                      {paidPlan && (
                        <>
                          <Grid item xs={12}>
                            <FormAddress />
                          </Grid>
                          <Grid item xs={12}>
                            <FormCard />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <UsingTerm recaptchaRef={recaptchaRef} />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Plan
