import { ChangeEvent, DragEvent, Fragment, useRef, useState } from 'react'
import { File as FileIcon, Upload as UploadIcon } from 'react-feather'
import { Controller, useFormContext } from 'react-hook-form'
import { visuallyHidden } from '@mui/utils'
import { isEmpty, isNil, size } from 'lodash'
import { Box, FormHelperText, Typography } from '@mui/material'

import { MUI } from 'components'

import { formatters } from 'helpers'

import { FileUploadInputType } from './fileUploadInput.types'

import { theme } from 'theme'

const FileUploadInput = ({
  accept,
  controlName,
  EndIcon = FileIcon,
  multiple = false,
  StartIcon = UploadIcon,
  subCaption = '',
  title,
  backgroundColor,
  width,
}: FileUploadInputType) => {
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const selectedFile = watch(controlName)
  const isMultiple = size(selectedFile) > 1

  const handleClickFormControl = () => inputRef?.current?.click()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files

    if (multiple) {
      if (!isNil(files))
        setValue(controlName, isEmpty(files) ? undefined : files)
    } else {
      if (!isNil(files))
        setValue(controlName, isEmpty(files) ? undefined : files[0])
    }
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => setIsDragging(false)

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    handleDragLeave()

    const files = event?.dataTransfer?.files

    if (multiple) {
      if (!isNil(files))
        setValue(controlName, isEmpty(files) ? undefined : files)
    } else {
      if (!isNil(files))
        setValue(controlName, isEmpty(files) ? undefined : files[0])
    }
  }

  const colorInput = () => {
    if (errors?.[controlName])
      return isDragging ? theme.palette.error.dark : theme.palette.error.main
    return isDragging
      ? theme.palette.secondary.main
      : theme.palette.neutral.main
  }

  return (
    <Fragment>
      <Controller
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ field }) => (
          <MUI.FormControl
            $display="flex"
            $flexDirection="column"
            $alignItems="center"
            $justifyContent="center"
            $border={`1px dashed ${colorInput()}`}
            $color={colorInput()}
            $textAlign="center"
            $cursor="pointer"
            $height={150}
            $width={width}
            $gap={2}
            $p={4}
            $backgroundColor={backgroundColor}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickFormControl}
          >
            <input
              ref={inputRef}
              type="file"
              accept={accept || '*/*'}
              aria-label="Selecionar arquivo"
              onChange={handleFileChange}
              style={visuallyHidden}
              multiple={multiple}
            />

            {isNil(selectedFile) ? (
              <Fragment>
                <StartIcon />
                <Typography
                  component="span"
                  fontWeight={isDragging ? 500 : 400}
                >
                  {title}
                </Typography>
                {accept && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      Tipos de arquivos suportados:&nbsp;
                      {formatters.file.allowedTypes(accept)}
                    </Typography>
                    {subCaption && (
                      <Typography variant="caption">{subCaption}</Typography>
                    )}
                  </Box>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box display="flex" alignItems="center" maxWidth="90%" gap={2}>
                  <Box>
                    <EndIcon size={20} />
                  </Box>
                  <Typography noWrap>
                    {!isMultiple
                      ? multiple
                        ? selectedFile[0]?.name
                        : selectedFile?.name
                      : `${size(selectedFile)} arquivos selecionados`}
                  </Typography>
                  {!isMultiple && (
                    <Box>
                      <Typography variant="caption" noWrap width="fit-content">
                        {formatters.file.size(
                          multiple ? selectedFile[0]?.size : selectedFile?.size
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Typography variant="caption">
                  Clique ou arraste para adicionar um novo arquivo
                </Typography>
              </Fragment>
            )}
          </MUI.FormControl>
        )}
        control={control}
        name={controlName}
      />
      {!!errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          <>{errors?.[controlName]?.message}</>
        </FormHelperText>
      )}
    </Fragment>
  )
}

export default FileUploadInput
