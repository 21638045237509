import { useMemo, useState } from 'react'
import { matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom'
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather'
import { Box, Collapse } from '@mui/material'

import { MenuButton } from './components'

import { MenuItemButtonType } from './menuItemButton.types'
import { MenuItemType } from 'types/menuItems.types'

import { theme } from 'theme'
import { flatten } from 'lodash'

const MenuItemButton = (props: MenuItemButtonType) => {
  const {
    menuItem: { Icon, title, childrens, subpages, children: child, url },
  } = props

  const match = useMatch(url)
  const location = useLocation()
  const subpagesMatches = subpages?.map((subpage) =>
    matchPath({ path: subpage }, location.pathname)
  )
  const childrenSubpagesMatches = childrens?.map((children) =>
    children.subpages?.map((subpage) =>
      matchPath({ path: subpage }, location.pathname)
    )
  )

  const subpagesMatch =
    !!subpagesMatches?.find((match) => !!match?.pattern?.path) ||
    !!flatten(childrenSubpagesMatches)?.find((match) => !!match?.pattern?.path)

  const isActive = match?.pattern.path === url || subpagesMatch
  const childrenMatches = childrens?.map((children) =>
    matchPath({ path: children.url }, location.pathname)
  )

  const isDefaultOpen =
    !!childrenMatches?.find((match) => !!match?.pattern?.path) || isActive

  const hasChildrens = useMemo(() => {
    if (Array.isArray(childrens)) {
      return childrens.length > 0
    }

    return false
  }, [childrens])

  const [open, setOpen] = useState(isDefaultOpen)
  const navigate = useNavigate()

  const handleClick = () => {
    if (hasChildrens) {
      setOpen((open) => !open)
      return
    }

    navigate(url)
  }

  return (
    <>
      <MenuButton
        $backgroundColor={
          isActive && !childrens ? theme.palette.secondary.main : undefined
        }
        $paddingLeft={child ? theme.spacing(12) : undefined}
        onClick={handleClick}
        disableElevation
        fullWidth
      >
        <Box display="flex" alignItems="center" gap={4}>
          {Icon && <Icon size={20} sx={{ fontSize: 20 }} />}
          {title}
        </Box>
        {hasChildrens && (
          <>
            {open ? <ChevronUpIcon size={18} /> : <ChevronDownIcon size={18} />}
          </>
        )}
      </MenuButton>

      {hasChildrens && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {childrens?.map((menuItemChild: MenuItemType) => (
            // <Permitted
            //   key={menuItemChild.title}
            //   tag={menuItemChild?.permission}
            //   someTags={menuItemChild?.somePermissions}
            // >
            <MenuItemButton menuItem={menuItemChild} key={menuItemChild.url} />
            // </Permitted>
          ))}
        </Collapse>
      )}
    </>
  )
}

export default MenuItemButton
