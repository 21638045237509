import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const BoxGridStructure = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '256px 1fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "sidebar content"
  `,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `
      "content"
    `,
  },
}))

export default BoxGridStructure
