import { ProductRequestModalItemsType } from 'types/marketplace/product.types'

import routes from 'constants/routes'

import cookiesBannerImg from 'images/marketplace/gestao_de_cookies.png'
import newsBannerImg from 'images/marketplace/veja_novidades.png'

import questionnairesImage from 'images/marketplace/products/questionnaires.png'
import servicesImage from 'images/marketplace/products/services.png'
import mappingImage from 'images/marketplace/products/mapping.png'
import trainingsImage from 'images/marketplace/products/trainings.png'

const TRAINING_CATEGORY_IDS = [2, 3, 4, 5, 15]
const SERVICE_CATEGORY_IDS = [1, 8, 18, 22, 23, 24, 25, 26, 27, 29, 31, 35]
const MAPPING_CATEGORY_IDS = [6, 20, 28]
const JOURNEY_HELP_CATEGORY_IDS = [7, 30]

const CAROUSEL_ITEMS = [
  {
    altText: 'Banner de boas vindas ao marketplace',
    img: cookiesBannerImg,
    route: routes.root,
  },
  {
    altText: 'Banner de novidades',
    img: newsBannerImg,
    route: routes.banners.all,
  },
]

const HIGHLIGHTS_ITEMS = [
  { imgUrl: trainingsImage, categoryIds: TRAINING_CATEGORY_IDS },
  { imgUrl: servicesImage, categoryIds: SERVICE_CATEGORY_IDS },
  { imgUrl: mappingImage, categoryIds: MAPPING_CATEGORY_IDS },
  { imgUrl: questionnairesImage, categoryIds: JOURNEY_HELP_CATEGORY_IDS },
]

const REQUEST_MODAL_ITEMS: ProductRequestModalItemsType[] = [
  {
    label: 'Empresa contratante',
    controlName: 'companyName',
    md: 7,
  },
  {
    label: 'Responsável',
    controlName: 'responsible',
    md: 5,
  },
  {
    label: 'E-mail',
    controlName: 'email',
    md: 7,
  },
  {
    label: 'Telefone',
    controlName: 'phone',
    md: 5,
    onChangeType: 'phone',
  },
]

export default {
  CAROUSEL_ITEMS,
  HIGHLIGHTS_ITEMS,
  REQUEST_MODAL_ITEMS,
}
