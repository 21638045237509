import autoTable from 'jspdf-autotable'
import { colors } from '@mui/material'

import { TicketTableType } from './ticketTable.types'

import constants from 'constants/index'
import { theme } from 'theme'

const ticketTable = ({
  pdf,
  head,
  body,
  startY,
  cellWidth = 0.65,
  type,
}: TicketTableType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH

  const WIDTH = WIDTH_PDF(pdf) - PADDING * 2

  autoTable(pdf, {
    head: head,
    body: body,
    startY: startY,
    tableWidth: WIDTH,
    margin: PADDING,
    columnStyles: {
      0: {
        cellWidth: WIDTH * cellWidth,
        font: 'Roboto',
        fontStyle: 'bold',
        halign: 'left',
        textColor: theme.palette.primary.main,
      },
    },
    headStyles: {
      cellPadding: 6,
      fillColor: theme.palette.primary.main,
      font: 'Roboto',
      fontStyle: 'bold',
      textColor: theme.palette.primary.main,
    },
    styles: {
      cellWidth: 'auto',
      textColor: theme.palette.primary.main,
    },
    bodyStyles: {
      font: 'Roboto',
      fontStyle: 'normal',
    },
    theme: 'grid',
    didParseCell: function (data) {
      if (data.row.index === 1 && type === 1) {
        data.cell.styles.fillColor = colors.orange[50]
      }
      if (data.row.index === 1 && (type === 2 || type === 3)) {
        data.cell.styles.fillColor = colors.blue[50]
      }
    },
  })
}

export default ticketTable
