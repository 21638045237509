import { useState, useEffect } from 'react'
import moment from 'moment'

moment.locale('pt-br')

const useCurrentDateTime = () => {
  const dateTimeFormat = 'DD/MM/YYYY [às] HH:mm'
  const [currentDateTime, setCurrentDateTime] = useState(
    moment().format(dateTimeFormat)
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment().format(dateTimeFormat))
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return currentDateTime
}

export default useCurrentDateTime
