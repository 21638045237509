import { Box, Grid, Paper, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { DPOnetCard, TrackCard } from './components'

import { useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks'

import { theme } from 'theme'
import constants from 'constants/index'
import * as helpers from 'helpers'
import { useMemo } from 'react'

const SuitabilityTrack = () => {
  const navigate = useNavigate()
  const { userCompany, companyMaturity } = useAuth()
  const initialJourney = helpers.mountHomeSteps(
    navigate,
    userCompany?.company,
    companyMaturity
  )
  const questionnaireConcluded = !isEmpty(companyMaturity?.answers)
  const automationConfigured = !!companyMaturity?.privacyChannelConfigured

  const compiance = useMemo(() => {
    let complianceCount = 60

    if (questionnaireConcluded) {
      complianceCount += 20
    }

    if (automationConfigured) {
      complianceCount += 20
    }

    return complianceCount
  }, [questionnaireConcluded, automationConfigured])

  return (
    <Box display="flex" flexDirection="column">
      <Box
        bgcolor={theme.palette.primary.main}
        borderRadius="3px 3px 0px 0px"
        p={4}
      >
        <Typography
          textTransform="uppercase"
          color="white"
          variant="h6"
          textAlign="center"
        >
          Sua jornada de adequação
        </Typography>
      </Box>
      <Paper variant="outlined">
        <Grid container spacing={4} p={4}>
          <Grid item xs={12} lg={4}>
            <TrackCard
              title="Jornada Inicial"
              compliance={compiance}
              items={initialJourney}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TrackCard
              title="Adequação Completa"
              compliance={0}
              items={constants.mooksValues.HOME_COMPLETE_SUITABILITY}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DPOnetCard />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default SuitabilityTrack
