import routes from './routes'

const DRAFT_ID = '1'
const ACTIVE_ID = '2'
const INACTIVE_ID = '3'

const DRAFT_LABEL = 'Rascunho'
const ACTIVE_LABEL = 'Publicado'
const INACTIVE_LABEL = 'Inativo'

const STATUSES_IDS = [DRAFT_ID, ACTIVE_ID, INACTIVE_ID] as const

const POLICIES_NEW_PAGE_HEADER_CATEGORIES = [
  { name: 'Políticas', url: routes.policies.all },
  { name: 'Nova', url: routes.policies.new },
]

const STATUSES = {
  [DRAFT_ID]: DRAFT_LABEL,
  [ACTIVE_ID]: ACTIVE_LABEL,
  [INACTIVE_ID]: INACTIVE_LABEL,
}

const STATUSES_OPTIONS = [
  {
    id: DRAFT_ID,
    name: DRAFT_LABEL,
  },
  {
    id: ACTIVE_ID,
    name: ACTIVE_LABEL,
  },
  {
    id: INACTIVE_ID,
    name: INACTIVE_LABEL,
  },
]

const SUPPORTED_LOGO_FILE_INPUT_TYPES = '.jpg,.jpeg,.png'
const SUPPORTED_IMAGE_FILE_SCHEMA_TYPES = ['image/jpeg', 'image/png']

export type PolicyStatusesType = (typeof STATUSES_IDS)[number]

const PRIVACY_NOTICE = 'privacy_notice'
const COOKIE_POLICY = 'cookie_policy'
const TERMS_OF_USE = 'terms_of_use'
const OTHER = 'other'

const PRIVACY_NOTICE_LABEL = 'Aviso de Privacidade'
const COOKIE_POLICY_LABEL = 'Política de Cookies'
const TERMS_OF_USE_LABEL = 'Termos de Uso'
const OTHER_LABEL = 'Outras'

const CATEGORIES = [
  {
    id: PRIVACY_NOTICE,
    name: PRIVACY_NOTICE_LABEL,
  },
  {
    id: COOKIE_POLICY,
    name: COOKIE_POLICY_LABEL,
  },
  {
    id: TERMS_OF_USE,
    name: TERMS_OF_USE_LABEL,
  },
  {
    id: OTHER,
    name: OTHER_LABEL,
  },
]

const TRANSLATED_CATEGORIES = {
  [PRIVACY_NOTICE]: PRIVACY_NOTICE_LABEL,
  [COOKIE_POLICY]: COOKIE_POLICY_LABEL,
  [TERMS_OF_USE]: TERMS_OF_USE_LABEL,
  [OTHER]: OTHER_LABEL,
}

const ALL_CATEGORIES = [
  PRIVACY_NOTICE,
  COOKIE_POLICY,
  TERMS_OF_USE,
  OTHER,
] as const

export type PolicyCategoryType = (typeof ALL_CATEGORIES)[number]

export default {
  ACTIVE_ID,
  CATEGORIES,
  DRAFT_ID,
  POLICIES_NEW_PAGE_HEADER_CATEGORIES,
  STATUSES_OPTIONS,
  STATUSES,
  SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
  SUPPORTED_LOGO_FILE_INPUT_TYPES,
  TRANSLATED_CATEGORIES,
}
