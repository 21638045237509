import Main from './MarketplaceMain'
import Orders from './Orders'
import Products from './Products'
import ProductShow from './ProductShow'
import Show from './MarketplaceShow'

export default {
  Main,
  Orders,
  Products,
  ProductShow,
  Show,
}
