import { Box } from '@mui/material'

import { styled } from '@mui/material/styles'

const WrapperBox = styled(Box)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  background: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    minHeight: '85vh',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}))

export default WrapperBox
