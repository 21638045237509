import { useFormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Grid, Link, Paper, Typography } from '@mui/material'

import { MUI } from 'components'

import { palette as paletteHelper } from 'helpers'

import { useMediaQuery } from 'hooks'

import { BannerConfigurationSchemaType } from 'types/banner.types'
import { PrimaryLevelBannerType } from './primaryLevelBanner.types.'

const PrimaryLevelBanner = ({
  size,
  handleOpenBanner,
}: PrimaryLevelBannerType) => {
  const { watch } = useFormContext<BannerConfigurationSchemaType>()
  const mediaQuery = useMediaQuery()

  const smOnly = mediaQuery.only.sm

  const primaryColor = paletteHelper.validateColor(watch('primaryColor'))
  const secondaryColor = paletteHelper.validateColor(watch('secondaryColor'))
  const description = watch('description')

  const calculatedSecondaryColor = paletteHelper.calculateColors(secondaryColor)
  const secondaryBGColor = paletteHelper.hexToRgba(secondaryColor, 0.06)

  const isFull = size === 'full'
  const isMedium = size === 'medium'

  return (
    <Paper
      variant="outlined"
      component={Box}
      textAlign="justify"
      p={4}
      maxWidth={isFull || !size ? undefined : isMedium ? 700 : 550}
      maxHeight="100%"
    >
      <Grid container spacing={isFull && smOnly ? 8 : 2} alignItems="center">
        <Grid
          item
          xs={12}
          sm={isFull ? 7 : 12}
          display="flex"
          flexDirection="column"
          textAlign="justify"
          gap={3}
        >
          <Typography
            variant="h6"
            fontFamily="Arial"
            fontWeight={600}
            color={primaryColor}
            fontSize={size && '16px !important'}
          >
            Controle sua privacidade
          </Typography>
          <PerfectScrollbar>
            <Box maxHeight={140} width="100%">
              <Typography
                variant="body2"
                fontFamily="Arial"
                color={primaryColor}
                fontSize={size && '10px !important'}
              >
                {description}
              </Typography>
            </Box>
          </PerfectScrollbar>
          <Link
            variant="body2"
            fontFamily="Arial"
            width="fit-content"
            color={primaryColor}
            underline="always"
            target="_blank"
            href="https://www.privacidade.com.br/portal-de-privacidade?token=c319342ef39f3dda91b50332c0abacd8"
            fontSize={size && '10px !important'}
            noWrap
          >
            Canal de Atendimento
          </Link>
        </Grid>
        <Grid item xs={12} sm={isFull ? 5 : 12}>
          <Grid container justifyContent="end" spacing={2}>
            <MUI.Grid
              item
              xs={12}
              sm={isFull ? 12 : 3.3}
              md={isFull ? 4 : 3.3}
              order={1}
              $smDown={{ order: 3 }}
              $mdDown={isFull ? { order: 3 } : {}}
            >
              <MUI.Button
                variant="text"
                fullWidth
                $fontFamily="Arial"
                $textTransform="initial"
                $color={secondaryColor}
                $hover={{
                  backgroundColor: secondaryBGColor,
                }}
                $fontSize={size && '10px !important'}
                $px={0}
              >
                Gerenciar cookies
              </MUI.Button>
            </MUI.Grid>
            <MUI.Grid
              item
              xs={12}
              sm={isFull ? 12 : 3.3}
              md={isFull ? 3.5 : 3.3}
              order={2}
            >
              <MUI.Button
                variant="outlined"
                fullWidth
                $fontFamily="Arial"
                $color={secondaryColor}
                $borderColor={calculatedSecondaryColor?.light}
                $hover={{
                  borderColor: calculatedSecondaryColor?.dark,
                  backgroundColor: secondaryBGColor,
                }}
                $fontSize={size && '10px !important'}
                $px={0}
                onClick={handleOpenBanner}
              >
                Rejeitar
              </MUI.Button>
            </MUI.Grid>
            <MUI.Grid
              item
              xs={12}
              sm={isFull ? 12 : 3.3}
              md={isFull ? 4.5 : 3.3}
              order={3}
              $smDown={{ order: 1 }}
              $mdDown={isFull ? { order: 1 } : {}}
            >
              <MUI.Button
                variant="contained"
                fullWidth
                $backgroundColor={secondaryColor}
                $fontFamily="Arial"
                $color={calculatedSecondaryColor?.contrastText}
                $hover={{ backgroundColor: calculatedSecondaryColor?.dark }}
                $fontSize={size && '10px !important'}
                $px={0}
                onClick={handleOpenBanner}
              >
                Aceitar Todos
              </MUI.Button>
            </MUI.Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PrimaryLevelBanner
