import 'utils/jsPDF/fonts/Raleway-normal'
import 'utils/jsPDF/fonts/Raleway-bold'
import 'utils/jsPDF/fonts/Roboto-normal'
import 'utils/jsPDF/fonts/Roboto-bold'

import { Fragment, useState } from 'react'
import jsPDF from 'jspdf'
import moment from 'moment'
import { Button } from '@mui/material'

import { LoadingFeedback } from 'components'

import { useAuth, useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { TicketPDFType } from './ticketPDF.types'
import { jsPDFAutoTableType } from 'interfaces/jsPdf.interfaces'

import ticketTemplates from './templates'
import globalTemplates from 'utils/jsPDF/templates'

moment.locale('pt-br')
let dateNow = moment().format('MMM YYYY')

const TicketPDF = ({
  ticket,
  ticketMessages,
  disabled = false,
  exportOptions,
}: TicketPDFType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { userCompany, user } = useAuth()

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  }) as jsPDFAutoTableType

  dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

  const exportPdf = async () => {
    try {
      setLoading(true)

      globalTemplates.firstCover({
        pdf,
        companyName: userCompany?.company?.name || '',
        document: userCompany?.company?.cnpj,
        date: dateNow,
        reportName: 'Histórico de Atendimento',
      })

      ticketTemplates.ticketInfo({
        pdf,
        date: `Informações | ${dateNow}`,
        ticket: ticket,
        ticketMessages: ticketMessages,
        company: userCompany?.company,
        user: user,
      })

      ticketTemplates.ticketMessages({
        pdf,
        date: `Mensagens | ${dateNow}`,
        ticketMessages: ticketMessages,
        exportOptions: exportOptions,
      })

      globalTemplates.lastCover({ pdf })

      pdf.save(`Histórico_de_atendimento_ticket_${ticket?.ticketRef}`)

      snackbar.open({
        variant: 'success',
        message: 'Histórico gerado com sucesso, aguarde o download começar',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error, false),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      <Button
        onClick={exportPdf}
        variant="contained"
        form="ticket-export-dialog-form"
        disabled={disabled}
      >
        Exportar
      </Button>
    </Fragment>
  )
}

export default TicketPDF
