/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup'

import { formatters, validates } from 'helpers'

import constants from 'constants/index'

const allowedTypes = constants.banner.SUPPORTED_IMAGE_FILE_INPUT_TYPES

const schema = yup.object({
  name: yup.string().min(4).max(150).required(),
  url: yup.string().min(4).max(256).required(),
  cookies: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      platform: yup.string().required(),
      categoryId: yup
        .number()
        .integer()
        .is(
          constants.cookie.VALID_CATEGORIES,
          'Escolha uma categoria conhecida para esse cookie'
        )
        .required(),
    })
  ),
  primaryColor: yup.string().required().min(4).max(7),
  secondaryColor: yup.string().required().min(4).max(7),
  description: yup.string().required().min(12).max(1000),
  position: yup.string().required(),
  logo: yup
    .mixed()
    .test('fileSize', 'A imagem deve ter no máximo 5 MB', (value: any) =>
      validates.file.size('MB', 5, value)
    )
    .test(
      'fileType',
      `Somente arquivos ${formatters.file.allowedTypes(allowedTypes)} são permitidos`,
      (value: any) =>
        validates.file.type(
          constants.banner.SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
          value
        )
    ),
})

export default schema
