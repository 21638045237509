import { colors } from '@mui/material'

import { TicketMessageType } from 'types/ticket.types'

import ticket from './ticket'

const getBackgroundColor = (
  isSystemInteraction?: boolean,
  message?: TicketMessageType,
  bgColor?: string
) => {
  if (isSystemInteraction) return colors.grey[200]

  if (message) {
    const messageBackgroundColor = ticket.colorKindMessage(
      message.kind,
      'background'
    )
    return messageBackgroundColor
  }

  return bgColor || colors.lightGreen['A100']
}

const getEyeColor = (
  isSystemInteraction?: boolean,
  message?: TicketMessageType,
  bgColor?: string
) => {
  if (isSystemInteraction) return colors.grey[800]

  if (message) {
    const messageBackgroundColor = ticket.colorKindMessage(message.kind, 'icon')
    return messageBackgroundColor
  }

  return bgColor || colors.lightGreen[800]
}

const determineAuthorName = (
  isSystemInteraction?: boolean,
  message?: TicketMessageType,
  title?: string
) => {
  if (isSystemInteraction) {
    return 'Resposta automática'
  }
  if (message) {
    return `${message?.author?.name} - ${ticket.discoveringKindMessage(message?.kind)}`
  }
  return title
}

export default {
  determineAuthorName,
  getBackgroundColor,
  getEyeColor,
}
