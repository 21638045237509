import {
  Briefcase as BriefcaseIcon,
  DollarSign as DollarSignIcon,
  User as UserIcon,
} from 'react-feather'
import { InferType } from 'yup'

import { test } from 'helpers'

import { InputModeType } from 'types/global.types'

import {
  CreditCard as CreditCardIcon,
  FileText as FileTextIcon,
} from 'react-feather'

import PersonalSchema from 'views/Settings/components/PersonalForm/schema'
import CompanySchema from 'views/Settings/components/CompanyForm/schema'

const PERSONAL_CATEGORY_ID = 1
const COMPANY_CATEGORY_ID = 2
const PAYMENT_CATEGORY_ID = 3

const ALL_CATEGORY_IDS = [PERSONAL_CATEGORY_ID, COMPANY_CATEGORY_ID] as const

const SETTINGS_CATEGORY_TABS = [
  { id: PERSONAL_CATEGORY_ID, label: 'Dados Pessoais', icon: UserIcon },
  { id: COMPANY_CATEGORY_ID, label: 'Dados da empresa', icon: BriefcaseIcon },
  { id: PAYMENT_CATEGORY_ID, label: 'Dados financeiros', icon: DollarSignIcon },
]

const INVOICES_STATUS_ID = 'invoices'
const PAYMENT_METHOD_STATUS_ID = 'payment_method'

const SUPPORTED_IMAGE_FILE_SCHEMA_TYPES = ['image/jpeg', 'image/png']

const SUPPORTED_IMAGE_FILE_INPUT_TYPES = '.jpg,.jpeg,.png'

const BASIC_DATA_CONTROLLER_ITEMS_FROM_PERSONAL_DATA: PersonalFormControllerItemsType[] =
  [
    {
      label: 'Nome',
      controlName: 'name',
      gridXs: 12,
    },
    {
      label: 'E-mail',
      controlName: 'email',
      inputMode: 'email',
      type: 'email',
      gridXs: 12,
      gridSm: 8,
    },
    {
      label: 'Telefone',
      controlName: 'phone',
      type: 'tel',
      inputMode: 'tel',
      gridXs: 12,
      gridSm: 4,
    },
  ]

const CHANGE_PASSWORD_CONTROLLER_ITEMS_FROM_PERSONAL_DATA: PersonalFormControllerItemsType[] =
  [
    {
      label: 'Senha atual',
      controlName: 'currentPassword',
      type: 'password',
    },
    {
      label: 'Nova senha',
      controlName: 'newPassword',
      type: 'password',
    },
    {
      label: 'Confirmar nova senha',
      controlName: 'confirmPassword',
      type: 'password',
    },
  ]

const BASIC_DATA_CONTROLLER_ITEMS_FROM_COMPANY_DATA: CompanyFormControllerItemsType[] =
  [
    {
      label: 'Nome fantasia',
      controlName: 'name',
      gridXs: 12,
    },
    {
      label: 'Breve descrição da empresa',
      controlName: 'description',
      rows: 3,
      gridXs: 12,
    },
    {
      label: 'Telefone da empresa',
      controlName: 'phone',
      type: 'tel',
      inputMode: 'tel',
      gridXs: 12,
      gridSm: 4,
      gridMd: 3,
    },
    {
      label: 'E-mail de cobrança',
      controlName: 'email',
      inputMode: 'email',
      type: 'email',
      gridXs: 12,
      gridSm: 8,
      gridMd: 9,
    },
    {
      label: 'URL',
      controlName: 'siteUrl',
      type: 'url',
      inputMode: 'url',
      gridXs: 12,
    },
  ]

const PASSWORD_VALIDATION_LABEL = [
  'Ao menos 8 caracteres',
  'Letras minúsculas',
  'Letras maiúsculas',
  'Caractere especial (Ex: # $)',
  'Números',
]

const FINANCIAL_MENU_ITEMS = [
  {
    id: INVOICES_STATUS_ID,
    label: 'Faturas',
    Icon: FileTextIcon,
  },
  {
    id: PAYMENT_METHOD_STATUS_ID,
    label: 'Meios de pagamento',
    Icon: CreditCardIcon,
  },
]

const PASSWORD_REGEX_VALIDATION: { [key: number]: (value?: string) => void } = {
  0: test.eightCharacters,
  1: test.lowercaseLetter,
  2: test.uppercaseLetter,
  3: test.specialCharacters,
  4: test.numberCharacters,
}

export type SettingsCategoryIdType = (typeof ALL_CATEGORY_IDS)[number]

export type PersonalFormSchemaType = InferType<typeof PersonalSchema>
export type PersonalFormControlType = keyof PersonalFormSchemaType

export type CompanyFormSchemaType = InferType<typeof CompanySchema>
export type CompanyFormControlType = keyof CompanyFormSchemaType

type ControllerStructureType = {
  label: string
  type?: React.InputHTMLAttributes<unknown>['type']
  inputMode?: InputModeType
  gridXs?: number
  gridSm?: number
  gridMd?: number
}

export type PersonalFormControllerItemsType = ControllerStructureType & {
  controlName: PersonalFormControlType
}

export type CompanyFormControllerItemsType = ControllerStructureType & {
  controlName: CompanyFormControlType
  rows?: number
}

export default {
  BASIC_DATA_CONTROLLER_ITEMS_FROM_COMPANY_DATA,
  BASIC_DATA_CONTROLLER_ITEMS_FROM_PERSONAL_DATA,
  CHANGE_PASSWORD_CONTROLLER_ITEMS_FROM_PERSONAL_DATA,
  PASSWORD_REGEX_VALIDATION,
  PASSWORD_VALIDATION_LABEL,
  PERSONAL_CATEGORY_ID,
  COMPANY_CATEGORY_ID,
  PAYMENT_CATEGORY_ID,
  SETTINGS_CATEGORY_TABS,
  SUPPORTED_IMAGE_FILE_INPUT_TYPES,
  SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
  FINANCIAL_MENU_ITEMS,
  INVOICES_STATUS_ID,
}
