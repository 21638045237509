import { Eye as EyeIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isEmpty } from 'lodash'
import { Avatar, Box, IconButton, Paper, Typography } from '@mui/material'

import { TicketMessageAttachment } from './components'

import { avatarLetters, formatters, validates } from 'helpers'

import { DefaultTicketMessageType } from './defaultTicketMessage.types'

import { theme } from 'theme'

const DefaultTicketMessage = ({
  ticket,
  hiddenContent,
  message,
  handleClickChange,
  bgColor,
  iconColor,
  title,
}: DefaultTicketMessageType) => {
  const isSystemInteraction = message?.author?.name === 'Sistema'

  const authorName = validates.ticketMessages.determineAuthorName(
    isSystemInteraction,
    message,
    title
  )

  const boxColor = validates.ticketMessages.getBackgroundColor(
    isSystemInteraction,
    message,
    bgColor
  )

  const eyeColor = validates.ticketMessages.getEyeColor(
    isSystemInteraction,
    message,
    iconColor
  )

  return (
    <Paper variant="outlined">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={boxColor}
        p={3}
      >
        <Box display="flex" gap={4}>
          {message && !isSystemInteraction && (
            <Avatar alt={message?.author?.name}>
              {avatarLetters(message?.author?.name)}
            </Avatar>
          )}
          <Box display="flex" flexDirection="column">
            <Typography fontWeight={700} color={theme.palette.neutral.dark}>
              {authorName}
            </Typography>
            {message && (
              <Typography variant="caption" color={theme.palette.neutral.main}>
                {formatters.date.ptBrFormatDateTime(message?.createdAt)}
              </Typography>
            )}
          </Box>
        </Box>
        <IconButton
          onClick={() => handleClickChange(message ? message?.id : 'content')}
        >
          <EyeIcon color={eyeColor} />
        </IconButton>
      </Box>
      {!hiddenContent[message ? message?.id : 'content'] && (
        <>
          <Box p={3}>
            <Typography
              variant="body2"
              color="text.secondary"
              whiteSpace="pre-line"
              textAlign="justify"
            >
              {message ? message?.content : ticket?.content || '-'}
            </Typography>
          </Box>
          <PerfectScrollbar>
            {!isEmpty(message?.attachments ?? ticket?.attachments) && (
              <Box display="flex" pb={2} pl={3} gap={3}>
                {message?.attachments && message?.attachments?.length > 0
                  ? message?.attachments?.map((attachment) => (
                      <TicketMessageAttachment
                        key={attachment?.name}
                        name={attachment?.name}
                        url={attachment?.url}
                      />
                    ))
                  : ticket?.attachments?.map((attachment) => (
                      <TicketMessageAttachment
                        key={attachment?.name}
                        name={attachment?.name}
                        url={attachment?.url}
                      />
                    ))}
              </Box>
            )}
          </PerfectScrollbar>
        </>
      )}
    </Paper>
  )
}

export default DefaultTicketMessage
