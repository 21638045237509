import { Box, BoxProps } from '@mui/material'

import { StandardPageStructureType } from './standardPageStructure.types'

const StandardPageStructure = ({
  children,
  ...rest
}: StandardPageStructureType & BoxProps) => {
  return (
    <Box paddingX="4vw" paddingY={12} {...rest}>
      {children}
    </Box>
  )
}

export default StandardPageStructure
