import { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Hidden,
  Radio,
  RadioGroup,
} from '@mui/material'

import { MUI } from 'components'

import { BannerConfigurationSchemaType } from 'types/banner.types'

import constants from 'constants/index'

const PositionCustomization = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BannerConfigurationSchemaType>()

  return (
    <Fragment>
      <Box>
        <Controller
          render={({ field }) => (
            <FormControl error={!!errors?.position}>
              <MUI.FormLabel $fontWeight={600}>
                Defina o posicionamento do banner de cookies no seu site:
              </MUI.FormLabel>
              <RadioGroup {...field}>
                {constants.banner.CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS.map(
                  (item, index) => (
                    <Box key={index}>
                      <FormControlLabel
                        control={<Radio />}
                        value={item.value}
                        label={item.label}
                      />
                    </Box>
                  )
                )}
              </RadioGroup>
              {!!errors?.position && (
                <FormHelperText error={!!errors?.position}>
                  {<>{errors?.position?.message}</>}
                </FormHelperText>
              )}
            </FormControl>
          )}
          control={control}
          name="position"
        />
      </Box>
      <Hidden xlDown>
        <Divider orientation="vertical" variant="middle" flexItem />
      </Hidden>
    </Fragment>
  )
}

export default PositionCustomization
