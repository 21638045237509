import { t } from 'i18next'
import * as yup from 'yup'
import testValidator from 'helpers/validates/testValidator'

const schema = yup
  .object()
  .shape({
    recaptcha: yup.string().required().nullable(),
    planPrice: yup.boolean(),
    email: yup.string().email().required(),
    privacyCheck: yup
      .boolean()
      .required('Aceite os Termos do Aviso de privacidade')
      .nullable()
      .isTrue('Aceite os Termos do Aviso de privacidade'),
    usingThermsCheck: yup
      .boolean()
      .required('Aceite os Termos e condições de uso')
      .nullable()
      .isTrue('Aceite os Termos e condições de uso'),
    address: yup.object().shape({
      street: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),
      number: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),
      neighborhood: yup.string(),
      postalCode: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),
      city: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),
      state: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),
    }),
    card: yup.object({
      name: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),

      number: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),

      expiration: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),

      cvv: yup
        .string()
        .test(
          'conditionally-required',
          t('validation.required'),
          testValidator('planPrice')
        ),
    }),
  })
  .required()

export type WelcomePlanFormSchemaType = yup.InferType<typeof schema>

export type WelcomePlanFormControlType = keyof WelcomePlanFormSchemaType

export default schema
