import { styled } from '@mui/material/styles'
type RootBoxStyledType = {
  $display?: string
}

const RootBoxStyled = styled('main', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<RootBoxStyledType>(({ $display, theme }) => ({
  overflowY: 'hidden',
  display: $display || 'grid',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    display: $display || 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  [theme.breakpoints.only('md')]: {
    gridTemplateColumns: '1fr 2fr',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '4fr 10fr',
  },
}))

export default RootBoxStyled
