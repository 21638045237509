import { Controller, useFormContext } from 'react-hook-form'

import { Grid, TextField } from '@mui/material'

import { MUI } from 'components'

import { formatters } from 'helpers'

import { NamedDpoSchemaType } from 'schemas/namedDpoSchema'
import { EventType, FormOutsourcedDpoType } from './formOutsourcedDpo.types'
import { useEffect } from 'react'

const COMPANY_KIND = 'juridic'
const PERSONAL_KIND = 'personal'

const FormOutsourcedDpo = ({ kind }: FormOutsourcedDpoType) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<NamedDpoSchemaType>()
  const handleCnpjFormat = (
    event: EventType,
    onChange: (event: EventType) => void
  ) => {
    const value = event?.target?.value
    if (isCompany) {
      event.target.value = formatters.cnpj.format(value)
    } else {
      event.target.value = formatters.cpf.format(value)
    }
    onChange(event)
  }

  useEffect(() => {
    register('namedDpo.hasNamedDpo')
    setValue('namedDpo.hasNamedDpo', true)

    return () => setValue('namedDpo.hasNamedDpo', false)

    //eslint-disable-next-line
  }, [])

  const isCompany = kind === COMPANY_KIND
  const isPersonal = kind === PERSONAL_KIND

  return (
    <>
      <Grid item xs={12}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="Selection o tipo da pessoa"
              error={!!errors?.namedDpo?.kindPerson}
              helperText={<>{errors?.namedDpo?.kindPerson?.message}</>}
              fullWidth
              id="kind-select"
              variant="outlined"
            >
              <MUI.MenuItem value={PERSONAL_KIND} $textTransform="none">
                Pessoa física
              </MUI.MenuItem>
              <MUI.MenuItem value={COMPANY_KIND} $textTransform="none">
                Pessoa jurídica
              </MUI.MenuItem>
            </TextField>
          )}
          control={control}
          name="namedDpo.kindPerson"
        />
      </Grid>
      {(isCompany || isPersonal) && (
        <>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={
                    isCompany
                      ? 'Encarregado (Razão social)'
                      : 'Encarregado (Nome do responsável)'
                  }
                  error={!!errors?.namedDpo?.name}
                  helperText={<>{errors?.namedDpo?.name?.message}</>}
                  fullWidth
                />
              )}
              control={control}
              name="namedDpo.name"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={isCompany ? 'Documento (CNPJ)' : 'Documento (CPF)'}
                  error={!!errors?.namedDpo?.document}
                  onChange={(e) => handleCnpjFormat(e, field.onChange)}
                  helperText={<>{errors?.namedDpo?.document?.message}</>}
                  fullWidth
                />
              )}
              control={control}
              name="namedDpo.document"
            />
          </Grid>
          {isCompany && (
            <>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome do responsável"
                      error={!!errors?.namedDpo?.responsible}
                      helperText={<>{errors?.namedDpo?.responsible?.message}</>}
                      fullWidth
                    />
                  )}
                  control={control}
                  name="namedDpo.responsible"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nome do substituto"
                      error={!!errors?.namedDpo?.substitute}
                      helperText={<>{errors?.namedDpo?.substitute?.message}</>}
                      fullWidth
                    />
                  )}
                  control={control}
                  name="namedDpo.substitute"
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  )
}

export default FormOutsourcedDpo
