import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { Box, Button, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import { BasicDialog } from 'components'
import { useAuth } from 'hooks'

import routes from 'constants/routes'

const DialogAnswerMaturity = () => {
  const { companyMaturity, loaded } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (loaded && !!companyMaturity && isEmpty(companyMaturity?.answers)) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [loaded, companyMaturity])

  const [open, setOpen] = useState(false)

  const onClose = () => setOpen(false)

  return (
    <BasicDialog
      open={open}
      title="Avalie sua Maturidade"
      onClose={onClose}
      maxWidth="md"
      dialogActions={
        <Box display="flex" gap={2}>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate(routes.maturityQuestionnaire)
            }}
          >
            Iniciar
          </Button>
        </Box>
      }
    >
      <Typography variant="body1">
        Responda a um rápido questionário que irá avaliar sua maturidade em
        relação à LGPD.
      </Typography>
    </BasicDialog>
  )
}

export default DialogAnswerMaturity
