import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const Span = styled('span', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $color?: Property.Color | undefined
  $overflowWrap?: Property.OverflowWrap
}>(({ $color, $overflowWrap }) => ({
  color: $color,
  overflowWrap: $overflowWrap,
}))

export default Span
