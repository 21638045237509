import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const get = async ({ policyId = '', ...params }) =>
  await dponetAPI.get(`/privacy_policies/${policyId}`, params)

const destroy = async (policyId: string | number) =>
  await dponetAPI.delete(`/privacy_policies/${policyId}`)

const create = async (data: object) =>
  await dponetAPI.post('/privacy_policies', data)

const update = async ({
  policyId,
  privacyPolicy,
}: {
  policyId: number
  privacyPolicy: object
}) => await dponetAPI.put(`/privacy_policies/${policyId}`, privacyPolicy)

const stamps = async () => await dponetAPI.get('/privacy_portal/stamps')
const consult = async () => await dponetAPI.get('/privacy_portal/consult')

export default {
  create,
  update,
  destroy,
  stamps,
  consult,
  get,
}
