import { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { AuthContext } from 'contexts'

import { UserCompanyType } from 'types/userCompany.types'
import { CompanyMaturityType } from 'types/maturityQuestionnaire.types'
import { CompanyMinimalType } from 'types/company.types'

import service from 'service'
import routes from 'constants/routes'
import { LoadingTrails } from 'components'

const AuthProvider = () => {
  const navigate = useNavigate()

  const [user, setUser] = useState({})
  const [userCompany, setUserCompany] = useState<UserCompanyType>({})
  const [companyMaturity, setUCompanyMaturity] = useState<CompanyMaturityType>()
  const [bannerConfigured, setBannerUnconfigured] = useState<boolean>(true)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) {
      loadData()
    } else {
      navigate(routes.auth.login)
    }

    //eslint-disable-next-line
  }, [])

  const loadData = async () => {
    setLoaded(false)
    const responseMe = await service.dponet.user.me()
    const responseUserCompany = await service.dponet.userCompany.identify()
    const responseBannerConfigured =
      await service.dpoeasy.banner.checkBannerStatus()
    await loadCompanyMaturity()
    setUser(responseMe?.data?.user)
    setUserCompany(responseUserCompany?.data?.userCompany)
    setBannerUnconfigured(responseBannerConfigured?.data?.status)
    setLoaded(true)
  }

  const loadOut = () => {
    setUser({})
    setUserCompany({})
    setUCompanyMaturity(undefined)
    service.dponet.auth.logout()
    navigate(routes.auth.login)
  }

  const loadCompanyMaturity = async () => {
    const responseMaturity = await service.dponet.companyMaturity.get()
    setUCompanyMaturity(responseMaturity?.data?.companyMaturity)
  }

  const updateCompany = (company: CompanyMinimalType) => {
    setUserCompany((userCompany) => ({
      ...userCompany,
      company: {
        ...userCompany.company,
        ...company,
      },
    }))
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userCompany,
        companyMaturity,
        bannerConfigured,
        loadOut,
        loaded,
        loadData,
        loadCompanyMaturity,
        setUCompanyMaturity,
        updateCompany,
      }}
    >
      <LoadingTrails open={!loaded} />
      {loaded && <Outlet />}
    </AuthContext.Provider>
  )
}

export default AuthProvider
