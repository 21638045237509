import { Controller, useForm } from 'react-hook-form'
import { Download as DownloadIcon } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { MUI, PocketCardImage } from 'components'

import { validates } from 'helpers'

import { useAuth, useClipboard, useMediaQuery } from 'hooks'

import { PocketCardStampType } from './pocketCardStamp.types'
import { PocketCardStampSizeType } from 'constants/serviceChannel'

import constants from 'constants/index'
import schema, { PocketCardStampSchemaType } from './schema'

const PocketCardStamp = ({ qrCode }: PocketCardStampType) => {
  const mediaQuery = useMediaQuery()
  const { userCompany } = useAuth()
  const { downloadHtmlToImage } = useClipboard()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PocketCardStampSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      size: constants.serviceChannel.POCKET_CARD_STAMP_ORIGINAL_SIZE_ID,
    },
  })

  const smDown = mediaQuery.down.sm
  const lgDown = mediaQuery.down.lg

  const htmlId = 'pocket-card-stamp'

  const onSubmit = async (data: PocketCardStampSchemaType) => {
    const htmlContent = document.getElementById(htmlId)
    const size = validates.serviceChannel.pocketCardStampSize(
      data.size as PocketCardStampSizeType
    )

    downloadHtmlToImage(
      `cartão_canal_de_comunicação_${size.name}`.toLowerCase(),
      htmlContent,
      size.width,
      size.height
    )
  }

  return (
    <MUI.Paper
      variant="outlined"
      $borderTopRightRadius={0}
      $borderTopLeftRadius={0}
    >
      <Box
        display="flex"
        flexDirection={lgDown ? 'column' : 'row'}
        p={4}
        gap={8}
      >
        <Box display="flex" justifyContent="center" width="100%" height="100%">
          <PocketCardImage
            id={htmlId}
            company={userCompany?.company}
            qrCode={qrCode}
          />
        </Box>
        <Box textAlign="justify">
          <Typography fontWeight={600} color="primary" gutterBottom>
            Cartão
          </Typography>
          <Typography variant="body2" color="primary">
            Pensando nos profissionais que não possuem um local fixo de
            atendimento e não podem disponibilizar um canal de comunicação em
            formato impresso ou online, oferecemos uma opção portátil, para que
            você possa sempre tê-lo consigo.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection={smDown ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        p={4}
        gap={4}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width={smDown ? '100%' : 200}>
          <Controller
            render={({ field }) => (
              <FormControl size="small" fullWidth>
                <InputLabel id="pocket-card-stamp-size-input">
                  Tamanho
                </InputLabel>
                <Select
                  labelId="pocket-card-stamp-size-input"
                  label="Tamanho"
                  size="small"
                  {...field}
                >
                  {constants.serviceChannel.POCKET_CARD_STAMP_SIZE_INPUT_ITEMS.map(
                    (item) => (
                      <MUI.MenuItem value={item.value} $textTransform="initial">
                        {item.name}
                      </MUI.MenuItem>
                    )
                  )}
                </Select>
                {!!errors?.size && (
                  <FormHelperText error={!!errors?.size}>
                    {errors?.size?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            control={control}
            name="size"
          />
        </Box>
        <Box display="flex" width={smDown ? '100%' : 'fit-content'}>
          <LoadingButton
            startIcon={<DownloadIcon size={18} />}
            variant="contained"
            type="submit"
            fullWidth
          >
            Download
          </LoadingButton>
        </Box>
      </Box>
    </MUI.Paper>
  )
}

export default PocketCardStamp
