import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'

import { FAQSItemsType } from './faqsItems.types'

import { theme } from 'theme'

const FAQItem = ({ item }: FAQSItemsType) => {
  return (
    <Accordion variant="outlined" component={Box} px={4} py={2}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography fontSize="21px !important" color="primary" fontWeight={600}>
          {item?.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color={theme.palette.neutral.main}>
          {item?.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default FAQItem
