import api from 'service/api'

const dponetAPI = api.create('dponet', false)

import {
  COMPANY_BANNER_TOKEN,
  COOKIE_AUTH_TOKEN,
  REGISTRATION_COOKIE_TOKEN,
} from 'service/constants'

import { ObjectOfString } from 'types/global.types'

const registrationCookieToken = async (params: object) => {
  const response = await dponetAPI.get('/cookie_token', { params })

  setRegistrationCookieToken(response?.data?.cookieToken)
}

const login = async (data: ObjectOfString) => {
  setToken(data?.cookieToken)
}

const logout = () => {
  removeToken()
}

const setToken = (token: string) => {
  sessionStorage.setItem(COOKIE_AUTH_TOKEN, token)
}

const getToken = () => {
  return sessionStorage.getItem(COOKIE_AUTH_TOKEN)
}

const removeToken = () => {
  sessionStorage.removeItem(COOKIE_AUTH_TOKEN)
}

const setRegistrationCookieToken = (token: string) => {
  localStorage.setItem(REGISTRATION_COOKIE_TOKEN, token)
}

const getRegistrationCookieToken = () => {
  return localStorage.getItem(REGISTRATION_COOKIE_TOKEN)
}

const removeRegistrationCookieToken = () => {
  localStorage.removeItem(REGISTRATION_COOKIE_TOKEN)
}

const setCompanyBannerToken = (token: string) => {
  localStorage.setItem(COMPANY_BANNER_TOKEN, token)
}

const getCompanyBannerToken = () => {
  return localStorage.getItem(COMPANY_BANNER_TOKEN)
}

const removeCompanyBannerToken = () => {
  localStorage.removeItem(COMPANY_BANNER_TOKEN)
}

export default {
  registrationCookieToken,
  getToken,
  getRegistrationCookieToken,
  setRegistrationCookieToken,
  removeRegistrationCookieToken,
  setCompanyBannerToken,
  getCompanyBannerToken,
  removeCompanyBannerToken,
  login,
  logout,
}
