const errorMessage = (obj: unknown, includeKey = true) => {
  //eslint-disable-next-line
  const errorObject = obj as any

  if (!errorObject) return ''

  let errorGeneralMessage = ''
  const errors =
    errorObject?.response?.data?.error?.errors ||
    errorObject?.response?.data?.error?.message

  if (typeof errors === 'string') return errors

  for (const key in errors) {
    const errorMessage = ` ${includeKey ? key + ' ' : ''}${errors[key]}`
    errorGeneralMessage = errorGeneralMessage.concat(errorMessage)
  }

  return (
    errorGeneralMessage || errorObject?.message || 'Ocorreu um erro inesperado!'
  )
}

export default errorMessage
