import { colors } from '@mui/material'

import { TicketExportDialogItemsType } from 'types/ticket.types'

import { theme } from 'theme'
import routes from './routes'

// STATUSES IDS
const NEW_TICKET_ID = 1
const PENDING_TICKET_ID = 2
const FINISHED_TICKET_ID = 3
const CANCELED_TICKET_ID = 4
const WAITING_CONFIRMATION_TICKET_ID = 5
const AWAITING_CONFIRMATION_IDENTITY_ID = 7
const AWAITING_COMPANY_REPLY_ID = 8
const AWAITING_REPLY_VALIDATION_ID = 9
const REPLIED_ID = 10

// STATUSES LABELS
const PENDING_TICKET = 'Pendente'
const FINISHED_TICKET = 'Concluído'
const CANCELED_TICKET = 'Cancelado'
const WAITING_CONFIRMATION_TICKET = 'Aguardando Confirmação'
const AWAITING_CONFIRMATION_IDENTITY = 'Pendente de Confirmação de Identidade'
const AWAITING_COMPANY_REPLY = 'Aguardando Resposta da Empresa'
const REPLIED = 'Respondido'

// TICKET MESSAGE KINDS LABELS
const INTERNAL_MESSAGE_TYPE = 'Interno'
const EXTERNAL_MESSAGE_TYPE = 'Externo'
const CLIENT_MESSAGE_TYPE = 'Solicitante'

const TITULAR_TICKET_KIND = 'titular'
const ANPD_TICKET_KIND = 'anpd'

const STATUSES_IDS = [
  NEW_TICKET_ID,
  PENDING_TICKET_ID,
  FINISHED_TICKET_ID,
  CANCELED_TICKET_ID,
  WAITING_CONFIRMATION_TICKET_ID,
  AWAITING_CONFIRMATION_IDENTITY_ID,
  AWAITING_COMPANY_REPLY_ID,
  AWAITING_REPLY_VALIDATION_ID,
  REPLIED_ID,
] as const

const CLOSED_STATUS = [
  WAITING_CONFIRMATION_TICKET_ID,
  AWAITING_CONFIRMATION_IDENTITY_ID,
]

const PERMITTED_STATUSES = [
  PENDING_TICKET_ID,
  FINISHED_TICKET_ID,
  CANCELED_TICKET_ID,
  REPLIED_ID,
]

// TICKET MESSAGE KINDS IDS
const INTERNAL_MESSAGE_ID = 1
const EXTERNAL_MESSAGE_ID = 2
const CLIENT_MESSAGE_ID = 3

const ALL_MESSAGE_ID = [
  INTERNAL_MESSAGE_ID,
  EXTERNAL_MESSAGE_ID,
  CLIENT_MESSAGE_ID,
] as const

// ALL TICKETS KINDS
const ALL_TICKETS_KINDS = [TITULAR_TICKET_KIND, ANPD_TICKET_KIND] as const

// TICKET CHAT TABS
const TICKET_CHAT_TABS = [
  { id: EXTERNAL_MESSAGE_ID, name: EXTERNAL_MESSAGE_TYPE },
  { id: INTERNAL_MESSAGE_ID, name: INTERNAL_MESSAGE_TYPE },
]

// TICKET TABS
const TICKET_STATUS_TABS = [
  { id: PENDING_TICKET_ID, tabName: 'Pendentes' },
  { id: WAITING_CONFIRMATION_TICKET_ID, tabName: 'Aguardando Confirmação' },
  { id: REPLIED_ID, tabName: 'Respondidos' },
  { id: FINISHED_TICKET_ID, tabName: 'Finalizados' },
  { id: null, tabName: 'Todos' },
]

// TICKET STATUS OPTIONS
const TICKET_STATUS_OPTIONS = [
  {
    id: PENDING_TICKET_ID,
    name: PENDING_TICKET,
    disabled: false,
  },
  { id: REPLIED_ID, name: REPLIED, disabled: false },
  {
    id: FINISHED_TICKET_ID,
    name: FINISHED_TICKET,
    disabled: false,
  },
  {
    id: CANCELED_TICKET_ID,
    name: CANCELED_TICKET,
    disabled: false,
  },
  {
    id: WAITING_CONFIRMATION_TICKET_ID,
    name: WAITING_CONFIRMATION_TICKET,
    disabled: true,
  },
  {
    id: AWAITING_CONFIRMATION_IDENTITY_ID,
    name: AWAITING_CONFIRMATION_IDENTITY,
    disabled: true,
  },
  {
    id: AWAITING_COMPANY_REPLY_ID,
    name: AWAITING_COMPANY_REPLY,
    disabled: true,
  },
]

// MESSAGES TYPE
const MESSAGE_TYPE = {
  [INTERNAL_MESSAGE_ID]: INTERNAL_MESSAGE_TYPE,
  [EXTERNAL_MESSAGE_ID]: EXTERNAL_MESSAGE_TYPE,
  [CLIENT_MESSAGE_ID]: CLIENT_MESSAGE_TYPE,
}

// TICKET CHIP STATUS COLORS
const CHIP_STATUS_BACKGROUND_COLOR = {
  [NEW_TICKET_ID]: theme.palette.support.main,
  [PENDING_TICKET_ID]: theme.palette.info.main,
  [FINISHED_TICKET_ID]: theme.palette.success.main,
  [CANCELED_TICKET_ID]: theme.palette.error.main,
  [WAITING_CONFIRMATION_TICKET_ID]:
    theme.palette.ticketStatus.waitingConfirmation,
  [AWAITING_CONFIRMATION_IDENTITY_ID]:
    theme.palette.ticketStatus.awaitingConfirmationIdentity,
  [AWAITING_COMPANY_REPLY_ID]: theme.palette.ticketStatus.awaitingCompanyReply,
  [AWAITING_REPLY_VALIDATION_ID]:
    theme.palette.ticketStatus.awaitingReplyValidation,
  [REPLIED_ID]: theme.palette.ticketStatus.replied,
}

// TICKET MESSAGE COLORS
const MESSAGE_TYPE_BOX_COLOR = {
  [INTERNAL_MESSAGE_ID]: colors.orange[50],
  [EXTERNAL_MESSAGE_ID]: colors.blue[50],
  [CLIENT_MESSAGE_ID]: colors.cyan[50],
}

const MESSAGE_TYPE_ICON_COLOR = {
  [INTERNAL_MESSAGE_ID]: colors.orange[700],
  [EXTERNAL_MESSAGE_ID]: colors.blue[700],
  [CLIENT_MESSAGE_ID]: colors.cyan[700],
}

const TICKET_CONTENT_PAGE_HEADER_CATEGORIES = [
  { name: 'Solicitações de titulares', url: routes.tickets.all },
  { name: 'Detalhes da Solicitação', url: '' },
]

const TICKET_EXPORT_DIALOG_ITEMS: TicketExportDialogItemsType[] = [
  {
    label: 'Interações públicas',
    controlName: 'externalMessage',
  },
  {
    label: 'Anotações automáticas',
    controlName: 'internalMessage',
  },
]

export type MessageTicketIdType = (typeof ALL_MESSAGE_ID)[number]
export type StatusTicketIdType = (typeof STATUSES_IDS)[number]
export type TicketKindType = (typeof ALL_TICKETS_KINDS)[number]

export default {
  AWAITING_COMPANY_REPLY_ID,
  AWAITING_CONFIRMATION_IDENTITY_ID,
  AWAITING_REPLY_VALIDATION_ID,
  CANCELED_TICKET_ID,
  CHIP_STATUS_BACKGROUND_COLOR,
  CLIENT_MESSAGE_ID,
  CLOSED_STATUS,
  EXTERNAL_MESSAGE_ID,
  FINISHED_TICKET_ID,
  INTERNAL_MESSAGE_ID,
  MESSAGE_TYPE_BOX_COLOR,
  MESSAGE_TYPE_ICON_COLOR,
  MESSAGE_TYPE,
  NEW_TICKET_ID,
  PENDING_TICKET_ID,
  REPLIED_ID,
  TICKET_CHAT_TABS,
  TICKET_CONTENT_PAGE_HEADER_CATEGORIES,
  TICKET_EXPORT_DIALOG_ITEMS,
  TICKET_STATUS_OPTIONS,
  TICKET_STATUS_TABS,
  TITULAR_TICKET_KIND,
  WAITING_CONFIRMATION_TICKET_ID,
  PERMITTED_STATUSES,
}
