import { Box, Card, CardActionArea, Grid, Typography } from '@mui/material'

import { CreditCardBoxType } from './creditCardBox types'

import { theme } from 'theme'

import constants from 'constants/index'

const CreditCardBox = ({
  creditCard,
  isActive,
  setCurrentCard,
}: CreditCardBoxType) => {
  const configs = constants.cardFlags.FLAGS_BY_LABEL[creditCard?.brand]
  const Logo = configs?.Flag

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        key={creditCard.token}
        variant="outlined"
        height="100%"
        component={Box}
        {...(isActive && {
          sx: {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        })}
      >
        <CardActionArea
          sx={{
            height: '100%',
          }}
          onClick={() => setCurrentCard(creditCard?.token)}
        >
          <Box
            p={2}
            gap={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            bgcolor={theme.palette.custom.light}
          >
            <Logo />
            <Typography variant="subtitle1" align="center">
              {creditCard?.brand}
            </Typography>
            {!!creditCard?.maskedCard && (
              <Typography variant="caption" align="center">
                **** {creditCard?.maskedCard?.split('*')?.slice(-1)}
              </Typography>
            )}
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default CreditCardBox
