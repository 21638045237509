export default {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
      h2: {
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
      h3: {
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
      h4: {
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
      h5: {
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
      h6: {
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
      subtitle1: {
        fontFamily: 'Roboto',
        fontWeight: '400',
      },
      subtitle2: {
        fontFamily: 'Roboto',
        fontWeight: '400',
      },
      body1: {
        fontFamily: 'Roboto',
        fontWeight: '400',
      },
      body2: {
        fontFamily: 'Roboto',
        fontWeight: '400',
      },
      button: {
        fontFamily: 'Roboto',
        fontWeight: '500',
      },
      caption: {
        fontFamily: 'Roboto',
        fontWeight: '400',
      },
      overline: {
        fontFamily: 'Roboto',
        fontWeight: '400',
      },
    },
  },
}
