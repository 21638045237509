import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Grid, Typography } from '@mui/material'

import { size } from 'lodash'

import { useFetch } from 'hooks'

import { TextField } from '..'
import { WelcomePlanFormSchemaType } from '../../schema'

import service from 'service'
import { CEPType } from 'types/viaCEP.types'
import { formatters } from 'helpers'

const FormAddress = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<WelcomePlanFormSchemaType>()

  const cep = watch('address.postalCode') ?? ''
  const isCEP = size(cep) === 9

  const { response, loading: loadingCep } = useFetch(
    service.external.viaCEP.get,
    {
      cep,
    },
    [cep],
    isCEP
  )

  const address: CEPType = response?.data

  useEffect(() => {
    if (isCEP) {
      setValue('address.city', address?.localidade)
      setValue('address.neighborhood', address?.bairro)
      setValue('address.state', address?.uf)
      setValue('address.street', address?.logradouro)
    } else {
      setValue('address.city', '')
      setValue('address.neighborhood', '')
      setValue('address.state', '')
      setValue('address.street', '')
      setValue('address.number', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isCEP])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="700" color="primary">
          Endereço
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label="CEP"
              name="address.postalCode"
              type="text"
              onChange={(e) => {
                e.target.value = formatters.cep.format(e.target.value)
              }}
              error={errors?.address?.postalCode}
              control={control}
              loading={loadingCep}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label="Número"
              name="address.number"
              type="text"
              error={errors?.address?.number}
              control={control}
              loading={loadingCep}
              disabled={!isCEP}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              error={errors?.address?.neighborhood}
              label="Bairro"
              type="text"
              name="address.neighborhood"
              control={control}
              loading={loadingCep}
              disabled={!isCEP}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label="Rua"
              name="address.street"
              type="text"
              error={errors?.address?.street}
              control={control}
              disabled={!isCEP}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label="Cidade"
              name="address.city"
              type="text"
              error={errors?.address?.city}
              control={control}
              loading={loadingCep}
              disabled={!isCEP}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              error={errors?.address?.state}
              label="Estado"
              type="text"
              name="address.state"
              control={control}
              loading={loadingCep}
              disabled={!isCEP}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormAddress
