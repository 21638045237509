import { isArray, isEmpty } from 'lodash'
import { Box, Divider, Paper, Tooltip, Typography, colors } from '@mui/material'

import { validates } from 'helpers'

import {
  CommonTypographyType,
  TicketInfoTableType,
} from './ticketInfoTable.types'

import { theme } from 'theme'

const commonTypography = ({ value, subIndex }: CommonTypographyType) => {
  const validationValue = isEmpty(value) ? 'Não informado' : value

  return (
    <Tooltip key={subIndex} title={validationValue} placement="bottom-start">
      <Typography variant="subtitle2" color={theme.palette.neutral.main}>
        {validationValue}
      </Typography>
    </Tooltip>
  )
}

const TicketInfoTable = ({ ticket, ticketMessages }: TicketInfoTableType) => {
  const tableItems = validates.ticketInfoTable(ticket, ticketMessages)

  return (
    <Paper variant="outlined">
      <Box px={5} py={3}>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          color={theme.palette.neutral.dark}
        >
          Informações
        </Typography>
      </Box>
      {tableItems.map((option, index) => {
        const highlight = option?.highlight
          ? colors.lightGreen['A100']
          : theme.palette.common.white

        return (
          <Box key={index}>
            <Divider />
            <Box p={4} bgcolor={highlight}>
              <Tooltip title={option?.title} placement="bottom-start">
                <Typography
                  variant="subtitle2"
                  color={theme.palette.neutral.main}
                  fontWeight={500}
                >
                  {option?.title}
                </Typography>
              </Tooltip>
              {isArray(option?.value) && !isEmpty(option?.value)
                ? option?.value?.map((value, subIndex) =>
                    commonTypography({ value, subIndex })
                  )
                : commonTypography({ value: option?.value })}
            </Box>
          </Box>
        )
      })}
    </Paper>
  )
}

export default TicketInfoTable
