import { BoxProps } from '@mui/material'

import { MUI } from 'components'

import { HexagonStructureType } from './hexagonStructure.types'

import { theme } from 'theme'
import HexagonBackground from 'images/hexagon_background.svg'

const HexagonStructure = ({
  children,
  ...rest
}: HexagonStructureType & BoxProps) => {
  return (
    <MUI.Box
      bgcolor={theme.palette.background.default}
      $backgroundImage={`url(${HexagonBackground})`}
      $backgroundSize="150%"
      $backgroundRepeat="no-repeat"
      $backgroundPosition="center"
      {...rest}
    >
      {children}
    </MUI.Box>
  )
}

export default HexagonStructure
