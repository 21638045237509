import { Typography as TypographyMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength } from 'types/materialUI.types'

type TypographyType = {
  $fontSize?: Property.FontSize<TLength>
  $maxWidth?: Property.MaxWidth<TLength>
  $overflow?: Property.Overflow
  $overflowWrap?: Property.OverflowWrap
  $textOverflow?: Property.TextOverflow
  $whiteSpace?: Property.WhiteSpace
  $wordBreak?: Property.WordBreak
}

interface TypographyWithValidationsType extends TypographyType {
  $lgOnly?: InferType<TypographyType>
  $mdUp?: InferType<TypographyType>
  $xlUp?: InferType<TypographyType>
}

const Typography = styled(TypographyMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<TypographyWithValidationsType>(
  ({
    $fontSize,
    $maxWidth,
    $overflow,
    $overflowWrap,
    $textOverflow,
    $whiteSpace,
    $wordBreak,
    $lgOnly,
    $xlUp,
    $mdUp,
    theme,
  }) => ({
    fontSize: $fontSize,
    maxWidth: $maxWidth,
    overflow: $overflow,
    overflowWrap: $overflowWrap,
    textOverflow: $textOverflow,
    whiteSpace: $whiteSpace,
    wordBreak: $wordBreak,
    [theme.breakpoints.only('lg')]: {
      fontSize: $lgOnly?.fontSize,
      maxWidth: $lgOnly?.maxWidth,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: $mdUp?.maxWidth,
      overflow: $mdUp?.overflow,
      textOverflow: $mdUp?.textOverflow,
      whiteSpace: $mdUp?.whiteSpace,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: $xlUp?.maxWidth,
    },
  })
)

export default Typography
