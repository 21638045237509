import { Box } from '@mui/material'

import { Sidebar, Content } from './components'

import { NodeChildren } from 'types/global.types'

const Body = ({ children }: NodeChildren) => {
  return (
    <>
      <Box position="relative" gridArea="sidebar">
        <Sidebar />
      </Box>

      <Content gridArea="content">{children}</Content>
    </>
  )
}

export default Body
