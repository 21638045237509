import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength } from 'types/materialUI.types'

type ImageType = {
  $alignContent?: Property.AlignContent
  $alignSelf?: Property.AlignSelf
  $borderRadius?: Property.BorderRadius<TLength>
  $fill?: Property.Fill
  $height?: Property.Height<TLength>
  $maxHeight?: Property.MaxHeight<TLength>
  $maxWidth?: Property.MaxWidth<TLength>
  $minHeight?: Property.MinHeight<TLength>
  $minWidth?: Property.MinWidth<TLength>
  $objectFit?: Property.ObjectFit
  $objectPosition?: Property.ObjectPosition<TLength>
  $textAlign?: Property.TextAlign
}

interface BoxWithValidationsType extends ImageType {
  $mdDown?: InferType<ImageType>
  $smDown?: InferType<ImageType>
}

const Img = styled('img', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<BoxWithValidationsType>(
  ({
    $alignContent,
    $alignSelf,
    $borderRadius,
    $fill,
    $height,
    $maxHeight,
    $maxWidth,
    $minHeight,
    $minWidth,
    $objectFit,
    $objectPosition,
    $textAlign,
    $mdDown,
    $smDown,
    theme,
  }) => ({
    alignContent: $alignContent,
    alignSelf: $alignSelf,
    borderRadius: $borderRadius,
    fill: $fill,
    height: $height,
    maxHeight: $maxHeight,
    maxWidth: $maxWidth,
    minHeight: $minHeight,
    minWidth: $minWidth,
    objectFit: $objectFit,
    objectPosition: $objectPosition,
    textAlign: $textAlign,
    [theme.breakpoints.down('md')]: {
      maxWidth: $mdDown?.maxWidth,
    },
    [theme.breakpoints.down('sm')]: {
      height: $smDown?.height,
    },
  })
)

export default Img
