import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Card, CardActionArea } from '@mui/material'

import { MUI } from 'components'

import { HighlightsType } from './highlights.types'

import { theme } from 'theme'
import constants from 'constants/index'

const Highlights = ({ handleFilterChange }: HighlightsType) => {
  return (
    <PerfectScrollbar>
      <Box
        display="flex"
        justifyContent="space-between"
        width="auto"
        gap={3}
        pb={4}
      >
        {constants.marketplace.products.HIGHLIGHTS_ITEMS.map((item, index) => (
          <Card key={index} component={Box} minWidth={325}>
            <CardActionArea
              onClick={() => handleFilterChange(item?.categoryIds)}
            >
              <Box bgcolor={theme.palette.primary.main}>
                <MUI.Box
                  bgcolor={theme.palette.primary.main}
                  $backgroundImage={`url(${item.imgUrl})`}
                  $backgroundRepeat="no-repeat"
                  $transition="opacity ease-out 0.2s"
                  $hover={{ opacity: 0.9 }}
                  borderRadius={1}
                  minHeight={200}
                />
              </Box>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </PerfectScrollbar>
  )
}

export default Highlights
