import { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

import { PageHead, StandardPageStructure } from 'components'
import {
  AutomaticAnswerCard,
  DialogTutorialAutomaticAnswer,
} from './components'

import routes from 'constants/routes'

const AutomaticAnswer = () => {
  const [openDialog, setOpenDialog] = useState(false)

  const navigate = useNavigate()
  const { state } = useLocation()

  const openTutorialFromPrivacyChannel = state?.openTutorial ?? false

  const handleJourney = () => {
    navigate(routes.privacyChannel.configuration)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  useEffect(() => {
    if (openTutorialFromPrivacyChannel) handleOpenDialog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTutorialFromPrivacyChannel])

  return (
    <StandardPageStructure>
      <PageHead
        name="Respostas automáticas"
        actionButton={
          <Box display="flex" gap={4}>
            <Button variant="outlined" onClick={handleOpenDialog}>
              Tutorial
            </Button>

            <Button variant="contained" onClick={handleJourney}>
              Refazer jornada
            </Button>
          </Box>
        }
      />
      <AutomaticAnswerCard />
      <DialogTutorialAutomaticAnswer
        open={openDialog}
        onClose={handleCloseDialog}
      />
    </StandardPageStructure>
  )
}

export default AutomaticAnswer
