import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import routes from 'constants/routes'
import service from 'service'

const Forbidden = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(routes.root)
  }

  const logout = () => {
    service.dponet.auth.logout()
    navigate(routes.root)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
    >
      <Typography variant="h1" textAlign="center">
        500
      </Typography>
      <Typography variant="h4" textAlign="center">
        Erro interno!
      </Typography>
      <Typography variant="subtitle1" textAlign="center" gutterBottom>
        Ocorreu um erro interno, estamos trabalhando para resolver esse
        problema.
      </Typography>
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClick}
        >
          VOLTAR PARA O INÍCIO
        </Button>
        <Button variant="contained" color="error" size="large" onClick={logout}>
          SAIR
        </Button>
      </Box>
    </Box>
  )
}

export default Forbidden
