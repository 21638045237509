import { useForm } from 'react-hook-form'
import { Alert, Grid, Typography } from '@mui/material'

import { useAuth, useJourney, useSnackbar } from 'hooks'

import { formatters } from 'helpers'
import service from 'service'

const AlertSmallCompany = () => {
  const { snackbar } = useSnackbar()
  const { loadCompanyMaturity } = useAuth()
  const { handleSubmit } = useForm()
  const { handleNext } = useJourney()

  const onSubmit = async () => {
    try {
      await service.dponet.companyMaturity.removeDpo()
      await loadCompanyMaturity()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error),
      })
    } finally {
      handleNext()
    }
  }

  return (
    <Grid item xs={12}>
      <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
        <Alert
          severity="error"
          sx={{
            backgroundColor: '#AFCADB',
            '.MuiAlert-icon': {
              color: '#233154',
              alignItems: 'center',
            },
          }}
        >
          <Typography color="#233154" fontWeight="700">
            Um agente de tratamento de pequeno porte é uma empresa ou
            organização de menor porte que processa dados pessoais com menos
            complexidade e volume, tendo obrigações simplificadas para cumprir a
            LGPD.
          </Typography>
        </Alert>
      </form>
    </Grid>
  )
}

export default AlertSmallCompany
