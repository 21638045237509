import { Menu as MenuIcon } from 'react-feather'
import { AppBar, Box, Container, IconButton } from '@mui/material'

import { AccountInfo, Logo, Toolbar } from './components'

import { HeaderType } from './header.types'

import { theme } from 'theme'
import DPOEasyLogo from 'images/dpoeasy_logo.svg'

const Header = ({
  handleActionSwipeDrawer,
  isSwipeableDrawer,
  color,
  maxWidth,
}: HeaderType) => {
  return (
    <AppBar color={color} position="fixed" elevation={0}>
      <Container maxWidth={maxWidth}>
        <Toolbar variant="dense">
          <Box display="flex" alignItems="center" width="100%">
            {isSwipeableDrawer ? (
              <IconButton
                aria-label="Open swipeable menu"
                onClick={handleActionSwipeDrawer}
              >
                <MenuIcon color={theme.palette.primary.contrastText} />
              </IconButton>
            ) : (
              <Logo alt="logo-dponet" src={DPOEasyLogo} />
            )}
          </Box>
          <AccountInfo />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
