import { colors } from '@mui/material'
import createTheme from '@mui/material/styles/createTheme'

declare module '@mui/material/styles/createPalette' {
  interface Palette extends CustomPaletteType {
    alert: Palette['primary']
    inactive: Palette['primary']
    neutral: Palette['primary']
    support: Palette['primary']
    tertiary: Palette['primary']
  }

  interface PaletteOptions {
    alert?: PaletteOptions['primary']
    inactive?: PaletteOptions['primary']
    neutral?: PaletteOptions['primary']
    support?: PaletteOptions['primary']
    tertiary?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    alert: true
    inactive: true
    neutral: true
    support: true
    tertiary: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    alert: true
    inactive: true
    neutral: true
    support: true
    tertiary: true
  }
}

const refTheme = createTheme()
const white = '#FFFFFF'

const palette = {
  primary: refTheme.palette.augmentColor({
    color: { main: '#233154' },
  }),
  secondary: refTheme.palette.augmentColor({
    color: {
      main: '#0763A0',
      light: '#2883BF',
    },
  }),
  success: refTheme.palette.augmentColor({
    color: { main: '#16A63C' }, // Chart Approved (Main)
  }),
  error: refTheme.palette.augmentColor({
    color: { main: '#E83A49' }, // Chart Disapproved (Main)
  }),
  warning: refTheme.palette.augmentColor({
    color: { main: '#FCC109' }, // Chart Revision (Main)
  }),
  info: refTheme.palette.augmentColor({
    color: { main: '#376FB6' }, // Chart Pending (Main)
  }),
  background: {
    default: '#F4F6F8',
  },
  // Cores customizadas
  tertiary: refTheme.palette.augmentColor({
    color: { main: '#80B92B', contrastText: white }, // Chart Treated (Light)
  }),
  support: refTheme.palette.augmentColor({
    color: { main: '#BA2980' },
  }),
  neutral: refTheme.palette.augmentColor({
    color: { main: '#546E7A' },
  }),
  inactive: refTheme.palette.augmentColor({
    color: { main: '#9F9E9E' }, // Chart Inactive (Main)
  }),
  alert: refTheme.palette.augmentColor({
    color: { main: '#F57C00', contrastText: white }, // Chart InProgress (Main)
  }),
}

const customPalette = {
  paper: {
    border: '#E0E0E0',
  },
  disabled: {
    text: '#A6A6A6',
    background: colors.grey[200],
  },
  ticketStatus: {
    waitingConfirmation: '#233154',
    awaitingConfirmationIdentity: '#0097A7',
    awaitingCompanyReply: '#00897B',
    awaitingReplyValidation: '#FF8F00',
    replied: '#3F51B5',
  },
  custom: {
    light: '#E7F2FB',
    main: '#B8DAF7',
  },
}

type CustomPaletteType = typeof customPalette

export default {
  ...palette,
  ...customPalette,
}
