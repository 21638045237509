import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft as ArrowLeftIcon } from 'react-feather'
import { Box, IconButton, Typography, TypographyProps } from '@mui/material'

import { PageNameType } from './pageName.types'

const PageName = ({
  name,
  onlyPageTitle,
  returnedUrl,
  ...rest
}: PageNameType & TypographyProps) => {
  const navigate = useNavigate()

  const handleCLickTableRow = () => {
    navigate({ pathname: returnedUrl })
  }

  useEffect(() => {
    document.title = `${name} - DPO Easy`

    return () => {
      document.title = 'DPO Easy'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (onlyPageTitle) return <></>

  return (
    <Box display="flex" gap={4}>
      {returnedUrl && (
        <IconButton color="inherit" onClick={() => handleCLickTableRow()}>
          <ArrowLeftIcon />
        </IconButton>
      )}
      <Typography variant="h6" component="h1" color="primary" {...rest}>
        {name}
      </Typography>
    </Box>
  )
}

export default PageName
