import {
  BookOpen as BookIcon,
  Heart as HeartIcon,
  Home as HomeIcon,
  MoreVertical as MoreIcon,
  ShoppingBag as ShopIcon,
  Volume2 as VolumeIcon,
} from 'react-feather'

const SEGMENT_LIST = [
  {
    id: 35,
    name: 'E-commerce',
    description: 'Plataforma online para compra e venda de produtos.',
    Icon: ShopIcon,
  },
  {
    id: 156,
    name: 'Ensino',
    description: 'Atividades de ensino e apoio à educação',
    Icon: BookIcon,
  },
  {
    id: 37,
    name: 'Farmácia',
    description: 'Comércio de medicamentos e insumos farmacêuticos',
    Icon: HeartIcon,
  },
  {
    id: 80,
    name: 'Agência de marketing',
    description: 'Promoção e comunicação de produtos/serviços.',
    Icon: VolumeIcon,
  },
  {
    id: 288,
    name: 'Imobiliário',
    description: 'Setor de compra, venda e locação de imóveis.',
    Icon: HomeIcon,
  },
  {
    id: 'another',
    name: 'Outros',
    description: 'Demais setores não listados.',
    Icon: MoreIcon,
  },
  // {
  //   id: 76,
  //   name: 'Hotelaria',
  //   description: 'Setor de hospedagem e acomodações.',
  //   Icon: HomeIcon,
  // },
]

const segments = {
  SEGMENT_LIST,
}

export default segments
