/* eslint-disable @typescript-eslint/no-unused-vars */
import { Download as DownloadIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import { Hidden, Paper, TablePagination } from '@mui/material'

import {
  LoadingFeedback,
  MUI,
  NoData,
  PageHead,
  StandardPageStructure,
} from 'components'
import { ConsentTable } from './components'

import { useFetch, usePagination } from 'hooks'

import service from 'service'

const ConsentMain = () => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dpoeasy.consent.get,
    { params: { page, perPage } },
    [page, perPage]
  )

  const consents = response?.data?.consents

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Consentimentos registrados (Logs)"
        // Implementação Futura
        // actionButton={
        //   <MUI.Button
        //     variant="contained"
        //     startIcon={<DownloadIcon size={18} />}
        //     disabled={isEmpty(consents)}
        //     $smDown={{ width: '100%' }}
        //   >
        //     Download
        //   </MUI.Button>
        // }
      />
      {isEmpty(consents) ? (
        <NoData title="Nenhum registro de consentimento foi encontrado" />
      ) : (
        <Paper variant="outlined">
          <ConsentTable consents={consents} />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            slotProps={{
              actions: {
                previousButton: { size: 'small' },
                nextButton: { size: 'small' },
              },
            }}
          />
        </Paper>
      )}
    </StandardPageStructure>
  )
}

export default ConsentMain
