import { ChangeEvent, useRef, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import { Controller, useFormContext } from 'react-hook-form'
import { truncate } from 'lodash'
import { Box, InputAdornment, TextField } from '@mui/material'

import { MUI } from 'components'

import { palette, regex } from 'helpers'

import { useOutsideClick } from 'hooks'

import { ColorInputType } from './colorInput.types'

import { theme } from 'theme'

const ColorInput = ({ controlName, label }: ColorInputType) => {
  const [showPicker, setShowPicker] = useState(false)

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const colorInputRef = useRef<HTMLDivElement | null>(null)
  const colorPicker = watch(controlName)

  const handleShowPicker = () => setShowPicker(true)
  const handleClosePicker = () => setShowPicker(false)

  useOutsideClick({
    ref: colorInputRef,
    callback: handleClosePicker,
  })

  const hexadecimalValidation = (value: string) => {
    let newValue = regex.onlyLetters(value)
    newValue = regex.removeSpaces(newValue).toUpperCase()

    return truncate(newValue, {
      length: 6,
      omission: '',
    })
  }

  const handleColorChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setValue(controlName, '#' + hexadecimalValidation(event.target.value))

  const handlePickerColorChange = (newColor: ColorResult) =>
    setValue(controlName, '#' + hexadecimalValidation(newColor.hex))

  return (
    <Box>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.[controlName]}
            helperText={<>{errors?.[controlName]?.message}</>}
            type="text"
            label={label}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MUI.Box
                    border={1}
                    bgcolor={palette.validateColor(colorPicker)}
                    borderColor={theme.palette.paper.border}
                    borderRadius={1}
                    $cursor="pointer"
                    p={3}
                  />
                </InputAdornment>
              ),
            }}
            ref={colorInputRef}
            onClick={handleShowPicker}
            onChange={handleColorChange}
          />
        )}
        name={controlName}
        control={control}
      />
      {showPicker && (
        <Box
          display="flex"
          justifyContent="end"
          ref={colorInputRef}
          position="relative"
        >
          <Box width="fit-content" position="absolute" zIndex={2}>
            <ChromePicker
              disableAlpha
              color={colorPicker}
              onChange={handlePickerColorChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ColorInput
