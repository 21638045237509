import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { Check as CheckIcon } from 'react-feather'
import { isEqual, last } from 'lodash'
import { Box, colors, Divider, Link, Paper, Typography } from '@mui/material'

import { MUI, Svg } from 'components'

import { TrackCardType } from './trackCard.types'

import { theme } from 'theme'

import routes from 'constants/routes'

const TrackCard = ({ title, compliance, items }: TrackCardType) => {
  const navigate = useNavigate()

  const handleCheckout = () => navigate(routes.checkout.all)

  return (
    <Box>
      <Box
        bgcolor={theme.palette.primary.main}
        borderRadius="5px 5px 0px 0px"
        color="white"
        p={4}
      >
        <Typography fontWeight={500}>{title}</Typography>
        <Box display="flex" gap={4} alignItems="center">
          <MUI.LinearProgress
            variant="determinate"
            value={compliance}
            $border="1px solid white"
            $width="100%"
          />
          <Typography variant="caption" fontWeight={500}>
            {compliance ?? 'N/A'}%
          </Typography>
        </Box>
      </Box>
      <Paper variant="outlined">
        {items?.map((item, index) => (
          <Fragment key={index}>
            <Box p={4} display="flex" gap={6} alignItems="center">
              <Box position="relative" width="fit-content">
                <Svg.Polygon
                  fill={!item.completed ? colors.grey[300] : undefined}
                />
                <Box position="absolute" top={10} left={8}>
                  <CheckIcon color="white" />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" width="100%">
                <Typography color="primary" fontWeight={600}>
                  {item?.name}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="end"
                  gap={2}
                >
                  <Typography
                    variant="caption"
                    color={theme.palette.neutral.main}
                  >
                    {item?.date}
                  </Typography>
                  <Link
                    href="#"
                    onClick={item?.action ?? handleCheckout}
                    fontWeight={600}
                    color="secondary"
                    variant="caption"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {item?.buttonName}
                  </Link>
                </Box>
              </Box>
            </Box>
            {!isEqual(item, last(items)) && <Divider />}
          </Fragment>
        ))}
      </Paper>
    </Box>
  )
}

export default TrackCard
