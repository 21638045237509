import lineWithDate from './LineWithDate/lineWithDate'
import numberAndTitle from './NumberAndTitle/numberAndTitle'
import numberPage from './NumberPage/numberPage'
import table from './Table/table'

const components = {
  lineWithDate,
  numberAndTitle,
  numberPage,
  table,
}

export default components
