import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Paper, TextField } from '@mui/material'

import { BasicDialog, MUI } from 'components'
import ProductCard from '../ProductCard'

import { formatters, regex } from 'helpers'

import { useSnackbar } from 'hooks'

import {
  HandleOnChangeType,
  ProductRequestModalType,
} from './productRequestModal.types'

import schema, {
  ProductRequestModalControlType,
  ProductRequestModalSchemaType,
} from './schema'
import service from 'service'
import constants from 'constants/index'

const ProductRequestModal = ({
  isLoading,
  onClose,
  open,
  partnerService,
  setIsLoading,
  user,
  userCompany,
}: ProductRequestModalType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<ProductRequestModalSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: userCompany?.company?.name || '',
      responsible: user.name || '',
      email: user.email || '',
      phone: '',
    },
  })

  const formId = 'product-request-modal-form'

  const handleOnChange = ({ event, type }: HandleOnChangeType) => {
    if (type === 'phone')
      event.target.value = formatters.phoneDynamicMask(event?.target?.value)

    setValue(type, event.target.value)
  }

  const handleDisableInput = (type: ProductRequestModalControlType) => {
    if (type === 'companyName') return !!userCompany?.company?.name
    else if (type === 'responsible') return !!user?.name
    else if (type === 'email') return !!user.email

    return false
  }

  const onSubmit = async (data: ProductRequestModalSchemaType) => {
    try {
      setIsLoading(true)
      data = { ...data, phone: regex.onlyNumbers(data?.phone) }

      await service.marketplace.orders.orderService({
        ...data,
        partnerServiceId: partnerService?.id,
        externalId: userCompany?.id,
      })

      snackbar.open({
        message: 'Solicitação enviada com sucesso!',
        variant: 'success',
      })
      reset({})
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Solicitar serviço"
      maxWidth="md"
      dialogActions={
        <MUI.Box
          display="flex"
          gap={2}
          $smDown={{ flexDirection: 'column', width: '100%' }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            form={formId}
            loading={isLoading}
          >
            Enviar Solicitação
          </LoadingButton>
        </MUI.Box>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        component="form"
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Paper variant="outlined">
          <ProductCard partnerService={partnerService} />
        </Paper>
        <Grid container spacing={4}>
          {constants.marketplace.products.REQUEST_MODAL_ITEMS.map(
            (item, index) => (
              <Grid item xs={12} md={item?.md} key={index}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors?.[item?.controlName]}
                      helperText={<>{errors?.[item?.controlName]?.message}</>}
                      label={item?.label}
                      onChange={(event) =>
                        handleOnChange({ event, type: item?.controlName })
                      }
                      fullWidth
                      disabled={handleDisableInput(item?.controlName)}
                    />
                  )}
                  control={control}
                  name={item?.controlName}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </BasicDialog>
  )
}

export default ProductRequestModal
