import { CardActionArea as CardActionAreaMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const CardActionArea = styled(CardActionAreaMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $height?: Property.Height<TLength>
}>(({ $height }) => ({
  height: $height,
}))

export default CardActionArea
