import { useEffect, useState } from 'react'

import { Box, colors, Paper, Typography } from '@mui/material'
import { MUI } from 'components'

import { useRegistration } from 'hooks'

import DAILogo from 'images/dai_logo.png'
import { theme } from 'theme'

const CardPolicy = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const [myInterval, setMyInterval] = useState<NodeJS.Timeout>()
  const policyParts = description?.split(' ')
  const [index, setIndex] = useState(1)
  const [descriptionMounted, setDescriptionMounted] = useState(policyParts[0])
  const { company } = useRegistration()

  const isLoading = index < policyParts.length

  const mountDescription = () => {
    if (isLoading) {
      const newDes = descriptionMounted + ` ${policyParts[index]}`
      setDescriptionMounted(newDes)
      setIndex(index + 1)
    } else {
      clearTimeout(myInterval)
    }
  }

  useEffect(() => {
    const interval = setTimeout(mountDescription, 100)
    setMyInterval(interval)

    return () => clearTimeout(myInterval)

    //eslint-disable-next-line
  }, [descriptionMounted])

  return (
    <Paper variant="outlined">
      <Box bgcolor={colors.blue[100]}>
        <Box px={4} py={4} display="flex" alignItems="center">
          <MUI.Img src={DAILogo} $maxWidth={80} />
          <Typography variant="h6" color="primary">
            {title?.toUpperCase()}
          </Typography>
        </Box>
        <Box p={4} bgcolor="#E8F4FD" height="16vh" overflow="hidden">
          <Typography
            variant="body1"
            fontWeight="700"
            color={theme.palette.neutral.main}
          >
            {title} da {company?.name}
          </Typography>
          <Typography
            variant="body1"
            whiteSpace="break-spaces"
            color={theme.palette.neutral.main}
          >
            {descriptionMounted}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default CardPolicy
