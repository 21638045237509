import {
  Box,
  Button,
  FormControl,
  InputBase,
  Paper,
  Select,
  Typography,
} from '@mui/material'

import { MUI } from 'components'
import { FiltersItems } from './components'

import { ProductFiltersType } from './productFilters.types'

import constants from 'constants/index'

const ProductFilters = ({
  categories,
  checkedState,
  handleFilterChange,
  loading,
  handleFilterOrderChange,
}: ProductFiltersType) => {
  return (
    <Paper
      variant="outlined"
      component={Box}
      display="flex"
      flexDirection="column"
      gap={4}
      p={5}
    >
      <Box
        display="flex"
        gap={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight={600} color="primary">
          Filtros
        </Typography>
        <Button variant="outlined" onClick={() => handleFilterChange()}>
          Filtrar
        </Button>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body2" color="primary">
          Ordenar por
        </Typography>
        <FormControl>
          <Select
            size="small"
            defaultValue={constants.filterOrder.NEWLY_CREATED_ID}
            variant="outlined"
            input={<InputBase className="marketplace-custom-input-base" />}
            onChange={handleFilterOrderChange}
          >
            {constants?.filterOrder.FILTER_MENU_ITEMS.map((item) => (
              <MUI.MenuItem value={item?.value} $textTransform="capitalize">
                <Typography variant="body2">{item?.name}</Typography>
              </MUI.MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {!loading && (
        <FiltersItems categories={categories} checkedState={checkedState} />
      )}
    </Paper>
  )
}

export default ProductFilters
