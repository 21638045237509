import { size } from 'lodash'

import regex from 'helpers/regex'

const format = (cep?: string) => {
  if (!cep) return ''
  cep = regex.onlyNumbers(cep)
  if (size(cep) > 5) cep = cep.substring(0, 5) + '-' + cep.substring(5)
  return cep.slice(0, 9)
}

export default {
  format,
}
