import { Accordion as AccordionMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const Accordion = styled(AccordionMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $border?: Property.Border<TLength>
}>(({ $border }) => ({
  border: $border,
}))

export default Accordion
