import regex from 'helpers/regex'

const expiringDate = (date: string) => {
  date = regex.onlyNumbers(date)
  date = date.replace(/(\d{2})(?=\d)/, '$1/')

  return date.slice(0, 5)
}

const number = (value: string) =>
  regex
    .onlyNumbers(value)
    .replace(/(\d{4})(?=\d)/g, '$1 ')
    .slice(0, 23)

export default {
  expiringDate,
  number,
}
