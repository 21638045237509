import dataDiscoveryIcon from 'images/marketplace/icons/prevencao_perda_dados.png'
import cyberInsuranceIcon from 'images/marketplace/icons/cyber_benefit_compliance.png'
import passwordVaultIcon from 'images/marketplace/icons/cofre_senhas.png'
import pentestIcon from 'images/marketplace/icons/cyber_benefit_preparacao.png'
import dataAnonymizationIcon from 'images/marketplace/icons/backup_cyber.png'
import antivirusIcon from 'images/marketplace/icons/pentest_descoberta_vunerabilidades.png'
import recyclingIcon from 'images/marketplace/icons/recycling.png'

import costAndBenefitIcon from 'images/marketplace/icons/custo_beneficio.png'
import serviceIntermediationIcon from 'images/marketplace/icons/intermediacao_servicos.png'
import maturityToLGPDIcon from 'images/marketplace/icons/maturidade_lgpd.png'

import servicesConstants from './services'

// DEFINITION OF PAGES
const ANTIVIRUS = servicesConstants.antivirus
const CYBER_INSURANCE = servicesConstants.cyberInsurance
const DATA_ANONYMIZATION = servicesConstants.dataAnonymization
const DATA_DISCOVERY = servicesConstants.dataDiscovery
const DLP = servicesConstants.DLP
const PASSWORD_VAULT = servicesConstants.passwordVault
const PENTEST = servicesConstants.pentest
const ELECTRONICS_RECYCLING = servicesConstants.electronicsRecycling

const PAGE_IDS = [DATA_DISCOVERY.PAGE_ID] as const

const CONTENT_PAGE = {
  [ANTIVIRUS.PAGE_ID]: ANTIVIRUS.CONTENT_PAGE,
  [CYBER_INSURANCE.PAGE_ID]: CYBER_INSURANCE.CONTENT_PAGE,
  [DATA_ANONYMIZATION.PAGE_ID]: DATA_ANONYMIZATION.CONTENT_PAGE,
  [DATA_DISCOVERY.PAGE_ID]: DATA_DISCOVERY.CONTENT_PAGE,
  [DLP.PAGE_ID]: DLP.CONTENT_PAGE,
  [PASSWORD_VAULT.PAGE_ID]: PASSWORD_VAULT.CONTENT_PAGE,
  [PENTEST.PAGE_ID]: PENTEST.CONTENT_PAGE,
  [ELECTRONICS_RECYCLING.PAGE_ID]: ELECTRONICS_RECYCLING.CONTENT_PAGE,
}

const SOLUTIONS_CARDS = [
  {
    title: 'Data Discovery',
    description:
      'Ferramentas ou processos que, quando usados por um usuário, podem garantir que dados confidenciais não sejam acessados, usados indevidamente ou perdidos por usuários não autorizados.',
    imgUrl: dataDiscoveryIcon,
    serviceId: DATA_DISCOVERY.PAGE_ID,
  },
  {
    title: 'Seguro Cyber',
    description:
      'Amparar perdas financeiras decorrentes de ataques virtuais maliciosos, ou mesmo de incidentes decorrentes de erros ou negligências causados internamente, que resultem em vazamento de dados e danos ligados ao sigilo da informação.',
    imgUrl: cyberInsuranceIcon,
    serviceId: CYBER_INSURANCE.PAGE_ID,
  },
  {
    title: 'Cofre de senhas',
    description:
      'Um cofre de senhas é uma ferramenta online que armazena senhas com segurança, exigindo apenas uma senha mestra para acesso. Simplifica a gestão, promovendo a criação de senhas únicas para cada conta, melhorando a segurança.',
    imgUrl: passwordVaultIcon,
    serviceId: PASSWORD_VAULT.PAGE_ID,
  },
  {
    title: 'Pentest',
    description:
      'O pentest, ou teste de intrusão, simula ataques para encontrar e corrigir falhas em sistemas, protegendo contra hackers e aumentando a confiança na segurança online.',
    imgUrl: pentestIcon,
    serviceId: PENTEST.PAGE_ID,
  },
  {
    title: 'Anonimização de dados',
    description:
      'A anonimização de dados protege a privacidade ao remover elementos de identificação pessoal, mantendo a utilidade dos dados para análises.',
    imgUrl: dataAnonymizationIcon,
    serviceId: DATA_ANONYMIZATION.PAGE_ID,
  },
  {
    title: 'Antivirus e Anti-malware',
    description:
      'As vulnerabilidades de segurança são as fraquezas/falhas de um sistema de segurança que poderiam ser potencialmente exploradas por uma ou mais ameaças e causar danos e prejuízos à organização.',
    imgUrl: antivirusIcon,
    serviceId: ANTIVIRUS.PAGE_ID,
  },
  {
    title: 'Prevenção de perda de dados (DLP)',
    description:
      'As ferramentas de DLP monitoram e evitam vazamentos de informações sensíveis, aplicando políticas, analisando atividades de usuários e garantindo conformidade com normas de segurança.',
    imgUrl: dataDiscoveryIcon,
    serviceId: DLP.PAGE_ID,
  },
  {
    title: 'Descarte de equipamentos eletrônicos',
    description:
      'Serviços de destruição segura de dados e reciclagem responsável de equipamentos eletrônicos, garantindo conformidade com regulamentações de proteção de dados e minimizando riscos de vazamento de informações sensíveis.',
    imgUrl: recyclingIcon,
    serviceId: ELECTRONICS_RECYCLING.PAGE_ID,
  },
]

const INFO_CARDS = [
  {
    title: 'Melhor custo-benefício',
    imgUrl: costAndBenefitIcon,
  },
  {
    title: 'Intermediação de serviços',
    imgUrl: serviceIntermediationIcon,
  },
  {
    title: 'Maturidade à LGPD',
    imgUrl: maturityToLGPDIcon,
  },
]

const FAQS_ITEMS = [
  {
    title: 'Como o Marketplace contribui para a conformidade regulatória?',
    answer:
      'No nosso marketplace ajudamos empresas de todos os portes a melhorar sua segurança e boas práticas através de produtos e serviços ofertados por parceiros certificados.',
  },
  {
    title: 'Quais são os riscos que o Marketplace ajuda a mitigar?',
    answer:
      'Ofertando uma ampla variedade de serviços, temos desde treinamentos para sua equipe até uma simulação de invasão para demonstrar a fragilidade da sua empresa referente à proteção de dados pessoais.',
  },
  {
    title: 'Como é feita a contratação de um serviço no Marketplace?',
    answer:
      'Ao finalizar seu pedido, o fornecedor receberá uma notificação da sua solicitação e entrará em contato em até 48 horas.',
  },
]

export type ServicesPagesIdsType = (typeof PAGE_IDS)[number]

export default {
  SOLUTIONS_CARDS,
  INFO_CARDS,
  FAQS_ITEMS,
  CONTENT_PAGE,
}
