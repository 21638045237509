import { NavigateNext } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const NavigateNextIcon = styled(NavigateNext, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $fontSize?: Property.FontSize<TLength> | undefined
  $color?: Property.Color | undefined
}>(({ $fontSize, $color }) => ({
  fontSize: $fontSize,
  color: $color,
}))

export default NavigateNextIcon
