import { Table as TableMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const Table = styled(TableMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $whiteSpace?: Property.WhiteSpace | undefined
}>(({ $whiteSpace }) => ({
  whiteSpace: $whiteSpace,
}))

export default Table
