import accordionOverride from './overrides/accordion'
import avatarOverride from './overrides/avatar'
import buttonOverride from './overrides/button'
import cardOverride from './overrides/card'
import controlLabelOverride from './overrides/controlLabel'
import menuOverride from './overrides/menu'
import paperOverride from './overrides/paper'
import stepperOverride from './overrides/stepper'
import tableOverride from './overrides/table'
import typographyOverride from './overrides/typography'
import link from './overrides/link'

export default {
  ...accordionOverride,
  ...avatarOverride,
  ...buttonOverride,
  ...cardOverride,
  ...controlLabelOverride,
  ...menuOverride,
  ...paperOverride,
  ...stepperOverride,
  ...tableOverride,
  ...typographyOverride,
  ...link,
}
