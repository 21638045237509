import * as yup from 'yup'

const schema = yup
  .object({
    name: yup.string().required().max(255),
    cnpj: yup.string().required().length(18),
    siteUrl: yup.string().url('URL Inválida').required().max(255),
    phone: yup.string().required().min(14).max(15),
  })
  .required()

export type WelcomeSchemaType = yup.InferType<typeof schema>

export type WelcomeControlType = keyof WelcomeSchemaType

export default schema
