import { useState } from 'react'
import { Box, Button } from '@mui/material'

import { DialogLogs } from 'components'
import { TicketExportDialog } from './components'

import { TicketHeadType } from './ticketHead.types'

const TicketHead = ({
  ticket,
  ticketMessages,
  logs,
  loadingLogs,
}: TicketHeadType) => {
  // const { userCompany } = useAuth()
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false)
  const [openExportDialog, setOpenExportDialog] = useState(false)

  // const isMiddle = mediaQuery.only.md

  const handleChangeHistoryDialog = () =>
    setOpenHistoryDialog((openHistoryDialog) => !openHistoryDialog)

  const handleChangeExportDialog = () =>
    setOpenExportDialog((openExportDialog) => !openExportDialog)

  return (
    // <Grid container spacing={4}>
    //   <Grid item xs={5} sm={2} lg={1.5}>
    //     <BigAvatar alt={userCompany?.company?.name} src={undefined} />
    //   </Grid>
    //   <Grid
    //     item
    //     xs={7}
    //     sm={10}
    //     lg={10.5}
    //     display="flex"
    //     flexDirection="column"
    //     justifyContent="center"
    //   >
    //     <Grid container spacing={4}>
    //       <Grid item xs={12} sm={7} md={8} lg={9}>
    //         <Typography
    //           fontWeight="normal"
    //           color={theme.palette.neutral.dark}
    //           variant="h6"
    //         >
    //           TICKET:&nbsp;
    //           {ticket?.ticketRef || 'Não informado'}
    //         </Typography>
    //         <Typography color={theme.palette.neutral.dark} variant="h6">
    //           {userCompany?.company?.name || 'Não informado'}
    //         </Typography>
    //         <Typography color="text.secondary">
    //           {userCompany?.company?.cnpj || 'Não informado'}
    //         </Typography>
    //       </Grid>
    // <Grid
    //   item
    //   xs={12}
    //   sm={5}
    //   md={4}
    //   lg={3}
    //   display="flex"
    //   flexDirection={isMiddle ? 'column' : 'row'}
    //   justifyContent="end"
    //   alignItems={isMiddle ? 'end' : 'start'}
    //   gap={5}
    // >
    <Box display="flex" gap={2}>
      <Button variant="outlined" onClick={handleChangeHistoryDialog} fullWidth>
        Histórico
      </Button>
      {!loadingLogs && (
        <DialogLogs
          open={openHistoryDialog}
          onClose={handleChangeHistoryDialog}
          logs={logs}
          showRegisterSession
        />
      )}
      <Button variant="outlined" onClick={handleChangeExportDialog} fullWidth>
        Exportar
      </Button>
      <TicketExportDialog
        open={openExportDialog}
        onClose={handleChangeExportDialog}
        ticket={ticket}
        ticketMessages={ticketMessages}
      />
    </Box>
    // </Grid>
    //     </Grid>
    //   </Grid>
    // </Grid>
  )
}

export default TicketHead
