import { BoxProps } from '@mui/material'

import { MUI } from 'components'

import { CheckoutPageStructureType } from './checkoutPageStructure.types'

import { theme } from 'theme'

const CheckoutPageStructure = ({
  children,
  ...rest
}: CheckoutPageStructureType & BoxProps) => {
  return (
    <MUI.Box
      bgcolor={theme.palette.background.default}
      minHeight="100vh"
      paddingX="4vw"
      paddingY={8}
      $xlUp={{ paddingY: 0 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...rest}
    >
      {children}
    </MUI.Box>
  )
}

export default CheckoutPageStructure
