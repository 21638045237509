import { createTheme, responsiveFontSizes } from '@mui/material'

import typography from './typography'
import components from './components'
import palette from './palette'

// import {
//   typography as typographyOverride,
//   button as buttonOverride,
// } from './overrides/journey'

import journeyOverride from './overrides/journey'

const themeJourney = responsiveFontSizes(
  createTheme({
    palette: palette,
    components: {
      ...components,
      ...journeyOverride,
    },
    typography: {
      ...typography,
      fontFamily: 'Raleway',
    },
    spacing: 4,
  })
)

export default themeJourney
