import { Box, Typography } from '@mui/material'

import { SupplierInfoCardType } from './supplierInfoCard'

import { theme } from 'theme'

const SupplierInfoCard = ({ category, supplier }: SupplierInfoCardType) => {
  return (
    <Box>
      <Box
        bgcolor={theme.palette?.background?.default}
        border={1}
        borderRadius="5px 5px 0px 0px"
        borderColor={theme.palette.paper.border}
        display="flex"
        justifyContent="space-between"
        p={4}
      >
        <Typography variant="body2" color="primary" fontWeight={600}>
          Fornecedor
        </Typography>
        <Typography variant="caption" color="primary">
          {supplier}
        </Typography>
      </Box>
      <Box
        borderLeft={1}
        borderBottom={1}
        borderRight={1}
        borderRadius="0px 0px 5px 5px"
        borderColor={theme.palette.paper.border}
        display="flex"
        justifyContent="space-between"
        p={4}
      >
        <Typography variant="body2" color="primary" fontWeight={600}>
          Categoria
        </Typography>
        <Typography variant="caption" color="primary">
          {category}
        </Typography>
      </Box>
    </Box>
  )
}

export default SupplierInfoCard
