import { DialogWithBackground } from 'components'

import { DialogTutorialAutomaticAnswerType } from './dialogTutorialAutomaticAnswer.types'

const DialogTutorialAutomaticAnswer = ({
  open,
  onClose,
}: DialogTutorialAutomaticAnswerType) => {
  return (
    <DialogWithBackground
      open={open}
      onClose={onClose}
      maxWidth="md"
      title="Configuração de Respostas Automáticas"
      buttonText="Concluir"
      buttonAction={onClose}
    >
      <iframe
        src="https://player.vimeo.com/video/943375195?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{
          width: '100%',
          height: '40vh',
          border: 0,
        }}
        title="DPOeasy - Configuração de Respostas Automáticas"
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </DialogWithBackground>
  )
}

export default DialogTutorialAutomaticAnswer
