import axios from 'axios'
import humps from 'humps'
import qs from 'qs'

import { BaseUrlType } from 'types/auth.types'

import { getBaseURL, getMarketplaceToken } from './env'
import routes from 'constants/routes'
import service from 'service'

export const create = (projectTag: BaseUrlType, treatError: boolean = true) => {
  const api = axios.create({ baseURL: getBaseURL(projectTag) })

  api.interceptors.request.use((reqConfig) => {
    const authToken = service.dponet.auth.getToken()
    const cookieToken = service.dpoeasy.auth.getToken()
    const cookieRegistrationToken =
      service.dpoeasy.auth.getRegistrationCookieToken()
    const marketplaceToken = getMarketplaceToken()

    if (authToken && projectTag === 'dponet') {
      reqConfig.headers.Authorization = authToken
    }

    if (projectTag === 'dpoeasy') {
      if (cookieToken) {
        reqConfig.headers.Authorization = cookieToken
      } else if (cookieRegistrationToken) {
        reqConfig.headers.Authorization = cookieRegistrationToken
      }
    }

    if (marketplaceToken && projectTag === 'marketplace') {
      reqConfig.headers.Authorization = marketplaceToken
    }

    reqConfig.headers.crossDomain = true
    reqConfig.data = humps.decamelizeKeys(reqConfig.data)
    reqConfig.params = humps.decamelizeKeys(reqConfig.params)
    reqConfig.paramsSerializer = (params: object) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      })
    }

    return reqConfig
  })

  api.interceptors.response.use(
    //eslint-disable-next-line
    (resp): any => humps.camelizeKeys(resp),
    (error): object => {
      if (treatError) {
        if (error?.response?.status === 401) {
          location.replace(routes.forbidden)

          return Promise.reject(humps.camelizeKeys(error))
        }

        if (error?.response?.status === 404) {
          location.replace(routes.notFound)

          return Promise.reject(humps.camelizeKeys(error))
        }

        if (error?.response?.status === 403) {
          location.replace(routes.forbidden)

          return Promise.reject(humps.camelizeKeys(error))
        }

        if (error?.response?.status === 500) {
          location.replace(routes.internalError)

          return Promise.reject(humps.camelizeKeys(error))
        }

        if (error?.code === 'ERR_NETWORK') {
          location.replace(routes.offline)
        }
      }
      return Promise.reject(humps.camelizeKeys(error))
    }
  )

  return api
}

const api = { create }

export default api
