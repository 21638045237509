import { Grid, Typography } from '@mui/material'
import { formatters } from 'helpers'
import { useRegistration } from 'hooks'

import { CardPolicy, GreyAlert } from 'views/WelcomeSteps/components'

const Company = () => {
  const { company } = useRegistration()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" color="primary">
          Gerando suas políticas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          textAlign="center"
          fontWeight="400"
          color="primary"
        >
          Nossa Inteligencia Artificial (DAI) está preparando as políticas da
          sua organização.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CardPolicy
              title="Aviso de Privacidade"
              description={`\nOlá, seja bem-vindo(a) ao portal da ${company?.name}.
\nInicialmente, agradecemos muito a sua visita! Saiba que, quando você ingressa em nosso portal, nos confia seus dados e informações pessoais. Por isso, nos comprometemos a manter essa confiança.
Nosso Aviso de Privacidade explicará a você, de forma clara e acessível...`}
            />
          </Grid>
          <Grid item xs={12}>
            <CardPolicy
              title="Política de cookies"
              description={`\nOlá, seja bem-vindo(a) ao portal da ${company?.name}.
\nAgradecemos muito sua visita! Quando você ingressa em nosso portal, nos confia seus dados e informações pessoais, e isso é muito importante! Por isso, nos comprometemos a manter essa confiança com todos os nossos usuários visitantes.
A ${company?.name}, inscrito no CNPJ sob o nº ${formatters.cnpj.format(company?.cnpj ?? '')}...`}
            />
          </Grid>
          <Grid item xs={12}>
            <GreyAlert description="Essa é apenas uma pré-visualização das políticas. Após a contratação o conteúdo integral será disponibilizado." />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Company
