import { Fragment, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Checkbox, FormControlLabel } from '@mui/material'

import { BasicDialog } from 'components'
import { TicketPDF } from './components'

import { TicketExportDialogType } from './ticketExportDialog.types'

import schema, { TicketExportDialogFormType } from './schema'
import constants from 'constants/index'

const TicketExportDialog = ({
  ticket,
  ticketMessages,
  open,
  onClose,
}: TicketExportDialogType) => {
  const { control, watch, reset } = useForm<TicketExportDialogFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      externalMessage: false,
      internalMessage: false,
    },
  })

  const externalMessage = watch('externalMessage')
  const internalMessage = watch('internalMessage')

  const buttonValidation = !(externalMessage || internalMessage)

  useEffect(() => {
    if (!open) {
      reset({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={`Exportar Ticket: ${ticket?.ticketRef || 'N/A'}`}
      dialogActions={
        <Fragment>
          <TicketPDF
            ticket={ticket}
            ticketMessages={ticketMessages}
            disabled={buttonValidation}
            exportOptions={{ externalMessage, internalMessage }}
          />
        </Fragment>
      }
    >
      <form id="ticket-export-dialog-form">
        <Box display="flex" flexDirection="column">
          {constants.ticket.TICKET_EXPORT_DIALOG_ITEMS.map((item, index) => (
            <Controller
              key={index}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={item.label}
                />
              )}
              name={item.controlName}
              control={control}
            />
          ))}
        </Box>
      </form>
    </BasicDialog>
  )
}

export default TicketExportDialog
