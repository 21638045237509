import { Box, Typography } from '@mui/material'

import { theme } from 'theme'

const BannerInfo = () => {
  return (
    <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
      <Typography color="primary" fontWeight={600} gutterBottom>
        Informação
      </Typography>
      <Typography color="primary" textAlign="justify">
        Um banner de cookies é um aviso exibido nos sites para informar e obter
        o consentimento dos usuários antes de rastrear e armazenar seus dados de
        navegação.
      </Typography>
    </Box>
  )
}

export default BannerInfo
