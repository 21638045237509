import { Box, Paper, Typography } from '@mui/material'

import { InputFile, MUI } from 'components'

import { formatters } from 'helpers'

import { useAuth, useMediaQuery } from 'hooks'

import { CompanyHighlightCardType } from './companyHighlightCard.types'

import { theme } from 'theme'
import constants from 'constants/index'

const CompanyHighlightCard = ({
  reactPalette,
  logo,
}: CompanyHighlightCardType) => {
  const mediaQuery = useMediaQuery()
  const { userCompany } = useAuth()

  const smDown = mediaQuery.down.sm

  return (
    <Paper
      variant="outlined"
      component={Box}
      display="flex"
      flexDirection="column"
      position="relative"
      alignItems={smDown ? 'center' : 'unset'}
    >
      <Box
        height={170}
        width="100%"
        bgcolor={reactPalette?.lightVibrant || theme.palette.secondary.light}
      />
      <Box position="absolute" left={!smDown ? 20 : undefined} top={65}>
        <MUI.Avatar
          alt="Logotipo da empresa"
          src={logo}
          $width={200}
          $height={200}
          $backgroundColor={theme.palette.background.default}
          $boxShadow={`0px 0px 4px ${reactPalette?.vibrant || theme.palette.secondary.dark}`}
          $color={theme.palette.secondary.main}
          $fontSize={82}
        />
      </Box>
      <Box
        p={5}
        height={225}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex" justifyContent="flex-end" pt={smDown ? 24 : 0}>
          <InputFile
            title="Logomarca da empresa"
            accept={constants.settings.SUPPORTED_IMAGE_FILE_INPUT_TYPES}
            controlName="logo"
          />
        </Box>
        <Box>
          <Typography color="primary" variant="h6">
            {userCompany?.company?.name}
          </Typography>
          {userCompany?.company?.cnpj && (
            <Typography color="primary">
              {formatters.cnpj.format(userCompany?.company?.cnpj)}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default CompanyHighlightCard
