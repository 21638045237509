import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const get = async ({
  ticketId = '',
  ...params
}: {
  ticketId?: string | number
  params?: object
}) => await dponetAPI.get(`/tickets/${ticketId}`, params)

const logs = async ({ ticketId = '' }: { ticketId?: string | number }) =>
  await dponetAPI.get(`/tickets/${ticketId}/logs`)

const update = async ({
  ticketId = '',
  ticket,
}: {
  ticketId?: string | number
  ticket: object
}) => await dponetAPI.put(`/tickets/${ticketId}`, { ticket })

const getMessages = async ({ ticketId = '' }: { ticketId?: string | number }) =>
  await dponetAPI.get(`/tickets/${ticketId}/messages`)

const postMessage = async ({
  ticketId = '',
  ticketMessage,
}: {
  ticketId?: string | number
  ticketMessage: object
}) => await dponetAPI.post(`/tickets/${ticketId}/messages`, { ticketMessage })

const reviewLegitimacySolicitation = async ({
  ticketId = '',
  ticketSteps,
}: {
  ticketId?: string | number
  ticketSteps: object
}) =>
  await dponetAPI.post(`/tickets/${ticketId}/confirm_solicitation`, {
    ticketSteps,
  })

const confirmTitularData = async ({
  ticketId = '',
  hasTitularData,
}: {
  ticketId?: string | number
  hasTitularData: boolean
}) =>
  await dponetAPI.post(`/tickets/${ticketId}/confirm_titular_data`, {
    hasTitularData,
  })

export default {
  get,
  logs,
  update,
  getMessages,
  postMessage,
  reviewLegitimacySolicitation,
  confirmTitularData,
}
