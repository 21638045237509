import { useContext } from 'react'
import { PrivacyChannelContext } from 'contexts'

const usePrivacyChannel = () => {
  const context = useContext(PrivacyChannelContext)

  if (context === undefined) {
    throw new Error(
      'usePrivacyChannel must be used within a PrivacyChannelContext.Provider'
    )
  }

  return context
}

export default usePrivacyChannel
