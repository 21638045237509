import { MobileStepper } from '@mui/material'

import { useJourney } from 'hooks'

const Stepper = () => {
  const { activeStep, steps } = useJourney()

  return (
    <MobileStepper
      variant="dots"
      steps={steps.length}
      position="static"
      activeStep={activeStep}
      backButton={<></>}
      nextButton={<></>}
    />
  )
}

export default Stepper
