import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { DialogWithBackground } from 'components'

import routes from 'constants/routes'
import { Typography } from '@mui/material'

const DialogCookiesError = () => {
  const navigate = useNavigate()

  const [open, setOpen] = useState(true)

  const onClose = () => setOpen(false)
  const handleNavigate = () => navigate(routes.banners.all)

  return (
    <DialogWithBackground
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title="Banner de cookies"
      buttonText="Configurar meu banner"
      buttonAction={handleNavigate}
      fullScreen={false}
    >
      <Typography variant="body1">
        Estamos enfrentando dificuldades para scanear o seu site. Por favor, vá
        para a tela de configurações e verifique se a URL está correta.
      </Typography>
    </DialogWithBackground>
  )
}

export default DialogCookiesError
