import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'

import { DialogConfirmTitularDataType } from './dialogConfirmTitularData.types'

import service from 'service'

const DialogConfirmTitularData = ({
  onClose,
  open,
  result,
  ticketId,
  refresh,
}: DialogConfirmTitularDataType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const onSubmit = async () => {
    try {
      setLoading(true)
      await service.dponet.ticket.confirmTitularData({
        ticketId,
        hasTitularData: result,
      })

      snackbar.open({
        message: `Confirmado com sucesso que a empresa ${
          result ? 'possui' : 'não possui'
        } os dados do titular `,
        variant: 'success',
      })

      onClose()
      refresh()

      setLoading(false)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        visible: true,
        message: 'Ocorreu um erro ao confirmar a solicitação',
        variant: 'error',
      })
    }
  }

  return (
    <BasicDialog
      open={open}
      title="Confirmação"
      onClose={onClose}
      dialogActions={
        <Box display="flex" gap={2}>
          <Button onClick={onClose}>CANCELAR</Button>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            CONFIRMO
          </Button>
        </Box>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography variant="body1">
        {result ? (
          'Você confirma que a sua empresa possui os dados deste titular?'
        ) : (
          <>
            Você confirma que a sua empresa <b>NÃO</b> possui os dados deste
            titular?
          </>
        )}
      </Typography>
    </BasicDialog>
  )
}

export default DialogConfirmTitularData
