import * as yup from 'yup'

const testValidator =
  (field: string) =>
  (value?: unknown, context?: yup.TestContext<yup.AnyObject>) => {
    const planPrice = context?.from?.[1]?.value?.[field]

    return !!value || !planPrice
  }

export default testValidator
