import { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Box } from '@mui/material'

import { LoadingFeedback } from 'components'

import { TinymceTextEditorType } from './tinymceTextEditor.types'

import { getEditorTyneMceToken } from 'service/env'

const TinymceTextEditor = ({ field, height = 500 }: TinymceTextEditorType) => {
  const [loading, setLoading] = useState(true)
  const { onChange, value } = field

  const handleEditorChange = (editor: unknown) => onChange(editor)
  const onLoad = () => setLoading(false)

  return (
    <Box>
      <LoadingFeedback open={loading} />
      <Editor
        onEditorChange={handleEditorChange}
        apiKey={getEditorTyneMceToken()}
        onLoadContent={onLoad}
        init={{
          height: height,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent ',
          language: 'pt_BR',
        }}
        value={value}
      />
    </Box>
  )
}

export default TinymceTextEditor
