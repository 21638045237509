import { Box, Typography } from '@mui/material'

import { BasicDialog } from 'components'

import { formatters, validates } from 'helpers'

import { ConsentDetailsDialogType } from './consentDetailsDialog.types'

import constants from 'constants/index'

const ConsentDetailsDialog = ({
  onClose,
  consent,
}: ConsentDetailsDialogType) => {
  const consentItems = [
    consent?.banner.domain,
    consent?.userAgent,
    validates.consent.readingTypeTranslation(consent?.kind),
    formatters.date.ptBrFormatDateTime(
      consent?.datetimeConsent,
      'Não encontrada'
    ),
    consent?.tags.optIns.join('; ') || 'Não encontrada',
    consent?.tags.optOuts.join('; ') || 'Não encontrada',
  ]

  return (
    <BasicDialog
      title="Detalhes do Consentimento"
      open={!!consent}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography color="primary">
          Algumas informações sobre esse consentimento
        </Typography>
        {constants.consent.CONSENT_DETAILS_DIALOG_ITEMS.map((item, index) => (
          <Box key={index}>
            <Typography color="primary" fontWeight={600}>
              {item.title}
            </Typography>
            <Typography color="primary">
              {item?.value(consentItems[index])}
            </Typography>
          </Box>
        ))}
      </Box>
    </BasicDialog>
  )
}

export default ConsentDetailsDialog
