import { styled } from '@mui/material/styles'

const HighlightText = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#FFF',
  fontWeight: 700,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
}))

export default HighlightText
