import { isNil } from 'lodash'

import { CheckoutTrailId } from 'constants/checkout/trails'

import constants from 'constants/index'

const trailItem = (trailsId: CheckoutTrailId | null | undefined) => {
  return (
    (!isNil(trailsId) &&
      constants.checkout.trails.CHECKOUT_TRAIL_ITEM[trailsId]) ||
    null
  )
}

export default {
  trailItem,
}
