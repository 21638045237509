import { Avatar as AvatarMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

type AvatarType = {
  $backgroundColor?: Property.BackgroundColor
  $fontSize?: Property.FontSize<TLength>
  $fontWeight?: Property.FontWeight
  $height?: Property.Height<TLength>
  $width?: Property.Width<TLength>
  $boxShadow?: Property.BoxShadow
  $color?: Property.Color
}

const Avatar = styled(AvatarMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<AvatarType>(
  ({
    $backgroundColor,
    $boxShadow,
    $color,
    $fontSize,
    $fontWeight,
    $height,
    $width,
  }) => ({
    backgroundColor: $backgroundColor,
    boxShadow: $boxShadow,
    color: $color,
    fontSize: $fontSize,
    fontWeight: $fontWeight,
    height: $height,
    width: $width,
  })
)

export default Avatar
