import { Download as DownloadIcon } from 'react-feather'
import { Box, Divider, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { MUI } from 'components'
import { SocialMediaImg } from './components'

import { useClipboard, useMediaQuery } from 'hooks'

const SocialMediaStamp = () => {
  const { downloadHtmlToImage, isLoading } = useClipboard()
  const mediaQuery = useMediaQuery()

  const lgDown = mediaQuery.down.lg

  const htmlId = 'social-media-stamp'

  const handleDownloadStamp = () => {
    const htmlContent = document.getElementById(htmlId)

    downloadHtmlToImage(
      'selo_canal_de_comunicação_para_redes_sociais',
      htmlContent,
      1080,
      1080
    )
  }

  return (
    <MUI.Paper
      variant="outlined"
      $borderTopRightRadius={0}
      $borderTopLeftRadius={0}
    >
      <Box
        display="flex"
        flexDirection={lgDown ? 'column' : 'row'}
        p={4}
        gap={8}
      >
        <Box display="flex" height="100%" justifyContent="center">
          <SocialMediaImg id={htmlId} />
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box>
            <Typography fontWeight={600} color="primary" gutterBottom>
              Publique em suas redes sociais
            </Typography>
            <Typography variant="body2" color="primary" gutterBottom>
              Publique de forma recorrente em suas redes sociais sobre a sua
              organização adotar boas práticas de proteção de dados. Ganhe
              credibilidade com os seus clientes e parceiros.
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              fontWeight={600}
              color="primary"
              gutterBottom
            >
              Imagem de Postagem
            </Typography>
            <Typography variant="body2" color="primary" gutterBottom>
              Realize o download da imagem para publicá-la em suas redes
              sociais.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="end" p={4}>
        <LoadingButton
          startIcon={<DownloadIcon size={18} />}
          variant="contained"
          loading={isLoading}
          onClick={handleDownloadStamp}
        >
          Download
        </LoadingButton>
      </Box>
    </MUI.Paper>
  )
}

export default SocialMediaStamp
