import { Button, Dialog, Typography } from '@mui/material'

import { HexagonStructure } from 'components'

import { useMediaQuery } from 'hooks'

import { DialogWithBackgroundType } from './dialogWithBackground.types'

const DialogWithBackground = ({
  children,
  title,
  open,
  maxWidth,
  disableBackdropClick,
  buttonAction,
  buttonText,
  onClose,
  fullScreen,
}: DialogWithBackgroundType) => {
  const { down } = useMediaQuery()
  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen ?? down.md}
      maxWidth={maxWidth}
      onClose={!disableBackdropClick ? onClose : undefined}
      open={open}
    >
      <HexagonStructure
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={10}
        p={10}
      >
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        {children}
        <Button variant="contained" onClick={buttonAction}>
          {buttonText}
        </Button>
      </HexagonStructure>
    </Dialog>
  )
}

export default DialogWithBackground
