import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const ColorError = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  gap: theme.spacing(2),
  display: 'flex',
}))

export default ColorError
