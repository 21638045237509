import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Grid,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { MUI } from 'components'

import { useMediaQuery } from 'hooks'

import { BannerConfigurationSchemaType } from 'types/banner.types'
import { ScannerCardType } from './scannerCard.types'

import { theme } from 'theme'
import constants from 'constants/index'

const ScannerCard = ({ cookies }: ScannerCardType) => {
  const mediaQuery = useMediaQuery()

  const mdDown = mediaQuery.down.md

  const {
    control,
    formState: { errors },
  } = useFormContext<BannerConfigurationSchemaType>()

  return (
    <Grid container spacing={2}>
      {cookies?.map((cookie, index) => {
        const disableInput =
          cookie?.category?.id === constants.cookie.NECESSARY_CATEGORY_ID
        return (
          <Grid item xs={12} key={index}>
            <Box
              bgcolor={theme.palette.background.default}
              border={1}
              borderColor={theme.palette.primary.main}
              borderRadius={1}
            >
              <Grid
                container
                p={4}
                justifyContent="space-between"
                spacing={mdDown ? 6 : 16}
              >
                <Grid item xs={12} sm={8}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Controller
                      render={({ field }) => (
                        <Tooltip
                          title={
                            disableInput &&
                            'Cookies necessários não podem ser modificados'
                          }
                        >
                          <TextField
                            {...field}
                            placeholder="Origem"
                            error={!!errors?.cookies?.[index]?.platform}
                            helperText={errors?.cookies?.[
                              index
                            ]?.platform?.message?.toString()}
                            type="text"
                            size="small"
                            disabled={disableInput}
                            fullWidth
                          />
                        </Tooltip>
                      )}
                      control={control}
                      name={`cookies.${index}.platform`}
                    />
                    <Tooltip title={cookie?.name} placement="bottom-start">
                      <Typography
                        color="primary"
                        variant="body2"
                        width="fit-content"
                        maxWidth="100%"
                        zIndex={1}
                        noWrap
                      >
                        {cookie?.name}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    color="primary"
                    variant="body2"
                    fontWeight={600}
                    gutterBottom
                  >
                    Classificação:
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <Tooltip
                        title={
                          disableInput &&
                          'Cookies necessários não podem ser modificados'
                        }
                      >
                        <Select
                          {...field}
                          fullWidth
                          error={!!errors?.cookies?.[index]?.categoryId}
                          size="small"
                          placeholder="Selecionar"
                          disabled={disableInput}
                        >
                          {constants.cookie.CATEGORY_OPTIONS.map(
                            (cookieCategory) => (
                              <MUI.MenuItem
                                key={cookieCategory?.id}
                                value={cookieCategory?.id}
                                disabled={cookieCategory?.disabled}
                                $textTransform="initial"
                              >
                                {cookieCategory.name}
                              </MUI.MenuItem>
                            )
                          )}
                        </Select>
                      </Tooltip>
                    )}
                    control={control}
                    name={`cookies.${index}.categoryId`}
                  />
                  {!!errors?.cookies?.[index] && (
                    <Typography variant="caption" color="error">
                      {errors?.cookies?.[
                        index
                      ]?.categoryId?.message?.toString()}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ScannerCard
