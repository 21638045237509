import { reverse } from 'named-urls'
import { Box, Button, Grid, Typography } from '@mui/material'

import { MUI, StandardPageStructure } from 'components'
import { FAQItem, InfoCard } from './components'

import { useNavigate } from 'react-router-dom'

import { theme } from 'theme'
import constants from 'constants/index'

const MarketplaceMain = () => {
  const navigate = useNavigate()

  const handleRedirectButton = (serviceId: number) =>
    navigate(
      reverse(constants.routes.marketplace.service, {
        serviceId,
      })
    )

  return (
    <StandardPageStructure display="flex" flexDirection="column" gap={16}>
      <MUI.Box
        bgcolor={theme.palette.primary.main}
        p={12}
        display="flex"
        flexDirection="column"
        textAlign="justify"
        gap={16}
        $smDown={{ padding: 24 }}
      >
        <Box>
          <Typography color="white" variant="h4" gutterBottom>
            Marketplace
          </Typography>
          <Typography color="white" gutterBottom>
            O Marketplace é um HUB de produtos e serviços que trazem uma
            variedade de ferramentas que aumentam a segurança da sua empresa em
            relação a proteção de dados. Aqui garantimos facilidade em contratar
            serviços pré-selecionados fornecidos por parceiros confiáveis,
            ajudando a impulsionar a a segurança dos seus clientes e
            solidificando sua reputação como líder em governança de dados
            responsável.
          </Typography>
        </Box>
        <Typography color="white" variant="h5" textAlign="center">
          Soluções mais solicitadas pelos nossos clientes
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {constants.marketplace.home.SOLUTIONS_CARDS.map((item, index) => (
            <Grid key={index} item xs={12} lg={6} xl={4} height="auto">
              <InfoCard
                title={item?.title}
                description={item?.description}
                imgUrl={item?.imgUrl}
                textAlign="justify"
                isHighlight
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRedirectButton(item?.serviceId)}
                >
                  Saiba Mais
                </Button>
              </InfoCard>
            </Grid>
          ))}
        </Grid>
      </MUI.Box>
      <Grid container spacing={16}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h4">O que é o Marketplace?</Typography>
        </Grid>
        {constants.marketplace.home.INFO_CARDS.map((item, index) => (
          <Grid key={index} item xs={12} lg={6} xl={4} height="auto">
            <InfoCard {...item} />
          </Grid>
        ))}
      </Grid>
      <Box textAlign="center">
        <Typography variant="h4">Perguntas Frequentes</Typography>
      </Box>
      <Grid container spacing={6}>
        {constants.marketplace.home.FAQS_ITEMS.map((item, index) => (
          <Grid key={index} item xs={12}>
            <FAQItem item={item} />
          </Grid>
        ))}
      </Grid>
    </StandardPageStructure>
  )
}

export default MarketplaceMain
