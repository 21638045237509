import { createContext } from 'react'

import { RegistrationContextType } from 'types/registration.types'

const contextValues: RegistrationContextType = {
  createBanner: async () => {},
  createCompany: async () => {},
  loadData: async () => {},
  loadOut: async () => {},
  recaptcha: '',
  setAnotherSegment: () => {},
  setBannerCreated: () => {},
  setCompanyChanged: () => {},
  setCompanyToken: () => {},
  setRecaptcha: () => {},
  updateCompany: () => {},
}

const authContext = createContext(contextValues)

export default authContext
