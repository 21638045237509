import { colors, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const ColorMissing = styled(Box)(({ theme }) => ({
  color: colors.blueGrey[700],
  gap: theme.spacing(2),
  display: 'flex',
}))

export default ColorMissing
