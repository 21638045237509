import { useFormContext } from 'react-hook-form'
import {
  CheckCircle as CheckCircleIcon,
  XCircle as XCircleIcon,
} from 'react-feather'
import { isNil } from 'lodash'
import { Box, Typography } from '@mui/material'

import { PasswordValidationType } from './passwordValidation.types'

import { theme } from 'theme'
import constants from 'constants/index'

const PasswordValidation = ({
  controlName = 'password',
}: PasswordValidationType) => {
  const { watch } = useFormContext()

  const password = watch(controlName)

  const passwordRegex = (index: number) => {
    const regex = constants.settings.PASSWORD_REGEX_VALIDATION[index]
    if (isNil(regex)) return false

    return regex(password)
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {constants.settings.PASSWORD_VALIDATION_LABEL.map((label, index) => {
        const isValid = passwordRegex(index)

        const color = () => {
          if (!password) return theme.palette.neutral.dark
          else if (isValid) return theme.palette.success.main
          return theme.palette.error.main
        }

        const ValidationIcon =
          isValid && !!password ? CheckCircleIcon : XCircleIcon

        return (
          <Box key={index} display="flex" gap={2} alignItems="center">
            <Box>
              <ValidationIcon size={20} color={color()} />
            </Box>
            <Typography color={color()} noWrap>
              {label}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default PasswordValidation
