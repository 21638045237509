import { createContext } from 'react'
import { JourneyContextType } from 'types/journey.types'

const contextValues: JourneyContextType = {
  steps: [],
  activeStep: 0,
  setSteps: () => {},
  handleNext: () => {},
  handleBack: () => {},
  setActiveStep: () => {},
  setBackDisabled: () => {},
  setStepInfo: () => {},
  setNextDisabled: () => {},
}

const journeyContext = createContext(contextValues)

export default journeyContext
