import { FormLabel as FormLabelMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

type FormLabelType = {
  $color?: Property.Color
  $fontWeight?: Property.FontWeight
  $mb?: Property.MarginBottom<TLength>
}

const FormLabel = styled(FormLabelMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<FormLabelType>(({ theme, $color, $fontWeight, $mb }) => ({
  color: $color,
  fontWeight: $fontWeight,
  marginBottom: $mb && theme.spacing($mb),
}))

export default FormLabel
