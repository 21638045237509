import { Fragment } from 'react'
import { Box, Divider, Hidden } from '@mui/material'

import { ColorInput } from 'components'

const ColorCustomization = () => {
  return (
    <Fragment>
      <Box display="flex" flexDirection="column" gap={4} width="100%">
        <ColorInput controlName="primaryColor" label="Cor Primária" />
        <ColorInput controlName="secondaryColor" label="Cor Secundária" />
      </Box>
      <Hidden lgDown>
        <Divider orientation="vertical" variant="middle" flexItem />
      </Hidden>
    </Fragment>
  )
}

export default ColorCustomization
