import {
  Box,
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { BasicDrawerType } from './basicDrawer.types'

const BasicDrawer = ({
  open,
  onClose,
  fullScreen = false,
  children,
  title,
  ...rest
}: BasicDrawerType & DrawerProps) => {
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: fullScreen ? '100%' : '' } }}
      {...rest}
    >
      <Box>
        <Box
          display="flex"
          justifyContent={title ? 'space-between' : 'end'}
          alignItems="center"
          paddingLeft="4vw"
          paddingRight="2vw"
          paddingY={2}
        >
          {title && (
            <Typography variant="h5" color="primary">
              {title}
            </Typography>
          )}
          <IconButton size="large" aria-label="Fechar" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box paddingX="4vw" paddingY={8}>
          {children}
        </Box>
      </Box>
    </Drawer>
  )
}

export default BasicDrawer
