import { RadioGroup as RadioGroupMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

type RadioGroupType = {
  $gap?: Property.Gap<TLength>
}

const RadioGroup = styled(RadioGroupMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<RadioGroupType>(({ $gap, theme }) => ({
  gap: $gap && theme.spacing($gap),
}))

export default RadioGroup
