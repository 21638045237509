import { Box, Grid, Typography } from '@mui/material'

import { PocketCardImage } from 'components'
import { GreyAlert } from 'views/WelcomeSteps/components'

import { useRegistration } from 'hooks'

import { StampType } from './stamp.types'

const Stamp = ({ qrCode }: StampType) => {
  const { company } = useRegistration()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" color="primary">
          Geramos o seu selo!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight="400"
          textAlign="center"
          color="primary"
        >
          Destaque o seu canal de atendimento com os selos. Escolha entre várias
          opções de formatos que preparamos para você
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PocketCardImage company={company} qrCode={qrCode} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <GreyAlert description="O canal de atendimento só ficará disponível após finalizar sua conta" />
      </Grid>
    </Grid>
  )
}

export default Stamp
