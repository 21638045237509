import { useNavigate } from 'react-router-dom'
import { LogOut as LogOutIcon, Settings as SettingsIcon } from 'react-feather'
import {
  Menu as MenuMUI,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { MenuType } from './menu.types'
import routes from 'constants/routes'

const Menu = ({ anchorEl, open, onClose, logout }: MenuType) => {
  const navigate = useNavigate()

  const handleSettingsNavigation = () => navigate(routes.settings.all)

  return (
    <MenuMUI
      id="account-menu"
      open={open}
      anchorEl={anchorEl}
      MenuListProps={{
        'aria-labelledby': 'account-box',
      }}
      onClose={onClose}
    >
      <MenuItem onClick={handleSettingsNavigation}>
        <ListItemIcon>
          <SettingsIcon size={20} />
        </ListItemIcon>
        <ListItemText>Configurações</ListItemText>
      </MenuItem>
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogOutIcon size={20} />
        </ListItemIcon>
        <ListItemText>Sair</ListItemText>
      </MenuItem>
    </MenuMUI>
  )
}

export default Menu
