import { useMediaQuery } from '@mui/material'

import { theme } from 'theme'

const useCustomMediaQuery = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  const mdDown = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  })

  const lgDown = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true,
  })

  const xlDown = useMediaQuery(theme.breakpoints.down('xl'), {
    defaultMatches: true,
  })

  const smOnly = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  })

  const mdOnly = useMediaQuery(theme.breakpoints.only('md'), {
    defaultMatches: true,
  })

  const lgOnly = useMediaQuery(theme.breakpoints.only('lg'), {
    defaultMatches: true,
  })

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const xlUp = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true,
  })

  const down = {
    sm: smDown,
    md: mdDown,
    lg: lgDown,
    xl: xlDown,
  }

  const up = {
    sm: smUp,
    md: mdUp,
    lg: lgUp,
    xl: xlUp,
  }

  const only = {
    sm: smOnly,
    md: mdOnly,
    lg: lgOnly,
  }

  return { down, up, only }
}

export default useCustomMediaQuery
