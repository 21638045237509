import api from 'service/api'

const dpoeasyAPI = api.create('dpoeasy', false)

const get = async ({
  bannerId,
  params,
}: {
  bannerId?: string
  params: object
}) => await dpoeasyAPI.get('/consents', { params: { ...params, bannerId } })

export default {
  get,
}
