import ReCAPTCHA from 'react-google-recaptcha'
import { MutableRefObject } from 'react'

import { Box, Button, Checkbox, FormControlLabel, Grid } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'

import { getGoogleRecaptchaToken } from 'service/env'
import { useJourney, useMediaQuery, useRegistration } from 'hooks'
import { WelcomePlanFormSchemaType } from '../../schema'

const UsingTerms = ({
  recaptchaRef,
}: {
  recaptchaRef: MutableRefObject<ReCAPTCHA | null>
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<WelcomePlanFormSchemaType>()

  const { down } = useMediaQuery()
  const { setRecaptcha } = useRegistration()
  const { setNextDisabled, handleBack } = useJourney()

  const expiredRecaptcha = () => {
    setValue('recaptcha', '')
    setNextDisabled(true)
    setRecaptcha(null)
  }

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setNextDisabled(false)
      setValue('recaptcha', token)
      setRecaptcha(token)
    }
  }

  const goBack = () => {
    setNextDisabled(false)
    handleBack()
  }

  return (
    <>
      <Grid item xs={12}>
        <Box>
          <Controller
            render={({ field }) => (
              <FormControlLabel
                {...field}
                componentsProps={{
                  typography: {
                    color: errors?.privacyCheck ? 'red' : 'primary',
                  },
                }}
                control={<Checkbox />}
                label="Li e concordo com o Aviso de privacidade"
              />
            )}
            control={control}
            name="privacyCheck"
          />
        </Box>
        <Controller
          render={({ field }) => (
            <FormControlLabel
              {...field}
              componentsProps={{
                typography: {
                  color: errors?.usingThermsCheck ? 'red' : 'primary',
                },
              }}
              control={<Checkbox />}
              label="Li e concordo com os Termos e condições de uso "
            />
          )}
          control={control}
          name="usingThermsCheck"
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          width="100%"
          display="flex"
          flexDirection={down.lg ? 'column' : 'row'}
          alignItems={down.lg ? 'flex-start' : 'center'}
          justifyContent="space-between"
        >
          <Controller
            render={({ field }) => (
              <ReCAPTCHA
                {...field}
                ref={recaptchaRef}
                sitekey={getGoogleRecaptchaToken()}
                onChange={(token) => handleRecaptcha(token)}
                onExpired={expiredRecaptcha}
                size="normal"
              />
            )}
            name="recaptcha"
            control={control}
          />
          <Box pt={down.lg ? 2 : 0} display="flex" gap={3}>
            <Button variant="outlined" color="primary" onClick={goBack}>
              Voltar
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Confirmar
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default UsingTerms
