import { Box, Grid, TextField, Typography } from '@mui/material'

import { CheckoutSchemaType } from 'constants/checkout/form'
import { formatters } from 'helpers'
import { Controller, useFormContext } from 'react-hook-form'

import { theme } from 'theme'

import { PaymentTextFieldType } from './PaymentTextField.types'
import { HandleOnChangeType } from 'views/Checkout/checkout.types'

const PaymentTextField = ({ item }: PaymentTextFieldType) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<CheckoutSchemaType>()

  const fieldName = item?.controlName?.split(
    '.'
  )[1] as keyof CheckoutSchemaType['card']

  const handleOnChange = ({ event, type }: HandleOnChangeType) => {
    let inputValue = event?.target?.value

    if (type === 'card.expiration')
      inputValue = formatters.cardFlag.expiringDate(inputValue)
    else if (type === 'card.cvv')
      inputValue = inputValue.slice(0, 4).toUpperCase()

    setValue(type, inputValue)
  }

  return (
    <Grid item xs={item?.gridXs} sm={item?.gridSm}>
      <Box display="flex" gap={1}>
        <Typography color="primary">{item.label}</Typography>
        {item?.required && <Typography color="error">*</Typography>}
      </Box>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.card?.[fieldName]}
            helperText={<>{errors?.card?.[fieldName]?.message}</>}
            inputProps={{
              inputMode: item?.type,
              style: { backgroundColor: theme.palette.common.white },
            }}
            onChange={(event) =>
              handleOnChange({ event, type: item?.controlName })
            }
            fullWidth
          />
        )}
        control={control}
        name={item?.controlName}
      />
    </Grid>
  )
}

export default PaymentTextField
