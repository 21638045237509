import PerfectScrollbar from 'react-perfect-scrollbar'
import { isEmpty } from 'lodash'
import { Typography } from '@mui/material'

import { LogsTable } from './components'
import { BasicDialog } from 'components'

import { useMediaQuery } from 'hooks'

import { DialogLogsType } from './dialogLogs.types'

const DialogLogs = ({
  logs,
  onClose,
  open,
  showRegisterSession,
}: DialogLogsType) => {
  const mediaQuery = useMediaQuery()

  const smDown = mediaQuery.down.sm

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Histórico de alterações"
      maxWidth="md"
      fullScreen={smDown}
    >
      <PerfectScrollbar>
        {isEmpty(logs) || !logs ? (
          <Typography color="primary">
            Não existe nenhum histórico de alterações para este registro.
          </Typography>
        ) : (
          <LogsTable logs={logs} showRegisterSession={showRegisterSession} />
        )}
      </PerfectScrollbar>
    </BasicDialog>
  )
}

export default DialogLogs
