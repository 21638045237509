import { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Plus as PlusIcon } from 'react-feather'

const AddInput = ({
  onClick,
}: {
  onClick: (textFieldValue: string) => void
}) => {
  const [textFieldValue, setTextFieldValue] = useState('')

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextFieldValue(event.target.value)
  }

  const handleClick = () => {
    onClick(textFieldValue)
    setTextFieldValue('')
  }

  return (
    <TextField
      onChange={handleTextFieldChange}
      value={textFieldValue}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleClick}>
              <PlusIcon size={20} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default AddInput
