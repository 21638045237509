import whiteBoxIcon from 'images/marketplace/icons/pentest_white_box.png'
import networkServiceIcon from 'images/marketplace/icons/pentest_network_service.png'
import webApplicationIcon from 'images/marketplace/icons/pentest_web_app.png'

import vulnerabilityIcon from 'images/marketplace/icons/pentest_descoberta_vunerabilidades.png'
import failureReductionIcon from 'images/marketplace/icons/pentest_reducao_falhas.png'
import criticalEvaluationIcon from 'images/marketplace/icons/pentest_avaliacao_critica.png'
import externalVisionIcon from 'images/marketplace/icons/pentest_visao_externa.png'
import financialLossesIcon from 'images/marketplace/icons/pentest_prevencao_prejuizos.png'
import protectionIcon from 'images/marketplace/icons/pentest_protecao_imagem.png'

import pentestCover from 'images/marketplace/covers/dlp_cover.png'

const PAGE_ID = 22

const DESCRIPTION_CARDS = [
  {
    title: 'Teste de caixa branca</br>(White Box)',
    imgUrl: whiteBoxIcon,
  },
  {
    title: 'Teste de serviços de rede',
    imgUrl: networkServiceIcon,
  },
  {
    title: 'Teste de aplicações web',
    imgUrl: webApplicationIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Descoberta de vulnerabilidades',
    description:
      'Identifica vulnerabilidades em um sistema antes que um cibercriminoso possa explorá-las.',
    imgUrl: vulnerabilityIcon,
  },
  {
    title: 'Redução de falhas',
    description:
      'Pode ajudar a reduzir as falhas durante o processo de desenvolvimento de software.',
    imgUrl: failureReductionIcon,
  },
  {
    title: 'Avaliação crítica',
    description:
      'Fornece uma avaliação crítica da capacidade de segurança cibernética de uma organização.',
    imgUrl: criticalEvaluationIcon,
  },
  {
    title: 'Visão externa especializada',
    description:
      'Oferece uma visão externa especializada da segurança de uma organização.',
    imgUrl: externalVisionIcon,
  },
  {
    title: 'Prevenção de prejuízos financeiros',
    description:
      'Prevenir prejuízos financeiros decorrentes de violações de segurança.',
    imgUrl: financialLossesIcon,
  },
  {
    title: 'Proteção da imagem e reputação da empresa',
    description:
      'Pode ajudar a proteger a imagem e a reputação de uma empresa, demonstrando que ela leva a segurança a sério.',
    imgUrl: protectionIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Pentest',
  description:
    'O Marketplace DPOnet oferece o Pentest que visa proteger empresas de possíveis perdas financeiras decorrentes de ataques virtuais maliciosos, erros ou negligências internas que resultem em vazamento de dados e outros danos ligados ao sigilo da informação. A tecnologia alterou a maneira como os negócios são feitos e, com ataques virtuais cada vez mais sofisticados, os riscos cibernéticos ou cyber risks são uma realidade nas companhias.',
  info: {
    description:
      'O pentest, ou teste de intrusão, é uma espécie de "simulação de ataque" realizado por especialistas em segurança cibernética. Eles buscam identificar e corrigir possíveis brechas em sistemas digitais, atuando como uma espécie de defesa prévia contra hackers mal-intencionados. É como um check-up de segurança para garantir que sites, aplicativos e redes estejam bem protegidos, proporcionando mais confiança no ambiente online.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Empresas de todos os tamanhos e setores estão cada vez mais vulneráveis a ameaças cibernéticas, tornando o seguro cibernético essencial. Grandes corporações, instituições financeiras e setores de saúde, que lidam com dados sensíveis, são clientes naturais. Pequenas e médias empresas também devem considerar, pois muitas vezes têm menos proteção e são alvos fáceis.',
    highlights: [
      'Identificação de vulnerabilidades',
      'Melhoria da segurança',
      'Conformidade com normas e regulamentações',
      'Prevenção de ataques futuros',
    ],
    imgUrl: pentestCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
