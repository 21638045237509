import { NodeChildren } from 'types/global.types'
import { RootBoxStyled } from './components'
import { useJourney } from 'hooks'

const RootBox = ({ children }: NodeChildren) => {
  const { stepInfo } = useJourney()
  const display = stepInfo?.leftBoxDisplay == 'none' ? 'flex' : 'grid'

  return <RootBoxStyled $display={display}>{children}</RootBoxStyled>
}

export default RootBox
