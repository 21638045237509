const NEWLY_CREATED_ID = 'newly-created'
const OLDER_CREATED_ID = 'older-created'
const NEWLY_UPDATED_ID = 'newly-updated'
const OLDER_UPDATED_ID = 'older-updated'
const ALPHABETICAL_A_TO_Z_ID = 'alphabetical-a-to-z'
const ALPHABETICAL_Z_TO_A_ID = 'alphabetical-z-to-a'

const NEWLY_CREATED = 'created_at ASC'
const OLDER_CREATED = 'created_at DESC'
const NEWLY_UPDATED = 'updated_at ASC'
const OLDER_UPDATED = 'updated_at DESC'
const ALPHABETICAL_A_TO_Z = 'alphabetical_a_to_z ASC'
const ALPHABETICAL_Z_TO_A = 'alphabetical_z_to_a DESC'

const FILTER_ORDER_IDS = [
  NEWLY_CREATED_ID,
  OLDER_CREATED_ID,
  NEWLY_UPDATED_ID,
  OLDER_UPDATED_ID,
  ALPHABETICAL_A_TO_Z_ID,
  ALPHABETICAL_Z_TO_A_ID,
] as const

const FILTER_ORDER_VALUES = [
  NEWLY_CREATED,
  OLDER_CREATED,
  NEWLY_UPDATED,
  OLDER_UPDATED,
  ALPHABETICAL_A_TO_Z,
  ALPHABETICAL_Z_TO_A,
] as const

const FILTER_MENU_ITEMS = [
  {
    name: 'Criação recente',
    value: NEWLY_CREATED_ID,
    type: 'common',
  },
  {
    name: 'Criação antiga',
    value: OLDER_CREATED_ID,
    type: 'common',
  },
  {
    name: 'Atualização recente',
    value: NEWLY_UPDATED_ID,
    type: 'common',
  },
  {
    name: 'Atualização antiga',
    value: OLDER_UPDATED_ID,
    type: 'common',
  },
]

const FILTER_MENU_ITEMS_WITH_ALPHABETIC = [
  ...FILTER_MENU_ITEMS,
  {
    name: 'De A-Z',
    value: ALPHABETICAL_A_TO_Z_ID,
    type: 'alphabetical',
  },
  {
    name: 'De Z-A',
    value: ALPHABETICAL_Z_TO_A_ID,
    type: 'alphabetical',
  },
]

const FILTER_ORDER_API_VALUE = {
  [NEWLY_CREATED_ID]: NEWLY_CREATED,
  [OLDER_CREATED_ID]: OLDER_CREATED,
  [NEWLY_UPDATED_ID]: NEWLY_UPDATED,
  [OLDER_UPDATED_ID]: OLDER_UPDATED,
  [ALPHABETICAL_A_TO_Z_ID]: ALPHABETICAL_A_TO_Z,
  [ALPHABETICAL_Z_TO_A_ID]: ALPHABETICAL_Z_TO_A,
}

export type FilterOderIDsType = (typeof FILTER_ORDER_IDS)[number]
export type FilterOderValuesType = (typeof FILTER_ORDER_VALUES)[number]

export default {
  NEWLY_CREATED_ID,
  OLDER_CREATED_ID,
  NEWLY_UPDATED_ID,
  OLDER_UPDATED_ID,
  ALPHABETICAL_A_TO_Z_ID,
  ALPHABETICAL_Z_TO_A_ID,
  NEWLY_CREATED,
  OLDER_CREATED,
  NEWLY_UPDATED,
  OLDER_UPDATED,
  ALPHABETICAL_A_TO_Z,
  ALPHABETICAL_Z_TO_A,
  FILTER_MENU_ITEMS,
  FILTER_MENU_ITEMS_WITH_ALPHABETIC,
  FILTER_ORDER_VALUES,
  FILTER_ORDER_API_VALUE,
}
