import { Fragment } from 'react'
import { Minus as MinusIcon } from 'react-feather'

import {
  BannerCustomizationIdType,
  BannerScannerStatusIdType,
  BannerStatusIdType,
} from 'constants/banner'

import { theme } from 'theme'
import constants from 'constants/index'

const bannerContent = (tabId: BannerCustomizationIdType) => {
  return constants.banner.CUSTOMIZATION_CARD_CONTENT[tabId] || Fragment
}

const bannerPreview = (tabId: BannerCustomizationIdType) => {
  return constants.banner.CUSTOMIZATION_CARD_PREVIEW[tabId] || Fragment
}

const chipStatusColor = (status?: BannerStatusIdType) => {
  return (status && constants.banner.STATUS_CHIP_COLOR[status]) || 'default'
}

const chipStatusLabel = (status?: BannerStatusIdType) => {
  return (
    (status && constants.banner.STATUS_TRANSLATION[status]) ||
    'indeferido'
  ).toUpperCase()
}

const scannerStatusLabel = (status?: BannerScannerStatusIdType) => {
  return (
    (status && constants.banner.SCANNER_STATUS_TRANSLATION[status]) ||
    'indeferido'
  ).toUpperCase()
}

const scannerStatusColor = (status?: BannerScannerStatusIdType) => {
  return (
    (status && constants.banner.SCANNER_STATUS_COLOR[status]) ||
    theme.palette.neutral.main
  )
}

const scannerStatusIcon = (status?: BannerScannerStatusIdType) => {
  return (status && constants.banner.SCANNER_STATUS_ICON[status]) || MinusIcon
}

export default {
  bannerContent,
  bannerPreview,
  chipStatusColor,
  chipStatusLabel,
  scannerStatusColor,
  scannerStatusIcon,
  scannerStatusLabel,
}
