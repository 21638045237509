import api from 'service/api'

const dpoeasyAPI = api.create('dpoeasy', false)

const update = async ({
  bannerId,
  cookies,
}: {
  bannerId: number
  cookies: object
}) => await dpoeasyAPI.patch(`/banners/${bannerId}/cookies`, { cookies })

export default {
  update,
}
