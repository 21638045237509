import {
  AlertCircle as AlertCircleIcon,
  AlertTriangle as AlertTriangleIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
} from 'react-feather'
import { theme } from 'theme'

// SEVERITY
const ERROR_SEVERITY_ID = 'error'
const INFO_SEVERITY_ID = 'info'
const SUCCESS_SEVERITY_ID = 'success'
const WARNING_SEVERITY_ID = 'warning'

const ALL_SEVERITY_IDS = [
  ERROR_SEVERITY_ID,
  INFO_SEVERITY_ID,
  SUCCESS_SEVERITY_ID,
  WARNING_SEVERITY_ID,
] as const

// VARIANTS
const STANDARD_VARIANT_ID = 'standard'
const FILLED_VARIANT_ID = 'filled'
const OUTLINED_VARIANT_ID = 'outlined'

const ALL_VARIANT_IDS = [
  STANDARD_VARIANT_ID,
  FILLED_VARIANT_ID,
  OUTLINED_VARIANT_ID,
] as const

// FILLED COLORS
const MAIN_COLOR_OF_FILLED_ERROR = '#E83A49'
const MAIN_COLOR_OF_FILLED_INFO = '#376FB6'
const MAIN_COLOR_OF_FILLED_SUCCESS = '#35A63C'
const MAIN_COLOR_OF_FILLED_WARNING = '#F9C10A'

const DARK_COLOR_OF_FILLED_WARNING = '#362A08'

// STANDARD COLORS
const LIGHT_COLOR_OF_STANDARD_ERROR = '#FDEFF0'
const LIGHT_COLOR_OF_STANDARD_INFO = '#EFF3F9'
const LIGHT_COLOR_OF_STANDARD_SUCCESS = '#ECF7EF'
const LIGHT_COLOR_OF_STANDARD_WARNING = '#FEFAEB'

const MAIN_COLOR_OF_STANDARD_ERROR = '#E94C5B'
const MAIN_COLOR_OF_STANDARD_INFO = '#477CBC'
const MAIN_COLOR_OF_STANDARD_SUCCESS = '#3EAE4F'
const MAIN_COLOR_OF_STANDARD_WARNING = '#FCCB30'

const DARK_COLOR_OF_STANDARD_ERROR = '#5E262B'
const DARK_COLOR_OF_STANDARD_INFO = '#26374E'
const DARK_COLOR_OF_STANDARD_SUCCESS = '#1B4927'
const DARK_COLOR_OF_STANDARD_WARNING = '#645217'

const CHOOSE_DARK_COLOR_OF_FILLED = {
  [ERROR_SEVERITY_ID]: theme.palette.common.white,
  [INFO_SEVERITY_ID]: theme.palette.common.white,
  [SUCCESS_SEVERITY_ID]: theme.palette.common.white,
  [WARNING_SEVERITY_ID]: DARK_COLOR_OF_FILLED_WARNING,
}

const CHOOSE_MAIN_COLOR_OF_FILLED = {
  [ERROR_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_ERROR,
  [INFO_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_INFO,
  [SUCCESS_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_SUCCESS,
  [WARNING_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_WARNING,
}

const CHOOSE_LIGHT_COLOR_OF_STANDARD = {
  [ERROR_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_ERROR,
  [INFO_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_INFO,
  [SUCCESS_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_SUCCESS,
  [WARNING_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_WARNING,
}

const CHOOSE_MAIN_COLOR_OF_STANDARD = {
  [ERROR_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_ERROR,
  [INFO_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_INFO,
  [SUCCESS_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_SUCCESS,
  [WARNING_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_WARNING,
}

const CHOOSE_DARK_COLOR_OF_STANDARD = {
  [ERROR_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_ERROR,
  [INFO_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_INFO,
  [SUCCESS_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_SUCCESS,
  [WARNING_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_WARNING,
}

// DIALOG
const CHOOSE_DIALOG_BACKGROUND_COLOR: {
  [key in VariantAlertType]: { [key in SeverityAlertType]?: string | undefined }
} = {
  [STANDARD_VARIANT_ID]: CHOOSE_LIGHT_COLOR_OF_STANDARD,
  [FILLED_VARIANT_ID]: CHOOSE_MAIN_COLOR_OF_FILLED,
  [OUTLINED_VARIANT_ID]: {},
}

const CHOOSE_DIALOG_ICON_COLOR = {
  [STANDARD_VARIANT_ID]: CHOOSE_MAIN_COLOR_OF_STANDARD,
  [FILLED_VARIANT_ID]: CHOOSE_DARK_COLOR_OF_FILLED,
  [OUTLINED_VARIANT_ID]: CHOOSE_MAIN_COLOR_OF_STANDARD,
}

const CHOOSE_DIALOG_DESCRIPTION_COLOR = {
  [STANDARD_VARIANT_ID]: CHOOSE_DARK_COLOR_OF_STANDARD,
  [FILLED_VARIANT_ID]: CHOOSE_DARK_COLOR_OF_FILLED,
  [OUTLINED_VARIANT_ID]: CHOOSE_DARK_COLOR_OF_STANDARD,
}

const CHOOSE_DIALOG_ICON = {
  [ERROR_SEVERITY_ID]: AlertCircleIcon,
  [INFO_SEVERITY_ID]: InfoIcon,
  [SUCCESS_SEVERITY_ID]: CheckCircleIcon,
  [WARNING_SEVERITY_ID]: AlertTriangleIcon,
}

export default {
  CHOOSE_DARK_COLOR_OF_FILLED,
  CHOOSE_LIGHT_COLOR_OF_STANDARD,
  CHOOSE_MAIN_COLOR_OF_STANDARD,
  CHOOSE_DARK_COLOR_OF_STANDARD,
  CHOOSE_MAIN_COLOR_OF_FILLED,
  STANDARD_VARIANT_ID,
  FILLED_VARIANT_ID,
  OUTLINED_VARIANT_ID,
  CHOOSE_DIALOG_BACKGROUND_COLOR,
  CHOOSE_DIALOG_ICON_COLOR,
  CHOOSE_DIALOG_DESCRIPTION_COLOR,
  CHOOSE_DIALOG_ICON,
}

export type SeverityAlertType = (typeof ALL_SEVERITY_IDS)[number]
export type VariantAlertType = (typeof ALL_VARIANT_IDS)[number]
