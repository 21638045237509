import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Loader as LoaderIcon } from 'react-feather'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Grid, Tooltip, Typography } from '@mui/material'

import { DetailsReadingCard, ScannerCard } from './components'
import { AlertDialog, BasicSection } from 'components'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import { CookieValidationCardType } from './cookieValidationCard.types'
import { BannerConfigurationSchemaType } from 'types/banner.types'

import { theme } from 'theme'
import service from 'service'
import constants from 'constants/index'

const CookieValidationCard = ({
  cookies,
  banner,
  refresh,
}: CookieValidationCardType) => {
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const { snackbar } = useSnackbar()
  const { watch } = useFormContext<BannerConfigurationSchemaType>()

  const url = watch('url')

  const disableScanner = banner?.url !== url
  const loadingScanner =
    loading ||
    banner?.scanner?.status === constants.banner.INITIATED_SCANNER_STATUS_ID

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleScan = async () => {
    try {
      setLoading(true)
      await service.dpoeasy.banner.scanner({ bannerId: banner?.id })
      snackbar.open({
        message:
          'Escaneamento iniciado com sucesso! Isso pode levar alguns segundos.',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      banner?.scanner?.status === constants.banner.INITIATED_SCANNER_STATUS_ID
    )
      handleOpenDialog()
  }, [banner?.scanner?.status])

  return (
    <BasicSection
      title="Cookies"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          04/04
        </Typography>
      }
    >
      <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
        <Typography color="primary" textAlign="justify">
          Verifique o código do seu site clicando no botão "Escanear". Nosso
          sistema analisará automaticamente o código em busca de erros ou
          inconsistências. Aguarde o feedback que indicará qualquer problema ou
          confirmará que tudo está correto.
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" fontWeight={600} gutterBottom>
          Detalhes da Leitura
        </Typography>
        <DetailsReadingCard scanner={banner?.scanner} />
      </Box>
      {banner?.scanner?.status ===
        constants.banner.INITIATED_SCANNER_STATUS_ID && (
        <Alert severity="warning" variant="outlined">
          O seu site está sendo escaneado, este processo pode levar até 2
          minutos. Por favor, aguarde alguns instantes e atualize a página se
          necessário. Obrigado pela compreensão!
        </Alert>
      )}
      <Grid container justifyContent="end">
        <Grid item xs={12} md={3} xl={2} zIndex={1}>
          <Tooltip
            title={
              (disableScanner &&
                'Salve as alterações de "Configuração do Banner" antes de prosseguir com o escaneamento.') ||
              (loadingScanner &&
                'Atualize a página para verificar se o escaneamento foi concluído.')
            }
          >
            <Box>
              <LoadingButton
                onClick={handleScan}
                variant="contained"
                startIcon={
                  <LoaderIcon
                    size={18}
                    className={loadingScanner ? 'spin' : ''}
                  />
                }
                disabled={disableScanner || loadingScanner}
                fullWidth
              >
                {loadingScanner ? 'Escaneando' : 'Escanear'}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <Box>
        <ScannerCard cookies={cookies} />
      </Box>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseDialog}
        content="O seu site está sendo escaneado, este processo pode levar até 2
          minutos. Por favor, aguarde alguns instantes e atualize a página se
          necessário. Obrigado pela compreensão!"
        severity="warning"
        variant="outlined"
      />
    </BasicSection>
  )
}

export default CookieValidationCard
