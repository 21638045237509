import { Box as BoxMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength, TTime } from 'types/materialUI.types'

type BoxType = {
  $alignItems?: Property.AlignItems
  $background?: Property.Background<TLength>
  $backgroundColor?: Property.BackgroundColor
  $backgroundImage?: Property.BackgroundImage
  $backgroundPosition?: Property.BackgroundPosition<TLength>
  $backgroundRepeat?: Property.BackgroundRepeat
  $backgroundSize?: Property.BackgroundSize<TLength>
  $cursor?: Property.Cursor
  $flexDirection?: Property.FlexDirection
  $gap?: Property.Gap<TLength>
  $minWidth?: Property.MinWidth<TLength>
  $opacity?: Property.Opacity
  $padding?: Property.Padding<TLength>
  $paddingY?: Property.PaddingBlock<TLength>
  $textAlign?: Property.TextAlign
  $transition?: Property.Transition<TTime>
  $width?: Property.Width<TLength>
}

export interface BoxWithValidationsType extends BoxType {
  $hover?: InferType<BoxType>
  $smDown?: InferType<BoxType>
  $mdDown?: InferType<BoxType>
  $lgDown?: InferType<BoxType>
  $xlDown?: InferType<BoxType>
  $mdUp?: InferType<BoxType>
  $xlUp?: InferType<BoxType>
}

const Box = styled(BoxMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<BoxWithValidationsType>(
  ({
    theme,
    $alignItems,
    $background,
    $backgroundColor,
    $backgroundImage,
    $backgroundPosition,
    $backgroundRepeat,
    $backgroundSize,
    $cursor,
    $flexDirection,
    $gap,
    $hover,
    $minWidth,
    $opacity,
    $padding,
    $paddingY,
    $textAlign,
    $transition,
    $width,
    $smDown,
    $mdDown,
    $lgDown,
    $xlDown,
    $mdUp,
    $xlUp,
  }) => ({
    alignItems: $alignItems,
    background: $background,
    backgroundColor: $backgroundColor,
    backgroundImage: $backgroundImage,
    backgroundPosition: $backgroundPosition,
    backgroundRepeat: $backgroundRepeat,
    backgroundSize: $backgroundSize,
    cursor: $cursor,
    flexDirection: $flexDirection,
    gap: $gap && theme.spacing($gap),
    minWidth: $minWidth,
    opacity: $opacity,
    padding: $padding,
    paddingBlock: $paddingY,
    textAlign: $textAlign,
    transition: $transition,
    width: $width,
    '&:hover': {
      backgroundColor: $hover?.backgroundColor,
      backgroundImage: $hover?.backgroundImage,
      backgroundRepeat: $hover?.backgroundRepeat,
      backgroundSize: $hover?.backgroundSize,
      cursor: $hover?.cursor,
      opacity: $hover?.opacity,
      transition: $hover?.transition,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: $smDown?.flexDirection,
      gap: $smDown?.gap && theme.spacing($smDown?.gap),
      padding: $smDown?.padding,
      textAlign: $smDown?.textAlign,
      width: $smDown?.width,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: $mdDown?.flexDirection,
      gap: $mdDown?.gap && theme.spacing($mdDown?.gap),
      alignItems: $mdDown?.alignItems,
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: $lgDown?.flexDirection,
    },
    [theme.breakpoints.down('xl')]: {
      flexDirection: $xlDown?.flexDirection,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: $mdUp?.minWidth,
    },
    [theme.breakpoints.up('xl')]: {
      paddingBlock: $xlUp?.paddingY,
    },
  })
)

export default Box
