import { Fragment, useState } from 'react'
import {
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { MenuButton, MUI, StandardTableCell } from 'components'
import { ConsentDetailsDialog } from './components'

import { formatters, validates } from 'helpers'

import { ConsentType } from 'types/consent.types'

const ConsentTable = ({ consents }: { consents: ConsentType[] }) => {
  const [consent, setConsent] = useState<ConsentType | null | undefined>()

  const handleOpenConsentDetailsModal = (consent: ConsentType) =>
    setConsent(consent)

  const handleCloseConsentDetailsModal = () => setConsent(null)

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StandardTableCell $initial>ID</StandardTableCell>
              <StandardTableCell>ID do visitante</StandardTableCell>
              <StandardTableCell align="center">
                Data do consentimento
              </StandardTableCell>
              <StandardTableCell align="center">
                Consentimento
              </StandardTableCell>
              <StandardTableCell align="center">Ações</StandardTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consents?.map((consent) => (
              <TableRow key={consent?.id}>
                <StandardTableCell $initial>{consent?.id}</StandardTableCell>
                <StandardTableCell
                  $maxWidth={400}
                  tooltipTitle={consent?.token}
                >
                  {consent?.token}
                </StandardTableCell>
                <StandardTableCell align="center">
                  {formatters.date.ptBrFormatDateTime(consent?.datetimeConsent)}
                </StandardTableCell>
                <StandardTableCell align="center">
                  <MUI.Chip
                    label={validates.consent
                      .readingTypeTranslation(consent?.kind)
                      .toUpperCase()}
                    variant="filled"
                    size="small"
                    color={validates.consent.chipStatusColor(consent?.kind)}
                  />
                </StandardTableCell>
                <StandardTableCell align="center">
                  <MenuButton id={`consent-${consent?.id}`}>
                    <MenuItem
                      onClick={() => handleOpenConsentDetailsModal(consent)}
                    >
                      Visualizar
                    </MenuItem>
                  </MenuButton>
                </StandardTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConsentDetailsDialog
        onClose={handleCloseConsentDetailsModal}
        consent={consent}
      />
    </Fragment>
  )
}

export default ConsentTable
