import autoTable from 'jspdf-autotable'

import { TableType } from './table.types'

import constants from 'constants/index'
import { theme } from 'theme'

const table = ({ pdf, head, body, startY, cellWidth = 0.65 }: TableType) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH

  const WIDTH = WIDTH_PDF(pdf) - PADDING * 2

  autoTable(pdf, {
    head: head,
    body: body,
    startY: startY,
    tableWidth: WIDTH,
    margin: PADDING,
    columnStyles: {
      0: {
        cellWidth: WIDTH * cellWidth,
        font: 'Roboto',
        fontStyle: 'bold',
        halign: 'left',
        textColor: theme.palette.primary.main,
      },
    },
    headStyles: {
      cellPadding: 6,
      fillColor: theme.palette.primary.main,
      font: 'Roboto',
      fontStyle: 'bold',
      textColor: theme.palette.primary.contrastText,
    },
    styles: {
      cellWidth: 'auto',
      textColor: theme.palette.primary.main,
    },
    bodyStyles: {
      font: 'Roboto',
      fontStyle: 'normal',
    },
    theme: 'grid',
  })
}

export default table
