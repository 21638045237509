import { Alert } from '@mui/material'

const AwaitingConfirmationAlert = ({ text }: { text: string }) => {
  return (
    <Alert severity="info" variant="filled" icon={false}>
      {text}
    </Alert>
  )
}

export default AwaitingConfirmationAlert
