import React, { Children, useState } from 'react'
import { useForm } from 'react-hook-form'
import { isArray, isObject, isNumber } from 'lodash'
import { Box, Button, Drawer, Typography } from '@mui/material'

import { ChildrenInput } from './components'

import {
  AutoCompleteOptions,
  FiltersComponentType,
  FiltersTypes,
  RangeSliderOptions,
} from 'types/filters.types'

const Filters = ({ children, filter }: FiltersComponentType) => {
  const { drawerOpen, setDrawerOpen } = filter
  const { handleSubmit } = useForm()

  const [values, setValues] = useState<FiltersTypes>(() => {
    const filters = filter.filters as FiltersTypes
    delete filters['page']
    return filters
  })

  const handleClose = () => setDrawerOpen(false)
  const handleChange = (
    name: string,
    value:
      | string
      | AutoCompleteOptions
      | AutoCompleteOptions[]
      | RangeSliderOptions
  ) => {
    const newValue = !isArray(value)
      ? isObject(value)
        ? value.id
        : value
      : value?.map((val) => {
          return isNumber(val) ? val : val?.id
        })

    setValues({
      ...values,
      [name]: newValue,
    })
  }

  const clearFilters = () => {
    setValues({})
    filter.setFilters({})
    filter.setDrawerOpen(false)
  }

  const onSubmit = () => {
    filter.setFilters(values)
    filter.setDrawerOpen(false)
  }

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={4} width={320} display="flex" flexDirection="column" gap={4}>
          <Typography variant="h5" gutterBottom>
            Filtros
          </Typography>
          {Children.toArray(children).map((child) => {
            const childToClone = child as React.ReactElement
            const value =
              values[childToClone.props.name] || childToClone.props.defaultValue
            return (
              <ChildrenInput
                key={childToClone.props.name}
                handleChange={handleChange}
                child={childToClone}
                initialValue={value}
              />
            )
          })}
          <Button
            onClick={clearFilters}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Limpar filtros
          </Button>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Filtrar
          </Button>
        </Box>
      </form>
    </Drawer>
  )
}

export default Filters
