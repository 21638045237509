import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'

import { MUI, PageHead, StandardPageStructure } from 'components'
import { MaturityForm } from './components'

import { useAuth } from 'hooks'

import { formatters, handleUpgrade } from 'helpers'

import { theme } from 'theme'
import routes from 'constants/routes'
import constants from 'constants/index'

import questionnaireIllustration from 'images/questionnaire_illustration.svg'

const MaturityQuestionnaire = () => {
  const navigate = useNavigate()
  const { companyMaturity } = useAuth()

  const isAnswered = !!companyMaturity && !isEmpty(companyMaturity?.answers)
  const pointResults = formatters.maturityPoints(companyMaturity?.points ?? 0)
  const answerInfo =
    constants.maturityQuestionnaire.MATURITY_FEEDBACKS[pointResults]

  const goToHome = () => navigate(routes.root)

  return (
    <StandardPageStructure>
      <PageHead
        name={
          isAnswered
            ? 'Resultado'
            : constants.maturityQuestionnaire.MATURITY_QUESTIONNAIRE.title
        }
        // actionButton={
        //   <Button
        //     startIcon={<DownloadIcon size={18} />}
        //     variant="contained"
        //   >
        //     Tutorial
        //   </Button>
        // }
      />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box
            py={isAnswered ? 8 : 4}
            component={Paper}
            variant="outlined"
            p={8}
          >
            <Grid container spacing={2}>
              <Grid
                item
                md={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={questionnaireIllustration}
                  alt="Ilustração de questionário"
                  height={250}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <MUI.Box
                  display="flex"
                  flexDirection="column"
                  textAlign="justify"
                  gap={3}
                  py={2}
                  $mdDown={{ alignItems: 'center' }}
                >
                  {isAnswered && (
                    <Box
                      bgcolor={theme.palette.custom.main}
                      color={theme.palette.common.white}
                      width="fit-content"
                      borderRadius={12}
                      px={4}
                    >
                      <Typography
                        color={theme.palette.secondary.main}
                        variant="h6"
                      >
                        Você pontuou {companyMaturity?.points} pontos -
                        Nota&nbsp;
                        {pointResults}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography variant="h6" color="primary" gutterBottom>
                      {isAnswered
                        ? answerInfo.title
                        : constants.maturityQuestionnaire.MATURITY_QUESTIONNAIRE
                            .subtitle}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      fontWeight="400"
                      lineHeight="2rem"
                      whiteSpace="break-spaces"
                    >
                      {isAnswered
                        ? answerInfo.description
                        : constants.maturityQuestionnaire.MATURITY_QUESTIONNAIRE
                            .description}
                    </Typography>
                  </Box>
                </MUI.Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {isAnswered && (
          <Grid item xs={12}>
            <MUI.Box
              display="flex"
              justifyContent="center"
              py={4}
              gap={4}
              $mdDown={{ flexDirection: 'column' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={goToHome}
              >
                Voltar para a home
              </Button>
              <Button
                variant="contained"
                color="tertiary"
                size="large"
                onClick={handleUpgrade}
              >
                QUER MELHORAR SEU RESULTADO? ENTRE EM CONTATO
              </Button>
            </MUI.Box>
          </Grid>
        )}
        {!!companyMaturity && (
          <Grid item xs={12}>
            <MaturityForm />
          </Grid>
        )}
      </Grid>
    </StandardPageStructure>
  )
}

export default MaturityQuestionnaire
