import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { DialogWithBackground } from 'components'

import routes from 'constants/routes'

const DialogServiceChannel = () => {
  const navigate = useNavigate()

  const [open, setOpen] = useState(true)

  const onClose = () => setOpen(false)
  const handleNavigate = () => {
    navigate(routes.privacyChannel.configuration)
  }

  return (
    <DialogWithBackground
      open={open}
      onClose={onClose}
      maxWidth="md"
      title="Seja Bem vindo(a) ao DPOeasy!"
      buttonText="Configurar canal de atendimento"
      buttonAction={handleNavigate}
      disableBackdropClick
    >
      <iframe
        src="https://player.vimeo.com/video/942071466?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{
          width: '100%',
          height: '40vh',
          border: 0,
        }}
        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
        title="DPOeasy - Tutorial da Tela Home"
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </DialogWithBackground>
  )
}

export default DialogServiceChannel
