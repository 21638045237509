import * as yup from 'yup'

const schema = yup
  .object({
    description: yup.string(),
  })
  .required()

export type DataDestroySchemaType = yup.InferType<typeof schema>

export type DataDestroyControlType = keyof DataDestroySchemaType

export default schema
