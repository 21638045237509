import { CardContent, Container, Dialog } from '@mui/material'

import { Close as CloseIcon } from '@mui/icons-material'

import { MUI } from 'components'

import { useMediaQuery } from 'hooks'
import { BannerUpgradeDialogType } from './bannerUpgradeDialog.types'
import { BannerPlanCardType } from '../BannerPlanCard/bannerPlanCard.types'
import { BannerPlanCard } from '..'

const PLAN_ITEMS: BannerPlanCardType[] = [
  {
    buttonText: 'Contratado',
    name: 'Plano básico',
    price: 'GRATUITO',
    height: '40vh',
    borderRight: false,
    priceKind: 'free',
    accessLimit: '50.000',
    buttonKind: 'active',
  },
  {
    buttonText: 'Começar',
    name: 'Plano business',
    price: '100,00',
    height: '60vh',
    accessLimit: '100.000',
    recommended: true,
  },
  {
    buttonText: 'Começar',
    name: 'Plano enterprise',
    price: '400,00',
    height: '40vh',
    accessLimit: '1.000.000',
    borderLeft: false,
  },
]

const BannerUpgradeDialog = ({ open, setOpen }: BannerUpgradeDialogType) => {
  const { down } = useMediaQuery()
  const handleClose = () => setOpen(false)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        sx: (theme) => ({ backgroundColor: theme.palette.primary.main }),
      }}
    >
      <MUI.Box p={1} display="flex" justifyContent="flex-end" width="100%">
        <MUI.IconButton
          $color="white"
          aria-label="Fechar"
          onClick={handleClose}
        >
          <CloseIcon />
        </MUI.IconButton>
      </MUI.Box>
      <CardContent component={Container} maxWidth="md">
        <MUI.Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          color="white"
          mb={10}
        >
          <MUI.Typography variant="h3" gutterBottom textAlign="center">
            Deseja liberar mais acessos?
          </MUI.Typography>
          <MUI.Typography variant="h6" lineHeight={1.3} textAlign="center">
            Atualmente seu plano permite até 50.000 acessos em seu site.
          </MUI.Typography>
          <MUI.Typography variant="h6" lineHeight={1.3} textAlign="center">
            Verifique nossos planos para aumentar o seu número de acessos.
          </MUI.Typography>
        </MUI.Box>
        <MUI.Grid
          container
          spacing={down.sm ? 4 : 0}
          justifyContent="center"
          alignItems="center"
          color="white"
        >
          {PLAN_ITEMS.map((plan) => (
            <BannerPlanCard {...plan} />
          ))}
        </MUI.Grid>
      </CardContent>
    </Dialog>
  )
}

export default BannerUpgradeDialog
