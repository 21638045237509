import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  FormHelperText,
  Grid,
  RadioGroup,
  Typography,
} from '@mui/material'

import { CardRadio } from 'components'

import { CheckoutSchemaType } from 'constants/checkout/form'

import { CompanySizeSectionType } from './companySizeSection.types'
import { formatters } from 'helpers'

const CompanySizeSection = ({ plans }: CompanySizeSectionType) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CheckoutSchemaType>()

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h6" color="primary">
        Porte
      </Typography>
      <Controller
        render={({ field }) => (
          <RadioGroup {...field}>
            <Grid container spacing={4}>
              {plans?.map((item, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <CardRadio
                    radioValue={item?.id?.toString()}
                    error={!!errors?.companySize}
                    field={field}
                  >
                    <Box display="flex" flexDirection="column" gap={2} p={4}>
                      <Box>
                        <Typography color="primary" fontWeight={500}>
                          {item?.name}
                        </Typography>
                        <Typography color="primary" variant="caption">
                          {item?.segmentName}
                        </Typography>
                      </Box>
                      <Typography
                        color="primary"
                        variant="body2"
                        fontWeight={500}
                      >
                        {formatters.formatToCurrencyBrl(item?.price)}
                      </Typography>
                    </Box>
                  </CardRadio>
                </Grid>
              ))}
            </Grid>
            {!!errors?.companySize && (
              <FormHelperText error={!!errors?.companySize}>
                {errors?.companySize?.message}
              </FormHelperText>
            )}
          </RadioGroup>
        )}
        control={control}
        name="companySize"
      />
    </Box>
  )
}

export default CompanySizeSection
