import { Paper as PaperMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength } from 'types/materialUI.types'

type PaperType = {
  $border?: Property.Border<TLength>
  $borderBottom?: Property.BorderBottom<TLength>
  $borderBottomLeftRadius?: Property.BorderBottomLeftRadius<TLength>
  $borderBottomRightRadius?: Property.BorderBottomRightRadius<TLength>
  $borderTopLeftRadius?: Property.BorderTopLeftRadius<TLength>
  $borderTopRightRadius?: Property.BorderTopRightRadius<TLength>
  $height?: Property.Height<TLength>
  $minHeight?: Property.MinHeight<TLength>
  $backgroundColor?: Property.BackgroundColor
  $borderColor?: Property.BorderColor
}

interface PaperWithValidationsType extends PaperType {
  $hover?: InferType<PaperType>
}

const Paper = styled(PaperMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<PaperWithValidationsType>(
  ({
    $border,
    $borderColor,
    $borderBottom,
    $borderBottomLeftRadius,
    $borderBottomRightRadius,
    $borderTopLeftRadius,
    $borderTopRightRadius,
    $height,
    $minHeight,
    $backgroundColor,
    $hover,
  }) => ({
    border: $border,
    borderColor: $borderColor,
    borderBottom: $borderBottom,
    borderBottomLeftRadius: $borderBottomLeftRadius,
    borderBottomRightRadius: $borderBottomRightRadius,
    borderTopLeftRadius: $borderTopLeftRadius,
    borderTopRightRadius: $borderTopRightRadius,
    height: $height,
    minHeight: $minHeight,
    backgroundColor: $backgroundColor,
    '&:hover': {
      border: $hover?.border,
    },
  })
)

export default Paper
