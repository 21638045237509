import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import routes from 'constants/routes'

const Forbidden = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(routes.root)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100vh"
    >
      <Typography variant="h1" textAlign="center">
        Ops!
      </Typography>
      <Typography variant="h4" textAlign="center">
        Parece que você está offline
      </Typography>
      <Typography variant="subtitle1" textAlign="center" gutterBottom>
        Verifique sua conexão e tente novamente
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        VOLTAR PARA O INÍCIO
      </Button>
    </Box>
  )
}

export default Forbidden
