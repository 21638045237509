import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTrait = api.create('dponet', false)

const get = async () => await dponetAPI.get('/answer_option_tickets')

const updateAnswer = async ({
  answerOptionTicketId,
  data,
}: {
  answerOptionTicketId: number
  data: object
}) =>
  await dponetAPINotTrait.put(
    `/answer_option_tickets/${answerOptionTicketId}/update_answer`,
    data
  )

const configure = async ({ data }: { data: object }) =>
  await dponetAPINotTrait.post('/answer_option_tickets/configure', data)

export default {
  get,
  updateAnswer,
  configure,
}
