import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const UL = styled('ul', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $margin?: Property.Margin<TLength>
}>(({ $margin }) => ({
  margin: $margin,
}))

export default UL
