import { SVGType } from 'types/global.types'

import { theme } from 'theme'

const Polygon = ({
  alt = 'Polígono',
  fill = theme.palette.success.main,
  height,
  viewBox = '0 0 40 43',
  width = 40,
}: SVGType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <path
        d="M17 1.62023C18.8564 0.548438 21.1436 0.548438 23 1.62023L36.0526 9.15613C37.909 10.2279 39.0526 12.2087 39.0526 14.3523V29.4241C39.0526 31.5677 37.909 33.5484 36.0526 34.6202L23 42.1561C21.1436 43.2279 18.8564 43.2279 17 42.1561L3.94744 34.6202C2.09104 33.5484 0.947441 31.5677 0.947441 29.4241V14.3523C0.947441 12.2087 2.09103 10.2279 3.94744 9.15613L17 1.62023Z"
        fill={fill}
      />
    </svg>
  )
}

export default Polygon
