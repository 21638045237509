import { Fragment, SyntheticEvent, useState } from 'react'

import {
  ButtonTab,
  LoadingFeedback,
  PageHead,
  StandardPageStructure,
} from 'components'

import { useAuth } from 'hooks'

import { SettingsCategoryIdType } from 'constants/settings'

import constants from 'constants/index'

import { CompanyForm, PaymentDetails, PersonalForm } from './components'

const SettingsMain = () => {
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    constants.settings.PERSONAL_CATEGORY_ID as SettingsCategoryIdType
  )

  const { loaded } = useAuth()

  const SETTINGS_MAIN_CONTENT_TAB = {
    [constants.settings.PERSONAL_CATEGORY_ID]: PersonalForm,
    [constants.settings.COMPANY_CATEGORY_ID]: CompanyForm,
    [constants.settings.PAYMENT_CATEGORY_ID]: PaymentDetails,
  }

  const TabContent = SETTINGS_MAIN_CONTENT_TAB[selectedTab] || Fragment

  const handleTabChange = (
    _: SyntheticEvent,
    clickedTab: SettingsCategoryIdType
  ) => setSelectedTab(clickedTab)

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead name="Configurações" />
      {constants.settings.SETTINGS_CATEGORY_TABS.map((tab) => (
        <ButtonTab
          key={tab.id}
          icon={<tab.icon />}
          label={tab.label}
          selectedTab={selectedTab}
          value={tab.id}
          onChange={handleTabChange}
        />
      ))}
      {loaded && <TabContent loading={loading} setLoading={setLoading} />}
    </StandardPageStructure>
  )
}

export default SettingsMain
