import { isNil } from 'lodash'

import { formatters } from 'helpers'

import { ChipColorType } from 'types/materialUI.types'
import { MessageTicketIdType, StatusTicketIdType } from 'constants/ticket'
import { TicketMessageType, TicketType } from 'types/ticket.types'

import constants from 'constants/index'

const deadlineTranslation = (deadline?: number | null): string => {
  if (isNil(deadline)) return 'N/A'
  const pluralSuffix = deadline !== 1 ? 's' : ''

  return deadline >= 1
    ? `${deadline} dia${pluralSuffix} restante${pluralSuffix}`
    : 'A resolução dessa solicitação se encontra atrasada!'
}

const deadlineChipTranslation = (deadline?: number | null): string => {
  if (isNil(deadline)) return 'N/A'
  const pluralSuffix = deadline !== 1 ? 'S' : ''

  return deadline >= 1 ? `${deadline} DIA${pluralSuffix}` : 'ATRASADA'
}

const deadlineChipColor = (deadline?: number | null): ChipColorType => {
  if (isNil(deadline)) return 'default'
  return deadline >= 7 ? 'success' : deadline <= 3 ? 'error' : 'warning'
}

const statusBackgroundColor = (statusId: StatusTicketIdType) => {
  return constants.ticket.CHIP_STATUS_BACKGROUND_COLOR[statusId] || ''
}

const discoveringKindMessage = (
  kind: MessageTicketIdType,
  response?: string
) => {
  return kind ? constants.ticket.MESSAGE_TYPE[kind] : response ?? '-'
}

const colorKindMessage = (kind: MessageTicketIdType, option: string) => {
  if (option === 'background') {
    return constants.ticket.MESSAGE_TYPE_BOX_COLOR[kind] || 'primary'
  } else if (option === 'icon') {
    return constants.ticket.MESSAGE_TYPE_ICON_COLOR[kind] || 'primary'
  }
}

const resolutionDate = (
  ticket: TicketType,
  ticketMessages: TicketMessageType[]
) => {
  const isFinished =
    ticket?.status?.id === constants.ticket.FINISHED_TICKET_ID ||
    ticket?.status?.id === constants.ticket.CANCELED_TICKET_ID

  const dateTime = ticketMessages
    ?.filter((message) => message?.kind === 2)
    .map((message) => message.createdAt)
    .shift()

  return (
    isFinished &&
    formatters.date.ptBrFormatDateTime(dateTime, 'Não possui data definida')
  )
}

export default {
  colorKindMessage,
  deadlineChipColor,
  deadlineChipTranslation,
  deadlineTranslation,
  discoveringKindMessage,
  statusBackgroundColor,
  resolutionDate,
}
