import { MUI } from 'components'

import { useMediaQuery } from 'hooks'

import { BannerPlanCardType } from './bannerPlanCard.types'
import { Check } from 'react-feather'
import { theme } from 'theme'

const BannerPlanCard = ({
  buttonText,
  name,
  price,
  height,
  accessLimit,
  borderLeft = true,
  borderRight = true,
  recommended = false,
  priceKind = 'contract',
  buttonKind = 'contract',
}: BannerPlanCardType) => {
  const { down } = useMediaQuery()

  return (
    <MUI.Grid item xs={12} md={4}>
      <MUI.Box
        sx={{
          borderTop: 3,
          borderBottom: 3,
          ...(down.sm
            ? {
                borderRadius: 3,
                border: `2px solid ${theme.palette.tertiary.main}`,
              }
            : {
                borderLeft: borderLeft ? 3 : 0,
                borderRight: borderRight ? 3 : 0,
                borderTopRightRadius: borderRight ? 10 : 0,
                borderBottomRightRadius: borderRight ? 10 : 0,
                borderTopLeftRadius: borderLeft ? 10 : 0,
                borderBottomLeftRadius: borderLeft ? 10 : 0,
                borderColor: theme.palette.tertiary.main,
              }),
        }}
      >
        {recommended && (
          <MUI.Box display="flex" justifyContent="center" width="100%" mt={-4}>
            <MUI.Chip
              $fontWeight="700"
              $height={27}
              $borderRadius={13.5}
              color="tertiary"
              label="RECOMENDADO"
            />
          </MUI.Box>
        )}
        <MUI.Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          p={8}
          height={height ?? '60vh'}
        >
          <MUI.Box display="flex" $flexDirection="column" $alignItems="center">
            <MUI.Typography gutterBottom variant="h6">
              {name}
            </MUI.Typography>
            {priceKind === 'free' ? (
              <MUI.Typography variant="h6">{price}</MUI.Typography>
            ) : (
              <MUI.Typography variant="h6">R$ {price} /mês</MUI.Typography>
            )}
            <MUI.Box
              mt={4}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <MUI.Box mr={2}>
                <Check size={22} color={theme.palette.tertiary.main} />
              </MUI.Box>
              <MUI.Typography variant="subtitle1">1 site</MUI.Typography>
            </MUI.Box>
            <MUI.Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <MUI.Box mr={2}>
                <Check size={22} color={theme.palette.tertiary.main} />
              </MUI.Box>
              <MUI.Typography variant="subtitle1">
                Até {accessLimit} acessos
              </MUI.Typography>
            </MUI.Box>
          </MUI.Box>
          {buttonKind === 'contract' ? (
            <MUI.Button
              $fontWeight="700 !important"
              variant="contained"
              color="tertiary"
              fullWidth
            >
              {buttonText}
            </MUI.Button>
          ) : (
            <MUI.Typography fontWeight="700">CONTRATADO</MUI.Typography>
          )}
        </MUI.Box>
      </MUI.Box>
    </MUI.Grid>
  )
}

export default BannerPlanCard
