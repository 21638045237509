import { Paperclip as PaperclipIcon } from 'react-feather'
import { Box, Link, Typography } from '@mui/material'

import { AttachmentType } from 'types/ticket.types'

import { theme } from 'theme'

const TicketMessageAttachment = ({ name, url }: AttachmentType) => {
  return (
    <Box
      display="flex"
      gap={1}
      color={theme.palette.support.main}
      component={Link}
      href={url}
      target="_blank"
      rel="noopener"
      download
    >
      <PaperclipIcon size={18} />
      <Typography variant="body2" noWrap>
        {name}
      </Typography>
    </Box>
  )
}

export default TicketMessageAttachment
