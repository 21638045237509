import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, TextField, Typography } from '@mui/material'

import { formatters, validates } from 'helpers'

import { CardNumberInputType } from './cardNumberInput.types'
import { EventType } from 'types/global.types'

import { theme } from 'theme'

import constants from 'constants/index'
import { CheckoutSchemaType } from 'constants/checkout/form'

const CardNumberInput = ({
  label,
  required = false,
  labelOutside = false,
  inputStyle,
  setBrand,
  permittedFlags = constants.cardFlags.STANDARD_FLAGS_ITEMS,
}: CardNumberInputType) => {
  const [cardFlagId, setCardFlagId] = useState<number | null | undefined>(null)

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<CheckoutSchemaType>()

  const handleOnChange = (event: EventType) => {
    const value = formatters.cardFlag.number(event?.target?.value)
    const cardId = validates.cardFlag.id(value)
    setValue('card.number', value)
    if (cardId) {
      setCardFlagId(cardId)
      setBrand(constants.cardFlags.CARD_BRAND_DEFINITION_BY_ID[cardId])
    }
  }

  return (
    <Box>
      {labelOutside && (
        <Box display="flex" gap={1}>
          <Typography color="primary">{label}</Typography>
          {required && <Typography color="error">*</Typography>}
        </Box>
      )}
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.card?.number}
            helperText={<>{errors?.card?.number?.message}</>}
            inputProps={{
              inputMode: 'numeric',
              style: inputStyle,
            }}
            onChange={handleOnChange}
            fullWidth
          />
        )}
        control={control}
        name="card.number"
      />
      <Box display="flex" flexDirection="column" pt={2}>
        <Typography color="primary" variant="caption">
          Bandeiras aceitas
        </Typography>
        <PerfectScrollbar>
          <Box display="flex" gap={4} pb={4}>
            {permittedFlags.map((flag, index) => {
              const Logo = flag?.Flag
              const logoPadding =
                flag?.id === constants.cardFlags.MASTER_CARD_ID ? 1.5 : 0
              const cardBgcolor =
                flag?.id !== cardFlagId
                  ? flag?.grayscale ?? theme.palette.common.white
                  : flag?.bgcolor ?? theme.palette.common.white

              return (
                <Box
                  key={flag?.id}
                  px={2}
                  bgcolor={cardBgcolor}
                  border={`solid 1px ${theme.palette.paper.border}`}
                  borderRadius={1}
                  display="flex"
                  alignItems="center"
                >
                  <Box width={46} height={32} p={logoPadding}>
                    <Logo
                      key={index}
                      grayscale={flag?.id !== cardFlagId}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Box>
              )
            })}
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  )
}

export default CardNumberInput
