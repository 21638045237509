import * as yup from 'yup'

const schema = yup.object({
  companyName: yup.string().required().max(200),
  responsible: yup.string().required().max(200),
  email: yup.string().required().max(200),
  phone: yup.string().required().min(14).max(15),
})

export type ProductRequestModalSchemaType = yup.InferType<typeof schema>

export type ProductRequestModalControlType = keyof ProductRequestModalSchemaType

export default schema
