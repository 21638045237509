import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Box, Button } from '@mui/material'

import {
  CompanyLogo,
  DataDestroyStep,
  DataShareStep,
  DataTratmentStep,
  DataUpdateStep,
  FinishStep,
  FormAddress,
  OutsourcedDpo,
  TemplateStep,
} from './steps'

import { useJourney, usePrivacyChannel } from 'hooks'

import constants from 'constants/index'

const PrivacyChannelConfiguration = () => {
  const {
    activeStep,
    backDisabled,
    nextDisabled,
    stepInfo,
    steps,
    handleBack,
    handleNext,
    setActiveStep,
    setSteps,
    setStepInfo,
  } = useJourney()
  const { showFinishTemplateButton, finishJourney } = usePrivacyChannel()

  const isFinalStep = activeStep === steps.length - 1
  const isFirstStep = activeStep === 0

  useEffect(() => {
    if (isEmpty(steps)) {
      setSteps(constants.privacyChannelConfigurationSteps.STEPS)
    }
    if (isEmpty(stepInfo)) {
      setStepInfo(constants.privacyChannelConfigurationSteps.STEPS[activeStep])
    }
    //eslint-disable-next-line
  }, [activeStep])

  const COMPONENTS_STEPS = [
    <TemplateStep />,
    <DataTratmentStep />,
    <DataShareStep />,
    <DataUpdateStep />,
    <DataDestroyStep />,
    <OutsourcedDpo />,
    <FormAddress />,
    <CompanyLogo />,
    <FinishStep />,
  ]

  const handleNextStep = () => {
    if (!stepInfo?.isForm) {
      handleNext()
    }
  }

  const goToOutsourcedDpoStep = () => {
    setActiveStep(5)
    setStepInfo(constants.privacyChannelConfigurationSteps.STEPS[5])
  }

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {COMPONENTS_STEPS[activeStep]}
      <Box
        py={4}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" justifyContent="end" width="100%">
          {activeStep > 0 && (
            <Box mr={2}>
              <Button
                disabled={backDisabled}
                variant="outlined"
                onClick={handleBack}
              >
                Voltar
              </Button>
            </Box>
          )}

          {showFinishTemplateButton ? (
            <>
              <Box mr={2}>
                <Button
                  {...(stepInfo?.isForm && {
                    key: 'button-submit-form',
                    type: 'submit',
                    form: 'form-register',
                  })}
                  variant="outlined"
                  onClick={handleNextStep}
                  disabled={nextDisabled}
                >
                  Continuar
                </Button>
              </Box>
              <Button
                variant="contained"
                onClick={isFirstStep ? goToOutsourcedDpoStep : finishJourney}
              >
                {isFirstStep ? 'Avançar' : 'Finalizar Jornada'}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              {...(stepInfo?.isForm && {
                key: 'button-submit-form',
                type: 'submit',
                form: 'form-register',
              })}
              disabled={nextDisabled}
              onClick={handleNextStep}
            >
              {isFinalStep ? 'Finalizar' : 'Avançar'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default PrivacyChannelConfiguration
