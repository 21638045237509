import { useState } from 'react'
import { Box, Typography, Hidden, ButtonBase } from '@mui/material'

import { MUI } from 'components'
import { Menu } from './components'

import { avatarLetters, formatters } from 'helpers'

import { useAuth } from 'hooks'

import { theme } from 'theme'

const AccountInfo = () => {
  const { loadOut, user, userCompany } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const openMenu = Boolean(anchorEl)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Box
        component={ButtonBase}
        id="account-box"
        onClick={handleOpenMenu}
        aria-controls={openMenu ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        display="flex"
        alignItems="center"
        gap={2}
        p={2}
        borderRadius={2}
      >
        <MUI.Avatar
          alt="Avatar"
          $backgroundColor={theme.palette.neutral.main}
          $fontSize={theme.typography.h6.fontSize}
          $fontWeight={500}
          src={formatters.baseURL(userCompany?.company?.logo?.url)}
        >
          {avatarLetters(user?.name)}
        </MUI.Avatar>
        <Hidden only="xs">
          <Box width="max-content" maxWidth={200} textAlign="start" px={1}>
            <Typography variant="body2" fontWeight="500" noWrap>
              {user?.name ?? 'Bem vindo'}
            </Typography>
            {userCompany?.company?.cnpj && (
              <Typography align="left" variant="body2">
                {formatters.cnpj.format(userCompany?.company?.cnpj)}
              </Typography>
            )}
          </Box>
        </Hidden>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        logout={loadOut}
      />
    </Box>
  )
}

export default AccountInfo
