import { Box, styled } from '@mui/material'

const AnimationImg = styled(Box)(() => ({
  animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
  opacity: 1,
  fill: 'red',
  '@keyframes pulse': {
    '50%': {
      opacity: 0.5,
    },
  },
}))

export default AnimationImg
