import constants from 'constants/index'
import { PolicyCategoryType } from 'constants/policies'

const translatePoliciesCategories = (
  category: PolicyCategoryType | undefined
) => {
  if (!category) return 'Sem categoria cadastrada'
  return (
    constants.policies.TRANSLATED_CATEGORIES[category] ||
    'Sem categoria cadastrada'
  )
}

export default translatePoliciesCategories
