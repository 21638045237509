import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const checkout = async (data: object) => {
  return await dponetAPI.post('/pre_registrations/checkout', data)
}

const promote = async (data: object) => {
  return await dponetAPI.post('/pre_registrations/promote', data)
}

const show = async () => {
  return await dponetAPI.get('/pre_registrations/show')
}

export default { checkout, promote, show }
