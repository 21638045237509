export default {
  MuiMobileStepper: {
    styleOverrides: {
      root: {
        background: 'transparent',
        height: 'max-content',
      },
      dots: {
        gap: '1.8vw',
      },
      dot: { backgroundColor: 'white', width: 24, height: 24 },
      dotActive: { backgroundColor: '#80B92B' },
    },
  },
}
