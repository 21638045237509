import { CheckoutSchemaType } from 'constants/checkout/form'
import { CompanyType, CompanyMinimalType } from 'types/company.types'
import { UserType } from 'types/user.types'
import { WelcomePlanFormSchemaType } from 'views/WelcomeSteps/steps/Plan/schema'

const mountCheckoutData = (
  recaptcha: string,
  data: CheckoutSchemaType | WelcomePlanFormSchemaType,
  company?: CompanyType | CompanyMinimalType,
  user?: UserType,
  plan?: string | number,
  brand?: string,
  rest?: object
) => ({
  preRegistration: {
    recaptcha,
    name: company?.name,
    fantasyName: company?.name,
    document: company?.cnpj,
    kind: 'C',
    phone: user?.phone,
    email: user?.email,
    plan_id: plan,
    type: 'REAL',
    address: data.address,
    card: { ...data.card, brand },
    responsibleUser: {
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
    },
    ...(rest ?? {}),
  },
})

export default mountCheckoutData
