import { TableCell as TableCellMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

export type MUITableCellType = {
  $initial?: boolean | undefined
  $paddingLeft?: Property.PaddingLeft<TLength> | undefined
  $maxWidth?: Property.MaxWidth<TLength> | undefined
  $overflow?: Property.Overflow | undefined
  $textOverflow?: Property.TextOverflow | undefined
  $whiteSpace?: Property.WhiteSpace | undefined
  $fontWeight?: Property.FontWeight | undefined
}

const TableCell = styled(TableCellMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<MUITableCellType>(
  ({
    $initial,
    $paddingLeft,
    $maxWidth,
    $overflow = 'hidden',
    $textOverflow = 'ellipsis',
    $whiteSpace = 'nowrap',
    $fontWeight,
  }) => ({
    paddingLeft: $paddingLeft ?? $initial ? 32 : undefined,
    maxWidth: $maxWidth,
    overflow: $overflow,
    textOverflow: $textOverflow,
    whiteSpace: $whiteSpace,
    fontWeight: $fontWeight,
  })
)

export default TableCell
