import api from 'service/api'

const marketplaceAPI = api.create('marketplace', false)

const get = async ({ ...params }) => {
  return await marketplaceAPI.get('/showcases/service_categories', { params })
}

export default {
  get,
}
