import { Fragment } from 'react'
import { Grid, Typography } from '@mui/material'

import InfoCard from '../InfoCard'

import { ServiceType } from 'types/marketplace/services.types'

import { theme } from 'theme'

const ServiceAbout = ({ product }: { product: ServiceType }) => {
  return (
    <Fragment>
      <Typography variant="h5" color="primary" textAlign="center">
        O que é {product?.name}?
      </Typography>
      <Typography color={theme.palette.neutral.main}>
        {product?.info?.description}
      </Typography>
      <Grid container spacing={6}>
        {product?.info?.cards?.map((item, index) => (
          <Grid key={index} item xs={12} lg={6} xl={4} height="auto">
            <InfoCard {...item} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}

export default ServiceAbout
