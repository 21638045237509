import { Copy as CopyIcon } from 'react-feather'
import { Box, IconButton, Paper, Typography } from '@mui/material'

import { MUI } from 'components'

import { useClipboard } from 'hooks'

import { CookieBannerCodeType } from './cookieBannerCode.types'

import { theme } from 'theme'
import { getBaseURL } from 'service/env'

const CookieBannerCode = ({ bannerToken }: CookieBannerCodeType) => {
  const { copy } = useClipboard()

  const divCode = '<div id="dpoeasy-banner"></div>'
  const scriptCode = `<script src="${getBaseURL('dpoeasy')}/banners/install_code?token=${bannerToken}"></script>`

  const handleCodeCopying = () => copy(`${divCode}\n${scriptCode}`)

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <Typography color="primary" fontWeight={600} gutterBottom>
          Código HTML de instalação do banner
        </Typography>
        <Typography color="primary" variant="body2">
          {`Selecione, copie e cole o código no <head> do seu arquivo HTML`}
        </Typography>
      </Box>
      <Paper variant="outlined">
        <Box bgcolor={theme.palette.background.default}>
          <Box display="flex" justifyContent="space-between">
            <MUI.Typography
              variant="body2"
              $wordBreak="break-all"
              pt={4}
              pl={4}
            >
              {divCode}
            </MUI.Typography>
            <Box height="fit-content">
              <IconButton onClick={handleCodeCopying}>
                <CopyIcon size={18} color={theme.palette.neutral.dark} />
              </IconButton>
            </Box>
          </Box>
          <MUI.Typography variant="body2" $wordBreak="break-all" pb={4} px={4}>
            {scriptCode}
          </MUI.Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default CookieBannerCode
