import { useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { SelectableButton } from 'components'

import { AlertSmallCompany, FormOutsourcedDpo } from './components'
import { useAuth, useJourney } from 'hooks'

const FORM_DPO_TAB = 'form_dpo'
const SMALL_COMPANY_TAB = 'small_company'

const OutsourcedDpo = () => {
  const { companyMaturity } = useAuth()

  const { setNextDisabled } = useJourney()
  const [activeTab, setActiveTab] = useState<string>()

  useEffect(() => {
    if (activeTab === SMALL_COMPANY_TAB) {
      setNextDisabled(false)
    }
    if (!activeTab) {
      if (companyMaturity?.namedDpo) {
        setActiveTab(FORM_DPO_TAB)
      } else {
        setNextDisabled(true)
      }
    }
    //eslint-disable-next-line
  }, [activeTab])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          fontWeight={700}
          textAlign="center"
          color="primary"
        >
          Nomeação do Encarregado (DPO)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap={5}>
          <Typography
            variant="h6"
            fontWeight={400}
            textAlign="center"
            color="primary"
            fontFamily="Raleway"
          >
            Para tornar sua experiência mais fácil, fizemos uma lista das
            plataformas com as quais nos integramos, para ajudar na configuração
            do atendimento.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <SelectableButton
              active={activeTab === FORM_DPO_TAB}
              name="Nomear um encarregado (DPO)"
              onClick={() => setActiveTab(FORM_DPO_TAB)}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectableButton
              active={activeTab === SMALL_COMPANY_TAB}
              name="Sou um agente de tratamento de pequeno porte"
              onClick={() => setActiveTab(SMALL_COMPANY_TAB)}
            />
          </Grid>
        </Grid>
      </Grid>
      {activeTab === FORM_DPO_TAB && <FormOutsourcedDpo />}
      {activeTab === SMALL_COMPANY_TAB && <AlertSmallCompany />}
    </Grid>
  )
}

export default OutsourcedDpo
