import { isEmpty } from 'lodash'
import { Hidden, Paper, TablePagination } from '@mui/material'

import {
  LoadingFeedback,
  NoData,
  PageHead,
  StandardPageStructure,
} from 'components'
import { OrderTable } from './components'

import { useFetch, usePagination } from 'hooks'

import { OrderResponseType } from 'types/marketplace/orders.types'

import constants from 'constants/index'
import service from 'service'

const Orders = () => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.marketplace.orders.get,
    {
      page,
      perPage,
    },
    [page, perPage]
  )

  const orders: OrderResponseType[] = response?.data?.orders

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Meus pedidos"
        categories={constants.marketplace.orders.PAGE_HEADER_CATEGORIES}
      />
      {isEmpty(orders) ? (
        <NoData
          title="Nenhum pedido foi encontrado"
          subTitle="Solicite um novo pedido em produtos e serviços"
        />
      ) : (
        <Paper variant="outlined">
          <OrderTable orders={orders} />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            slotProps={{
              actions: {
                previousButton: { size: 'small' },
                nextButton: { size: 'small' },
              },
            }}
          />
        </Paper>
      )}
    </StandardPageStructure>
  )
}

export default Orders
