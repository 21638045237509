import { Box, Divider, Paper, Typography } from '@mui/material'

import { BasicSectionType } from './basicSection.types'

const BasicSection = ({ title, extraActions, children }: BasicSectionType) => {
  return (
    <Paper variant="outlined">
      <Box p={4} display="flex" justifyContent="space-between">
        <Typography color="primary" fontWeight={500}>
          {title}
        </Typography>
        {extraActions}
      </Box>
      <Divider />
      <Box p={4} display="flex" flexDirection="column" gap={4}>
        {children}
      </Box>
    </Paper>
  )
}

export default BasicSection
