import { BasicDialog } from 'components'

import { RemovePolicyModalType } from './removePolicyModal.types'
import { Button, Typography } from '@mui/material'
import { Fragment } from 'react/jsx-runtime'

import { useSnackbar } from 'hooks'

import service from 'service'

const RemovePolicyModal = ({
  open,
  onClose,
  privacyPolicy,
  refresh,
}: RemovePolicyModalType) => {
  const { snackbar } = useSnackbar()

  const handleDestroy = async () => {
    if (privacyPolicy?.id) {
      try {
        await service.dponet.privacyPortal.destroy(privacyPolicy?.id)
        snackbar.open({
          message: 'Politica de privacidade removida com sucesso!',
          variant: 'success',
        })
        refresh()
        onClose()
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Remover política"
      maxWidth="sm"
      dialogActions={
        <Fragment>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="error" onClick={handleDestroy}>
            Remover
          </Button>
        </Fragment>
      }
    >
      <Typography gutterBottom>
        Tem certeza de que deseja remover esta política?
      </Typography>
      <Typography variant="caption" color="error">
        Essa ação não pode ser desfeita!
      </Typography>
    </BasicDialog>
  )
}

export default RemovePolicyModal
