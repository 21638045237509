import { useState } from 'react'
import { CheckoutTrailId } from 'constants/checkout/trails'

import { isNil } from 'lodash'

import { visuallyHidden } from '@mui/utils'

const useCheckoutTrails = () => {
  const [trailId, setTrailId] = useState<CheckoutTrailId | null | undefined>(
    null
  )
  const [errorTitle, setErrorTitle] = useState<string>()

  const styleTrail = isNil(trailId) ? visuallyHidden : undefined
  const styleChildren = !isNil(trailId) ? visuallyHidden : undefined

  return {
    trailId,
    setTrailId,
    errorTitle,
    setErrorTitle,
    styleTrail,
    styleChildren,
  }
}

export default useCheckoutTrails
