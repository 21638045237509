import api from 'service/api'

const marketplaceRedirectAPI = api.create('marketplace')
const marketplaceAPI = api.create('marketplace', false)

const getAll = async ({ ...params }) => {
  return marketplaceAPI.get('showcases/partners_services', { params })
}

const get = async ({ partnerServiceId = '', ...params }) => {
  return marketplaceRedirectAPI.get(
    `showcases/partners_services/${partnerServiceId}`,
    {
      params,
    }
  )
}

export default {
  getAll,
  get,
}
