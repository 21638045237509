import api from 'service/api'

const dponetAPI = api.create('dponet')

const me = async () => await dponetAPI.get(`/me`)

const update = async (data = {}) => {
  return await dponetAPI.put(`/users/update`, data)
}

export default {
  me,
  update,
}
