import { Typography } from '@mui/material'

import { MUI } from 'components'
import { theme } from 'theme'
import { SelectableButtonType } from './selectableButton.types'

const SelectableButton = ({
  active = false,
  name,
  image,
  icon: Icon,
  onClick,
}: SelectableButtonType) => {
  return (
    <MUI.Box
      onClick={onClick}
      display="flex"
      alignItems="center"
      gap={2}
      px={4}
      py={3}
      color={active ? theme.palette.common.white : theme.palette.primary.main}
      minHeight="56px"
      borderRadius={1}
      border={active ? 'none' : `1px solid ${theme.palette.primary.main}`}
      $backgroundColor={active ? theme.palette.secondary.light : 'transparent'}
      $cursor="pointer"
    >
      {image && <MUI.Img src={image} alt="button" $maxWidth={30} height={30} />}
      {Icon && <Icon size={30} sx={{ fontSize: 30 }} />}
      <Typography variant="body1" fontWeight={600}>
        {name}
      </Typography>
    </MUI.Box>
  )
}

export default SelectableButton
