import { Chip as ChipMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const Chip = styled(ChipMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $color?: Property.Color | undefined
  $background?: Property.Background<TLength> | undefined
  $width?: Property.Width<TLength> | undefined
  $height?: Property.Height<TLength> | undefined
  $borderRadius?: Property.BorderRadius<TLength> | undefined
  $fontWeight?: Property.FontWeight | undefined
}>(
  ({
    $color,
    $background,
    $width,
    $height,
    $borderRadius = 3,
    $fontWeight = 500,
  }) => ({
    color: $color,
    background: $background,
    width: $width,
    height: $height,
    borderRadius: $borderRadius,
    fontWeight: $fontWeight,
  })
)

export default Chip
