import { Controller } from 'react-hook-form'
import { Box, TextField } from '@mui/material'
import { isEmpty } from 'lodash'

import { TicketMessageBoxType } from './ticketMessageBox.types'

const TicketMessageBox = ({
  disabled,
  selectedFiles,
  useForm,
}: TicketMessageBoxType) => {
  const { control, errors } = useForm

  return (
    <Box>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.content?.message}
            helperText={errors?.content?.message}
            rows={!isEmpty(selectedFiles) ? 4 : 6}
            disabled={disabled}
            type="text"
            fullWidth
            multiline
          />
        )}
        name="content"
        control={control}
      />
    </Box>
  )
}

export default TicketMessageBox
