import { BaseSyntheticEvent, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Copy as CopyIcon } from 'react-feather'

import { MUI, TinymceTextEditor } from 'components'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import { PrivacyPolciyType } from 'types/policies.types'

import routes from 'constants/routes'
import service from 'service'
import constants from 'constants/index'

import schema, { ManagePoliciesSchemaType } from './schema'
import { isNil } from 'lodash'
import { theme } from 'theme'

const ManagePolicies = ({
  setLoading,
  policy,
}: {
  setLoading: (state: boolean) => void
  policy: PrivacyPolciyType
}) => {
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ManagePoliciesSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: policy?.title || '',
      content: policy?.content || '',
      status: policy?.status || '',
      category: policy?.category || '',
      allowAutomaticEditing: policy?.allowAutomaticEditing || false,
    },
  })
  const reference = useRef<HTMLInputElement>()

  const handlePoliciesList = () => navigate(routes.policies.all)

  const onSubmit = async (
    data: ManagePoliciesSchemaType,
    e?: BaseSyntheticEvent
  ) => {
    const submitType = (e?.nativeEvent as SubmitEvent).submitter?.id
    const isDraft = submitType === 'draft'
    const newStatus = isDraft
      ? constants.policies.DRAFT_ID
      : constants.policies.ACTIVE_ID

    const status = data?.status || newStatus
    try {
      setLoading(true)
      if (!policy?.id) {
        await service.dponet.privacyPortal.create({
          privacyPolicy: { ...data, status, platform: 'lite' },
        })
      } else {
        await service.dponet.privacyPortal.update({
          policyId: policy.id,
          privacyPolicy: {
            ...data,
            allowAutomaticEditing: policy.createdBySystem
              ? data.allowAutomaticEditing
              : undefined,
            status,
          },
        })
      }

      snackbar.open({
        message: 'Sucesso ao publicar a política',
        variant: 'success',
      })
      handlePoliciesList()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const copyToClipboard = () => {
    try {
      if (!reference.current) return
      window.navigator.clipboard.writeText(reference.current.value)
      snackbar.open({
        message: 'copiado com sucesso!',
        variant: 'success',
      })
    } catch (e) {
      console.error(e)
      snackbar.open({
        message: 'ocorreu um erro ao tentar copiar o link',
        variant: 'error',
      })
    }
  }

  return (
    <Paper
      component="form"
      id="manage-policies"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box p={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InputLabel id="status-form">Categoria</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  error={!!errors?.category}
                  size="medium"
                  placeholder="Categoria do documento"
                >
                  {constants.policies.CATEGORIES.map((category) => (
                    <MUI.MenuItem
                      key={category?.id}
                      value={category?.id}
                      $textTransform="initial"
                    >
                      {category.name}
                    </MUI.MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              name="category"
            />
          </Grid>
          {!!policy?.id && (
            <Grid item xs={12}>
              <InputLabel id="status-form">Link do documento</InputLabel>
              <TextField
                variant="outlined"
                defaultValue={
                  policy?.documentLink || 'Não há um link disponível'
                }
                fullWidth
                disabled
                sx={{ backgroundColor: theme.palette.custom.main }}
                inputRef={reference}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Button
                        onClick={copyToClipboard}
                        disabled={isNil(policy?.documentLink)}
                      >
                        <CopyIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel id="status-form">Título</InputLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.title}
                  helperText={<>{errors?.title?.message}</>}
                  fullWidth
                />
              )}
              control={control}
              name="title"
            />
          </Grid>
          {!!policy?.id && (
            <Grid item xs={12}>
              <InputLabel id="status-form">Status</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} id="status-form" fullWidth>
                    {constants.policies.STATUSES_OPTIONS.map((item) => (
                      <MUI.MenuItem value={item.id} $textTransform="initial">
                        {item.name}
                      </MUI.MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
                name="status"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {policy?.createdBySystem && (
              <Controller
                render={({ field }) => (
                  <FormControl>
                    <FormControlLabel
                      control={<Switch {...field} checked={!!field.value} />}
                      label={
                        <Typography fontWeight={600} color="primary">
                          Permitir edição automática do sistema
                        </Typography>
                      }
                    />
                    {!!errors?.allowAutomaticEditing && (
                      <FormHelperText error={!!errors?.allowAutomaticEditing}>
                        {errors?.allowAutomaticEditing?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                control={control}
                name="allowAutomaticEditing"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth>
                  <TinymceTextEditor field={field} height={600} />
                  {!!errors?.content && (
                    <FormHelperText error={!!errors?.content}>
                      {errors?.content?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              control={control}
              name="content"
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" gap={3}>
              <Button variant="outlined" onClick={handlePoliciesList}>
                Voltar
              </Button>
              {!policy?.id && (
                <Button variant="outlined" id="draft" type="submit">
                  Salvar Rascunho
                </Button>
              )}
              <Button variant="contained" type="submit">
                {policy?.id ? 'Salvar' : 'Publicar'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default ManagePolicies
