import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import { formatters } from 'helpers'

import {
  DefaultControllerType,
  HandleOnChangeType,
} from './defaultController.types'
import {
  PersonalFormControlType,
  PersonalFormSchemaType,
} from 'constants/settings'

const DefaultController = ({ item }: DefaultControllerType) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<PersonalFormSchemaType>()

  const handleOnChange = ({ event, controlName }: HandleOnChangeType) => {
    let inputValue = event?.target?.value

    if (controlName === 'phone')
      inputValue = formatters.phoneDynamicMask(inputValue)

    setValue(controlName, inputValue)
  }

  const handleClickShowPassword = (controlName: PersonalFormControlType) => {
    if (controlName === 'currentPassword')
      setShowCurrentPassword((show) => !show)
    else if (controlName === 'newPassword') setShowNewPassword((show) => !show)
    else if (controlName === 'confirmPassword') setShowPassword((show) => !show)
  }

  const showPasswordValue = (controlName: PersonalFormControlType) => {
    if (controlName === 'currentPassword') return showCurrentPassword
    else if (controlName === 'newPassword') return showNewPassword
    else if (controlName === 'confirmPassword') return showPassword
  }

  const isPassword =
    item?.controlName === 'currentPassword' ||
    item?.controlName === 'newPassword' ||
    item?.controlName === 'confirmPassword'

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          label={item?.label}
          error={!!errors?.[item?.controlName]}
          helperText={<>{errors?.[item?.controlName]?.message}</>}
          InputProps={{
            inputMode: item?.inputMode,
            ...(isPassword && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword(item?.controlName)}
                  >
                    {showPasswordValue(item?.controlName) ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
          type={
            isPassword && showPasswordValue(item?.controlName)
              ? 'text'
              : item?.type
          }
          onChange={(event) =>
            handleOnChange({ event, controlName: item?.controlName })
          }
          autoComplete="off"
          fullWidth
        />
      )}
      control={control}
      name={item?.controlName}
    />
  )
}

export default DefaultController
