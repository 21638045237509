import { useNavigate } from 'react-router-dom'
import { Box, Card, CardActionArea } from '@mui/material'

import { MUI } from 'components'

import { CarouselType } from './carousel.types'
import { CarouselState } from 'react-responsive-carousel/lib/ts/components/Carousel/types'

import { theme } from 'theme'

const Carousel = ({
  items,
  bgColor = theme.palette.primary.dark,
  ...rest
}: CarouselType & Partial<CarouselState>) => {
  const navigate = useNavigate()

  return (
    <Card>
      <CardActionArea>
        <Box bgcolor={bgColor}>
          <MUI.Carousel
            interval={6000}
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            autoPlay
            $transition="opacity ease-out 0.2s"
            $hover={{ opacity: 0.9 }}
            {...rest}
          >
            {items.map((banner, index) => (
              <MUI.Box
                key={index}
                bgcolor={theme.palette.primary.main}
                onClick={() => navigate(banner?.route)}
              >
                <MUI.Img
                  src={banner.img}
                  alt={banner.altText}
                  height={170}
                  $objectPosition="center"
                  $objectFit="scale-down"
                />
              </MUI.Box>
            ))}
          </MUI.Carousel>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default Carousel
