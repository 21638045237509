import chapterCover from './ChapterCover/chapterCover'
import firstCover from './FirstCover/firstCover'
import lastCover from './LastCover/lastCover'

const components = {
  chapterCover,
  firstCover,
  lastCover,
}

export default components
