import { TableCellProps, Tooltip, Typography } from '@mui/material'
import { isString } from 'lodash'

import { MUI } from 'components'

import { StandardTableCellType } from './standardTableCell.types'
import { MUITableCellType } from 'components/MUI/TableCell/TableCell'

const StandardTableCell = ({
  children,
  placement = 'bottom-start',
  disableTooltip = false,
  tooltipTitle,
  ...rest
}: StandardTableCellType & TableCellProps & MUITableCellType) => {
  return (
    <MUI.TableCell {...rest}>
      {((rest?.width || rest?.$maxWidth) &&
        isString(children) &&
        !disableTooltip) ||
      tooltipTitle ? (
        <Tooltip title={tooltipTitle ?? children} placement={placement}>
          <Typography variant="body2" noWrap>
            {children}
          </Typography>
        </Tooltip>
      ) : (
        children
      )}
    </MUI.TableCell>
  )
}

export default StandardTableCell
