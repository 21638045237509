import DPOEasy from './DPOEasy'
import DPOnet from './DPOnet'
import Polygon from './Polygon'
import Mastercard from './Mastercard'
import Visa from './Visa'
import Elo from './Elo'
import Amex from './Amex'
import Hipercard from './Hipercard'

export default {
  DPOEasy,
  DPOnet,
  Polygon,
  Mastercard,
  Visa,
  Elo,
  Amex,
  Hipercard,
}
