import * as yup from 'yup'

const namedDpoSchema = yup.object({
  namedDpo: yup.object({
    hasNamedDpo: yup.boolean(),
    kindPerson: yup.string().when('hasNamedDpo', {
      is: true,
      then: (schema) => schema.required(),
    }),
    name: yup.string().when('hasNamedDpo', {
      is: true,
      then: (schema) => schema.required(),
    }),
    document: yup.string().when('hasNamedDpo', {
      is: true,
      then: (schema) => schema.required(),
    }),
    responsible: yup.string().when('kindPerson', {
      is: 'juridic',
      then: (schema) => schema.required(),
    }),
    substitute: yup.string().when('kindPerson', {
      is: 'juridic',
      then: (schema) => schema.required(),
    }),
  }),
})

export type NamedDpoSchemaType = yup.InferType<typeof namedDpoSchema>

export default namedDpoSchema
