import cnpj from './cnpj'
import cpf from './cpf'

const cpfOrCnpj = (document: string) => {
  if (document.replace(/\D/g, '').length >= 14) {
    return cnpj.mask(document)
  }

  return cpf.mask(document)
}

export default cpfOrCnpj
