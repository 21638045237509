import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

type LeftBoxStyledType = {
  $background?: string
  $display?: string
}

const LeftBoxStyled = styled(Box, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<LeftBoxStyledType>(
  ({ $background = 'gradient', $display = 'grid', theme }) => ({
    display: $display,
    justifyItems: 'center',
    alignContent: 'space-around',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover, 50%',
    backgroundPosition: 'bottom',
    backgroundImage:
      $background === 'gradient'
        ? `linear-gradient(to bottom, ${theme.palette.primary.main} 70%, ${theme.palette.secondary.main} 100%)`
        : undefined,
    background:
      $background === 'normal' ? theme.palette.primary.main : undefined,
    padding: `${theme.spacing(10)} ${theme.spacing(4)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
      height: 'fit-content',
      borderTopRightRadius: 0,
    },
  })
)

export default LeftBoxStyled
