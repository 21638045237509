import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Grow } from '@mui/material'

import { MUI } from 'components'
import { PrimaryLevelBanner } from './components'

import { palette as paletteHelper } from 'helpers'

import { BannerPreviewType } from './bannerPreview.types'
import { BannerConfigurationSchemaType } from 'types/banner.types'

import constants from 'constants/index'

const BannerPreview = ({ position, iconProps }: BannerPreviewType) => {
  const [openBanner, setOpenBanner] = useState(false)

  const { watch } = useFormContext<BannerConfigurationSchemaType>()

  const secondaryColor = paletteHelper.validateColor(watch('secondaryColor'))

  const handleOpenBanner = () => setOpenBanner((prevState) => !prevState)

  const isCenter = position === constants.banner.BANNER_CENTER_POSITION
  const isFixed = position === constants.banner.BANNER_FIXED_POSITION
  const isRight = position === constants.banner.BANNER_RIGHT_POSITION

  const bannerSize = () => {
    if (isCenter) return 'medium'
    if (isFixed) return 'full'
    if (position) return 'default'
    return undefined
  }

  return (
    <Box height="100%">
      {openBanner && (
        <Grow in={openBanner}>
          <Box
            height="100%"
            display="flex"
            alignItems="end"
            justifyContent={isRight ? 'end' : isCenter ? 'center' : 'start'}
          >
            <PrimaryLevelBanner
              size={bannerSize()}
              handleOpenBanner={handleOpenBanner}
            />
          </Box>
        </Grow>
      )}
      {!openBanner && (
        <Grow in={!openBanner}>
          <Box
            height="100%"
            display="flex"
            alignItems="end"
            justifyContent={isRight ? 'end' : 'start'}
            {...iconProps}
          >
            <MUI.IconButton
              $color={secondaryColor}
              onClick={handleOpenBanner}
              aria-label="Abrir o gerenciador de cookies"
            >
              <MUI.CookieIcon
                $fontSize={48}
                $borderRadius={30}
                $bgcolor={paletteHelper.hexToRgba(secondaryColor, 0.2)}
              />
            </MUI.IconButton>
          </Box>
        </Grow>
      )}
    </Box>
  )
}

export default BannerPreview
