const fontFamily = 'Raleway'

export default {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontFamily,
        fontWeight: '600',
      },
      h2: {
        fontFamily,
        fontWeight: '600',
      },
      h3: {
        fontFamily,
        fontWeight: '600',
      },
      h4: {
        fontFamily,
        fontWeight: '600',
      },
      h5: {
        fontFamily,
        fontWeight: '600',
      },
      h6: {
        fontFamily,
        fontWeight: '600',
        lineHeight: '3rem',
      },
      subtitle1: {
        fontFamily,
        fontWeight: '400',
        lineHeight: '18.78px',
      },
      subtitle2: {
        fontFamily,
        fontWeight: '400',
      },
      body1: {
        fontFamily,
        fontWeight: '400',
      },
      body2: {
        fontFamily,
        fontWeight: '400',
      },
      button: {
        fontFamily,
        fontWeight: '500',
      },
      caption: {
        fontFamily,
        fontWeight: '400',
      },
      overline: {
        fontFamily,
        fontWeight: '400',
      },
    },
  },
}
