import PerfectScrollbar from 'react-perfect-scrollbar'
import DOMPurify from 'dompurify'
import { Box, Paper, Typography, TypographyProps } from '@mui/material'

import { MUI } from 'components'

import { InfoCardType } from './infoCard.types'

const InfoCard = ({
  children,
  description,
  imgUrl,
  isHighlight = false,
  title,
  ...rest
}: InfoCardType & TypographyProps) => {
  return (
    <Paper
      variant="outlined"
      component={Box}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      height="100%"
      pr={description || isHighlight ? 12 : 16}
      pl={isHighlight ? 12 : 16}
      py={isHighlight ? 12 : 16}
      gap={6}
    >
      {imgUrl && (
        <Box pr={4}>
          <MUI.Img
            src={imgUrl}
            height={isHighlight ? 64 : 125}
            $smDown={{ height: isHighlight ? 64 : 80 }}
            alt={title}
          />
        </Box>
      )}
      <Typography
        fontSize="21px !important"
        fontWeight={600}
        color="primary"
        pr={4}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(title),
        }}
      />
      {description && (
        <PerfectScrollbar>
          <Box maxHeight={300} pr={4}>
            <Typography {...rest}>{description}</Typography>
          </Box>
        </PerfectScrollbar>
      )}
      {children}
    </Paper>
  )
}

export default InfoCard
