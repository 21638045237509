import axios from 'axios'

import service from 'service'
import { getBaseURL } from 'service/env'

const baseURL = getBaseURL('dponet')

const solicitation = async ({ ...data }) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/octadesk_intermediator/solicitation`,
    data: { solicitation: data },
    ...config,
  })
}

export default {
  solicitation,
}
