import riskReductionIcon from 'images/marketplace/icons/pentest_web_app.png'
import safeDisposalIcon from 'images/marketplace/icons/antivirus_protection.png'
import privacyPreservedIcon from 'images/marketplace/icons/safe_password_lock.png'

import legalComplianceIcon from 'images/marketplace/icons/antivirus_law.png'
import dataSecurityIcon from 'images/marketplace/icons/cyber_benefit_protecao_empresa.png'
import sustainabilityIcon from 'images/marketplace/icons/eco.png'

import electronicsRecyclingCover from 'images/marketplace/covers/data_anonimizacao_cover.png'

const PAGE_ID = 26

const DESCRIPTION_CARDS = [
  {
    title: 'Redução de Riscos',
    imgUrl: riskReductionIcon,
  },
  {
    title: 'Descarte seguro',
    imgUrl: safeDisposalIcon,
  },
  {
    title: 'Privacidade preservada',
    imgUrl: privacyPreservedIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Conformidade legal',
    description:
      'Evite multas e penalidades se mantendo dentro da conformidade legal.',
    imgUrl: legalComplianceIcon,
  },
  {
    title: 'Segurança de dados',
    description:
      'Protege informações confidenciais contra vazamentos e uso indevido.',
    imgUrl: dataSecurityIcon,
  },
  {
    title: 'Sustentabilidade ambiental',
    description: 'Contribui para a redução do impacto ambiental.',
    imgUrl: sustainabilityIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Descarte de equipamentos eletrônicos',
  description:
    'Empresas especializadas oferecem serviços de destruição segura de dados e reciclagem responsável de equipamentos eletrônicos, garantindo conformidade com regulamentações de proteção de dados e minimizando riscos de vazamento de informações sensíveis. Essa prática essencial não só protege a privacidade dos clientes e funcionários, mas também demonstra responsabilidade ambiental, contribuindo para um ciclo sustentável de consumo e descarte de tecnologia.',
  info: {
    description:
      'O serviço de descarte consciente de equipamentos eletrônicos para a LGPD garante a destruição segura de dados pessoais, em conformidade com a legislação, protegendo a privacidade e evitando vazamentos.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Empresas de todos os setores, especialmente aquelas que lidam com dados sensíveis de clientes e funcionários, como bancos, hospitais, escritórios de advocacia e empresas de tecnologia, podem se interessar pelo serviço de descarte consciente de equipamentos eletrônicos. Contratar esse serviço é crucial para garantir a conformidade com regulamentações como a LGPD, evitando multas e danos à reputação. Além disso, proporciona segurança na proteção de dados, reduzindo o risco de vazamentos e violações de privacidade. A reciclagem responsável também promove a sustentabilidade ambiental, demonstrando responsabilidade corporativa.',
    highlights: [
      'Conformidade legal',
      'Segurança de dados',
      'Sustentabilidade ambiental',
    ],
    imgUrl: electronicsRecyclingCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
