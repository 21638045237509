import { useState, useEffect } from 'react'
import { Button, Divider, Grid } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

import { BaseBox, FormLogin, GreyTypography } from './components'

import { useAuth } from 'hooks'

import routes from 'constants/routes'
import service from 'service'

const Login = () => {
  const [isLoading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()
  const { loadData } = useAuth()

  const goToRegister = () => navigate(routes.auth.register)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    const authToken = queryParams.get('authToken')
    const companyToken = queryParams.get('companyToken')

    if (authToken && companyToken) {
      service.dponet.auth.transparentLogin(authToken, companyToken)
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <BaseBox title="Bem vindo(a)!">
      <Grid item xs={12}>
        <FormLogin isLoading={isLoading} setLoading={setLoading} />
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <GreyTypography variant="caption">ou</GreyTypography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={goToRegister}
          disabled={isLoading}
          fullWidth
        >
          Criar conta
        </Button>
      </Grid>
    </BaseBox>
  )
}

export default Login
