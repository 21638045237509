import { LoadingButton } from '@mui/lab'
import { Button, Typography } from '@mui/material'

import { BasicDialog, MUI } from 'components'

import { formatters } from 'helpers'

import { useAuth, useSnackbar } from 'hooks'

import { SupportRequestDialogType } from './supportRequestDialog.types'

import service from 'service'

const SupportRequestDialog = ({
  isLoadingState,
  onClose,
  open,
  serviceCategory,
}: SupportRequestDialogType) => {
  const { isLoading, setIsLoading } = isLoadingState

  const { snackbar } = useSnackbar()
  const { user, userCompany } = useAuth()

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      await service.dponet.octadeskIntermediator.solicitation({
        head: 'Dúvidas no Marketplace',
        body: `Um usuário criou uma solicitação de dúvidas sobre a categoria: ${serviceCategory}`,
        sender_user: {
          name: user?.name,
          email: user?.email,
        },
        company: {
          document: userCompany?.company?.cnpj,
        },
      })

      snackbar.open({
        message: 'Solicitação enviada com sucesso!',
        variant: 'success',
      })
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      dialogActions={
        <MUI.Box
          display="flex"
          gap={2}
          $smDown={{ flexDirection: 'column', width: '100%' }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isLoading}
            onClick={onSubmit}
          >
            Solicitar Contato
          </LoadingButton>
        </MUI.Box>
      }
    >
      <Typography textAlign="justify" color="primary">
        Nosso time entrará em contato em breve por e-mail para esclarecer todas
        as suas dúvidas.
      </Typography>
      <Typography color="primary" textAlign="center" fontWeight={600}>
        Agradecemos o seu interesse
      </Typography>
    </BasicDialog>
  )
}

export default SupportRequestDialog
