import { isEmpty, size } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, Paper } from '@mui/material'

import { useAuth, useSnackbar } from 'hooks'

import { QuestionItem } from '../'

import { formatters } from 'helpers'

import { QuestionSchemaType } from 'types/maturityQuestionnaire.types'

import service from 'service'
import schema from './schema'
import maturityQuestionnaire from 'constants/maturityQuestionnaire'

const MaturityForm = () => {
  const { snackbar } = useSnackbar()
  const { companyMaturity, setUCompanyMaturity } = useAuth()
  const disabled = !!companyMaturity && !isEmpty(companyMaturity.answers)

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { answers: companyMaturity?.answers ?? [] },
    disabled,
  })

  const { handleSubmit } = formMethods

  const onSubmit = async (data: QuestionSchemaType) => {
    const { answers } = data
    const answerAssessment = answers?.at(answers.length - 1)

    try {
      const response = await service.dponet.companyMaturity.reply({ answers })
      setUCompanyMaturity(response?.data?.companyMaturity)
      snackbar.open({
        message: 'Questionário de maturidade respondido com sucesso!',
        variant: 'success',
      })
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    } catch (e) {
      console.error(e)
      snackbar.open({
        message: formatters.errorMessage(e, false),
        variant: 'error',
      })
    }

    return answerAssessment
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper variant="outlined">
        <FormProvider {...formMethods}>
          {maturityQuestionnaire.MATURITY_QUESTIONNAIRE.questions?.map(
            (question, index) => (
              <Box key={index}>
                <Box p={8}>
                  <QuestionItem question={question} index={index} />
                </Box>
                {index <
                  size(maturityQuestionnaire.MATURITY_QUESTIONNAIRE.questions) -
                    1 && <Divider />}
              </Box>
            )
          )}
        </FormProvider>
      </Paper>
      <Box width="100%" display="flex" pt={4} justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          Responder questionário
        </Button>
      </Box>
    </form>
  )
}

export default MaturityForm
