import { Copy as CopyIcon, Info as InfoIcon } from 'react-feather'
import { Box, IconButton, Typography, colors } from '@mui/material'

import { BasicDialog, MUI } from 'components'

import { useClipboard } from 'hooks'

import { TagManagerDialogType } from './tagManagerDialog.types'

import { theme } from 'theme'
import { getBaseURL } from 'service/env'

const TagManagerDialog = ({
  open,
  onClose,
  bannerToken,
}: TagManagerDialogType) => {
  const { copy } = useClipboard()

  const htmlCode = `<div id="dpoeasy-banner"></div>\n<script src="${getBaseURL('dpoeasy')}/banners/install_code?token=${bannerToken}"></script>`

  const handleCodeCopying = () => copy(htmlCode)

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title="Como instalar com Tag Manager"
      maxWidth="md"
    >
      <Box display="flex" flexDirection="column" gap={8}>
        <Box display="flex" flexDirection="column" gap={4} textAlign="justify">
          <Typography color={theme.palette.neutral.main} fontWeight={700}>
            Configuração via Google Tag Manager do bloqueio das tags e cookies
            de terceiros:
          </Typography>
          <Box
            p={4}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            alignItems="center"
            gap={4}
          >
            <Box>
              <InfoIcon color={theme.palette.primary.main} />
            </Box>
            <Typography color="primary" mt={1}>
              É necessário você ou um especialista instalar o código do Google
              Tag Manager em seu site.
            </Typography>
          </Box>
          <Typography color={theme.palette.neutral.main} variant="body2">
            Veja se já está instalado. Deve aparecer conforme código abaixo
            com&nbsp;
            <strong>seu id do Google Tag Manager.</strong>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box bgcolor={colors.grey[300]} borderRadius={1}>
            <Box display="flex" justifyContent="space-between">
              <MUI.Typography
                variant="body2"
                $wordBreak="break-all"
                pt={4}
                pl={4}
              >
                <MUI.Span $color={colors.red[900]}>&lt;div</MUI.Span>
                <MUI.Span $color={colors.orange[900]}> id="</MUI.Span>
                <MUI.Span $color={colors.blue[800]}>dpoeasy-banner</MUI.Span>
                <MUI.Span $color={colors.orange[900]}>"</MUI.Span>
                <MUI.Span $color={colors.red[900]}>&gt;&lt;/div&gt;</MUI.Span>
              </MUI.Typography>
              <Box height="fit-content">
                <IconButton onClick={handleCodeCopying}>
                  <CopyIcon size={18} color={theme.palette.neutral.dark} />
                </IconButton>
              </Box>
            </Box>
            <MUI.Typography
              variant="body2"
              $wordBreak="break-all"
              pb={4}
              px={4}
            >
              <MUI.Span $color={colors.red[900]}>&lt;script</MUI.Span>
              <MUI.Span $color={colors.orange[900]}> src="</MUI.Span>
              <MUI.Span $color={colors.blue[800]}>
                https://www.googletagmanager.com.br/gtag/js?id=G-35W6WYOL3H
              </MUI.Span>
              <MUI.Span $color={colors.orange[900]}>"</MUI.Span>
              <MUI.Span $color={colors.red[900]}>&gt;&lt;/script&gt;</MUI.Span>
            </MUI.Typography>
          </Box>
          <Typography color={theme.palette.neutral.main} variant="body2">
            Vá até a seção de <strong>Validação de Cookies.</strong>
          </Typography>
        </Box>
        <Box color={theme.palette.neutral.main} textAlign="justify">
          <Typography variant="body2" gutterBottom>
            Copie o "Código HTML da sua Tag - (Tag 1)", que pode ser encontrado
            logo no primeiro campo dessa seção. Vale lembrar que geramos uma tag
            única para cada cookie criado.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Copie o código do Google Tag Manager (ID).
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>Acesse seu GTM: Google Tag Manager</li>
              <li>
                Crie uma tag. Em seu Google Tag Manager, crie uma nova tag
                clicando em Novo &gt; HTML personalizado. No campo HTML cole o
                código do DPOEasy. Para você identificar a tag depois, coloque
                um nome na parte superior da página (exemplo: “Tag Banner de
                Cookie”)
              </li>
              <li>
                Acionador: Defina o disparo em todas as páginas / All Pages Na
                parte de “x”, escolha todas as páginas
              </li>
              <li>Salve/Guardar as alterações</li>
              <li>Clique em “Salvar/Guardar” e a tag estará configurada.</li>
              <li>
                Publique/Envie as alterações Não basta salvar é necessário
                publicar as alterações para que o aviso apareça em seu site.
              </li>
              <li>Clique em Submit e depois publicar.</li>
            </ol>
          </Typography>
        </Box>
      </Box>
    </BasicDialog>
  )
}

export default TagManagerDialog
