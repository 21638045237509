import { FilterOderIDsType } from 'constants/filterOrder'

import constants from 'constants/index'

const filterOrderType = (id: string) => {
  return (
    constants.filterOrder.FILTER_ORDER_API_VALUE[id as FilterOderIDsType] ||
    constants.filterOrder.NEWLY_CREATED
  )
}

export default { filterOrderType }
