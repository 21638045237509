import { useEffect, useState } from 'react'
import { Box, colors, Grid, Paper, Typography } from '@mui/material'

import { LoadingFeedback, MUI } from 'components'

import { useFetch } from 'hooks'

import service from 'service'
import { AutomaticAnswerForm } from '..'
import { AutomaticAnswerMenuItemsObjectType } from 'constants/serviceChannel'

const AutomaticAnswerCard = () => {
  const { response, refresh, loading } = useFetch(
    service.dponet.answerOptionTicket.get,
    {},
    []
  )
  const answerOptionTickets = response?.data?.answerOptionTickets

  const [selectedItem, setSelectedItem] =
    useState<AutomaticAnswerMenuItemsObjectType>()

  useEffect(() => {
    if (!loading && !selectedItem) {
      setSelectedItem(answerOptionTickets?.[0])
    }
  }, [loading, answerOptionTickets, selectedItem])

  const handleSelectedItem = (item: AutomaticAnswerMenuItemsObjectType) => {
    setSelectedItem(item)
  }

  return (
    <Paper variant="outlined" component={Box} p={4}>
      <LoadingFeedback open={loading} />
      <Grid container spacing={8}>
        <Grid item xs={12} lg={3}>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            height="100%"
            minHeight="100%"
          >
            {response?.data?.answerOptionTickets.map(
              (item: AutomaticAnswerMenuItemsObjectType) => (
                <MUI.Box
                  key={item.id}
                  py={3}
                  px={5}
                  bgcolor={
                    selectedItem?.id === item?.id
                      ? colors.blueGrey[200]
                      : colors.grey[300]
                  }
                  $cursor="pointer"
                  $transition="background-color ease-out 0.2s"
                  $hover={{
                    backgroundColor: colors.blueGrey[100],
                  }}
                  onClick={() => handleSelectedItem(item)}
                >
                  <Typography color="primary" fontWeight={500}>
                    {item.title}
                  </Typography>
                </MUI.Box>
              )
            )}
          </Box>
        </Grid>
        {!!selectedItem && (
          <AutomaticAnswerForm
            key={selectedItem.id}
            refresh={refresh}
            answerOptionTicket={selectedItem}
          />
        )}
      </Grid>
    </Paper>
  )
}

export default AutomaticAnswerCard
