import { Fragment, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import {
  CookieBannerCode,
  SourceCodeDialog,
  TagManagerDialog,
} from './components'
import { BasicSection, MUI } from 'components'

import { theme } from 'theme'

const BannerInstallationCard = ({ bannerToken }: { bannerToken: string }) => {
  const [openSourceCodeDialog, setOpenSourceCodeDialog] = useState(false)
  const [openTagManagerDialog, setOpenTagManagerDialog] = useState(false)

  const handleOpenSourceDialog = () => setOpenSourceCodeDialog(true)
  const handleCloseSourceDialog = () => setOpenSourceCodeDialog(false)

  const handleOpenTagManagerDialog = () => setOpenTagManagerDialog(true)
  const handleCloseTagManagerDialog = () => setOpenTagManagerDialog(false)

  return (
    <Fragment>
      <BasicSection
        title="Instalação do Banner"
        extraActions={
          <Typography color="primary" fontWeight={500}>
            03/04
          </Typography>
        }
      >
        <Box
          p={4}
          bgcolor={theme.palette.custom.light}
          borderRadius={1}
          display="flex"
          flexDirection="column"
          gap={6}
        >
          <Typography color="primary" textAlign="justify">
            Para instalar cookies, insira um script no cabeçalho&nbsp;
            <strong>HTML</strong> do seu site ou use o&nbsp;
            <strong>Google Tag Manager</strong>, criando uma tag "HTML
            personalizado", inserindo o código JavaScript para o cookie,
            definindo acionadores e publicando as alterações; sempre informe os
            usuários conforme as normas de privacidade. Clique em um dos botões
            abaixo para saber mais:
          </Typography>
          <MUI.Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            $mdDown={{ flexDirection: 'column', gap: 2 }}
            gap={6}
          >
            <MUI.Box $mdUp={{ minWidth: 300 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleOpenSourceDialog}
              >
                Como instalar com HTML
              </Button>
            </MUI.Box>
            <MUI.Box $mdUp={{ minWidth: 300 }}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleOpenTagManagerDialog}
              >
                Como instalar com Tag Manager
              </Button>
            </MUI.Box>
          </MUI.Box>
        </Box>
        <CookieBannerCode bannerToken={bannerToken} />
        <Typography variant="caption" color="dimgray" fontWeight={500}>
          Esse é o código do banner de cookies do seu site, essencial para as
          funcionalidades da DPOEasy.
        </Typography>
      </BasicSection>
      <SourceCodeDialog
        bannerToken={bannerToken}
        open={openSourceCodeDialog}
        onClose={handleCloseSourceDialog}
      />
      <TagManagerDialog
        bannerToken={bannerToken}
        open={openTagManagerDialog}
        onClose={handleCloseTagManagerDialog}
      />
    </Fragment>
  )
}

export default BannerInstallationCard
