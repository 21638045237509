import { MenuItem as MenuItemMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'

const MenuItem = styled(MenuItemMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $textTransform?: Property.TextTransform
}>(({ $textTransform }) => ({
  textTransform: $textTransform,
}))

export default MenuItem
