import { Card as CardMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType, TLength, TTime } from 'types/materialUI.types'

type CardType = {
  $backgroundColor?: Property.BackgroundColor
  $border?: Property.Border<TLength>
  $borderColor?: Property.BorderColor
  $borderRadius?: Property.BorderRadius<TLength>
  $transition?: Property.Transition<TTime>
  $height?: Property.Height<TLength>
}

export interface CardWithValidationsType extends CardType {
  $hover?: InferType<CardType>
}

const Card = styled(CardMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<CardWithValidationsType>(
  ({
    $backgroundColor,
    $border,
    $borderColor,
    $borderRadius,
    $transition,
    $height,
    $hover,
  }) => ({
    backgroundColor: $backgroundColor,
    border: $border,
    borderColor: $borderColor,
    borderRadius: $borderRadius,
    transition: $transition,
    height: $height,
    '&:hover': {
      borderRadius: $hover?.borderRadius,
      borderColor: $hover?.borderColor,
      border: $hover?.border,
    },
  })
)

export default Card
