import { Controller, useFormContext } from 'react-hook-form'
import { TextField, Typography } from '@mui/material'

import { BasicSection } from 'components'
import { BannerInfo } from 'views/Banner/components'

import { BannerConfigurationSchemaType } from 'types/banner.types'

import constants from 'constants/index'

const BannerConfigurationCard = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BannerConfigurationSchemaType>()

  return (
    <BasicSection
      title="Configuração do Banner"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          01/04
        </Typography>
      }
    >
      <BannerInfo />
      {constants.banner.BANNER_CONFIGURATION_CARD_ITEMS.map((item, index) => (
        <Controller
          key={index}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.[item.controlName]}
              helperText={<>{errors?.[item.controlName]?.message}</>}
              label={item.label}
              fullWidth
            />
          )}
          control={control}
          name={item.controlName}
        />
      ))}
    </BasicSection>
  )
}

export default BannerConfigurationCard
