import { Radio as RadioMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

type RadioType = {
  $p?: Property.Padding<TLength>
}

const Radio = styled(RadioMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<RadioType>(({ theme, $p }) => ({
  padding: $p && theme.spacing($p),
}))

export default Radio
