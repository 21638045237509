import { Check as CheckIcon, Icon, X as XIcon } from 'react-feather'

const DPO_EASY_PLAN_CARD_ITEMS: CardPlansItems[] = [
  {
    benefit: 'Canal de Atendimento',
    descriptions: ['Respostas Automáticas'],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Banner de Cookies',
    descriptions: ['1 domínio', '100 mil acessos'],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Políticas',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Treinamentos',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Mapeamento de Processos',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Diagnóstico',
    descriptions: ['Questionários', 'Medidas de Segurança', 'Ameaças'],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Relatórios',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Incidentes',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Avaliação de Fornecedores',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Documentos de Adequação',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Serviço de DPO',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Suporte Jurídico',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
  {
    benefit: 'Suporte Segurança da Informação',
    descriptions: [],
    icon: XIcon,
    type: 'denied',
  },
]

const DPO_NET_PLAN_CARD_ITEMS: CardPlansItems[] = [
  {
    benefit: 'Canal de Atendimento',
    descriptions: ['Respostas Automáticas'],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Banner de Cookies',
    descriptions: ['1 domínio', '100 mil acessos'],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Políticas',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Treinamentos',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Mapeamento de Processos',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Diagnóstico',
    descriptions: ['Questionários', 'Medidas de Segurança', 'Ameaças'],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Relatórios',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Incidentes',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Avaliação de Fornecedores',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Documentos de Adequação',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Serviço de DPO',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Suporte Jurídico',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
  {
    benefit: 'Suporte Segurança da Informação',
    descriptions: [],
    icon: CheckIcon,
    type: 'conceived',
  },
]

export type CardPlansItems = {
  benefit: string
  descriptions?: string[]
  icon: Icon
  type: 'conceived' | 'denied'
}

export default {
  DPO_EASY_PLAN_CARD_ITEMS,
  DPO_NET_PLAN_CARD_ITEMS,
}
