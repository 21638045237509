import * as cpf from '@fnando/cpf'

const NonNumeric = /\D/g
const mapToNumeric = (value: string): string => value.replace(NonNumeric, '')

const format = (value: string): string =>
  mapToNumeric(value)
    .slice(0, 11)
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(\d{2})$/, '$1')

const isValid = (value: string): boolean => cpf.isValid(value)

const mask = (value: string) => cpf.format(value)

export default {
  format,
  isValid,
  mask,
}
