import { Link2 as Link2Icon } from 'react-feather'
import { Box, Link, Tooltip, Typography } from '@mui/material'

import { MUI } from 'components'
import { ScannerStatus } from '../../../'

import { formatters } from 'helpers'

import { DetailsReadingCardType } from './detailsReadingCard types'

import { theme } from 'theme'

const DetailsReadingCard = ({ scanner }: DetailsReadingCardType) => {
  return (
    <MUI.Box
      bgcolor={theme.palette.background.default}
      border={1}
      borderColor={theme.palette.primary.main}
      borderRadius={1}
      p={4}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      gap={4}
      $mdDown={{ flexDirection: 'column' }}
    >
      <Box>
        <Typography color="primary" gutterBottom>
          Escaneamento feito por
        </Typography>
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          component={Link}
          href={scanner?.url}
          target="_blank"
          underline="none"
          width="fit-content"
        >
          <Box>
            <Link2Icon size={20} />
          </Box>
          <Tooltip title={scanner?.url || 'Não encontrado'}>
            <MUI.Typography
              color="primary"
              fontWeight={600}
              width="fit-content"
              $wordBreak="break-all"
              $mdUp={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 150,
              }}
              $xlUp={{ maxWidth: 300 }}
            >
              {scanner?.url || 'Não encontrado'}
            </MUI.Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Status
        </Typography>
        <ScannerStatus status={scanner?.status} />
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Data
        </Typography>
        <Typography color="primary" fontWeight={600}>
          {formatters.date.ptBrFormatDateTime(
            scanner?.scannedAt,
            'Não encontrada'
          )}
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Frequência do scanner
        </Typography>
        <Typography color="primary" fontWeight={600}>
          Manual
        </Typography>
      </Box>
    </MUI.Box>
  )
}

export default DetailsReadingCard
