import { Fragment } from 'react'
import { Grid, Typography } from '@mui/material'

import InfoCard from '../InfoCard'

import { ServiceBenefitsType } from './serviceBenefits.types'

const ServiceBenefits = ({ product }: ServiceBenefitsType) => {
  return (
    <Fragment>
      <Typography variant="h5" color="primary" textAlign="center">
        Benefícios disponíveis
      </Typography>
      <Grid container spacing={6}>
        {product?.benefits?.cards?.map((item, index) => (
          <Grid key={index} item xs={12} lg={6} xl={4} height="auto">
            <InfoCard {...item} textAlign="justify" />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}

export default ServiceBenefits
