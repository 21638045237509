import { X as XIcon } from 'react-feather'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material'

import { AlertDialogType } from './alertDialog.types'

import constants from 'constants/index'

const AlertDialog = ({
  open,
  onClose,
  content,
  Icon,
  variant = 'standard',
  severity = 'success',
  dialogContentProps,
  dialogActions,
  ...rest
}: AlertDialogType & DialogProps) => {
  const AlertIcon = Icon || constants.alert.CHOOSE_DIALOG_ICON[severity]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          background:
            constants.alert.CHOOSE_DIALOG_BACKGROUND_COLOR[variant]?.[severity],
          border:
            variant === constants.alert.OUTLINED_VARIANT_ID
              ? `2px solid ${constants.alert.CHOOSE_DIALOG_ICON_COLOR[variant]?.[severity]}`
              : 'none',
        },
      }}
      {...rest}
    >
      <DialogTitle
        align="center"
        color={constants.alert.CHOOSE_DIALOG_ICON_COLOR[variant]?.[severity]}
      >
        <Box display="flex" justifyContent="end">
          <IconButton onClick={onClose}>
            <XIcon
              size={20}
              color={
                constants.alert.CHOOSE_DIALOG_DESCRIPTION_COLOR[variant]?.[
                  severity
                ]
              }
            />
          </IconButton>
        </Box>
        <AlertIcon size={82} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          color={
            constants.alert.CHOOSE_DIALOG_DESCRIPTION_COLOR[variant]?.[severity]
          }
          fontWeight={600}
          align="center"
          {...dialogContentProps}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box pb={4}>{dialogActions}</Box>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
