import { ObjectOf } from 'types/global.types'
import { ChipColorType } from 'types/materialUI.types'

const PARTIAL_KIND = 'partial'
const FULL_KIND = 'full'
const DENIED_KIND = 'denied'

const ALL_TYPES_READING = [PARTIAL_KIND, FULL_KIND, DENIED_KIND] as const

const READING_TYPE_TRANSLATION = {
  [PARTIAL_KIND]: 'Aceito parcial',
  [FULL_KIND]: 'Aceito',
  [DENIED_KIND]: 'Negado',
}

const READING_TYPE_CHIP_COLOR: ObjectOf<ChipColorType> = {
  [PARTIAL_KIND]: 'info',
  [FULL_KIND]: 'success',
  [DENIED_KIND]: 'error',
}

const CONSENT_DETAILS_DIALOG_ITEMS = [
  {
    title: 'Domínio do banner',
    value: (value?: string) => value,
  },
  {
    title: 'Dispositivo',
    value: (value?: string) => value,
  },
  {
    title: 'Tipo:',
    value: (value?: string) => value,
  },
  {
    title: 'Data do consentimento:',
    value: (value?: string) => value,
  },
  {
    title: 'Tags aceitas:',
    value: (value?: string) => value,
  },
  {
    title: 'Tags recusadas',
    value: (value?: string) => value,
  },
]

export type KindReadingType = (typeof ALL_TYPES_READING)[number]

export default {
  CONSENT_DETAILS_DIALOG_ITEMS,
  READING_TYPE_TRANSLATION,
  READING_TYPE_CHIP_COLOR,
}
