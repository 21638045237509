import * as cnpj from '@fnando/cnpj'

const NonNumeric = /\D/g
const mapToNumeric = (value: string): string => value.replace(NonNumeric, '')

const format = (value: string): string =>
  mapToNumeric(value)
    .slice(0, 14)
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2')

const isValid = (value: string): boolean => cnpj.isValid(value)

const mask = (value: string) => cnpj.format(value)

export default {
  format,
  isValid,
  mask,
}
