import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Grid } from '@mui/material'

import { ChangePasswordModal, DefaultController } from './components'
import { BasicSection, PasswordValidation } from 'components'

import { formatters, validates } from 'helpers'

import { useAuth, useSnackbar } from 'hooks'

import { PersonalFormType } from './personalForm.types'
import { PersonalFormSchemaType } from 'constants/settings'

import constants from 'constants/index'
import schema from './schema'
import service from 'service'

const PersonalForm = ({ loading, setLoading }: PersonalFormType) => {
  const [openModal, setOpenModal] = useState(false)

  const { user, loadData, loadOut } = useAuth()
  const { snackbar } = useSnackbar()
  const formMethods = useForm<PersonalFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name || '',
      email: user?.email || '',
      phone: formatters.phoneDynamicMask(user?.phone) || '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })

  const { handleSubmit, reset, watch, trigger } = formMethods

  const newPassword = watch('newPassword')
  const confirmPassword = watch('confirmPassword')

  const formId = 'personal-form'
  const isPasswordChange = !!newPassword && !!confirmPassword

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseDialog = () => setOpenModal(false)

  const handleSubmitForm = async () => {
    const result = await trigger()
    if (!result) return

    if (!isPasswordChange) handleSubmit(onSubmit)()
    else handleOpenModal()
  }

  const onSubmit = async (data: PersonalFormSchemaType) => {
    try {
      setLoading(true)

      await service.dponet.user.update(validates.settings.personalData(data))

      if (!!data?.newPassword && !!data?.confirmPassword) {
        await service.dponet.auth.updatePassword(
          validates.settings.passwordData(data)
        )
        loadOut()
      } else {
        reset({})
        loadData()
      }

      snackbar.open({
        message: `Dados pessoais atualizados com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      handleCloseDialog()
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={8}
      >
        <BasicSection title="Dados básicos">
          <Grid container spacing={4}>
            {constants.settings.BASIC_DATA_CONTROLLER_ITEMS_FROM_PERSONAL_DATA.map(
              (item, index) => (
                <Grid item key={index} xs={item?.gridXs} sm={item?.gridSm}>
                  <DefaultController item={item} />
                </Grid>
              )
            )}
          </Grid>
        </BasicSection>
        <BasicSection title="Alterar senha">
          <Box display="flex" gap={4}>
            <Box display="flex" flexDirection="column" gap={4} width="100%">
              {constants.settings.CHANGE_PASSWORD_CONTROLLER_ITEMS_FROM_PERSONAL_DATA.map(
                (item, index) => (
                  <DefaultController key={index} item={item} />
                )
              )}
            </Box>
            <PasswordValidation controlName="newPassword" />
          </Box>
        </BasicSection>
        <Box display="flex" justifyContent="end">
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmitForm}
          >
            Salvar dados pessoais
          </LoadingButton>
        </Box>
      </Box>
      <ChangePasswordModal
        open={openModal}
        onClose={handleCloseDialog}
        loading={loading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  )
}

export default PersonalForm
