export default {
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        fontSize: '2rem !important',
        padding: '8px 24px',
      },
    },
  },
}
