import { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Divider, Hidden, TextField } from '@mui/material'

import { BannerConfigurationSchemaType } from 'types/banner.types'

const DescriptionCustomization = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BannerConfigurationSchemaType>()

  return (
    <Fragment>
      <Box width="100%">
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              label="Descrição"
              error={!!errors?.description}
              helperText={errors?.description?.message}
              type="text"
              rows={9}
              multiline
              fullWidth
            />
          )}
          control={control}
          name="description"
        />
      </Box>
      <Hidden lgDown>
        <Divider orientation="vertical" variant="middle" flexItem />
      </Hidden>
    </Fragment>
  )
}

export default DescriptionCustomization
