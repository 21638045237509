import { useJourney } from 'hooks'
import { LeftBoxStyled } from './components'
import { LeftBoxType } from './leftBox.types'

const LeftBox = ({ children, background }: LeftBoxType) => {
  const { stepInfo } = useJourney()

  return (
    <LeftBoxStyled $background={background} $display={stepInfo?.leftBoxDisplay}>
      {children}
    </LeftBoxStyled>
  )
}
export default LeftBox
