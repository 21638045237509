const size = (bytes: number) => {
  const units = ['bytes', 'kB', 'MB', 'GB']
  let index = 0
  let size = bytes

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024
    index++
  }

  const formattedSize = size.toFixed(1)
  return `${formattedSize} ${units[index]}`
}

const allowedTypes = (types: string) => {
  return types.replace(/\./g, '').replace(/,/g, ', ').toUpperCase()
}

export default {
  size,
  allowedTypes,
}
