import api from 'service/api'

const dponetAPI = api.create('dponet')

const listContracts = async () => {
  return await dponetAPI.get('client_financial_panel/list_contracts')
}

export default {
  listContracts,
}
