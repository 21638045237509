import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { LoadingFeedback, PageHead, StandardPageStructure } from 'components'
import { BannerForm } from './components'

import { useFetch } from 'hooks'

import service from 'service'
import constants from 'constants/index'

const BannerConfiguration = () => {
  const [isLoading, setIsLoading] = useState(false)

  const params = useParams()

  const { response, loading, refresh } = useFetch(service.dpoeasy.banner.get, {
    bannerId: params?.bannerId,
  })

  const banner = response?.data?.banner

  return (
    <StandardPageStructure>
      <LoadingFeedback open={isLoading || loading} />
      <PageHead
        name="Configurações do banner e cookies"
        categories={constants.banner.CONFIGURATION_PAGE_HEADER_CATEGORIES}
      />
      {!loading && (
        <BannerForm
          banner={banner}
          loading={isLoading}
          setLoading={setIsLoading}
          refresh={refresh}
        />
      )}
    </StandardPageStructure>
  )
}

export default BannerConfiguration
