import { Box, styled } from '@mui/material'

const PulseBox = styled(Box, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $pulse: boolean
}>(({ $pulse }) => ({
  opacity: 1,
  ...($pulse && {
    animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    '@keyframes pulse': {
      '50%': {
        opacity: 0.5,
      },
    },
  }),
}))

export default PulseBox
