import './i18n'
import 'configs/yup'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { ptBR } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ThemeProvider } from '@mui/material'

import { SnackbarProvider } from 'providers'

import Router from 'Router'
import { theme } from 'theme'
import { Error } from 'views'

import 'moment/locale/pt-br'

const App = () => {
  if (!navigator.onLine) {
    return <Error.Offline />
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <Router />
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
