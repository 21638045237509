import Login from './Login'
import NewPassword from './NewPassword'
import Recovery from './Recovery'

const Auth = {
  Login,
  NewPassword,
  Recovery,
}

export default Auth
