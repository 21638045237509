import { InputBase as InputBaseMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType } from 'types/materialUI.types'

type InputBaseType = {
  $color?: Property.Color
}

interface InputBaseWithValidationsType extends InputBaseType {
  $hover?: InferType<InputBaseType>
}

const InputBase = styled(InputBaseMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<InputBaseWithValidationsType>(({ $color }) => ({
  color: $color,
}))

export default InputBase
