import comprehensiveProtectionIcon from 'images/marketplace/icons/antivirus_protection.png'
import realTimeSecurityIcon from 'images/marketplace/icons/antivirus_realtime.png'
import intuitiveUsabilityIcon from 'images/marketplace/icons/pentest_white_box.png'

import dataSecurityIcon from 'images/marketplace/icons/antivirus_security.png'
import complianceIcon from 'images/marketplace/icons/antivirus_law.png'
import protectionIcon from 'images/marketplace/icons/dlp_brain.png'

import DLPCover from 'images/marketplace/covers/dlp_cover.png'

const PAGE_ID = 37

const DESCRIPTION_CARDS = [
  {
    title: 'Proteção abrangente',
    imgUrl: comprehensiveProtectionIcon,
  },
  {
    title: 'Segurança em tempo real',
    imgUrl: realTimeSecurityIcon,
  },
  {
    title: 'Usabilidade intuitiva',
    imgUrl: intuitiveUsabilityIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Segurança de dados',
    description:
      'Evita vazamentos, monitora e impede transferências não autorizadas.',
    imgUrl: dataSecurityIcon,
  },
  {
    title: 'Conformidade com regulamentações',
    description:
      'Garante alinhamento com regulamentações de segurança de dados.',
    imgUrl: complianceIcon,
  },
  {
    title: 'Proteção da reputação e propriedade intelectual',
    description:
      'Previne danos financeiros, mantém confiança e protege propriedade intelectual.',
    imgUrl: protectionIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Prevenção de perda de dados (DLP)',
  description:
    'A implementação eficaz do DLP é crucial para empresas que lidam com informações sensíveis, como dados financeiros, informações pessoais dos clientes, propriedade intelectual e outros ativos valiosos. A combinação de tecnologias de segurança, políticas claras e conscientização dos usuários é fundamental para garantir o sucesso de uma estratégia de prevenção contra perda de dados.',
  info: {
    description:
      'DLP (Data Loss Prevention), em português Prevenção contra Perda de Dados, refere-se a um conjunto de técnicas e soluções projetadas para proteger informações confidenciais, sensíveis ou críticas contra vazamentos não autorizados, extravio ou acesso indevido. O objetivo principal do DLP é evitar que dados valiosos deixem a organização de maneira não autorizada.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Empresas de diversos setores e tamanhos podem se beneficiar da implementação de soluções de DLP, especialmente aquelas que lidam com informações sensíveis ou confidenciais. Alguns setores e tipos de empresas que frequentemente têm interesse em DLP são bancos, empresas ligada a área da saúde, setor de tecnologia e pesquisa, entre outros segmentos.',
    highlights: [
      'Proteção contra vazamento de dados',
      'Conformidade com regulamentações',
      'Proteção da reputação e propriedade intelectual',
    ],
    imgUrl: DLPCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
