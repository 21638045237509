import { theme } from 'theme'

const calculateColors = (primaryColorHex: string) => {
  const primaryColor = hexToRgb(primaryColorHex)

  if (primaryColor) {
    const lightColor = adjustLuminosity(primaryColor, 0.16)
    const darkColor = adjustLuminosity(primaryColor, -0.16)
    const contrast = calculateContrast(primaryColor)

    return {
      light: rgbToHex(lightColor),
      dark: rgbToHex(darkColor),
      contrastText: contrast,
    }
  }
}

const adjustLuminosity = (color: number[], amount: number) => {
  const adjustedColor = color.map((c) =>
    Math.round(Math.min(Math.max(c + amount * 255, 0), 255))
  )
  return adjustedColor
}

const calculateContrast = (color: number[]) => {
  const luminosity = calculateLuminosity(color)
  return luminosity > 128
    ? theme.palette.common.black
    : theme.palette.common.white
}

const calculateLuminosity = (color: number[]) => {
  const luminosity = 0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2]
  return luminosity
}

const hexToRgb = (hex: string) => {
  if (!/^#?([a-f\d]{3}|[a-f\d]{4}|[a-f\d]{6}|[a-f\d]{8})$/i.test(hex))
    return undefined

  hex = hex.replace(/^#/, '')

  if (hex.length === 3 || hex.length === 4) {
    hex = hex
      .split('')
      .map(function (char) {
        return char + char
      })
      .join('')
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return [r, g, b]
}

const hexToRgba = (hex: string, alpha: number) => {
  if (!/^#?([a-f\d]{3}|[a-f\d]{4}|[a-f\d]{6}|[a-f\d]{8})$/i.test(hex))
    return undefined

  hex = hex.replace(/^#/, '')

  if (hex.length === 3 || hex.length === 4) {
    hex = hex
      .split('')
      .map(function (char) {
        return char + char
      })
      .join('')
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const rgbToHex = (rgb: number[]) => {
  return '#' + rgb.map((c) => c.toString(16).padStart(2, '0')).join('')
}

const validateColor = (color: string) => {
  return color === '#' ? theme.palette.common.white : color
}

export default {
  calculateColors,
  rgbToHex,
  hexToRgb,
  hexToRgba,
  validateColor,
}
