import Forbidden from './Forbidden'
import InternalError from './InternalError'
import NotFound from './NotFound'
import Offline from './Offline'

const Error = {
  Forbidden,
  InternalError,
  NotFound,
  Offline,
}

export default Error
