import { test } from 'helpers'

const validateStrongPassword = (value?: string | null) => {
  if (!value) return true

  return (
    test.eightCharacters(value) &&
    test.lowercaseLetter(value) &&
    test.uppercaseLetter(value) &&
    test.specialCharacters(value) &&
    test.numberCharacters(value)
  )
}

export default {
  validateStrongPassword,
}
