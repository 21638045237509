import { getBaseURL } from 'service/env'

const { host, protocol } = new URL(getBaseURL('dponet'))

const baseURL = (endPoint?: string) => {
  if (endPoint?.startsWith('/')) return `${protocol}//${host}${endPoint}`

  return `${protocol}//${host}/${endPoint}`
}

export default baseURL
