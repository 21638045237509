import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { Box, Button, Grid, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth, useSnackbar } from 'hooks'
import { Link } from 'components'

import { FormType } from './form.types'

import { getGoogleRecaptchaToken } from 'service/env'

import { theme } from 'theme'
import { formatters } from 'helpers'

import routes from 'constants/routes'
import service from 'service'
import schema from './schema'

const Form = ({ isLoading, setLoading }: FormType) => {
  const [recaptcha, setRecaptcha] = useState<string | null>()

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const { loadData } = useAuth()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const resetRecaptcha = () => {
    recaptchaRef?.current?.reset()
    setRecaptcha(undefined)
  }

  const expiredRecaptcha = () => {
    setValue('recaptcha', '')
    setRecaptcha(null)
  }

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setValue('recaptcha', token)
      setRecaptcha(token)
    }
  }

  const onSubmit = async (data: object) => {
    try {
      setLoading(true)

      await service.dponet.auth.login(data)
      await loadData()

      navigate(routes.root)
    } catch (error) {
      console.error(error)
      resetRecaptcha()
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.email}
                helperText={<>{errors?.email?.message}</>}
                label="E-mail"
                type="text"
                fullWidth
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.password}
                helperText={<>{errors?.password?.message}</>}
                label="Senha"
                type="password"
                autoComplete="on"
                fullWidth
              />
            )}
            name="password"
            control={control}
          />
          <Box width="100%" mt={1}>
            <Link
              to={routes.auth.recoveryPassword}
              // textTransform="none"
              color={theme.palette.neutral.main}
            >
              Esqueceu a sua senha?
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <ReCAPTCHA
                {...field}
                ref={recaptchaRef}
                sitekey={getGoogleRecaptchaToken()}
                onChange={(token) => handleRecaptcha(token)}
                onExpired={expiredRecaptcha}
                size="normal"
              />
            )}
            name="recaptcha"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={!recaptcha || isLoading}
          >
            Acessar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
