import { SyntheticEvent, useState } from 'react'
import { Grid, Tab, Tabs, Typography } from '@mui/material'

import { BasicSection, MUI } from 'components'

import { validates } from 'helpers'

import { CookieBannerType } from 'types/banner.types'
import { BannerCustomizationIdType } from 'constants/banner'

import constants from 'constants/index'

const BannerCustomizationCard = ({ banner }: { banner: CookieBannerType }) => {
  const [selectedTab, setSelectedTab] = useState(
    constants.banner.COLOR_CUSTOMIZATION_CARD_ID as BannerCustomizationIdType
  )

  const logoPreview =
    selectedTab === constants.banner.LOGO_CUSTOMIZATION_CARD_ID

  const positionPreview =
    selectedTab === constants.banner.POSITION_CUSTOMIZATION_CARD_ID

  const BannerContent = validates.banner.bannerContent(selectedTab)
  const BannerPreview = validates.banner.bannerPreview(selectedTab)

  const handleTabChange = (
    _: SyntheticEvent,
    clickedTab: BannerCustomizationIdType
  ) => setSelectedTab(clickedTab)

  return (
    <BasicSection
      title="Personalização do Banner"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          02/04
        </Typography>
      }
    >
      <Tabs value={selectedTab} variant="scrollable" onChange={handleTabChange}>
        {constants.banner.CUSTOMIZATION_CARD_TABS.map((tab) => (
          <Tab key={tab.id} label={tab.tabName} value={tab.id} />
        ))}
      </Tabs>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={positionPreview ? 12 : 5}
          lg={positionPreview ? 12 : logoPreview ? 6 : 5}
          xl={positionPreview ? 4 : 6}
        >
          <MUI.Box
            display="flex"
            gap={4}
            height="100%"
            flexDirection="row"
            justifyContent="space-between"
            $lgDown={{ flexDirection: 'column' }}
          >
            <BannerContent />
          </MUI.Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={positionPreview ? 12 : 7}
          lg={positionPreview ? 12 : logoPreview ? 6 : 7}
          xl={positionPreview ? 8 : 6}
        >
          <BannerPreview banner={banner} />
        </Grid>
      </Grid>
    </BasicSection>
  )
}

export default BannerCustomizationCard
