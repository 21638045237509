import banner from './banner'
import cardFlags from './cardFlags'
import checkout from './checkout'
import consent from './consent'
import cookie from './cookie'
import filterOrder from './filterOrder'
import jsPdf from './jsPdf'
import marketplace from './marketplace'
import maturityQuestionnaire from './maturityQuestionnaire'
import mooksValues from './mooksValues'
import paymentOrder from './paymentOrder'
import policies from './policies'
import privacyChannelConfigurationSteps from './privacyChannelConfigurationSteps'
import routes from './routes'
import segments from './segments'
import serviceChannel from './serviceChannel'
import settings from './settings'
import ticket from './ticket'
import validations from './validations'
import welcomeSteps from './welcomeSteps'
import alert from './alert'

const constants = {
  banner,
  cardFlags,
  checkout,
  consent,
  cookie,
  filterOrder,
  jsPdf,
  marketplace,
  maturityQuestionnaire,
  mooksValues,
  paymentOrder,
  policies,
  privacyChannelConfigurationSteps,
  routes,
  segments,
  serviceChannel,
  settings,
  ticket,
  validations,
  welcomeSteps,
  alert,
}

export default constants
