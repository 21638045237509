import { Box, Typography, Paper } from '@mui/material'

import { isEmpty } from 'lodash'

import { theme } from 'theme'

import constants from 'constants/index'

import { PaymentsType } from './payments.types'
import { formatters } from 'helpers'

import { useMediaQuery } from 'hooks'

const Payments = ({ paymentContracts }: PaymentsType) => {
  const { down } = useMediaQuery()

  return (
    <Paper elevation={0}>
      <Box px={4} pb={4}>
        {isEmpty(paymentContracts) && (
          <Typography
            color={theme.palette.neutral.main}
            fontWeight="700"
            variant="subtitle1"
          >
            Nenhuma forma de pagamento encontrada
          </Typography>
        )}
        {paymentContracts?.map(({ creditCard, ...paymentContract }) => {
          const configs = constants.cardFlags.FLAGS_BY_LABEL[creditCard?.brand]
          const Logo = configs?.Flag

          return (
            <Box mt={4}>
              <Typography
                color={theme.palette.neutral.main}
                fontWeight="700"
                variant="subtitle1"
              >
                O contrato nº {paymentContract?.contractIdentifier} está
                vinculado
              </Typography>
              <Box
                mt={4}
                p={2}
                bgcolor={configs?.bgcolor}
                border={`solid 1px ${theme.palette.paper.border}`}
                borderRadius={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Box maxWidth={65} p={2}>
                    <Logo width="100%" height="100%" />
                  </Box>
                  <Box pl={1} display="flex" flexDirection="column">
                    <Typography
                      color={configs.textColor ?? theme.palette.neutral.dark}
                      gutterBottom
                      variant="body1"
                    >
                      {creditCard?.brand} ****{' '}
                      {creditCard?.maskedCard?.split('*')?.slice(-1)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={configs?.textColor ?? theme.palette.neutral.main}
                    >
                      Expira em {creditCard?.expiration}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  {...(!down.sm && { alignItems: 'end' })}
                >
                  <Typography variant="caption" color="textSecondary">
                    Próxima cobrança:{' '}
                    {formatters.date.ptBrFormatDate(
                      paymentContract?.nextDateAdvance
                    ) ?? '-'}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {paymentContract?.price}/mês
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Paper>
  )
}

export default Payments
