import privacyIcon from 'images/marketplace/icons/safe_password_lock.png'
import secureTransformationIcon from 'images/marketplace/icons/pentest_network_service.png'
import riskReductionIcon from 'images/marketplace/icons/pentest_web_app.png'

import conformityIcon from 'images/marketplace/icons/data_anonimization_law.png'
import securityIcon from 'images/marketplace/icons/safe_password_security.png'

import dataAnonymizationCover from 'images/marketplace/covers/data_anonimizacao_cover.png'

const PAGE_ID = 32

const DESCRIPTION_CARDS = [
  {
    title: 'Privacidade preservada',
    imgUrl: privacyIcon,
  },
  {
    title: 'Transformação segura',
    imgUrl: secureTransformationIcon,
  },
  {
    title: 'Redução de riscos',
    imgUrl: riskReductionIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Conformidade legal',
    description:
      'Elimine riscos associados à perda, esquecimento ou compartilhamento inadequado de senhas.',
    imgUrl: conformityIcon,
  },
  {
    title: 'Privacidade aprimorada',
    description:
      'Funcionários não têm conhecimento das senhas, impedindo possíveis vazamentos.',
    imgUrl: privacyIcon,
  },
  {
    title: 'Segurança reforçada',
    description:
      'Reduza o tempo gasto em recuperação de senhas e resolva problemas de acesso de forma ágil.',
    imgUrl: securityIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Anonimização de dados',
  description:
    'A anonimização de dados é um processo utilizado para proteger a privacidade e a segurança das informações pessoais. Em termos simples, é uma técnica que transforma dados de forma a remover ou ocultar qualquer elemento que possa identificar uma pessoa específica. O objetivo principal é garantir que os dados não possam ser facilmente vinculados a um indivíduo específico, mantendo, ao mesmo tempo, a utilidade desses dados para análises e pesquisas.',
  info: {
    description:
      'O mascaramento de dados é realizado substituindo informações sensíveis por dados fictícios ou ofuscados, preservando a privacidade. Pode incluir técnicas como troca de caracteres ou substituição por valores similares, garantindo a utilidade dos dados para análises sem expor detalhes pessoais.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Empresas de todos os tamanhos e setores estão cada vez mais vulneráveis a ameaças cibernéticas, tornando o seguro cibernético essencial. Grandes corporações, instituições financeiras e setores de saúde, que lidam com dados sensíveis, são clientes naturais. Pequenas e médias empresas também devem considerar, pois muitas vezes têm menos proteção e são alvos fáceis.',
    highlights: [
      'Conformidade legal',
      'Privacidade aprimorada',
      'Segurança reforçada',
    ],
    imgUrl: dataAnonymizationCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
