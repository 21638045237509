const phoneDynamicMask = (value?: string) => {
  if (!value) return ''

  let digits = value.replace(/\D/g, '')

  digits = digits.substring(0, 11)

  let formattedPhone = digits.replace(/(\d{2})(\d)/, '($1) $2')
  formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2')

  return formattedPhone
}

export default phoneDynamicMask
