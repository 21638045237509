import { Fragment, SyntheticEvent, useState } from 'react'
import { Hidden, Paper, Tab, TablePagination, Tabs } from '@mui/material'

import { isEmpty } from 'lodash'

import {
  LoadingFeedback,
  NoData,
  PageHead,
  StandardPageStructure,
} from 'components'
import { TicketTable } from './components'

import { useFetch, usePagination } from 'hooks'

import constants from 'constants/index'
import service from 'service'

const TicketMain = () => {
  const [selectedTab, setSelectedTab] = useState<number | null>(
    constants.ticket.PENDING_TICKET_ID
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dponet.ticket.get,
    {
      params: { page, perPage, statusId: selectedTab },
    },
    [page, perPage, selectedTab]
  )

  const tickets = response?.data?.tickets

  const handleTabChange = (_: SyntheticEvent, clickedTab: number) =>
    setSelectedTab(clickedTab)

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead name="Solicitações de titulares" />
      <Paper variant="outlined">
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {constants.ticket.TICKET_STATUS_TABS.map((tab) => (
            <Tab key={tab.id} label={tab.tabName} value={tab.id} />
          ))}
        </Tabs>
        {isEmpty(tickets) ? (
          <NoData
            title="Nenhuma solicitação foi encontrada"
            withoutPaper
            withTab
          />
        ) : (
          <Fragment>
            <TicketTable tickets={tickets} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              slotProps={{
                actions: {
                  previousButton: { size: 'small' },
                  nextButton: { size: 'small' },
                },
              }}
            />
          </Fragment>
        )}
      </Paper>
    </StandardPageStructure>
  )
}

export default TicketMain
