import { useContext } from 'react'
import { RegistrationContext } from 'contexts'

const useRegistration = () => {
  const context = useContext(RegistrationContext)

  if (context === undefined) {
    throw new Error(
      'useRegistration must be used within a RegistrationContext.Provider'
    )
  }

  return context
}

export default useRegistration
