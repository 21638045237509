import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '@mui/material'

import { formatters } from 'helpers'

import {
  DefaultControllerType,
  HandleOnChangeType,
} from './defaultController.types'

import { useAuth } from 'hooks'

import { CompanyFormSchemaType } from 'constants/settings'

const DefaultController = ({ item }: DefaultControllerType) => {
  const { user } = useAuth()
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<CompanyFormSchemaType>()

  const handleOnChange = ({ event, controlName }: HandleOnChangeType) => {
    let inputValue = event?.target?.value

    if (controlName === 'phone')
      inputValue = formatters.phoneDynamicMask(inputValue)

    setValue(controlName, inputValue)
  }

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          label={item?.label}
          error={!!errors?.[item?.controlName]}
          helperText={<>{errors?.[item?.controlName]?.message}</>}
          InputProps={{
            inputMode: item?.inputMode,
          }}
          type={item?.type}
          multiline={!!item?.rows}
          rows={item?.rows}
          fullWidth
          onChange={(event) =>
            handleOnChange({ event, controlName: item?.controlName })
          }
          disabled={item?.controlName === 'siteUrl' && user?.active}
        />
      )}
      control={control}
      name={item?.controlName}
    />
  )
}

export default DefaultController
