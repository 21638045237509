import { isNumber, isObject, keys, map, pickBy, toPairs } from 'lodash'

import { CheckedType, SubCheckedType } from 'types/helpers.types'
import { ServicesPagesIdsType } from 'constants/marketplace/home'
import { OrderStatusType } from 'constants/marketplace/orders'

import constants from 'constants/index'

const filterCategoryId = (checked: CheckedType) => {
  const entries = toPairs(checked)
  const filteredItems: SubCheckedType = {}

  map(entries, ([key, value]) => {
    if (isObject(value)) {
      map(toPairs(value), ([subKey, subValue]) => {
        if (subValue) {
          filteredItems[subKey] = subValue
        }
      })
    } else if (value) {
      filteredItems[key] = value
    }
  })

  const numericKeysObj = pickBy(filteredItems, (_, key) =>
    isNumber(Number(key))
  )

  return keys(numericKeysObj).map(Number)
}

const orderStatusesName = (kind: OrderStatusType) => {
  return (
    constants.marketplace.orders.CHIP_STATUSES_LABEL[kind].toUpperCase() || '-'
  )
}

const orderStatusesColor = (kind: OrderStatusType) => {
  return constants.marketplace.orders.CHIP_STATUSES_COLOR[kind] || 'neutral'
}

const servicePageContent = (id: number) => {
  return (
    constants.marketplace.home.CONTENT_PAGE[id as ServicesPagesIdsType] || null
  )
}

export default {
  filterCategoryId,
  orderStatusesName,
  orderStatusesColor,
  servicePageContent,
}
