import { createTheme, responsiveFontSizes } from '@mui/material'

import palette from './palette'
import typography from './typography'
import components from './components'

const theme = responsiveFontSizes(
  createTheme({
    palette: palette,
    typography: typography,
    components: components,
    spacing: 4,
  })
)

export default theme
