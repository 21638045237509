import PerfectScrollbar from 'react-perfect-scrollbar'
import { TableProps } from '@mui/material'

import MUI from 'components/MUI'

import { StandardTableType } from './standardTable.types'

const StandardTable = ({
  children,
  ...rest
}: StandardTableType & TableProps) => {
  return (
    <PerfectScrollbar>
      <MUI.Table $whiteSpace="nowrap" {...rest}>
        {children}
      </MUI.Table>
    </PerfectScrollbar>
  )
}

export default StandardTable
