import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Hidden,
  Paper,
  SelectChangeEvent,
  TablePagination,
  Typography,
} from '@mui/material'

import { Carousel, LoadingFeedback, StandardPageStructure } from 'components'
import { Highlights, ProductCard, ProductFilters } from './components'

import { validates } from 'helpers'

import { useFetch, useFilter, usePagination } from 'hooks'

import { ServiceCategoriesResponseType } from 'types/marketplace/categories.types'
import { PartnerServicesResponseType } from 'types/marketplace/partners.types'
import { CheckedType } from 'types/helpers.types'

import service from 'service'
import constants from 'constants/index'

const Products = () => {
  const [checked, setChecked] = useState<CheckedType>({})
  const [filterOrder, setFilterOrder] = useState(
    constants.filterOrder.NEWLY_CREATED
  )

  const navigate = useNavigate()
  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ initialPerPage: 5 })

  const { response: categoriesResponse, loading: categoriesLoading } = useFetch(
    service.marketplace.categories.get,
    {},
    []
  )

  const { response: partnersResponse, loading: partnersLoading } = useFetch(
    service.marketplace.partners.getAll,
    {
      page,
      perPage,
      order: filterOrder,
      ...filter.filters,
    },
    [filter.filters, page, perPage]
  )

  const loading = categoriesLoading || partnersLoading

  const serviceCategories: ServiceCategoriesResponseType[] =
    categoriesResponse?.data?.serviceCategories

  const partnerServices: PartnerServicesResponseType[] =
    partnersResponse?.data?.partnerServices

  const handleFilterOrderChange = (event: SelectChangeEvent<string>) => {
    const value = validates.filterOrder.filterOrderType(event.target.value)
    setFilterOrder(value)
  }

  const handleCardClick = (partnerServiceId: number) =>
    navigate(
      reverse(constants.routes.marketplace.productShow, {
        partnerServiceId,
      })
    )

  const handleFilterChange = (categoryIds?: number[]) => {
    if (categoryIds) {
      setChecked({})
      filter.setFilters({ categoryIds: categoryIds })
    } else {
      const checkedValues = validates.marketplace.filterCategoryId(checked)
      filter.setFilters({
        ...filter.filters,
        categoryIds: checkedValues,
      })
    }
  }

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Carousel items={constants.marketplace.products.CAROUSEL_ITEMS} />
        </Grid>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            component={Box}
            display="flex"
            flexDirection="column"
            p={4}
            px={6}
            gap={4}
          >
            <Typography variant="h6" color="primary">
              Destaques do mês
            </Typography>
            <Highlights handleFilterChange={handleFilterChange} />
            <Grid container spacing={8}>
              <Grid
                item
                xs={12}
                xl={4}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <Typography variant="body1" fontWeight={600} color="primary">
                  Pesquisar
                </Typography>
                <ProductFilters
                  categories={serviceCategories}
                  checkedState={{ checked, setChecked }}
                  loading={loading}
                  handleFilterChange={handleFilterChange}
                  handleFilterOrderChange={handleFilterOrderChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                xl={8}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <Typography fontWeight={600} color="primary">
                  Resultados dos Serviços
                </Typography>
                <Box display="flex" flexDirection="column" gap={4}>
                  {partnerServices?.map((partnerService) => (
                    <Card key={partnerService?.id} variant="outlined">
                      <CardActionArea
                        onClick={() => handleCardClick(partnerService?.id)}
                      >
                        <ProductCard partnerService={partnerService} />
                      </CardActionArea>
                    </Card>
                  ))}
                </Box>
                <TablePagination
                  component="div"
                  count={partnersResponse?.data?.meta?.totalCount || 0}
                  onPageChange={(_, page) => handleChangePage(page)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
                  slotProps={{
                    actions: {
                      previousButton: { size: 'small' },
                      nextButton: { size: 'small' },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </StandardPageStructure>
  )
}

export default Products
