import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
// import { DAIButton } from 'components'

import { useSnackbar } from 'hooks'
import { yupResolver } from '@hookform/resolvers/yup'

import schema, { AutomaticAnswerSchemaType } from './schema'

import { formatters } from 'helpers'
import { AutomaticAnswerMenuItemsObjectType } from 'constants/serviceChannel'
import service from 'service'

const AutomaticAnswerForm = ({
  answerOptionTicket,
  refresh,
}: {
  answerOptionTicket: AutomaticAnswerMenuItemsObjectType
  refresh: VoidFunction
}) => {
  const { snackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AutomaticAnswerSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      automaticAnswerEnabled:
        answerOptionTicket?.automaticAnswerEnabled ?? false,
      automaticAnswer: answerOptionTicket?.automaticAnswer || '',
    },
  })

  const onSubmit = async (data: object) => {
    try {
      setIsLoading(true)
      await service.dponet.answerOptionTicket.updateAnswer({
        answerOptionTicketId: answerOptionTicket.id,
        data: { answerOptionTicket: data },
      })
      await refresh()
      snackbar.open({
        message: 'Sucesso ao salvar a resposta automática',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid
      item
      xs={12}
      lg={9}
      display="flex"
      flexDirection="column"
      gap={4}
      justifyContent="space-between"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" gap={4} justifyContent="space-between">
          <Controller
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Switch {...field} checked={!!field.value} />}
                  label={
                    <Typography fontWeight={600} color="primary">
                      Resposta Automática
                    </Typography>
                  }
                />
                {!!errors?.automaticAnswerEnabled && (
                  <FormHelperText error={!!errors?.automaticAnswerEnabled}>
                    {errors?.automaticAnswerEnabled?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            control={control}
            name="automaticAnswerEnabled"
          />
          {/* <DAIButton text="Gerar resposta por IA" /> */}
        </Box>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.automaticAnswer}
              helperText={<>{errors.automaticAnswer?.message}</>}
              fullWidth
              variant="outlined"
              placeholder="Configure uma mensagem para ser enviada automaticamente ao receber solicitações deste tipo"
              multiline
              rows={26}
            />
          )}
          control={control}
          name="automaticAnswer"
        />
      </Box>
      <Box display="flex" gap={4} justifyContent="end">
        <Button type="submit" variant="contained" disabled={isLoading}>
          Salvar
        </Button>
      </Box>
    </Grid>
  )
}

export default AutomaticAnswerForm
