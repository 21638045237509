const maturityPoints = (points: number) => {
  if (points >= 21) {
    return 'A'
  }
  if (points >= 16) {
    return 'B'
  }

  if (points >= 11) {
    return 'C'
  }

  if (points >= 6) {
    return 'D'
  }

  return 'E'
}

export default maturityPoints
