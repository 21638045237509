import { Outlet } from 'react-router-dom'

import { Navigation } from './components'
import { GridStructure } from 'layouts/components'

import { DrawerProvider } from 'providers'

const MainLayout = () => {
  // const navigate = useNavigate()

  return (
    <GridStructure>
      <DrawerProvider>
        <Navigation>
          <Outlet />
        </Navigation>
      </DrawerProvider>
    </GridStructure>
  )
}

export default MainLayout
