import { ChangeEvent, useState } from 'react'

import {
  Autocomplete,
  Box,
  CircularProgress,
  InputLabel,
  Slider,
  TextField,
} from '@mui/material'

import { AutocompleteConfigs, FilterInputProps } from './filterInput.types'

const FilterInput = ({
  fieldConfigs,
  loading,
  marks,
  inputLabel,
  autocompleteOnChange,
  ...rest
}: FilterInputProps) => {
  const [currentTimer, setTimer] = useState<NodeJS.Timeout>()
  const [isLoading, setIsLoading] = useState(loading)

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (autocompleteOnChange) {
      const value = event.target.value
      if (currentTimer) clearTimeout(currentTimer)
      if (value.length < 3) return
      setIsLoading(true)
      const timer = setTimeout(() => {
        autocompleteOnChange(event.target.value)
        setIsLoading(false)
      }, 1200)
      setTimer(timer)
      return event
    }
  }

  if (fieldConfigs.textFieldInput) {
    return <TextField {...rest} fullWidth />
  }

  if (fieldConfigs.fieldAutocomplete) {
    const configs = rest as AutocompleteConfigs
    return (
      <Autocomplete
        noOptionsText="Sem opções"
        {...rest}
        options={configs?.options || []}
        loadingText="Carregando..."
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            {...(!!autocompleteOnChange && {
              onChange: handleOnChange,
            })}
            {...(!autocompleteOnChange &&
              loading && {
                InputProps: {
                  endAdornment: (
                    <Box
                      display="flex"
                      alignItems="center"
                      ml="100%"
                      width={20}
                      height={20}
                      justifyContent="center"
                    >
                      <CircularProgress variant="indeterminate" size={15} />
                    </Box>
                  ),
                },
              })}
            InputLabelProps={{
              children: null,
            }}
          />
        )}
        loading={!!autocompleteOnChange && isLoading}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name || ''}
      />
    )
  }

  if (fieldConfigs.rangeSlider) {
    return (
      <Box mx={2} width="95%">
        <InputLabel>{inputLabel}</InputLabel>
        <Slider {...rest} marks={marks} />
      </Box>
    )
  }

  return <></>
}

export default FilterInput
