import { useEffect, useState } from 'react'

import { RegistrationContext } from 'contexts'

import { useJourney } from 'hooks'

import { NodeChildren } from 'types/global.types'
import { CompanyType } from 'types/company.types'

import { useLocation } from 'react-router-dom'

import queryString from 'query-string'
import humps from 'humps'

import service from 'service'
import { SegmentType } from 'types/segment.types'
import { AxiosError } from 'axios'
import { IntegrationPartnerType } from 'types/integrationPartner.types'
import { isEmpty } from 'lodash'

const RegistrationProvider = ({ children }: NodeChildren) => {
  const location = useLocation()
  const search = humps.camelizeKeys(
    queryString.parse(location?.search)
  ) as queryString.ParsedQuery
  const integrationToken = search?.partnerToken

  const [recaptcha, setRecaptcha] = useState<string | undefined | null>(null)
  const [integrationPartner, setIntegrationPartner] =
    useState<IntegrationPartnerType>()

  const bannerToken = service.dpoeasy.auth.getCompanyBannerToken()
  const user = {}
  const [companyChanged, setCompanyChanged] = useState(false)
  const [companyToken, setCompanyToken] = useState(
    service.dponet.auth.getRegistrationCompanyToken()
  )
  const [loaded, setLoaded] = useState(!companyToken)
  const [anotherSegment, setAnotherSegment] = useState<SegmentType>()
  const [bannerCreated, setBannerCreated] = useState(!!bannerToken)

  const { setActiveStep } = useJourney()

  const [company, setCompany] = useState<CompanyType>({})

  const updateCompany = (data: CompanyType) => {
    setCompany((company) => ({ ...company, ...data }))
  }

  const loadData = async (toActiveStep = 2) => {
    const token =
      service.dponet.auth.getRegistrationCompanyToken() || companyToken

    if (token) {
      try {
        setLoaded(false)
        const response = await service.dponet.company.getByToken(token)
        const companyResponse = response?.data?.company
        const bannerTokenResponse = companyResponse?.bannerToken
        const companyToken = companyResponse?.token
        if (!service.dpoeasy.auth.getRegistrationCookieToken()) {
          await service.dpoeasy.auth.registrationCookieToken({
            token: companyToken,
          })
        }

        updateCompany(companyResponse ?? company)
        setCompanyToken(companyToken)
        if (bannerTokenResponse) {
          service.dpoeasy.auth.setCompanyBannerToken(bannerTokenResponse)
        }
        setBannerCreated(!!bannerTokenResponse)
        setActiveStep(toActiveStep)
        setLoaded(true)
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404) {
          loadOut()
        }
      }
    }
  }

  useEffect(() => {
    const loadIntegrationPartner = async () => {
      if (!isEmpty(integrationToken)) {
        const response = await service.dponet.integrationPartner.consult(
          integrationToken as string
        )

        setIntegrationPartner(response?.data?.integrationPartner)
      }
    }

    loadIntegrationPartner()
  }, [integrationToken])

  const loadOut = () => {
    setCompany({})
    service.dponet.auth.removeRegistrationCompanyToken()
    service.dpoeasy.auth.removeRegistrationCookieToken()
    service.dpoeasy.auth.removeCompanyBannerToken()
  }

  const createCompany = async () => {
    if (!company?.token) {
      const response = await service.dponet.company.create(company)
      const companyToken = response?.data?.company?.token
      service.dponet.auth.setRegistrationCompanyToken(companyToken)
      setCompanyToken(companyToken)
      return updateCompany(response?.data?.company ?? {})
    }

    if (companyChanged) {
      await service.dponet.company.update(company?.token, company)
      setCompanyChanged(false)
    }
  }

  const createBanner = async () => {
    if (!bannerToken) {
      if (!service.dpoeasy.auth.getRegistrationCookieToken()) {
        const token =
          company?.token || service.dponet.auth.getRegistrationCompanyToken()
        await service.dpoeasy.auth.registrationCookieToken({
          token,
        })
      }

      const response = await service.dpoeasy.banner.create({
        name: `Banner de cookies da ${company?.name}`,
        status: 'unconfigured',
        url: company?.siteUrl,
      })
      if (response?.data?.token) {
        service.dpoeasy.auth.setCompanyBannerToken(response?.data?.token)
      }
    } else {
      if (companyChanged && bannerToken) {
        if (!service.dpoeasy.auth.getRegistrationCookieToken()) {
          await service.dpoeasy.auth.registrationCookieToken({
            bannerToken,
          })
        }
        await service.dpoeasy.banner.updateByToken({
          token: bannerToken,
          banner: {
            name: `Banner de cookies da ${company?.name}`,
            url: company?.siteUrl,
          },
        })
      }
    }
  }

  return (
    <RegistrationContext.Provider
      value={{
        anotherSegment,
        bannerCreated,
        company,
        companyChanged,
        createBanner,
        createCompany,
        loadData,
        loaded,
        loadOut,
        recaptcha,
        integrationPartner,
        setAnotherSegment,
        setBannerCreated,
        setCompanyChanged,
        setCompanyToken,
        updateCompany,
        setRecaptcha,
        user,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}

export default RegistrationProvider
