import { Box as BoxMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { theme } from 'theme'

const Box = styled(BoxMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $pointerEvents?: Property.PointerEvents | undefined
  $opacity?: Property.Opacity | undefined
  $backgroundColor?: Property.BackgroundColor
}>(({ $pointerEvents, $opacity, $backgroundColor }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: 9999,
  display: 'flex',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: $backgroundColor ?? theme.palette.primary.main,
  transitionProperty: 'opacity',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '300ms',
  pointerEvents: $pointerEvents,
  opacity: $opacity,
}))

export default Box
