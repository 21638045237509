import * as yup from 'yup'

const schema = yup
  .object({
    segmentId: yup
      .string()
      .required()
      .nullable()
      .when({
        is: 'another',
        then: (field) => field.notRequired(),
      }),
  })
  .required()

export default schema
