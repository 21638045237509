import { Fragment, useState } from 'react'
import {
  MenuItem,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { useNavigate } from 'react-router-dom'

import { MenuButton, StandardTable, StandardTableCell } from 'components'
import { RemovePolicyModal } from './components'

import { PrivacyPolciyType } from 'types/policies.types'
import { formatters, translatePoliciesCategories } from 'helpers'
import { reverse } from 'named-urls'

import routes from 'constants/routes'
import constants from 'constants/index'
import { useSnackbar } from 'hooks'

const PolicyTable = ({
  privacyPolicies,
  refresh,
}: {
  privacyPolicies: PrivacyPolciyType[]
  refresh: () => void
}) => {
  const navigate = useNavigate()
  const [openRemovePolicyModal, setOpenRemovePolicyModal] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolciyType>()

  const handleOpenRemovePolicyModal = (privacyPolicy: PrivacyPolciyType) => {
    setPrivacyPolicy(privacyPolicy)
    setOpenRemovePolicyModal(true)
  }
  const handleCloseRemovePolicyModal = () => {
    setPrivacyPolicy(undefined)
    setOpenRemovePolicyModal(false)
  }
  const navigateToEdit = (policyId: number) => {
    navigate(reverse(routes.policies.edit, { policyId }))
  }

  const { snackbar } = useSnackbar()

  const copyToClipboard = (link: string | undefined) => {
    try {
      if (!link) return
      window.navigator.clipboard.writeText(link)
      snackbar.open({
        message: 'copiado com sucesso!',
        variant: 'success',
      })
    } catch (e) {
      console.error(e)
      snackbar.open({
        message: 'ocorreu um erro ao tentar copiar o link',
        variant: 'error',
      })
    }
  }

  return (
    <Fragment>
      <TableContainer>
        <StandardTable aria-label="Listagem de políticas">
          <TableHead>
            <TableRow>
              <StandardTableCell $initial>ID</StandardTableCell>
              <StandardTableCell>Nome do documento</StandardTableCell>
              <StandardTableCell align="center">Categoria</StandardTableCell>
              <StandardTableCell align="center">Origem</StandardTableCell>
              <StandardTableCell align="center">Status</StandardTableCell>
              <StandardTableCell align="center">
                Última atualização
              </StandardTableCell>
              <StandardTableCell align="center">Ações</StandardTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {privacyPolicies?.map((policy) => (
              <TableRow key={policy?.id}>
                <StandardTableCell $initial>{policy?.id}</StandardTableCell>
                <StandardTableCell width={300} $maxWidth={300}>
                  {policy?.title}
                </StandardTableCell>
                <StandardTableCell align="center">
                  {translatePoliciesCategories(policy?.category)}
                </StandardTableCell>
                <StandardTableCell align="center">Próprio</StandardTableCell>
                <StandardTableCell align="center">
                  {constants.policies.STATUSES[policy?.status ?? '1']}
                </StandardTableCell>
                <StandardTableCell align="center">
                  {formatters.date.ptBrFormatDateTime(policy.updatedAt)}
                </StandardTableCell>
                <StandardTableCell align="center">
                  <MenuButton id={`policy`}>
                    <MenuItem onClick={() => navigateToEdit(policy?.id)}>
                      Editar
                    </MenuItem>

                    <MenuItem
                      onClick={() => copyToClipboard(policy?.documentLink)}
                    >
                      Copiar link
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleOpenRemovePolicyModal(policy)}
                    >
                      Excluir
                    </MenuItem>
                  </MenuButton>
                </StandardTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StandardTable>
      </TableContainer>
      <RemovePolicyModal
        open={openRemovePolicyModal}
        onClose={handleCloseRemovePolicyModal}
        privacyPolicy={privacyPolicy}
        refresh={refresh}
      />
    </Fragment>
  )
}

export default PolicyTable
