import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Grid } from '@mui/material'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormOutsourcedDpo as FormOutsourcedDpoComponent } from 'components'

import { useAuth, useJourney, useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'

import schema from 'schemas/namedDpoSchema'

import { NamedDpoKindsType } from 'types/companyMaturity.types'

const FormOutsourcedDpo = () => {
  const { handleNext, setNextDisabled } = useJourney()
  const { snackbar } = useSnackbar()
  const { companyMaturity, loadCompanyMaturity } = useAuth()

  const { handleSubmit, watch, ...formRest } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      namedDpo: {
        kindPerson: companyMaturity?.namedDpo?.kindPerson || '',
        name: companyMaturity?.namedDpo?.name || '',
        document: formatters.cpfOrCnpj(
          companyMaturity?.namedDpo?.document ?? ''
        ),
        responsible: companyMaturity?.namedDpo?.responsible || '',
        substitute: companyMaturity?.namedDpo?.substitute || '',
      },
    },
  })
  const kind = watch('namedDpo.kindPerson') as NamedDpoKindsType

  useEffect(() => {
    if (kind) {
      setNextDisabled(false)
    }

    //eslint-disable-next-line
  }, [kind])

  const onSubmit = async (data: object) => {
    try {
      setNextDisabled(true)
      await service.dponet.companyMaturity.nameDpo(data)
      await loadCompanyMaturity()
      handleNext()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: formatters.errorMessage(error),
      })
    } finally {
      setNextDisabled(false)
    }
  }

  return (
    <Grid item xs={12}>
      <FormProvider handleSubmit={handleSubmit} watch={watch} {...formRest}>
        <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <FormOutsourcedDpoComponent kind={kind} />
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  )
}

export default FormOutsourcedDpo
