import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { NodeChildren } from 'types/global.types'

import { DrawerContext } from 'contexts'
import { theme } from 'theme'

const DrawerProvider = ({ children }: NodeChildren) => {
  const [openedDrawer, setOpenedDrawer] = useState(false)

  const { pathname } = useLocation()
  const isSwipeableDrawer = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true,
  })

  const handleActionSwipeDrawer = () => {
    if (!isSwipeableDrawer) return

    setOpenedDrawer((openedDrawer) => !openedDrawer)
  }

  useEffect(() => {
    if (openedDrawer) handleActionSwipeDrawer()
    //eslint-disable-next-line
  }, [pathname])

  return (
    <DrawerContext.Provider
      value={{
        handleActionSwipeDrawer,
        isSwipeableDrawer,
        openedDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerProvider
