import * as yup from 'yup'

const schema = yup
  .object({
    description: yup.string(),
  })
  .required()

export type DataUpdateSchemaType = yup.InferType<typeof schema>

export type DataUpdateControlType = keyof DataUpdateSchemaType

export default schema
