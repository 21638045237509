import { Box, Typography } from '@mui/material'

import { Image } from '..'

import { useJourney } from 'hooks'

const DescriptionBox = () => {
  const { stepInfo } = useJourney()

  return (
    <Box
      minHeight="52vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box display="flex" justifyContent="center" width="100%">
        {stepInfo?.image && (
          <Image width="55%" src={stepInfo?.image} alt="Hello DPO Easy" />
        )}
        {stepInfo?.video && (
          <>
            <Box position="relative" width="100%" height="200px">
              <iframe
                src={`${stepInfo?.video}?autoplay=1&loop=1&autopause=0`}
                allow="autoplay; fullscreen"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
              ></iframe>
            </Box>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </>
        )}
      </Box>
      <Box p={4}>
        <Typography
          textAlign="justify"
          color="white"
          variant="body1"
          fontFamily="Raleway"
          fontWeight={400}
        >
          {stepInfo?.text}
        </Typography>
      </Box>
    </Box>
  )
}

export default DescriptionBox
