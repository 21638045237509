import { Grid } from '@mui/material'

import { StandardPageStructure } from 'components'
import {
  HomeDialogs,
  QuestionCard,
  SuitabilityTrack,
  WelcomeCard,
} from './components'

const HomeMain = () => {
  return (
    <StandardPageStructure>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={5}>
          <QuestionCard />
        </Grid>
        <Grid item xs={12} lg={7}>
          <WelcomeCard />
        </Grid>
        <Grid item xs={12}>
          <SuitabilityTrack />
        </Grid>
      </Grid>
      <HomeDialogs />
    </StandardPageStructure>
  )
}

export default HomeMain
