import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const get = async ({ ...params }) => {
  return await dponetAPI.get('/marketplace/list_orders', { params })
}

const orderService = async ({
  partnerServiceId = '',
  externalId = '',
  ...data
}: {
  partnerServiceId?: string | number
  externalId?: string | number
}) => {
  return await dponetAPI.post('/marketplace/order_services', {
    order: { partnerServiceId, externalId, ...data },
  })
}

export default {
  get,
  orderService,
}
