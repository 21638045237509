import { Box } from '@mui/material'

import { DonutChart } from 'components'
import { useAuth } from 'hooks'

import { theme } from 'theme'

import maturityQuestionnaire from 'constants/maturityQuestionnaire'
import { formatters } from 'helpers'

const QuestionChart = () => {
  const { companyMaturity } = useAuth()

  const points = companyMaturity?.points ?? 0
  const totalPoints = maturityQuestionnaire.MATURITY_QUESTIONNAIRE.totalPoints

  // eslint-disable-next-line
  const totalFormatter = (a: any) => {
    const points = a.globals.series[1] ?? 0

    return formatters.maturityPoints(points)
  }

  return (
    <Box className="apexcharts-legend-text-custom">
      <DonutChart
        id="home-question-chart"
        names={['Fragilidades', 'Pontuação']}
        values={[totalPoints - points, points]}
        colors={[theme.palette.error.main, theme.palette.success.main]}
        title="Nota"
        heightValue={320}
        scale={0.9}
        trimChartLegend={false}
        legendColor={theme.palette.common.white}
        valueColor={theme.palette.common.white}
        totalFormatter={totalFormatter}
      />
    </Box>
  )
}

export default QuestionChart
