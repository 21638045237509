const MAX_FILE_SIZE = 10 * 1024 * 1024
const FILE_TYPES_IMAGE = '.jpg,.gif,.png,.jpeg,.svg,.webp'
const FILE_TYPES_DOCS = '.doc,.docx,.ppt,.pptx,.txt,.pdf'

const BYTES_UNIT = 'bytes'
const KILOBYTE_UNIT = 'kB'
const MEGABYTE_UNIT = 'MB'
const GIGABYTE_UNIT = 'GB'

const STORAGE_MEASUREMENT_UNITS = [
  BYTES_UNIT,
  KILOBYTE_UNIT,
  MEGABYTE_UNIT,
  GIGABYTE_UNIT,
] as const

const BYTE_UNIT_EXPONENT = {
  [BYTES_UNIT]: 2 ** 1,
  [KILOBYTE_UNIT]: 2 ** 10,
  [MEGABYTE_UNIT]: 2 ** 20,
  [GIGABYTE_UNIT]: 2 ** 30,
}

export type StorageMeasurementUnitType =
  (typeof STORAGE_MEASUREMENT_UNITS)[number]

export default {
  MAX_FILE_SIZE,
  FILE_TYPES_IMAGE,
  FILE_TYPES_DOCS,
  BYTE_UNIT_EXPONENT,
}
