import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material'

import { MUI, StandardTable, StandardTableCell } from 'components'

import { formatters, validates } from 'helpers'

import { OrderTableType } from './orderTable.types'

const OrderTable = ({ orders }: OrderTableType) => {
  return (
    <TableContainer>
      <StandardTable aria-label="Listagem de pedidos do marketplace">
        <TableHead>
          <TableRow>
            <StandardTableCell $initial>ID</StandardTableCell>
            <StandardTableCell align="left">Serviço</StandardTableCell>
            <StandardTableCell align="left">Parceiro</StandardTableCell>
            <StandardTableCell align="center">Solicitado em</StandardTableCell>
            <StandardTableCell align="center">Status</StandardTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((order) => (
            <TableRow key={order?.id}>
              <StandardTableCell $initial>{order?.id}</StandardTableCell>
              <StandardTableCell align="left" width={450} $maxWidth={450}>
                {order?.partnerService?.companyService?.name}
              </StandardTableCell>
              <StandardTableCell align="left" width={450} $maxWidth={450}>
                {order?.partnerService?.partner?.name}
              </StandardTableCell>
              <StandardTableCell align="center" $fontWeight={500}>
                {formatters.date.ptBrFormatDateTime(order?.createdAt)}
              </StandardTableCell>
              <StandardTableCell align="center">
                <MUI.Chip
                  label={validates.marketplace.orderStatusesName(order?.status)}
                  color={validates.marketplace.orderStatusesColor(
                    order?.status
                  )}
                  variant="filled"
                  size="small"
                />
              </StandardTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StandardTable>
    </TableContainer>
  )
}

export default OrderTable
