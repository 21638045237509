const HOME_COMPLETE_SUITABILITY = [
  {
    name: 'Treinamentos',
    date: 'Atualize para DPOnet',
    buttonName: 'Contratar',
    completed: false,
  },
  {
    name: 'Diagnósticos',
    date: 'Atualize para DPOnet',
    buttonName: 'Contratar',
    completed: false,
  },
  {
    name: 'Processos',
    date: 'Atualize para DPOnet',
    buttonName: 'Contratar',
    completed: false,
  },
  {
    name: 'Ameaças',
    date: 'Atualize para DPOnet',
    buttonName: 'Contratar',
    completed: false,
  },
  {
    name: 'Medidas de Segurança',
    date: 'Atualize para DPOnet',
    buttonName: 'Contratar',
    completed: false,
  },
  {
    name: 'RIPD',
    date: 'Atualize para DPOnet',
    buttonName: 'Contratar',
    completed: false,
  },
]

export default {
  HOME_COMPLETE_SUITABILITY,
}
