import * as yup from 'yup'

const schema = yup.object({
  answers: yup
    .array()
    .of(
      yup
        .tuple([
          yup.string().required('Selecione uma opção'),
          yup
            .array()
            .nullable()
            .test(
              'at-least-one-true',
              'Selecione pelo menos uma opção',
              (value) => (value ? value.some((v) => v === true) : true)
            ),
        ])
        .transform((val) => {
          if (val?.length === 1) return [val[0], undefined]
          return val
        })
    )
    .required(),
})

export default schema
