import Lottie from 'lottie-react'
import { Box, BoxProps, Button, Typography } from '@mui/material'

import { HexagonStructure } from 'components'

import { validates } from 'helpers'

import { CheckoutTrailsType } from './checkoutTrails.types'

const CheckoutTrails = ({
  trailId,
  setTrailId,
  errorTitle,
  ...rest
}: CheckoutTrailsType & BoxProps) => {
  const trailItem = validates.checkout.trailItem(trailId)

  const handleClickChange = () => setTrailId(null)

  const subtitle =
    trailId === 'error'
      ? errorTitle || trailItem?.subTitle
      : trailItem?.subTitle

  return (
    <HexagonStructure
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={8}
      {...rest}
    >
      <Lottie
        animationData={trailItem?.animation}
        loop={trailItem?.loop}
        style={trailItem?.style}
      />
      <Box textAlign="center">
        <Typography variant="h4" color="primary" zIndex={1}>
          {trailItem?.title}
        </Typography>
        {trailItem?.subTitle && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography color="primary" zIndex={1} gutterBottom>
              {subtitle}
            </Typography>
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickChange}
              >
                Voltar para o checkout
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </HexagonStructure>
  )
}

export default CheckoutTrails
