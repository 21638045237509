import { Divider as DividerMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { InferType } from 'types/materialUI.types'

type DividerType = {
  $opacity?: Property.Opacity
}

interface DividerWithValidationsType extends DividerType {
  $xlDown?: InferType<DividerType>
}

const Divider = styled(DividerMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<DividerWithValidationsType>(({ $opacity }) => ({
  opacity: $opacity,
}))

export default Divider
