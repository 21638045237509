import { Typography } from '@mui/material'

import { MUI } from 'components'

import socialMediaStamp from 'images/social_media_HD.png'
import { theme } from 'theme'
import { formatters, validates } from 'helpers'

import { useAuth, useMediaQuery } from 'hooks'

import { SocialMediaImgType } from './socialMediaImg.types'

const SocialMediaImg = ({ id }: SocialMediaImgType) => {
  const mediaQuery = useMediaQuery()
  const { userCompany } = useAuth()

  const smDown = mediaQuery.down.sm

  const companyName = userCompany?.company?.name || ''

  const fontSize = validates.serviceChannel.adaptCompanyName(
    companyName,
    smDown
  )

  return (
    <MUI.Box
      id={id}
      $backgroundImage={`url(${socialMediaStamp})`}
      height={smDown ? 240 : 480}
      width={smDown ? 240 : 480}
      $backgroundSize="cover"
      color={theme.palette.common.white}
    >
      <MUI.Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        alignItems="center"
        pb={smDown ? 14 : 25}
      >
        <Typography
          variant="h5"
          maxWidth={smDown ? 200 : 400}
          textAlign="center"
          fontSize={fontSize}
        >
          {companyName.toUpperCase()}
        </Typography>
        <Typography
          maxWidth={400}
          textAlign="center"
          fontSize={smDown ? '8px !important' : ''}
          noWrap
        >
          CNPJ: {formatters.cnpj.mask(userCompany?.company?.cnpj || '')}
        </Typography>
      </MUI.Box>
    </MUI.Box>
  )
}

export default SocialMediaImg
