import pentestWhiteBoxIcon from 'images/marketplace/icons/pentest_white_box.png'
import pentestNetworkServiceIcon from 'images/marketplace/icons/pentest_network_service.png'
import pentestWebAppIcon from 'images/marketplace/icons/pentest_web_app.png'

import cyberProtectionBenefitsIcon from 'images/marketplace/icons/cyber_benefit_protecao.png'
import cyberSupportBenefitsIcon from 'images/marketplace/icons/cyber_benefit_suporte.png'
import cyberComplianceBenefitsIcon from 'images/marketplace/icons/cyber_benefit_compliance.png'
import cyberPreparationBenefitsIcon from 'images/marketplace/icons/cyber_benefit_preparacao.png'
import cyberCompanyProtectionBenefitsIcon from 'images/marketplace/icons/cyber_benefit_protecao_empresa.png'
import cyberManagementBenefitIcon from 'images/marketplace/icons/cyber_benefit_gerenciamento.png'

import dataDiscoveryCover from 'images/marketplace/covers/data_discovery_cover.png'

const PAGE_ID = 20

const DESCRIPTION_CARDS = [
  {
    title: 'Visualização intuitiva',
    imgUrl: pentestWhiteBoxIcon,
  },
  {
    title: 'Apoio á tomada de decisões',
    imgUrl: pentestNetworkServiceIcon,
  },
  {
    title: 'Descoberta automatizada de padrões',
    imgUrl: pentestWebAppIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Busca de forma autônoma',
    description: 'Sem a necessidade de acompanhamento de um humano.',
    imgUrl: cyberProtectionBenefitsIcon,
  },
  {
    title: 'Realiza buscas simultâneas',
    description:
      'É possível fazer a mesma descoberta de dados em mais de um computador por vez.',
    imgUrl: cyberSupportBenefitsIcon,
  },
  {
    title: 'Acurácia de 100% nas buscas',
    description: 'Não haverá perdas na busca pelo dado.',
    imgUrl: cyberComplianceBenefitsIcon,
  },
  {
    title: 'Conectividade',
    description:
      'Computadores não precisam estar na mesma rede, apenas conectados à internet.',
    imgUrl: cyberPreparationBenefitsIcon,
  },
  {
    title: 'Produtividade',
    description:
      'Possibilidade de anonimizar e excluir dados em mais de um computador por vez.',
    imgUrl: cyberCompanyProtectionBenefitsIcon,
  },
  {
    title: 'Conformidade',
    description:
      'Auxilia no processo de descarte caso solicitado pelo titular de dados, previsto na LGPD.',
    imgUrl: cyberManagementBenefitIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Data Discovery',
  description:
    'O Data Discovery é como um detetive digital: encontra e organiza dados, revelando informações valiosas para empresas tomarem decisões mais inteligentes.',
  info: {
    description:
      'O Data Discovery é uma solução que realiza a descoberta de dados (nome, cpf, rg etc.) em HDs, partições, servidores, entre outros. Ao realizar a busca pelos dados solicitados é possível tomar algumas ações com os dados encontrados como Anonimiza-los ou apaga-los.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Um dos direitos do titular de dados, previstos na LGPD, é a exclusão de seus dados quando solicitado. Nem todas as empresas conseguem encontrar, com facilidade, os dados solicitados para a exclusão, imagine o seguinte cenário, uma planilha de cadastro compartilhada entre departamentos em que cada um tem sua cópia e faz suas respectivas alterações, além de outros documentos necessários para o cadastro. Quão difícil será certificar-se que todos os campos contendo os dado do solicitante foram devidamente excluído? O Data Discovery faz sentido para empresas que:',
    highlights: [
      'Armazene um ou mais documentos com dados de pessoas físicas',
      'Compartilha dados pessoais com 1 ou mais computadores',
      'Necessitam de conformidade com a LGPD',
    ],
    imgUrl: dataDiscoveryCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
