import { size } from 'lodash'
import { Breadcrumbs as BreadcrumbsMui, Link, Typography } from '@mui/material'

import MUI from 'components/MUI'

import { BreadcrumbsType } from './breadcrumbs.types'

import { theme } from 'theme'

const Breadcrumbs = ({
  categories,
  themeComponent = 'light',
}: BreadcrumbsType) => {
  const getLinkColor = (isLastItem: boolean) => {
    if (themeComponent === 'light')
      return isLastItem ? 'primary' : theme.palette.neutral.main
    else return 'white'
  }

  return (
    <BreadcrumbsMui
      separator={
        <MUI.NavigateNextIcon
          $fontSize={18}
          $color={themeComponent === 'light' ? 'primary' : 'white'}
        />
      }
      aria-label="breadcrumb"
    >
      {categories?.map((category, index) => {
        const isLastItem = index === size(categories) - 1
        const linkColor = getLinkColor(isLastItem)

        return (
          <Link
            key={index}
            href={category?.url}
            underline="none"
            color={linkColor}
          >
            <Typography
              variant="caption"
              fontWeight={isLastItem ? 500 : undefined}
            >
              {category?.name}
            </Typography>
          </Link>
        )
      })}
    </BreadcrumbsMui>
  )
}

export default Breadcrumbs
