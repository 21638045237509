import { HandleFileChangeType, HandleFileRemoveType } from 'types/helpers.types'

const handleFileChange = ({
  event,
  fileInputRef,
  files,
  setFiles,
}: HandleFileChangeType) => {
  if (setFiles && files) {
    const newFiles: File[] = Array.from(event?.target?.files || [])
    setFiles([...files, ...newFiles])

    if (fileInputRef.current) fileInputRef.current.value = ''
  }
}

const handleFileRemove = ({
  fileName,
  files,
  setFiles,
}: HandleFileRemoveType) => {
  if (setFiles && files) {
    const updatedFiles = files?.filter((file) => file?.name !== fileName)
    setFiles(updatedFiles)
  }
}

const handleImageChange = (image?: unknown, imageUrl?: string) => {
  if (image) return URL.createObjectURL(image as Blob)
  else if (imageUrl) return imageUrl
  return ''
}

export default {
  handleFileChange,
  handleFileRemove,
  handleImageChange,
}
