import { Grid, TypographyProps } from '@mui/material'
import { isEmpty } from 'lodash'

import { MUI, PageName } from 'components'
import { Breadcrumbs } from './components'

import { PageHeadType } from './pageHead.types'

const PageHead = ({
  name,
  actionButton,
  returnedUrl,
  categories = [],
  themeComponent,
  ...rest
}: PageHeadType & TypographyProps) => {
  return (
    <Grid container spacing={2} mb={7}>
      <MUI.Grid
        item
        xs={12}
        sm={actionButton ? 6 : 12}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        $smDown={{ alignItems: 'center' }}
      >
        {!isEmpty(categories) && (
          <Breadcrumbs
            categories={categories}
            themeComponent={themeComponent}
          />
        )}
        <PageName name={name} returnedUrl={returnedUrl} {...rest} />
      </MUI.Grid>
      {actionButton && (
        <MUI.Grid
          item
          xs={12}
          sm={6}
          display="flex"
          alignItems="center"
          justifyContent="end"
          $smDown={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {actionButton}
        </MUI.Grid>
      )}
    </Grid>
  )
}

export default PageHead
