import { Fragment } from 'react'
import { reverse } from 'named-urls'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'

import InfoCard from '../InfoCard'

import { FeaturedCompaniesType } from './featuredCompanies.types'
import routes from 'constants/routes'

const FeaturedCompanies = ({ partnerServices }: FeaturedCompaniesType) => {
  const navigate = useNavigate()

  const handleRedirectButton = (partnerServiceId: number) =>
    navigate(
      reverse(routes.marketplace.productShow, {
        partnerServiceId,
      })
    )

  return (
    <Fragment>
      <Typography variant="h5" color="primary" textAlign="center">
        Empresas destaques
      </Typography>
      <Grid container spacing={6}>
        {partnerServices?.map((service, index) => (
          <Grid key={index} item xs={12} lg={6} xl={4} height="auto">
            <InfoCard
              title={service?.partner?.tradeName}
              description={service?.partner?.description}
              imgUrl={service?.partner?.logo}
              textAlign="justify"
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleRedirectButton(service?.id)}
              >
                Contratar
              </Button>
            </InfoCard>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}

export default FeaturedCompanies
