import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { AxiosError } from 'axios'

import { Grid, IconButton, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useForm } from 'react-hook-form'
import { useJourney, useRegistration } from 'hooks'

import { TextField } from './components'

import { formatters, regex } from 'helpers'
import schema, { WelcomeSchemaType } from './schema'
import service from 'service'

import { WelcomeOnChangeType } from './welcome.types'
import { X as XIcon } from 'react-feather'

const Welcome = () => {
  const { handleNext, setNextDisabled } = useJourney()
  const {
    company,
    updateCompany,
    loadData,
    loadOut,
    setCompanyToken,
    setCompanyChanged,
  } = useRegistration()
  const [urlValidatorTimeout, setUrlValidatorTimeout] =
    useState<NodeJS.Timeout>()
  const [shouldLoadData, setShouldLoadData] = useState(false)

  useEffect(() => {
    if (isEmpty(company) && shouldLoadData) {
      loadData(0)
      setShouldLoadData(false)
    }

    //eslint-disable-next-line
  }, [shouldLoadData])

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<WelcomeSchemaType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: company?.name ?? '',
      cnpj: formatters.cnpj.format(company?.cnpj ?? ''),
      siteUrl: company?.siteUrl ?? '',
      phone: formatters.phoneDynamicMask(company?.phone) ?? '',
    },
  })

  const onSubmit = (data: object) => {
    const someFieldChanged = Object.values(dirtyFields).includes(true)
    if (someFieldChanged) {
      updateCompany(data)
      setCompanyChanged(true)
    }
    handleNext()
  }

  const validateCnpj = async (cnpj: string) => {
    if (!formatters.cnpj.isValid(cnpj)) {
      setNextDisabled(true)
      return setError('cnpj', {
        message: 'CNPJ inválido',
        type: 'validate',
      })
    }

    try {
      const response = await service.dponet.company.verifyDocument(cnpj)
      const token = response?.data?.token
      if (token) {
        service.dponet.auth.setRegistrationCompanyToken(token)
        setCompanyToken(token)
        setShouldLoadData(true)
      }
    } catch (error) {
      const responseError = error as AxiosError
      const status = responseError.response?.status
      if (status === 404) {
        setNextDisabled(false)
        return clearErrors('cnpj')
      }

      if (status === 422) {
        setNextDisabled(true)
        setError('cnpj', {
          message:
            'Esse documento não está disponível, verifique se já não cadastrou essa empresa anteriormente.',
          type: 'required',
        })
      }
    }
  }

  const validURL = (url: string) => {
    try {
      const urlObject = new URL(url)
      return urlObject.host.at(-1) !== '.'
    } catch {
      return false
    }
  }

  const validateURL = async (url: string) => {
    try {
      await service.dpoeasy.banner.checkUrlUsing({ banner: { url } })
      setNextDisabled(false)
      clearErrors('siteUrl')
    } catch (error) {
      if ((error as TypeError).message.includes('Invalid URL')) {
        return
      }

      setNextDisabled(true)
      setError('siteUrl', {
        message: 'Essa URL não está disponível para ser utilizada.',
        type: 'required',
      })
    }
  }

  const handleCnpjFormat = ({ event, onChange }: WelcomeOnChangeType) => {
    const value = event?.target?.value
    if (value?.length >= 18) {
      validateCnpj(regex.onlyNumbers(value))
    }

    event.target.value = formatters.cnpj.format(value)
    onChange(event)
  }

  const handlePhoneFormat = ({ event, onChange }: WelcomeOnChangeType) => {
    event.target.value = formatters.phoneDynamicMask(event?.target.value) ?? ''
    onChange(event)
  }

  const handleUrlFormat = ({ event, onChange }: WelcomeOnChangeType) => {
    if (urlValidatorTimeout) clearTimeout(urlValidatorTimeout)
    const value = formatters.website(event?.target.value) ?? ''
    event.target.value = value
    if (validURL(value) && value !== company?.siteUrl) {
      const newTimeout = setTimeout(() => validateURL(value), 1000)
      setUrlValidatorTimeout(newTimeout)
    }
    if (value === company?.siteUrl) {
      setNextDisabled(false)
      clearErrors('siteUrl')
    }

    onChange(event)
  }

  const handleRemoveCnpj = () => {
    setValue('cnpj', '')
    loadOut()
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center">
          Bem vindo ao DPO Easy
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight="400"
          textAlign="center"
          color="primary"
        >
          Para iniciarmos, precisamos de algumas informações!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form id="form-register" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} xl={8}>
              <TextField
                name="name"
                label="Razão Social"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} xl={8}>
              <TextField
                name="cnpj"
                label="CNPJ"
                disabled={!!company?.token}
                onChange={handleCnpjFormat}
                errors={errors}
                control={control}
                {...(!!company?.token && {
                  InputProps: {
                    endAdornment: (
                      <IconButton size="small" onClick={handleRemoveCnpj}>
                        <XIcon />
                      </IconButton>
                    ),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} xl={8}>
              <TextField
                name="siteUrl"
                label="Site (URL)"
                onChange={handleUrlFormat}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} xl={8}>
              <TextField
                name="phone"
                label="Telefone"
                errors={errors}
                onChange={handlePhoneFormat}
                control={control}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default Welcome
