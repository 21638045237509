import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'

const useFetch = <T>(
  fn: (params: T) => Promise<AxiosResponse>,
  params: T,
  observable: React.DependencyList = [],
  condition = true,
  oneTime = false
) => {
  const [response, setResponse] = useState<AxiosResponse | null | void>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(condition)

  const request = () => {
    setLoading(true)

    fn(params)
      .then((data) => setResponse(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (condition && (!oneTime || !response)) request()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...observable, condition])

  return {
    response,
    error,
    loading,
    refresh: request,
  }
}

export default useFetch
