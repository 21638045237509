import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TTime } from 'types/materialUI.types'

type HoverType = {
  opacity?: Property.Opacity
  backgroundColor?: Property.BackgroundColor
}

const Carousel = styled(ReactCarousel, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $cursor?: Property.Cursor
  $transition?: Property.Transition<TTime>
  $hover?: HoverType
}>(({ $cursor, $transition, $hover }) => ({
  cursor: $cursor,
  transition: $transition,
  '&:hover': {
    opacity: $hover?.opacity,
    backgroundColor: $hover?.backgroundColor,
  },
}))

export default Carousel
