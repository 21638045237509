import lossProtectionIcon from 'images/marketplace/icons/cyber_protecao_perdas.png'
import comprehensiveCoverageIcon from 'images/marketplace/icons/cyber_cobertura_abrangente.png'
import expertSupportIcon from 'images/marketplace/icons/cyber_suporte_especializado.png'

import protectionIcon from 'images/marketplace/icons/cyber_benefit_protecao.png'
import specializedSupportIcon from 'images/marketplace/icons/cyber_benefit_suporte.png'
import complianceWithLGPDIcon from 'images/marketplace/icons/cyber_benefit_compliance.png'
import cyberAttacksIcon from 'images/marketplace/icons/cyber_benefit_preparacao.png'
import protectionForCompaniesIcon from 'images/marketplace/icons/cyber_benefit_protecao_empresa.png'
import crisisManagementIcon from 'images/marketplace/icons/cyber_benefit_gerenciamento.png'

import cyberInsuranceCover from 'images/marketplace/covers/cyber_cover.png'

const PAGE_ID = 38

const DESCRIPTION_CARDS = [
  {
    title: 'Proteção contra perdas financeiras',
    imgUrl: lossProtectionIcon,
  },
  {
    title: 'Cobertura abrangente',
    imgUrl: comprehensiveCoverageIcon,
  },
  {
    title: 'Suporte especializado',
    imgUrl: expertSupportIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Proteção contra perdas financeiras',
    description:
      'Visa proteger empresas de possíveis perdas financeiras decorrentes de ataques virtuais maliciosos, erros ou negligências internas que resultem em vazamento de dados e outros danos ligados ao sigilo da informação.',
    imgUrl: protectionIcon,
  },
  {
    title: 'Suporte especializado',
    description:
      'Oferece suporte técnico de consultoria especializada e equipe especializada em sinistros disponível para prestar todo o suporte ao segurado quando necessário.',
    imgUrl: specializedSupportIcon,
  },
  {
    title: 'Compliance com a LGPD',
    description:
      'Ajuda as empresas a estarem em compliance com a Lei Geral de Proteção de Dados (LGPD).',
    imgUrl: complianceWithLGPDIcon,
  },
  {
    title: 'Preparação para ataques cibernéticos',
    description:
      'Ajuda as empresas a se prepararem para possíveis ataques cibernéticos, com suporte técnico especializado e cobertura abrangente.',
    imgUrl: cyberAttacksIcon,
  },
  {
    title: 'Proteção para empresas de todos os portes',
    description:
      'Voltado para empresas de todos os portes e segmentos que desejam se proteger contra os riscos cibernéticos.',
    imgUrl: protectionForCompaniesIcon,
  },
  {
    title: 'Gerenciamento de crise',
    description:
      'Oferece gerenciamento de crise para o tomador, incluindo suporte técnico especializado e cobertura abrangente.',
    imgUrl: crisisManagementIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Seguro Cyber',
  description:
    'O Marketplace DPOnet oferece o Seguro Cyber que visa proteger empresas de possíveis perdas financeiras decorrentes de ataques virtuais maliciosos, erros ou negligências internas que resultem em vazamento de dados e outros danos ligados ao sigilo da informação. A tecnologia alterou a maneira como os negócios são feitos e, com ataques virtuais cada vez mais sofisticados, os riscos cibernéticos ou cyber risks são uma realidade nas companhias.',
  info: {
    description:
      'O seguro cibernético é uma proteção vital para nossos dias digitais. Imagine-o como um guarda-costas para seus dados online. Ele cobre danos causados por ataques virtuais, como hackers, vírus ou até mesmo um descuido acidental. Se sua informação pessoal ou empresarial for comprometida, o seguro entra em ação, cobrindo prejuízos financeiros e ajudando na recuperação. É como um escudo para seu mundo digital, garantindo tranquilidade em um universo cada vez mais conectado.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Empresas de todos os tamanhos e setores estão cada vez mais vulneráveis a ameaças cibernéticas, tornando o seguro cibernético essencial. Grandes corporações, instituições financeiras e setores de saúde, que lidam com dados sensíveis, são clientes naturais. Pequenas e médias empresas também devem considerar, pois muitas vezes têm menos proteção e são alvos fáceis.',
    highlights: [
      'Minimização de riscos financeiros',
      'Rápida recuperação em caso de ataque',
      'Conformidade legal e normativa',
      'Confiança do cliente reforçada',
    ],
    imgUrl: cyberInsuranceCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
