import { Box, Card, Grid, Typography } from '@mui/material'
import { Check } from 'react-feather'

import { CardPlanType } from './cardPlan.types'

import { themeJourney } from 'theme'

const CardPlan = ({
  title,
  subtitle,
  advantages,
  radius,
  isCentered,
  observation,
  selected,
  oldPrice,
  exclusive,
  height = '100%',
  opacity,
}: CardPlanType) => {
  return (
    <Card
      variant="outlined"
      component={Box}
      height={height}
      px={8}
      pt={4}
      sx={{
        borderColor: selected ? '#233154' : '#D9D9D9',
        borderRadius: radius ?? '4px',
        cursor: 'pointer',
      }}
    >
      <Box
        py={4}
        display="flex"
        justifyContent="center"
        {...(isCentered && {
          alignItems: 'center',
          gap: 6,
        })}
        flexDirection="column"
      >
        <Box display="flex" maxHeight={60}>
          <Typography
            textAlign={isCentered ? 'center' : 'start'}
            variant="h6"
            fontWeight="700"
          >
            {title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="baseline" gap={2}>
          <Typography
            color={themeJourney.palette.secondary.light}
            variant="h5"
            fontWeight="700"
          >
            {subtitle ?? 'R$ 0,00'}
          </Typography>
          {oldPrice && (
            <Typography
              color={themeJourney.palette.neutral.main}
              fontWeight="700"
              variant="subtitle1"
              sx={{
                textDecoration: 'line-through',
                textDecorationThickness: 2,
                textDecorationColor: themeJourney.palette.primary.main,
                fontFamily: 'Roboto',
              }}
            >
              {oldPrice}
            </Typography>
          )}
        </Box>
        {exclusive && (
          <Box mt={-5}>
            <Typography
              fontWeight="400"
              color={themeJourney.palette.neutral.main}
              variant="caption"
            >
              Exclusivo para clientes {exclusive}
            </Typography>
          </Box>
        )}
      </Box>
      <Box pt={2} pb={8}>
        <Grid container spacing={4}>
          {!!observation && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">{observation}</Typography>
            </Grid>
          )}
          {advantages.map((advantage) => (
            <Grid key={advantage?.name} item xs={12}>
              <Box display="flex" alignItems="center">
                <Box mr={2}>
                  <Check color={opacity ? '#3BB7103C' : '#3BB710'} size={22} />
                </Box>
                <Typography variant="subtitle1">{advantage?.name}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  )
}

export default CardPlan
