import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { size } from 'lodash'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup as FormGroupMUI,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import {
  FormGroupType,
  HandleChangeMultipleType,
  QuestionItemType,
} from './questionItem.types'
import { QuestionSchemaType } from 'types/maturityQuestionnaire.types'

import { theme } from 'theme'

const FormGroup = ({
  kind,
  children,
  defaultValue,
  ...rest
}: FormGroupType) => {
  if (kind === 'unique') {
    return (
      <RadioGroup
        defaultValue={defaultValue}
        color={theme.palette.common.white}
        {...rest}
      >
        {children}
      </RadioGroup>
    )
  }

  return <FormGroupMUI {...rest}>{children}</FormGroupMUI>
}

const QuestionItem = ({ question, index }: QuestionItemType) => {
  const [multipleValue, setMultipleValue] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: boolean
  }>({})
  const [isMasterSelected, setIsMasterSelected] = useState(false)

  const {
    control,
    formState: { errors, defaultValues },
    setValue,
    getValues,
  } = useFormContext<QuestionSchemaType>()

  const isError =
    !!errors?.answers?.[index]?.[1] || !!errors?.answers?.[index]?.[0]

  const errorMessage =
    errors?.answers?.[index]?.[1]?.message ||
    errors?.answers?.[index]?.[0]?.message

  const handleChangeMultiple = ({
    e,
    option,
    optionIndex,
    options,
  }: HandleChangeMultipleType) => {
    const checked = e.target.checked
    let value = multipleValue
    let updatedSelections =
      getValues(`answers.${index}.1`) || Array(size(options)).fill(false)

    updatedSelections = updatedSelections.map((selection) =>
      selection !== undefined ? selection : false
    )

    if (option.master && checked) {
      updatedSelections = Array(size(options)).fill(false)
      updatedSelections[optionIndex] = true

      setSelectedOptions(updatedSelections)
      setIsMasterSelected(true)
      value = Number(option.points) || 0
    } else {
      updatedSelections[optionIndex] = checked

      setSelectedOptions(updatedSelections)
      if (checked) {
        value += Number(option.points) || 0
      } else {
        value -= Number(option.points) || 0
      }
      setIsMasterSelected(false)
    }

    setMultipleValue(value)
    setValue(`answers.${index}`, [value?.toString(), updatedSelections])

    return e
  }

  useEffect(() => {
    const savedAnswers = getValues(`answers.${index}`)
    if (savedAnswers) {
      const [savedValue, savedSelections] = savedAnswers
      setMultipleValue(Number(savedValue))
      setSelectedOptions(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        savedSelections?.reduce((acc: any, curr: boolean, idx: number) => {
          acc[idx] = curr
          return acc
        }, {})
      )
    }
  }, [index, getValues])

  return (
    <FormControl>
      <Box pb={2}>
        <Typography
          variant="subtitle1"
          fontWeight="700"
          color="primary"
          {...(isError && { color: 'red' })}
        >
          {question?.question}
        </Typography>
        {isError && (
          <FormHelperText error={isError}>{<>{errorMessage}</>}</FormHelperText>
        )}
      </Box>
      <FormGroup
        kind={question?.kind}
        defaultValue={defaultValues?.answers?.[index]?.[0]}
      >
        {question.options.map((option, optionIndex) => (
          <Controller
            key={`${index}-${optionIndex}`}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                value={option?.points}
                control={
                  question?.kind === 'unique' ? (
                    <Radio />
                  ) : (
                    <Checkbox
                      defaultChecked={!!field?.value}
                      value={option.points}
                      onChange={(e) =>
                        handleChangeMultiple({
                          e,
                          option,
                          optionIndex,
                          options: question?.options,
                        })
                      }
                      checked={!!selectedOptions[optionIndex]}
                      disabled={
                        selectedOptions[optionIndex] && option.master
                          ? false
                          : isMasterSelected && !option.master
                      }
                    />
                  )
                }
                label={option?.name}
              />
            )}
            control={control}
            name={
              question?.kind === 'unique'
                ? `answers.${index}.0`
                : `answers.${index}.1.${optionIndex}`
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default QuestionItem
