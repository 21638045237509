import { Download as DownloadIcon } from 'react-feather'
import { Box, Divider, Skeleton, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { MUI } from 'components'

import { useClipboard, useMediaQuery } from 'hooks'

import { PrintingStampType } from './printingStamp.types'

const PrintingStamp = ({ stampURL = '' }: PrintingStampType) => {
  const { downloadImage, isLoading } = useClipboard()
  const mediaQuery = useMediaQuery()

  const lgDown = mediaQuery.down.lg
  const smDown = mediaQuery.down.sm

  const handleDownloadStamp = () =>
    downloadImage('selo_canal_de_comunicação_para_impressão', stampURL)

  return (
    <MUI.Paper
      variant="outlined"
      $borderTopRightRadius={0}
      $borderTopLeftRadius={0}
    >
      <Box
        display="flex"
        flexDirection={lgDown ? 'column' : 'row'}
        justifyContent="center"
        p={4}
        gap={8}
      >
        <Box display="flex" justifyContent="center">
          {!stampURL ? (
            <Skeleton
              variant="rectangular"
              width={smDown ? 240 : 480}
              height={smDown ? 319 : 637}
            />
          ) : (
            <img
              src={stampURL}
              alt="Selo do canal de atendimento para impressão"
              width={smDown ? 240 : 480}
            />
          )}
        </Box>
        <Box textAlign="justify">
          <Typography fontWeight={600} color="primary" gutterBottom>
            Publique em seus departamentos
          </Typography>
          <Typography variant="body2" color="primary" gutterBottom>
            Você também pode imprimir e exibir em suas recepções e
            departamentos, permitindo que qualquer pessoa que circule possa
            verificar a autenticidade do seu Selo.
          </Typography>
          <Typography variant="body2" color="primary">
            Ao escanear o QR Code, o interessado é direcionado ao portal&nbsp;
            <strong>Privacidade & Você</strong> e pode verificar a autenticidade
            da adequação da empresa à LGPD, inclusive, podendo verificar: a
            última revisão de seus mecanismos de controle com a exibição da data
            e hora em que foi feita a revisão; as políticas de privacidade da
            empresa; o meio de contato dos titulares de dados; e o meio de
            contato da ANPD.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="end" p={4}>
        <LoadingButton
          startIcon={<DownloadIcon size={18} />}
          variant="contained"
          loading={isLoading}
          onClick={handleDownloadStamp}
        >
          Download
        </LoadingButton>
      </Box>
    </MUI.Paper>
  )
}

export default PrintingStamp
