import { MUI } from 'components'

import { ButtonTabType } from './buttonTab.types'

const ButtonTab = ({
  icon,
  label,
  selectedTab,
  value,
  onChange,
}: ButtonTabType) => {
  const isSelected = selectedTab === value

  return (
    <MUI.Button
      startIcon={icon}
      variant="contained"
      color={isSelected ? 'primary' : 'inherit'}
      $borderRadius={0}
      $boxShadow="none"
      $hover={{ boxShadow: 'none' }}
      $py={4}
      $px={6}
      onClick={(event) => onChange(event, value)}
    >
      {label}
    </MUI.Button>
  )
}

export default ButtonTab
