import { Grow as GrowMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const Grow = styled(GrowMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $transformOrigin?: Property.TransformOrigin<TLength> | undefined
}>(({ $transformOrigin }) => ({
  transformOrigin: $transformOrigin,
}))

export default Grow
