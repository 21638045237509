import { useState } from 'react'
import { Box, Card, CardActionArea, Paper, Typography } from '@mui/material'

import { Invoices, Payments } from './components'

import { useFetch } from 'hooks'

import { theme } from 'theme'

import { PaymentContractType, PaymentOrderType } from 'types/paymentOrder.types'

import constants from 'constants/index'
import service from 'service'

const PaymentDetails = () => {
  const [currentTab, setCurrentTab] = useState(
    constants.settings.INVOICES_STATUS_ID
  )

  const { response } = useFetch(service.dponet.payment.index, {}, [])
  const { response: responseContracts } = useFetch(
    service.dponet.financialPanel.listContracts,
    {},
    []
  )

  const paymentOrders: PaymentOrderType[] = response?.data?.paymentOrders
  const paymentContracts: PaymentContractType[] =
    responseContracts?.data?.paymentContracts

  return (
    <Paper elevation={0} sx={{ pb: 2 }}>
      <Box display="flex" width="100%" p={4} gap={4}>
        {constants?.settings.FINANCIAL_MENU_ITEMS.map((menu) => (
          <Card
            key={menu.id}
            variant="outlined"
            component={Box}
            width="50%"
            {...(currentTab === menu.id && {
              sx: {
                border: `1px solid ${theme.palette.primary.main}`,
              },
            })}
          >
            <CardActionArea onClick={() => setCurrentTab(menu?.id)}>
              <Box
                p={4}
                gap={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                bgcolor={theme.palette.custom.light}
              >
                <menu.Icon />
                <Typography variant="subtitle1" align="center">
                  {menu?.label}
                </Typography>
              </Box>
            </CardActionArea>
          </Card>
        ))}
      </Box>
      {currentTab === constants.settings.INVOICES_STATUS_ID ? (
        <Invoices paymentOrders={paymentOrders} />
      ) : (
        <Payments paymentContracts={paymentContracts} />
      )}
    </Paper>
  )
}

export default PaymentDetails
