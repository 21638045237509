import { size } from 'lodash'

import { AdaptTextResponseType } from 'types/helpers.types'
import {
  PocketCardStampSizeType,
  StampItemTabsType,
} from 'constants/serviceChannel'

import constants from 'constants/index'

const stepsContent = (stampId: StampItemTabsType) => {
  return constants.serviceChannel.STAMPS_CONTENT_TABS[stampId] || (() => null)
}

const adaptCompanyName = (companyName: string, responsive = false) => {
  const companySize = size(companyName)

  if (responsive) {
    if (companySize >= 60) return '9px !important'
    if (companySize >= 24) return '10px !important'
    return '15px !important'
  }

  if (companySize >= 60) return '18px !important'
  if (companySize >= 24) return '20px !important'
  return '32px !important'
}

const adaptTextResponse = ({
  smText = 8,
  mdText = 14,
  lgText = 20,
  smResponse = false,
  mdResponse = false,
  lgResponse = true,
}: AdaptTextResponseType) => {
  if (smResponse) return `${smText}px !important`
  if (mdResponse) return `${mdText}px !important`
  if (lgResponse) return `${lgText}px !important`
}

const pocketCardStampSize = (size: PocketCardStampSizeType) => {
  return constants.serviceChannel.POCKET_CARD_STAMP_SIZE_ITEMS[size] || {}
}

export default {
  stepsContent,
  adaptCompanyName,
  adaptTextResponse,
  pocketCardStampSize,
}
