import { Close as CloseIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'

import { BasicDialogType } from './basicDialog.types'
import { theme } from 'theme'

const BasicDialog = ({
  children,
  dialogActions,
  disableBackdropClick = false,
  fullScreen = false,
  maxWidth = 'sm',
  onClose = () => {},
  open,
  title = '',
  dividers = true,
}: BasicDialogType) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      onClose={!disableBackdropClick ? onClose : undefined}
      open={open}
    >
      {title && (
        <DialogTitle
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          color={theme.palette.primary.main}
        >
          {title}
          <IconButton size="small" aria-label="Fechar" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent dividers={dividers}>{children}</DialogContent>
      {(dialogActions || !title) && (
        <DialogActions>
          {dialogActions || (
            <Button variant="outlined" onClick={onClose}>
              Fechar
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default BasicDialog
