import { useState } from 'react'
import { toPng } from 'html-to-image'
import { saveAs } from 'file-saver'

import { useSnackbar } from 'hooks'

const useClipboard = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const snackbarSuccess = (
    message = 'Conteúdo copiado para a área de transferência'
  ) =>
    snackbar.open({
      message: message,
      variant: 'success',
    })

  const snackbarError = (
    message = 'Ocorreu uma falha inesperada ao tentar copiar o conteúdo'
  ) =>
    snackbar.open({
      message: message,
      variant: 'error',
    })

  const copy = (value: string) => {
    try {
      setIsLoading(true)
      navigator.clipboard.writeText(value)

      snackbarSuccess()
    } catch (error) {
      snackbarError()
    } finally {
      setIsLoading(false)
    }
  }

  const copyComponentContent = (
    component: Element | null,
    message = 'Assinatura copiada com sucesso'
  ) => {
    try {
      if (!component) throw new Error('Elemento não encontrado')

      setIsLoading(true)
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(component)
      selection?.addRange(range)
      document.execCommand('copy')
      selection?.removeAllRanges()
      setIsLoading(false)
      snackbarSuccess(message)
    } catch (error) {
      snackbarError(
        `Ocorreu um erro ao copiar o elemento. ${error?.toString()?.replace('Error', 'Erro')}`
      )
    }
  }

  const copyImage = (imageUrl: string) => {
    try {
      setIsLoading(true)
      const img = new Image()
      img.src = imageUrl

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = img.width
        canvas.height = img.height

        ctx?.drawImage(img, 0, 0)

        canvas.toBlob((blob) => {
          if (blob) {
            const item = new ClipboardItem({ 'image/png': blob })
            navigator.clipboard.write([item])
          } else {
            throw new Error()
          }
        }, 'image/png')
      }
      snackbarSuccess('Imagem copiada para a área de transferência')
    } catch (error) {
      snackbarError('Ocorreu uma falha inesperada ao tentar copiar a imagem')
    } finally {
      setIsLoading(false)
    }
  }

  const downloadHtmlToImage = async (
    fileName: string,
    htmlContent?: HTMLElement | null,
    width?: number,
    height?: number
  ) => {
    try {
      setIsLoading(true)
      if (!htmlContent) throw new Error()

      const img = await toPng(htmlContent, {
        canvasWidth: width,
        canvasHeight: height,
      })

      const blob = await fetch(img).then((res) => res.blob())

      saveAs(blob, fileName + '.png')

      snackbarSuccess(
        'Imagem gerada com sucesso. Por favor, aguarde o início do download'
      )
    } catch (error) {
      snackbarError(
        'Ocorreu uma falha inesperada ao tentar iniciar o download da imagem'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const downloadImage = (fileName: string, image?: string) => {
    try {
      setIsLoading(true)
      if (!image) throw new Error()

      saveAs(image, fileName)

      snackbarSuccess(
        'Imagem gerada com sucesso. Por favor, aguarde o início do download'
      )
    } catch (error) {
      snackbarError(
        'Ocorreu uma falha inesperada ao tentar iniciar o download da imagem'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return {
    copy,
    copyComponentContent,
    copyImage,
    downloadHtmlToImage,
    downloadImage,
    isLoading,
  }
}

export default useClipboard
