import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Logo, RightBannerBox, RootBox, WrapperBox } from './components'

import service from 'service'
import routes from 'constants/routes'
import DPOEasyLogo from 'images/dpoeasy_logo.svg'

const Auth = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) {
      navigate(routes.root)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <RootBox>
      <WrapperBox>
        <Outlet />
      </WrapperBox>

      <RightBannerBox>
        <Logo width="40%" src={DPOEasyLogo} alt="Logo DPO Easy" />
      </RightBannerBox>
    </RootBox>
  )
}

export default Auth
