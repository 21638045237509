import * as yup from 'yup'
import { t } from 'i18next'
import testValidator from 'helpers/validates/testValidator'

const schema = yup.object({
  plan: yup.string().required(),
  typeCard: yup.boolean().required('Escolha um modo de pagamento'),
  companySize: yup.string().required('Escolha uma opção'),
  address: yup.object().shape({
    street: yup.string().required(),
    number: yup.string().required(),
    complement: yup.string().max(100),
    neighborhood: yup.string(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
  }),
  card: yup.object().shape({
    id: yup.string(),
    name: yup
      .string()
      .test(
        'conditionally-required',
        t('validation.required'),
        testValidator('typeCard')
      ),
    number: yup
      .string()
      .test(
        'conditionally-required',
        t('validation.required'),
        testValidator('typeCard')
      ),
    expiration: yup
      .string()
      .test(
        'conditionally-required',
        t('validation.required'),
        testValidator('typeCard')
      ),
    cvv: yup
      .string()
      .test(
        'conditionally-required',
        t('validation.required'),
        testValidator('typeCard')
      ),
  }),
  privacyNotice: yup
    .bool()
    .oneOf([true], 'Concorde com o Aviso de privacidade')
    .required(),
  terms: yup
    .bool()
    .oneOf([true], 'Concorde com os Termos e condições de uso')
    .required(),
  recaptcha: yup.string().required('Complete o recaptcha'),
})

export default schema
