import form from './form'
import planCard from './planCard'
import trails from './trails'

const checkout = {
  form,
  planCard,
  trails,
}

export default checkout
