import { LineWidthDate } from './lineWithDate.types'

import { ratio } from '../../helpers'
import constants from 'constants/index'
import { theme } from 'theme'

const lineWithDate = ({
  pdf,
  date,
  color = theme.palette.primary.main,
}: LineWidthDate) => {
  const PADDING = constants.jsPdf.PADDING
  const WIDTH_PDF = constants.jsPdf.WIDTH

  const WIDTH = WIDTH_PDF(pdf)
  pdf.setFont('Raleway', 'normal')
  pdf.setFillColor(color)
  pdf.rect(PADDING, PADDING - 20, WIDTH - PADDING * 2, ratio(4), 'F')
  pdf.setFontSize(ratio(44))
  pdf.setTextColor(color)
  pdf.text(date, WIDTH - PADDING, PADDING - 25, undefined, 'right')
}

export default lineWithDate
