const NECESSARY_CATEGORY_ID = 3
const ANALYTICAL_PERFORMANCE_CATEGORY_ID = 5
const FUNCTIONALITY_CATEGORY_ID = 6
const ADVERTISING_CATEGORY_ID = 7
const UNKNOWN_CATEGORY_ID = 10

const NECESSARY_CATEGORY_LABEL = 'Necessários'
const ANALYTICAL_PERFORMANCE_CATEGORY_LABEL = 'Analíticos ou de desempenho'
const FUNCTIONALITY_CATEGORY_LABEL = 'Funcionalidade'
const ADVERTISING_CATEGORY_LABEL = 'Publicidade'
const UNKNOWN_CATEGORY_LABEL = 'Desconhecidos'

const VALID_CATEGORIES = [
  NECESSARY_CATEGORY_ID,
  ANALYTICAL_PERFORMANCE_CATEGORY_ID,
  FUNCTIONALITY_CATEGORY_ID,
  ADVERTISING_CATEGORY_ID,
]

const CATEGORY_OPTIONS = [
  {
    id: NECESSARY_CATEGORY_ID,
    name: NECESSARY_CATEGORY_LABEL,
    disabled: true,
  },
  {
    id: ANALYTICAL_PERFORMANCE_CATEGORY_ID,
    name: ANALYTICAL_PERFORMANCE_CATEGORY_LABEL,
  },
  {
    id: FUNCTIONALITY_CATEGORY_ID,
    name: FUNCTIONALITY_CATEGORY_LABEL,
  },
  {
    id: ADVERTISING_CATEGORY_ID,
    name: ADVERTISING_CATEGORY_LABEL,
  },
  {
    id: UNKNOWN_CATEGORY_ID,
    name: UNKNOWN_CATEGORY_LABEL,
  },
]

export default {
  NECESSARY_CATEGORY_ID,
  ANALYTICAL_PERFORMANCE_CATEGORY_ID,
  FUNCTIONALITY_CATEGORY_ID,
  ADVERTISING_CATEGORY_ID,
  CATEGORY_OPTIONS,
  VALID_CATEGORIES,
}
