import { useEffect } from 'react'

import { OutsideClickHookType } from 'types/hooks.types'

const useOutsideClick = <T extends HTMLElement>({
  ref,
  callback,
}: OutsideClickHookType<T>) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback])
}

export default useOutsideClick
