import protectionIcon from 'images/marketplace/icons/antivirus_protection.png'
import realTimeSecurityIcon from 'images/marketplace/icons/antivirus_realtime.png'
import usabilityIcon from 'images/marketplace/icons/pentest_white_box.png'

import dataSecurityIcon from 'images/marketplace/icons/antivirus_security.png'
import integrityIcon from 'images/marketplace/icons/antivirus_mountain.png'
import conformityIcon from 'images/marketplace/icons/antivirus_law.png'

import antivirusCover from 'images/marketplace/covers/antivirus_cover.png'

const PAGE_ID = 26

const DESCRIPTION_CARDS = [
  {
    title: 'Proteção abrangente',
    imgUrl: protectionIcon,
  },
  {
    title: 'Segurança em tempo real',
    imgUrl: realTimeSecurityIcon,
  },
  {
    title: 'Usabilidade intuitiva',
    imgUrl: usabilityIcon,
  },
]

const BENEFITS_CARDS = [
  {
    title: 'Segurança de dados',
    description:
      'Elimine riscos associados à perda, esquecimento ou compartilhamento inadequado de senhas.',
    imgUrl: dataSecurityIcon,
  },
  {
    title: 'Integridade operacional',
    description:
      'Funcionários não têm conhecimento das senhas, impedindo possíveis vazamentos.',
    imgUrl: integrityIcon,
  },
  {
    title: 'Conformidade e regulamentação',
    description:
      'Ajuda a cumprir requisitos legais e regulamentações de segurança de dados.',
    imgUrl: conformityIcon,
  },
]

const CONTENT_PAGE = {
  id: PAGE_ID,
  name: 'Antivírus e Anti-malware',
  description:
    'Empresas devem ter antivírus para proteger seus dados e sistemas contra ameaças cibernéticas. Essa medida essencial minimiza riscos de ataques, preserva a integridade das informações e assegura a continuidade operacional, garantindo a confiança de clientes e parceiros.',
  info: {
    description:
      'Antivírus é um software que identifica, bloqueia e remove ameaças cibernéticas, como vírus e malware. Utiliza bancos de dados e algoritmos para analisar arquivos em busca de padrões suspeitos, protegendo assim os sistemas contra infecções e garantindo a segurança digital.',
    cards: DESCRIPTION_CARDS,
  },
  indicated: {
    description:
      'Empresas de todos os portes e setores, desde pequenos negócios até grandes corporações, podem se beneficiar ao contratar um antivírus. A proteção contra ameaças cibernéticas é crucial para preservar dados confidenciais, manter a integridade operacional e salvaguardar a reputação da empresa. Setores financeiros, saúde e tecnologia, especialmente, são alvos frequentes, tornando essencial a adoção de soluções de segurança robustas para evitar prejuízos e garantir a confiança de clientes e parceiros.',
    highlights: [
      'Segurança de dados',
      'Integridade operacional',
      'Reputação e confiança',
      'Conformidade e regulamentação',
    ],
    imgUrl: antivirusCover,
  },
  benefits: {
    cards: BENEFITS_CARDS,
  },
}

export default {
  PAGE_ID,
  CONTENT_PAGE,
}
