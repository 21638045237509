import { Cookie as CookieIconMui } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const CookieIcon = styled(CookieIconMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $fontSize?: Property.FontSize<TLength>
  $borderRadius?: Property.BorderRadius<TLength>
  $bgcolor?: Property.BackgroundColor
}>(({ $fontSize, $borderRadius, $bgcolor }) => ({
  fontSize: $fontSize,
  borderRadius: $borderRadius,
  backgroundColor: $bgcolor,
}))

export default CookieIcon
