import { CheckCircle as CheckCircleIcon } from 'react-feather'
import { Box, Grid, Typography } from '@mui/material'

import { MUI } from 'components'

import { ServiceType } from 'types/marketplace/services.types'

import { theme } from 'theme'

const ServiceIndicator = ({ product }: { product: ServiceType }) => {
  return (
    <Grid container spacing={12}>
      <Grid item xs={12} xl={6} display="flex" flexDirection="column" gap={6}>
        <Typography variant="h5" color="primary">
          Para quem é {product?.name}?
        </Typography>
        <Typography color={theme.palette.neutral.main}>
          {product?.indicated?.description}
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {product?.indicated?.highlights?.map((highlight, index) => (
            <Box key={index} display="flex" alignItems="center" gap={2}>
              <Box>
                <CheckCircleIcon size={18} color={theme.palette.success.main} />
              </Box>
              <Typography color={theme.palette.neutral.main}>
                {highlight}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} xl={6} alignContent="center">
        <MUI.Img
          src={product?.indicated?.imgUrl}
          alt={product?.name}
          width="100%"
          $borderRadius={8}
        />
      </Grid>
    </Grid>
  )
}

export default ServiceIndicator
